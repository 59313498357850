<h2 mat-dialog-title>Change Password</h2>
<mat-dialog-content class="mat-typography">
  <h3>Create a new, strong password that you don't use for other websites</h3>
  <form
    fxLayout="column"
    fxLayoutAlign="start stretch"
    [formGroup]="changePasswordForm"
    (ngSubmit)="changePasswordPressed()"
  >
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        required
      />
      <mat-error
        *ngIf="changePasswordForm.controls['password'].hasError('required')"
        >Password is required</mat-error
      >
      <mat-error
        *ngIf="changePasswordForm.controls['password'].hasError('minlength')"
        >Password needs to be at least 8 character long</mat-error
      >
      <mat-error
        *ngIf="
          changePasswordForm.controls['password'].hasError('minlength') ==
            false &&
          changePasswordForm.controls['password'].hasError('required') ==
            false &&
          changePasswordForm.controls['password'].hasError('strong')
        "
        >Password needs to have at least a number, uppercase letter, and
        lowercase letter</mat-error
      >
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="hidePassword = !hidePassword"
      >
        <mat-icon>{{
          hidePassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="margin-bottom-16">
      <mat-label>Confirm Password</mat-label>
      <input
        matInput
        [type]="hideConfirmPassword ? 'password' : 'text'"
        formControlName="confirmPassword"
        required
      />
      <mat-error
        *ngIf="
          changePasswordForm.controls['confirmPassword'].hasError('required')
        "
        >Confirm Password is required</mat-error
      ><mat-error
        *ngIf="
          changePasswordForm.controls['confirmPassword'].hasError(
            'NoPassswordMatch'
          )
        "
        >Password does not match</mat-error
      >
      <button
        mat-icon-button
        matSuffix
        type="button"
        (click)="hideConfirmPassword = !hideConfirmPassword"
      >
        <mat-icon>{{
          hideConfirmPassword ? "visibility" : "visibility_off"
        }}</mat-icon>
      </button>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-button
    color="primary"
    cdkFocusInitial
    [mat-dialog-close]="passwordChanged"
    (click)="changePasswordPressed()"
    type="submit"
  >
    Change password
  </button>
</mat-dialog-actions>
