<form
  [formGroup]="yearForm"
  class="margin-top-16 margin-bottom-24"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title
      title="Choose Year"
      *ngIf="!isMobile"
    ></app-section-title>
    <mat-grid-list [cols]="isMobile ? 2 : 3" rowHeight="2:1" gutterSize="16px">
      <mat-grid-tile
        class="year-tile"
        *ngFor="let year of years"
        (click)="
          yearIsNotAvailable(year) || yearIsLoading ? null : yearSelected(year)
        "
        [ngClass]="{
          selected: isSelectedYear(year),
          'year-created': yearIsNotAvailable(year),
          mobile: isMobile,
          shimmer: yearIsLoading
        }"
        [matTooltip]="yearIsNotAvailable(year) ? 'Year already created' : ''"
        >{{ year }}</mat-grid-tile
      >
    </mat-grid-list>
  </div>
</form>
