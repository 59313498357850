<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24"
  fxLayoutGap="32px"
>
  <div>
    <button mat-stroked-button [routerLink]="['/requests']" class="back-button">
      <mat-icon>arrow_back</mat-icon>
      <span>Requests</span>
    </button>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="32px">
    <div
      fxLayout="column"
      fxLayoutGap="32px"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      [ngClass]="isMobile ? 'margin-bottom-40' : ''"
    >
      <div>
        <app-section-title title="Request Details"></app-section-title>
        <!-- Received At -->
        <p class="bold">Received At</p>
        <p>
          <span *ngIf="!isLoading">{{
            request.createdDate | date: "medium"
          }}</span>
        </p>
      </div>

      <!-- Title -->
      <div>
        <p class="bold">Title</p>
        <p>
          <span *ngIf="!isLoading">{{ request.title }}</span>
        </p>
      </div>

      <!-- Sender -->
      <div>
        <p class="bold">Requester</p>
        <p>
          <span *ngIf="!isLoading">Name: {{ request.user?.name }}</span
          ><br />
          <span *ngIf="!isLoading">Email: {{ request.user?.email }}</span>
          <br />
          <span *ngIf="!isLoading"
            >Phone Number: {{ request.user?.handphoneNumber }}</span
          >
        </p>
      </div>

      <!-- Remarks -->
      <div>
        <p class="bold">Remarks</p>
        <p>
          <span *ngIf="!isLoading">{{ request.remarks }}</span>
        </p>
      </div>

      <!-- Attachments -->
      <div *ngIf="!isLoading && request.fileName !== null">
        <p class="bold">Attachments</p>
        <button mat-stroked-button (click)="downloadAttachmentPressed()">
          <mat-icon>download</mat-icon>
          <span class="margin-left-8">{{ request.fileName }}</span>
        </button>
      </div>
    </div>
    <mat-divider vertical *ngIf="!isMobile"></mat-divider>
    <form
      [formGroup]="requestStatusForm"
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      (ngSubmit)="updateStatusPressed()"
    >
      <app-section-title title="Manage Request"></app-section-title>
      <!-- Status -->
      <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option
            *ngFor="let status of statuses"
            [value]="status.value"
            [disabled]="isStatusOptionDisabled(status)"
          >
            {{ status.valueName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="requestStatusForm.controls['status'].hasError('required')"
        >
          Status is required
        </mat-error>
      </mat-form-field>

      <!-- Department -->
      <mat-form-field
        appearance="outline"
        (click)="$event.stopPropagation()"
        fxHide
        [fxShow]="!statusIsPending"
      >
        <mat-label>Department</mat-label>
        <mat-select formControlName="department">
          <mat-option
            *ngFor="let department of departments"
            [value]="department.value"
            [disabled]="isDepartmentOptionDisabled(department)"
          >
            {{ department.valueName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="requestStatusForm.controls['department'].hasError('required')"
        >
          Department is required
        </mat-error>
      </mat-form-field>

      <!-- User/ Personnel -->
      <mat-form-field
        appearance="outline"
        (click)="$event.stopPropagation()"
        fxHide
        [fxShow]="!statusIsAssignedDepartment && !statusIsPending"
      >
        <mat-label>Assign to</mat-label>
        <mat-select formControlName="personnel">
          <mat-option
            *ngFor="let personnel of personnels"
            [value]="personnel.id"
          >
            {{ personnel.name }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="requestStatusForm.controls['personnel'].hasError('required')"
        >
          Personnel is required
        </mat-error>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="end" class="margin-top-16">
        <button
          mat-raised-button
          class="uppercase"
          color="primary"
          type="submit"
          [disabled]="isLoading || refreshNeeded"
        >
          Update
        </button>
      </div>
    </form>
  </div>
</section>
