import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Request } from 'src/app/shared/models/request/request.model';

@Component({
  selector: 'app-delete-request-dialog',
  templateUrl: './delete-request-dialog.component.html',
  styleUrls: ['./delete-request-dialog.component.scss'],
})
export class DeleteRequestDialogComponent implements OnInit {
  request: Request;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.request = data.request;
  }

  ngOnInit(): void {}
}
