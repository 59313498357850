<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Users"
      subtitle="Manage all the users in the system"
    ></app-page-title>
    <button
      class="extended-fab-button"
      mat-fab
      color="primary"
      routerLink="/users/create"
    >
      <mat-icon>add</mat-icon>
      <span class="extended-fab-button__text">User</span>
    </button>
  </div>

  <!-- Search and Filter -->
  <app-users-filter></app-users-filter>

  <!-- Table -->
  <mat-table
    [fxHide]="!users.length"
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="margin-bottom-16"
  >
    <!-- User ID -->
    <ng-container matColumnDef="id">
      <mat-header-cell mat-sort-header="id" *matHeaderCellDef>
        ID
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">{{ data.id }}</mat-cell>
    </ng-container>

    <!-- User's Name -->
    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header="name" *matHeaderCellDef>
        Name
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data"
        ><span class="ellipsis" [matTooltip]="data.name">{{
          data.name
        }}</span></mat-cell
      >
    </ng-container>

    <!-- Email -->
    <ng-container matColumnDef="email">
      <mat-header-cell mat-sort-header="email" *matHeaderCellDef>
        Email
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data" class="padding-right-16"
        ><span class="ellipsis" [matTooltip]="data.email">{{
          data.email
        }}</span></mat-cell
      >
    </ng-container>

    <!-- ID Type -->
    <ng-container matColumnDef="identificationType">
      <mat-header-cell mat-sort-header="identificationType" *matHeaderCellDef>
        ID Type
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="data.identificationType == null ? 'not-available' : ''"
        ><span
          class="ellipsis"
          matTooltip="{{
            data.identificationType != null
              ? data.identificationType.identificationType
              : 'Not available'
          }}"
          >{{
            data.identificationType != null
              ? data.identificationType.identificationType
              : "Not available"
          }}</span
        ></mat-cell
      >
    </ng-container>

    <!-- Role -->
    <ng-container matColumnDef="role">
      <mat-header-cell mat-sort-header="role" *matHeaderCellDef>
        Role
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data" class="padding-right-16">
        <span class="ellipsis">
          <span
            class="capitalize"
            *ngFor="let role of data.roles; let isLast = last"
            [matTooltip]="role.role"
            >{{ role.role.toLowerCase() }}{{ isLast ? "" : ", " }}</span
          >
        </span>
      </mat-cell>
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="status">
      <mat-header-cell mat-sort-header="status" *matHeaderCellDef>
        Status
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [matTooltip]="data.active ? 'Active' : 'Inactive'"
      >
        {{ data.active ? "Active" : "Inactive" }}
      </mat-cell>
    </ng-container>

    <!-- Created Date -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-sort-header="createdDate" *matHeaderCellDef>
        Created Date
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        matTooltip="{{ data.createdDate | date }}"
      >
        {{ data.createdDate | date }}
      </mat-cell>
    </ng-container>

    <!-- More info -->
    <ng-container matColumnDef="options">
      <mat-header-cell *matHeaderCellDef>
        <span class="text-align-center">More info</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['edit', data.id]">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="showDeleteDialog(data)">
            <mat-icon>delete_outline</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <div
    *ngIf="!users.length"
    class="empty-table-message"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    Could not find any data
  </div>
  <mat-paginator
    #paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = onPageChanged($event)"
  >
  </mat-paginator>
</section>
