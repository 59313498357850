<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/entities/details', customerEntityDetails?.id]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span>{{
          customerEntityDetails && !isLoading
            ? customerEntityDetails.name
            : "Loading"
        }}</span>
      </button>
      <app-page-title
        title="Files — {{
          entityYearDetails && !isLoading ? entityYearDetails.year : 'Loading'
        }}"
        [subtitle]="
          currentService !== 'shared_services'
            ? currentService
            : 'Shared Services'
        "
        [subtitleCapitalize]="true"
      ></app-page-title>
    </div>
  </div>

  <app-file-organizer
    class="margin-bottom-40"
    [clientType]="currentService"
    [yearID]="entityYearDetails.id"
    [isCreateUpdate]="false"
    *ngIf="!isLoading"
  ></app-file-organizer>
</section>
