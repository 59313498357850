<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/profile']"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span>Profile</span>
      </button>
      <app-page-title
        title="Edit Profile"
        subtitle="Edit your information to stay updated"
        ngClass.xs="margin-bottom-16"
      ></app-page-title>
    </div>
  </div>

  <form
    [formGroup]="editProfileForm"
    fxLayout="row wrap"
    fxLayoutGap="40px"
    fxLayoutGap.xs="0px"
  >
    <!-- Account Information Section -->
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <app-section-title title="Account Information"></app-section-title>
      <!-- Roles -->
      <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
        <mat-label>Roles</mat-label>
        <mat-select formControlName="roles" multiple required>
          <mat-option
            *ngFor="let role of roles"
            [value]="role.role"
            [disabled]="isOptionDisabled(role)"
          >
            {{ role.role }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="editProfileForm.controls['roles'].hasError('required')"
        >
          At least 1 role is required
        </mat-error>
      </mat-form-field>

      <!-- Branch -->
      <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
        <mat-label>Branch</mat-label>
        <mat-select formControlName="branch" required>
          <mat-option
            *ngFor="let branch of branches"
            [value]="branch.valueName"
          >
            {{ branch.valueName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="editProfileForm.controls['branch'].hasError('required')"
        >
          Branch is required
        </mat-error>
      </mat-form-field>

      <!-- Department -->
      <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
        <mat-label>Department</mat-label>
        <mat-select formControlName="department" required>
          <mat-option
            *ngFor="let department of departments"
            [value]="department.value"
          >
            {{ department.valueName }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="editProfileForm.controls['department'].hasError('required')"
        >
          Department is required
        </mat-error>
      </mat-form-field>

      <!-- Email -->
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" required />
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-error *ngIf="editProfileForm.controls['email'].hasError('email')">
          Please enter a valid email address
        </mat-error>
        <mat-error
          *ngIf="editProfileForm.controls['email'].hasError('required')"
        >
          Email is required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Personal Information Section -->
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <!-- Personal Information Section -->
      <app-section-title title="Personal Information"></app-section-title>
      <!-- Name -->
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
        <mat-error
          *ngIf="editProfileForm.controls['name'].hasError('required')"
        >
          Name is required
        </mat-error>
      </mat-form-field>
    </div>
  </form>
  <div class="bottom-action-bar" fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      (click)="saveProfilePressed()"
    >
      Save
    </button>
  </div>
</section>
