import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RequestFormConstants {
  public STATUSES = [
    { value: 'PENDING', valueName: 'Pending' },
    { value: 'ASSIGNED_TO_DEPARTMENT', valueName: 'Assigned to Department' },
    { value: 'ASSIGNED_TO_PERSONNEL', valueName: 'Assigned to Personnel' },
    { value: 'COMPLETED', valueName: 'Completed' },
  ];
}
