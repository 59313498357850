import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { Article } from 'src/app/shared/models/article/article.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { User } from 'src/app/shared/models/user/user.model';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { environment } from 'src/environments/environment';
import { DeleteArticleDialogComponent } from '../delete-article-dialog/delete-article-dialog.component';

@Component({
  selector: 'app-edit-article',
  templateUrl: './edit-article.component.html',
  styleUrls: ['./edit-article.component.scss'],
})
export class EditArticleComponent implements OnInit {
  // Form
  articleForm!: FormGroup;
  selectedCategories: Array<string> = [];
  pendingImages: PendingFile[] = [];
  existingImageUrl!: string;

  // User's Role
  isAdmin = false;
  isCustodian = false;
  isAuthor = false;

  // Loading
  isLoading = false;
  isSaving = false;
  isDeleting = false;
  isUploading = false;
  isUploaded = false;

  // Variables
  user!: User;
  article!: Article;

  // Subscription
  userSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private userAccessService: UserAccessService,
    private authenticationService: AuthenticationService,
    private articlesService: ArticlesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private jwtService: JwtService,
    private datepipe: DatePipe,
    private dialog: MatDialog
  ) {
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            break;
        }
      },
    });
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
  }

  ngOnInit(): void {
    this.setupArticleForm();
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getArticle(id);
      },
    });
  }

  setupArticleForm(): void {
    this.articleForm = this.formBuilder.group({
      imageFile: new FormControl(''),
      mainTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
      ]),
      shortDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
      ]),
      fullDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(11000),
      ]),
      categories: new FormControl(null, Validators.required),
      engagementTitle: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
      ]),
      engagementDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(254),
      ]),
      engagementButtonLabel: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      referenceLink: new FormControl('', [
        Validators.pattern(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ),
        Validators.maxLength(200),
      ]),
    });
  }

  getArticle(id: number): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: 3000 });
    this.articlesService.getArticleDetails(id).subscribe({
      next: (article: Article) => {
        this.isLoading = false;
        this.article = article;
        if (this.article.author.id === this.user.id) {
          this.isAuthor = true;
        } else {
          this.isAuthor = false;
        }
        this.prefillArticleForm();
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.isLoading = false;
      },
    });
  }

  prefillArticleForm(): void {
    // Fill up article category list
    if (this.article.categories?.length) {
      const categories: Array<string> = [];
      this.article.categories.forEach((category: ArticleCategory) => {
        categories.push(category.name);
      });

      this.selectedCategories = categories;

      this.articleForm.controls.categories.setErrors(null);
    }

    if (this.article.fileUrl) {
      this.existingImageUrl = `${environment.baseUrl}/api/core/articles/downloadFile/${this.article.id}`;
    }

    // Fill up form
    this.articleForm.patchValue({
      mainTitle: this.article.mainTitle,
      shortDescription: this.article.shortDescription,
      fullDescription: this.article.fullDescription,
      engagementTitle: this.article.engagementTitle,
      engagementDescription: this.article.engagementDescription,
      engagementButtonLabel: this.article.engagementButtonLabel,
      referenceLink: this.article.referenceLink,
    });
  }

  savePressed(isPublish?: boolean): void {
    if (this.articleForm.invalid) {
      this.snackBar.open('Please fill in the required field', '', {
        duration: 3000,
      });
      this.articleForm.markAllAsTouched();
    } else {
      const formData = {
        isPublish,
        publishedDate:
          isPublish === true
            ? this.datepipe.transform(Date.now(), 'yyy-MM-dd 00:00:00')
            : null,
        mainTitle: this.articleForm.get('mainTitle')?.value,
        shortDescription: this.articleForm.get('shortDescription')?.value,
        fullDescription: this.articleForm.get('fullDescription')?.value,
        categories: this.selectedCategories,
        authorId: this.user.id,
        engagementTitle: this.articleForm.get('engagementTitle')?.value,
        engagementDescription: this.articleForm.get('engagementDescription')
          ?.value,
        engagementButtonLabel: this.articleForm.get('engagementButtonLabel')
          ?.value,
        referenceLink: this.articleForm.get('referenceLink')?.value,
      };

      this.isSaving = true;
      this.articleForm.disable();
      this.snackBar.open('Saving', '', {
        duration: undefined,
      });

      this.articlesService.updateArticle(formData, this.article.id).subscribe({
        next: (updatedArticle: Article) => {
          if (this.pendingImages.length > 0) {
            this.uploadFiles(updatedArticle);
          } else {
            this.isSaving = false;
            this.snackBar.dismiss();
            this.router.navigateByUrl(`/articles/details/${updatedArticle.id}`);
          }
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isSaving = false;
        },
      });
    }
  }

  uploadFiles(updatedArticle: Article): void {
    this.isUploading = true;
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingImages.length; index++) {
      const file: PendingFile = this.pendingImages[index];
      formData.append(file.folderName, file.file);
    }

    this.articlesService
      .uploadArticleImage(formData, this.article.id)
      .subscribe({
        next: (_: Article) => {
          this.isUploading = false;
          this.isUploaded = true;
          this.jwtService.destroyContentType();
          this.isSaving = false;
          this.snackBar.dismiss();
          this.router.navigateByUrl(`/articles/details/${updatedArticle.id}`);
        },
        error: (error) => {
          console.log(error);
          this.isUploading = false;
          this.isUploaded = false;
          this.isSaving = false;
          this.articleForm.enable();
          this.articleForm.controls.categories.setErrors(null);
          this.snackBar.open(error, '', { duration: 3000 });
          this.jwtService.destroyContentType();
        },
      });
  }

  showDeleteArticleDialog(): void {
    const dialogRef = this.dialog.open(DeleteArticleDialogComponent, {
      data: { article: this.article },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteArticle();
        }
      },
    });
  }

  deleteArticle(): void {
    this.isDeleting = true;
    this.snackBar.open('Deleting Article', '', { duration: undefined });
    this.articleForm.disable();

    this.articlesService.deleteArticle(this.article.id).subscribe({
      next: (_) => {
        this.snackBar.dismiss();
        this.router.navigateByUrl('/articles');
        this.snackBar.open(`Article ${this.article.id} deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        console.log(error);
        this.isDeleting = false;
        this.articleForm.enable();
        this.articleForm.controls.categories.setErrors(null);
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }
}
