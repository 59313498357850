import { MatSnackBar } from '@angular/material/snack-bar';
import { UserAccessService } from './../../../shared/user-access/user-access.service';
import { UserFormConstants } from './../../../shared/constants/user-form.constants';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { User } from 'src/app/shared/models/user/user.model';
import { Role } from 'src/app/shared/models/role/role.model';
import { UserRole } from 'src/app/shared/enums/user-role.enum';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  isMobile = false;
  isTablet = false;
  isLargeScreen = false;
  isXLargeScreen = false;
  roles: Array<Role> = [];
  user!: User;
  isLoading = false;
  isAdmin = false;
  isCustodian = false;
  isPersonnel = false;
  isCustomer = false;

  constructor(
    private customBreakPointService: CustomBreakpointService,
    private userAccessService: UserAccessService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
    this.userAccessService.isLoadingUser.subscribe({
      next: (isLoading: boolean) => {
        this.isLoading = isLoading;
        if (isLoading) {
          this.snackBar.open('Loading drawer content', '', {
            duration: undefined,
          });
        } else {
          this.snackBar.dismiss();
        }
      },
    });
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          case UserRole.personnel:
            this.isPersonnel = true;
            break;
          case UserRole.customer:
            this.isCustomer = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            this.isPersonnel = false;
            this.isCustomer = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.customBreakPointService.isDevice$.subscribe({
      next: (device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            this.isTablet = false;
            this.isLargeScreen = false;
            this.isXLargeScreen = false;
            break;
          case Device.tablet:
            this.isMobile = false;
            this.isTablet = true;
            this.isLargeScreen = false;
            this.isXLargeScreen = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            this.isTablet = false;
            this.isLargeScreen = true;
            this.isXLargeScreen = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            this.isTablet = false;
            this.isLargeScreen = false;
            this.isXLargeScreen = true;
            break;
          default:
            break;
        }
      },
    });
  }
}
