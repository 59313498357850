import { Injectable } from '@angular/core';
import { Department } from '../models/department/department.model';
import { EventType } from '../models/event-type/event-type.model';
import { Gender } from '../models/gender/gender.model';
import { IdentificationType } from '../models/identification-type/identification-type.model';
import { ProfessionalType } from '../models/professional-type/professional-type.model';
import { Role } from '../models/role/role.model';
import { ShareType } from '../models/share-type/share-type.model';

@Injectable({
  providedIn: 'root',
})
export class UserFormConstants {
  public MALAYSIAN_IC_REGEX = '^\\d{6}\\-\\d{2}\\-\\d{4}$';
  public STATUSES = [
    { value: 1, valueName: 'Active' },
    { value: 0, valueName: 'Inactive' },
  ];
  public DEPARTMENTS: Department[] = [
    { value: 'TAX', valueName: 'Tax' } as Department,
    { value: 'SECRETARIAL', valueName: 'Secretarial' } as Department,
    { value: 'SHARED_SERVICES', valueName: 'Shared Services' } as Department,
    { value: 'AUDIT', valueName: 'Audit' } as Department,
  ];
  public BRANCHES = [{ value: 1, valueName: 'HQ' }];
  public ROLES: Array<Role> = [
    {
      id: 1,
      role: 'Admin',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as Role,
    {
      id: 2,
      role: 'Custodian',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as Role,
    {
      id: 3,
      role: 'Personnel',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as Role,
    {
      id: 4,
      role: 'Customer',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as Role,
  ];
  public IDTYPE: Array<IdentificationType> = [
    {
      id: 1,
      type: 'IC',
      value: 'IC',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as IdentificationType,
    {
      id: 2,
      type: 'Passport',
      value: 'PASSPORT',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as IdentificationType,
    {
      id: 3,
      type: 'Company ID',
      value: 'COMP_ID',
      createdBy: undefined,
      createdDate: undefined,
      lastModifiedBy: undefined,
      lastModifiedDate: undefined,
    } as IdentificationType,
  ];
  public GENDER: Array<Gender> = [
    {
      value: true,
      name: 'Male',
    } as Gender,
    {
      value: false,
      name: 'Female',
    } as Gender,
  ];
  public PROFESSIONAL_TYPES: Array<ProfessionalType> = [
    {
      value: 'MAICSA',
      type: 'MAICSA',
    } as ProfessionalType,
    {
      value: 'LS',
      type: 'LS',
    } as ProfessionalType,
  ];
  public TYPES_OF_SHARE: Array<ShareType> = [
    {
      value: true,
      type: 'Cash',
    } as ShareType,
    {
      value: false,
      type: 'Non-Cash',
    } as ShareType,
  ];
  public TYPES_OF_EVENT: Array<EventType> = [
    {
      value: 'ALLOT',
      type: 'Allot',
    } as EventType,
    {
      value: 'TRANSFERRED',
      type: 'Transferred',
    } as EventType,
  ];
}
