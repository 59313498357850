<h2 mat-dialog-title>New Request</h2>
<mat-dialog-content class="mat-typography">
  <form
    [formGroup]="customerRequestForm"
    fxLayout="column"
    fxLayoutAlign="stretch"
  >
    <mat-form-field appearance="outline" fxFlex="calc(100%)" class="width-100">
      <mat-label>Title</mat-label>
      <input matInput type="text" formControlName="title" required />
      <mat-error
        *ngIf="customerRequestForm.controls['title'].hasError('required')"
      >
        Title is required
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="calc(100%)">
      <mat-label>Remarks</mat-label>
      <textarea
        matInput
        type="text"
        formControlName="remarks"
        rows="4"
        #remarks
        required
      ></textarea>
      <mat-hint align="end">{{ remarks.value?.length || 0 }}/255</mat-hint>
      <mat-error
        *ngIf="customerRequestForm.controls['remarks'].hasError('required')"
      >
        Remarks is required
      </mat-error>
    </mat-form-field>
    <div
      fxLayout="column"
      fxLayoutAlign="stretch"
      class="margin-top-16 margin-bottom-32"
    >
      <input
        formControlName="file"
        type="file"
        class="file-input"
        (change)="uploadFilePressed($event)"
        accept="image/*, .pdf, .doc, .docx, .txt, .xls,.xlxs"
        #fileUpload
      />
      <button
        (click)="fileUpload.click()"
        mat-stroked-button
        type="button"
        [color]="pendingFiles.length ? 'normal' : 'primary'"
      >
        <mat-icon class="material-icons-outlined">{{
          pendingFiles.length ? "image" : "upload"
        }}</mat-icon>
        <span class="margin-left-8">{{
          pendingFiles.length ? pendingFiles[0].file.name : "Upload File"
        }}</span>
      </button>
    </div>
  </form>
  <!-- Start Status -->
  <div
    class="padding-16 loading-status-container border-radius-8"
    fxLayoutAlign="space-between center"
    *ngIf="isCreatingRequest || isUploadingFile"
  >
    <p *ngIf="isCreatingRequest" class="no-margin primary-color">
      Creating request
    </p>
    <p *ngIf="isUploadingFile" class="no-margin primary-color">
      Attaching file to request
    </p>
    <mat-spinner diameter="18"></mat-spinner>
  </div>
  <div
    class="padding-16 failed-status-container border-radius-8"
    *ngIf="failedToUploadFile"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
  >
    <mat-icon>error_outline</mat-icon>
    <p class="no-margin">Failed to attach file. Try uploading it again.</p>
  </div>
  <!-- End Status -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary" cdkFocusInitial (click)="submitPressed()">
    Submit
  </button>
</mat-dialog-actions>
