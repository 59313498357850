<mat-toolbar *ngIf="isMobile">
  <mat-toolbar-row>
    <button mat-icon-button (click)="backButtonPressed()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <span>{{
      showEmailSent ? "Password Reset Link Sent" : "Forgot Password"
    }}</span>
  </mat-toolbar-row>
</mat-toolbar>
<section
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.min-height.px]="contentMinHeight$ | async"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="56px"
    class="padding-24"
    [style.width.px]="contentMaxWidth$ | async"
  >
    <!-- Email Form -->
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="width-100"
      *ngIf="!showEmailSent"
    >
      <div
        fxLayout="row"
        fxLayoutGap="32px"
        fxLayoutAlign="start start"
        class="margin-bottom-40"
        *ngIf="!isMobile"
      >
        <button mat-icon-button (click)="backButtonPressed()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <div fxLayout="column">
          <h1 class="no-margin">Forgot Password</h1>
          <p>
            Tell us your email and we will send you a link to reset your
            password
          </p>
        </div>
      </div>
      <div *ngIf="isMobile" class="margin-bottom-40">
        <h4>
          Tell us your email and we will send you a link to reset your password
        </h4>
      </div>
      <form
        [formGroup]="forgotPasswordForm"
        fxLayout="column"
        fxLayoutAlign="start stretch"
        (ngSubmit)="sendResetPassword()"
      >
        <mat-form-field appearance="outline" class="margin-bottom-16">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required />
          <mat-error
            *ngIf="forgotPasswordForm.controls['email'].hasError('email')"
          >
            Please enter a valid email address
          </mat-error>
          <mat-error
            *ngIf="forgotPasswordForm.controls['email'].hasError('required')"
          >
            Email is required
          </mat-error>
        </mat-form-field>
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="isSubmitting"
        >
          Submit
        </button>
      </form>
    </div>

    <!-- Email Sent -->
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="width-100"
      *ngIf="showEmailSent"
      fxLayoutGap="24px"
    >
      <div
        fxLayout="row"
        fxLayoutGap="32px"
        fxLayoutAlign="start center"
        class="margin-bottom-40"
        *ngIf="!isMobile"
      >
        <button mat-icon-button (click)="backButtonPressed()">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <h1 class="no-margin">Password Reset Link Sent</h1>
      </div>
      <p class="text-align-center">
        An email has been sent to <b>{{ email }}</b
        >. Follow the directions in the email to reset your password.
      </p>
      <button mat-raised-button color="primary" (click)="backButtonPressed()">
        Back to Login
      </button>
    </div>
  </div>
</section>
