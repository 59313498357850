<div fxLayout="column">
  <p class="bold-500">Weekly Stats</p>
  <div fxLayout="row wrap" fxLayoutGap="16px grid" class="width-100">
    <!-- Start Weekly Stats -->
    <div
      fxFlex="calc(33% - 16px)"
      fxFlex.xs="calc(100%)"
      fxFlex.sm="calc(50% - 16px)"
      fxFlex.md="calc(50% - 16px)"
      fxFlex.lg="calc(50% - 16px)"
      fxFlex.xl="calc(33% - 16px)"
      *ngFor="let weeklyStat of weeklyStats"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px"
        class="padding-16 border border-color border-radius-8"
      >
        <div>
          <p class="value">{{ weeklyStat.count }}</p>
          <p>new {{ weeklyStat.module }}</p>
        </div>
        <a
          mat-stroked-button
          color="primary"
          class="uppercase"
          [routerLink]="['/', weeklyStat.module]"
        >
          View {{ weeklyStat.module }}
        </a>
      </div>
    </div>
    <!-- End Weekly Stats -->
    <!-- Start dummy -->
    <div fxFlex="calc(50% - 16px)"></div>
    <!-- End dummy -->
  </div>
</div>
