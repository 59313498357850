<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24"
  fxFlex
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button mat-stroked-button [routerLink]="['/users']" class="back-button">
        <mat-icon>arrow_back</mat-icon>
        <span>Users</span>
      </button>
      <app-page-title
        title="Edit User"
        subtitle="Update your users' data for better "
      ></app-page-title>
    </div>
  </div>
  <app-user-form
    class="margin-bottom-40"
    [userForm]="editUserForm"
    [isCustomerForm]="isCustomerForm$"
    [selectedClients]="selectedClients"
  ></app-user-form>
  <div
    class="bottom-action-bar"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
  >
    <button mat-stroked-button class="uppercase" (click)="showDeleteDialog()">
      Delete
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      (click)="saveUserPressed()"
    >
      Save
    </button>
  </div>
</section>
