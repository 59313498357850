import { ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { Device } from 'src/app/shared/enums/device.enum';
import { MsicCode } from 'src/app/shared/models/msic-code/msic-code.model';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-year-client-details-form',
  templateUrl: './year-client-details-form.component.html',
  styleUrls: ['./year-client-details-form.component.scss'],
})
export class YearClientDetailsFormComponent implements OnInit, OnDestroy {
  // Form
  @Input() formTitle = 'Add Year Details';
  @Input() yearDetailsForm!: FormGroup;
  @Input() stepper!: MatStepper;
  @Input() selectedMsicCodes!: Array<{ code: string }>;
  @Input() isSecretarial = false;
  @Input() isTax = false;
  @Input() isAudit = false;
  @Input() isSharedServices = false;
  @Input() isFreelancer!: BehaviorSubject<boolean>;

  // HTML Element Ref
  @ViewChild('msicCodeInput')
  msicCodeInput!: ElementRef<HTMLInputElement>;

  // Loading
  isMatchingMsicCode = false;
  isMatchingSecretary = false;

  // Chips
  separatorKeysCodes: number[] = [ENTER];

  // Dropdown Lists
  msicOptions: BehaviorSubject<Array<MsicCode>> = new BehaviorSubject<
    Array<MsicCode>
  >([]);
  commonSealOptions: Array<{ value: boolean; valueName: string }>;
  stocktakeOptions: Array<{ value: boolean; valueName: string }>;
  registerOfficeOptions: Array<{ value: boolean; valueName: string }>;
  nameTagOptions: Array<{ value: boolean; valueName: string }>;
  serviceTypeOptions: Array<{ value: string; valueName: string }>;
  msicCodes!: Array<string>;
  secretaryOptions: BehaviorSubject<Array<User>> = new BehaviorSubject<
    Array<User>
  >([]);

  // Subscriptions
  msicCodeFieldSubscription!: Subscription;
  secretaryNameFieldSubscription!: Subscription;

  // Responsive
  isMobile = true;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private clientsService: ClientsService,
    private snackBar: MatSnackBar,
    private clientFormConstants: ClientFormConstants
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.commonSealOptions = this.clientFormConstants.COMMON_SEAL;
    this.stocktakeOptions = this.clientFormConstants.STOCKTAKE;
    this.registerOfficeOptions = this.clientFormConstants.REGISTER_OFFICE;
    this.nameTagOptions = this.clientFormConstants.NAME_TAG;
    this.serviceTypeOptions = this.clientFormConstants.SERVICE_TYPES;
  }
  ngOnDestroy(): void {
    this.msicCodeFieldSubscription.unsubscribe();
    if (this.isSecretarial || this.isAudit) {
      this.secretaryNameFieldSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.subscribeToMsicCode();
    if (this.isSecretarial || this.isAudit) {
      this.getMatchingSecretaryName('a', true);
      this.subscribeToSecretaryName();
    }
  }

  selectablePastDates(date: Date | null): boolean {
    const now: Date = new Date();
    const today: Date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const selectedDate: Date = date as Date;
    return selectedDate <= today;
  }

  selectableFutureDates(date: Date | null): boolean {
    const now: Date = new Date();
    const today: Date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const selectedDate: Date = date as Date;
    return selectedDate >= today;
  }

  subscribeToSecretaryName(): void {
    this.secretaryNameFieldSubscription =
      this.yearDetailsForm.controls.secretaryName.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe({
          next: (secretaryName: string) => {
            this.getMatchingSecretaryName(secretaryName, false);
          },
        });
  }

  getMatchingSecretaryName(secretaryName: string, isInit: boolean): void {
    this.isMatchingSecretary = true;
    const nospaceSecretaryName: string = secretaryName?.replace(/\s/g, '');

    this.clientsService
      .getMatchingSecretaryName(nospaceSecretaryName ? secretaryName : 'a')
      .subscribe({
        next: (secretaryNameList: any) => {
          if (secretaryNameList) {
            this.secretaryOptions.next(secretaryNameList.secretary);
            if (isInit) {
              this.yearDetailsForm.patchValue({
                secretaryName: this.secretaryOptions.value[0].name,
              });
            }
          } else {
            this.secretaryOptions.next([]);
          }
          this.isMatchingSecretary = false;
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isMatchingSecretary = false;
        },
      });
  }

  subscribeToMsicCode(): void {
    this.msicCodeFieldSubscription =
      this.yearDetailsForm.controls.msicCode.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe({
          next: (msic: string) => {
            this.getMatchingMsicCode(msic);
          },
        });
  }

  getMatchingMsicCode(msic: string): void {
    this.isMatchingMsicCode = true;
    // Remove spaces
    const nospaceMSIC: string = msic?.replace(/\s/g, '');
    if (nospaceMSIC?.length > 0) {
      // Get MSIC Code
      this.clientsService.getMsicCode(msic).subscribe({
        next: (msicCodes: any) => {
          if (msicCodes) {
            this.msicOptions.next(msicCodes.msiccodes);
          } else {
            this.msicOptions.next([]);
            this.yearDetailsForm.controls.msicCode.setErrors({
              notAvailable: true,
            });
          }
          this.isMatchingMsicCode = false;
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isMatchingMsicCode = false;
        },
      });
    } else {
      // When input is empty
      this.isMatchingMsicCode = false;
      this.msicOptions.next([]);
      this.yearDetailsForm.patchValue({
        msicCode: null,
      });

      // Check if there is existing msic codes selected
      // Clear errors if there is
      if (this.selectedMsicCodes.length > 0) {
        this.yearDetailsForm.controls.msicCode.setErrors(null);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const existingMsicCode = this.selectedMsicCodes.some(
      (selectedMsic) => selectedMsic.code === event.option.value
    );

    if (!existingMsicCode) {
      this.selectedMsicCodes.push({ code: event.option.value });
    }

    this.msicCodeInput.nativeElement.value = '';
  }

  removeMsicCode(msicCode: { code: string }): void {
    const index = this.selectedMsicCodes.indexOf(msicCode);

    if (index >= 0) {
      this.selectedMsicCodes.splice(index, 1);
    }

    if (this.selectedMsicCodes.length < 1) {
      this.yearDetailsForm.patchValue({
        msicCode: null,
      });
    }
  }
}
