import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';

@Component({
  selector: 'app-branch-addresses-form',
  templateUrl: './branch-addresses-form.component.html',
  styleUrls: ['./branch-addresses-form.component.scss'],
})
export class BranchAddressesFormComponent implements OnInit {
  // Form
  @Input() branchAddressesForm!: FormGroup;

  // Responsive
  isMobile = true;

  constructor(
    private formBuilder: FormBuilder,
    private customBreakpointService: CustomBreakpointService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.createBranchAddressItem();
  }

  get branchAddresses(): FormArray {
    return this.branchAddressesForm.get('branchAddresses') as FormArray;
  }

  createBranchAddressItem(): FormGroup {
    return this.formBuilder.group({
      branchNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      address: new FormControl('', Validators.maxLength(255)),
      contactNumber: new FormControl('', [Validators.maxLength(50)]),
      email: new FormControl('', [Validators.maxLength(100), Validators.email]),
    });
  }

  addBranchAddress(): void {
    this.branchAddresses.push(this.createBranchAddressItem());
  }

  removeBranchAddress(index: number): void {
    this.branchAddresses.removeAt(index);
  }
}
