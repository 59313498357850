<section
  fxLayout="column"
  fxLayoutAlign="center start"
  class="padding-24"
  style="height: 90%"
>
  <h1>{{ article?.mainTitle ? article.mainTitle : "Article" }} created.</h1>
  <div class="margin-bottom-24 margin-top-24">
    <p>
      <span>Your article is submitted for review.</span>
      <span>You may either look at your created draft or,</span>
      <br />
      <span>Go back to where you came from.</span>
    </p>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    *ngIf="pendingImages?.length"
    class="file-status margin-bottom-24"
    [ngClass]="{
      green: isUploaded && !isUploading,
      red: !isUploaded && !isUploading
    }"
  >
    <mat-spinner *ngIf="isUploading" diameter="18"></mat-spinner>
    <span *ngIf="isUploading">Uploading article image</span>
    <mat-icon *ngIf="isUploaded && !isUploading">check_circle_outline</mat-icon>
    <span *ngIf="isUploaded && !isUploading">Image attached to article</span>
    <mat-icon *ngIf="!isUploaded && !isUploading">error_outline</mat-icon>
    <span *ngIf="!isUploaded && !isUploading">
      Some images failed to upload. Try uploading it again.
    </span>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="16px" fxLayout.xs="column-reverse">
    <button mat-stroked-button routerLink="/articles" class="uppercase">
      <mat-icon class="margin-right-8">list</mat-icon>
      <span>Back to Articles</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      class="uppercase"
      [routerLink]="['/articles', 'details', article.id]"
    >
      <mat-icon class="margin-right-8">article</mat-icon>
      <span>View Article</span>
    </button>
  </div>
</section>
