<h2 mat-dialog-title>Rename File</h2>
<mat-dialog-content
  class="mat-typography"
  fxLayout="column"
  fxLayoutAlign="stretch"
>
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" class="width-100">
      <mat-label>File Name</mat-label>
      <input matInput type="text" formControlName="fileName" />
    </mat-form-field>
  </form>
  <p class="italic">Click on rename will change the file's name instantly.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Rename</button>
</mat-dialog-actions>
