<section fxLayout="column" fxLayoutAlign="start stretch">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 padding-24"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        *ngIf="client && clientYearDetails"
        mat-stroked-button
        [routerLink]="[
          '/clients',
          clientType,
          'details',
          client.id,
          'year',
          clientYearDetails.id
        ]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span class="capitalize">{{ client.name }}</span>
      </button>
      <app-page-title
        title="Edit Year Details"
        subtitle="{{ client ? client.name : 'Loading' }} — {{
          clientYearDetails ? clientYearDetails.year : 'Loading'
        }}"
      ></app-page-title>
    </div>
  </div>

  <!-- Create Forms -->
  <!-- Desktop Steps -->
  <mat-horizontal-stepper #stepper *ngIf="!isMobile">
    <mat-step [stepControl]="yearDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Add year details</ng-template>
      <!-- Form -->
      <app-year-client-details-form
        [yearDetailsForm]="yearDetailsForm"
        [stepper]="stepper"
        [selectedMsicCodes]="selectedMsicCodes"
        [isSecretarial]="isSecretarial"
        [isTax]="isTax"
        [isAudit]="isAudit"
        [isSharedServices]="isSharedServices"
        [isFreelancer]="isFreelancer"
      ></app-year-client-details-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="banksForm" *ngIf="isAudit">
      <!-- Title -->
      <ng-template matStepLabel>Add bank details</ng-template>
      <!-- Form -->
      <app-banks-form [banksForm]="banksForm"></app-banks-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="directorsForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add directors</ng-template>
      <!-- Form -->
      <app-directors-form
        [directorsForm]="directorsForm"
        [disabled]="clientIsInSecretarial"
      ></app-directors-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="membersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add members</ng-template>
      <!-- Form -->
      <app-members-form
        [membersForm]="membersForm"
        [disabled]="clientIsInSecretarial"
      ></app-members-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Add files</ng-template>
      <!-- Form -->
      <app-files-form
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [yearID]="yearID"
      ></app-files-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Review & Save</ng-template>
      <!-- Form -->
      <app-review-publish
        [yearForm]="yearForm"
        [yearDetailsForm]="yearDetailsForm"
        [banksForm]="banksForm"
        [directorsForm]="directorsForm"
        [membersForm]="membersForm"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [msicCodes]="selectedMsicCodes"
        [isSecretarial]="isSecretarial"
        [isTax]="isTax"
        [isAudit]="isAudit"
        [isSharedServices]="isSharedServices"
        [isFreelancer]="isFreelancer"
      ></app-review-publish>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" (click)="savePressed()">
          <span>Save</span>
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <mat-vertical-stepper #stepper [linear]="isLinear" *ngIf="isMobile">
    <mat-step [stepControl]="yearDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Add year details</ng-template>
      <!-- Form -->
      <app-year-client-details-form
        [yearDetailsForm]="yearDetailsForm"
        [stepper]="stepper"
        [selectedMsicCodes]="selectedMsicCodes"
        [isSecretarial]="isSecretarial"
        [isTax]="isTax"
        [isAudit]="isAudit"
        [isSharedServices]="isSharedServices"
        [isFreelancer]="isFreelancer"
      ></app-year-client-details-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="banksForm" *ngIf="isAudit">
      <!-- Title -->
      <ng-template matStepLabel>Add bank details</ng-template>
      <!-- Form -->
      <app-banks-form [banksForm]="banksForm"></app-banks-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="directorsForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add directors</ng-template>
      <!-- Form -->
      <app-directors-form
        [directorsForm]="directorsForm"
        [disabled]="clientIsInSecretarial"
      ></app-directors-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="membersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add members</ng-template>
      <!-- Form -->
      <app-members-form
        [membersForm]="membersForm"
        [disabled]="clientIsInSecretarial"
      ></app-members-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Add files</ng-template>
      <!-- Form -->
      <app-files-form
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [yearID]="yearID"
      ></app-files-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Review & Save</ng-template>
      <!-- Form -->
      <app-review-publish
        [yearForm]="yearForm"
        [yearDetailsForm]="yearDetailsForm"
        [banksForm]="banksForm"
        [directorsForm]="directorsForm"
        [membersForm]="membersForm"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [msicCodes]="selectedMsicCodes"
        [isSecretarial]="isSecretarial"
        [isTax]="isTax"
        [isAudit]="isAudit"
        [isSharedServices]="isSharedServices"
        [isFreelancer]="isFreelancer"
      ></app-review-publish>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" (click)="savePressed()">
          <span>Save</span>
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</section>
