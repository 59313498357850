import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { PasswordValidator } from './../../shared/validators/password.validator';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControlOptions,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { LoginBreakpointService } from 'src/app/shared/login-breakpoint/login-breakpoint.service';
import { WindowSize } from 'src/app/shared/models/window-size/window-size';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;
  isResetting = false;
  passwordResetSuccess = false;
  contentMaxWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  contentMinHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  token!: string;
  email: string | undefined;

  constructor(
    private loginBreakpointService: LoginBreakpointService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.strong,
        ]),
        confirmPassword: new FormControl(''),
      },
      {
        validator: PasswordValidator.passwordMatchValidator,
      } as AbstractControlOptions
    );

    this.activatedRoute.queryParams.subscribe({
      next: (params: { [x: string]: any }) => {
        this.token = params.token;
        this.email = params.email;

        if (!this.token) {
          this.router.navigate(['/']);
          this.snackBar.open('Access Denied', '', {
            duration: 3000,
          });
        }
      },
    });
  }

  ngOnInit(): void {
    this.loginBreakpointService.maxContentSize$.subscribe({
      next: (result: WindowSize) => {
        this.contentMaxWidth$.next(result.width);
        this.contentMinHeight$.next(result.height);
      },
    });
  }

  resetPasswordPressed(): void {
    if (this.resetPasswordForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.isResetting = true;
      this.resetPasswordForm.disable();
      this.snackBar.open('Resetting', '', {
        duration: undefined,
      });
      this.authenticationService
        .resetPassword(
          this.token,
          this.resetPasswordForm.get('password')?.value
        )
        .subscribe({
          next: (result) => {
            this.authenticationService.signOut();
            this.resetPasswordForm.reset();
            this.snackBar.dismiss();
            this.passwordResetSuccess = true;
          },
          error: (error) => {
            console.log(error);
            this.isResetting = false;
            this.resetPasswordForm.enable();
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }

  backToLoginPressed(): void {
    this.router.navigate(['/login']);
  }
}
