import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceItem } from 'src/app/shared/models/services/service-item/service-item.model';

@Component({
  selector: 'app-delete-service-dialog',
  templateUrl: './delete-service-dialog.component.html',
  styleUrls: ['./delete-service-dialog.component.scss'],
})
export class DeleteServiceDialogComponent implements OnInit {
  serviceDetails: ServiceItem;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.serviceDetails = data.serviceDetails;
  }

  ngOnInit(): void {}
}
