<h2 mat-dialog-title>Delete Year {{ clientYearDetails.year }}?</h2>
<mat-dialog-content class="mat-typography">
  <h3>
    Are you sure you want to delete <b>Year {{ clientYearDetails.year }}</b> in
    <b>{{ clientYearDetails.clientBean.name }}</b
    >?
  </h3>
  <p>
    This year will be permanently deleted and will not be able to retrieve
    again.
  </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
