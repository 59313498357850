<div fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="16px">
  <p class="bold-500">Quick Actions</p>
  <div
    class="border border-color border-radius-8 padding-bottom-8"
    ngClass="width-70"
    ngClass.xs="width-100"
    ngClass.lg="width-60"
    ngClass.xl="width-50"
  >
    <mat-nav-list role="list">
      <a mat-list-item role="listitem" routerLink="/user-requests">
        <h3 mat-line>Request</h3>
        <p mat-line class="subtitle smaller">For a change</p>
        <mat-icon>chevron_right</mat-icon>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item role="listitem" routerLink="/entities">
        <h3 mat-line>View</h3>
        <p mat-line class="subtitle smaller">Your entities</p>
        <mat-icon>chevron_right</mat-icon>
      </a>
    </mat-nav-list>
  </div>
</div>
