<div
  fxLayout="row wrap"
  fxLayoutGap="40px"
  fxLayout.xs="column"
  fxLayoutGap.xs="8px"
  class="padding-top-16 padding-bottom-16"
>
  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    class="margin-bottom-24"
    fxLayoutGap="8px"
  >
    <!-- Start Register Office -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Register Office</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          entityYearDetails.registerOffice === null ? 'not-available' : ''
        "
      >
        {{
          entityYearDetails.registerOffice !== null
            ? entityYearDetails.registerOffice === true
              ? "Yes"
              : "No"
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Register Office -->
    <!-- Start Secretary Name -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Secretary Name</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="!entityYearDetails.secretaryName ? 'not-available' : ''"
      >
        {{
          entityYearDetails.secretaryName
            ? entityYearDetails.secretaryName
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Secretary Name -->
    <!-- Start Nature of Business -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Nature of Business</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="!entityYearDetails.natureOfBusiness ? 'not-available' : ''"
      >
        {{
          entityYearDetails.natureOfBusiness
            ? entityYearDetails.natureOfBusiness
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Nature of Business -->
  </div>
  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    class="margin-bottom-24"
    fxLayoutGap="8px"
  >
    <!-- Start MSIC Code -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">MSIC Code</p>
      <div
        fxFlex="calc(50%-40px)"
        *ngIf="
          entityYearDetails.msicCodes &&
          entityYearDetails.msicCodes.length > 0 &&
          !isLoading
        "
        fxLayout="row wrap"
        fxLayoutAlign="start center"
      >
        <div
          *ngFor="
            let msicCode of entityYearDetails.msicCodes;
            let isLast = last
          "
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>
            {{ msicCode.code }}
          </span>
          <mat-icon
            class="msic-help margin-left-4"
            [matTooltip]="msicCode.description"
            >help_outline</mat-icon
          >
          <span class="margin-right-8">{{ isLast ? "" : ", " }}</span>
        </div>
      </div>
      <p
        fxFlex="calc(50%-40px)"
        *ngIf="
          !entityYearDetails.msicCodes || entityYearDetails.msicCodes.length < 1
        "
        class="not-available"
      >
        Not available
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End MSIC Code -->
    <!-- Start Next Annual Return Date -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Next Annual Return Date</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          !entityYearDetails.nextAnnualReturnDueDate ? 'not-available' : ''
        "
      >
        {{
          entityYearDetails.nextAnnualReturnDueDate
            ? (entityYearDetails.nextAnnualReturnDueDate | date)
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Next Annual Return Date -->
    <!-- Start Circulation Date -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Circulation Date</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="!entityYearDetails.circulationDate ? 'not-available' : ''"
      >
        {{
          entityYearDetails.circulationDate
            ? (entityYearDetails.circulationDate | date)
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Circulation Date -->
  </div>
</div>
