<section
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.min-height.px]="contentMinHeight$ | async"
  class="padding-40"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="padding-24"
    [style.width.px]="contentMaxWidth$ | async"
  >
    <div class="four_zero_four_bg">
      <h1 class="not-found-title text-align-center">404</h1>
    </div>
    <div
      class="contant_box_404"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <h2 class="no-margin text-align-center">Not Found</h2>
      <p class="text-align-center">
        Ooops, it seems like the page you are looking for is not available.
      </p>
      <div class="margin-16"></div>
      <button mat-raised-button color="primary" routerLink="/">
        Back to Home
      </button>
    </div>
  </div>
</section>
