<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <!-- Start Back Button -->
    <button mat-stroked-button [routerLink]="['/articles']" class="back-button">
      <mat-icon>arrow_back</mat-icon>
      <span>Articles</span>
    </button>
    <!-- End Back Button -->
    <!-- Start Edit Article -->
    <button
      mat-stroked-button
      [routerLink]="['/articles', 'edit', article.id]"
      *ngIf="!isLoading && article"
    >
      <mat-icon class="margin-right-8">edit</mat-icon>
      <span>Edit Article</span>
    </button>
    <!-- End Edit Article -->
  </div>

  <!-- Start Content -->
  <div fxLayout="column" *ngIf="!isLoading && article">
    <!-- Start Published Date -->
    <small
      [ngClass]="{
        'not-available': !article.publish
      }"
    >
      {{
        article.publishedDate && article.publish
          ? (article.publishedDate | date)
          : "Not Published"
      }}
    </small>
    <!-- End Published Date -->

    <!-- Start Title -->
    <h1 class="main-title">{{ article.mainTitle }}</h1>
    <!-- End Title -->

    <!-- Start Short Description -->
    <h2 class="short-description">{{ article.shortDescription }}</h2>
    <!-- End Short Description -->

    <!-- Start Tags -->
    <p class="subtitle">
      Tags:
      <span
        class="capitalize"
        *ngFor="
          let department of getCategoriesStr(article.categories);
          let isLast = last
        "
      >
        {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
      </span>
    </p>
    <!-- End Tags -->
  </div>

  <!-- Start Author -->
  <div class="margin-top-24 margin-bottom-24" *ngIf="!isLoading && article">
    <h2 class="author">Written by {{ article.author.name }}</h2>
  </div>
  <!-- End Author -->

  <!-- Start Article Image -->
  <div fxLayout="row" fxLayoutAlign="center center">
    <img
      [src]="imageUrl"
      alt=""
      *ngIf="imageUrl && !isLoading && article"
      class="margin-top-32 margin-bottom-32 article-image"
      ngClass="width-50"
      ngClass.xs="width-100"
    />
  </div>
  <!-- End Article Image -->

  <!-- Start Content -->
  <div
    [innerHtml]="article.fullDescription | safe: 'html'"
    class="margin-bottom-32"
    *ngIf="!isLoading && article"
  ></div>
  <!-- End Content -->

  <!-- Start Reference -->
  <div class="margin-bottom-56" *ngIf="!isLoading && article">
    <h2>Reference Link</h2>
    <a [href]="article.referenceLink" class="reference-link">{{
      article.referenceLink
    }}</a>
  </div>
  <!-- End Reference -->

  <mat-divider *ngIf="!isLoading && article"></mat-divider>

  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    class="margin-top-56 margin-bottom-56"
    *ngIf="!isLoading && article"
  >
    <h2 class="engagement-title">{{ article.engagementTitle }}</h2>
    <h3 class="engagement-description">{{ article.engagementDescription }}</h3>
    <button mat-stroked-button>{{ article.engagementButtonLabel }}</button>
  </div>
  <!-- End Content -->
</section>
