<h2 mat-dialog-title>Delete File?</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to delete file?</h3>
  <p class="dialog-info-container">
    <span>Name: {{ selectedFile.name }}</span>
  </p>
  <p class="italic">
    The file will be deleted from the system immediately. <br />
    You will not be able to retrieve this file again.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
