<section
  fxLayout="column"
  fxLayoutAlign="center start"
  class="padding-24"
  style="height: 90%"
>
  <h1>{{ client?.name ? client.name : "Client" }} created.</h1>
  <div class="margin-bottom-24 margin-top-24">
    <p>
      <span>You may add more info of the company or,</span> <br />
      <span>Go back to where you came from.</span>
    </p>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
    *ngIf="pendingFiles?.length"
    class="file-status margin-bottom-24"
    [ngClass]="{
      green: isUploaded && !isUploading,
      red: !isUploaded && !isUploading
    }"
  >
    <mat-spinner *ngIf="isUploading" diameter="18"></mat-spinner>
    <span *ngIf="isUploading">Uploading files</span>
    <mat-icon *ngIf="isUploaded && !isUploading">check_circle_outline</mat-icon>
    <span *ngIf="isUploaded && !isUploading">Files uploaded</span>
    <mat-icon *ngIf="!isUploaded && !isUploading">error_outline</mat-icon>
    <span *ngIf="!isUploaded && !isUploading">
      Some files failed to upload. Try editing this year.
    </span>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="16px" fxLayout.xs="column-reverse">
    <button mat-stroked-button [routerLink]="['/clients', clientType]">
      <mat-icon class="margin-right-8">list</mat-icon>
      <span>Back to Clients — </span>
      <span class="capitalize">{{
        clientType !== "shared_services" ? clientType : "Shared Services"
      }}</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/clients', clientType, 'details', client.id]"
      *ngIf="isSecretarial"
    >
      <mat-icon class="margin-right-8">article</mat-icon>
      <span>View Client</span>
    </button>
    <button
      *ngIf="!isSecretarial"
      mat-raised-button
      color="primary"
      [routerLink]="[
        '/clients',
        clientType,
        'details',
        client.id,
        'year',
        'create'
      ]"
    >
      <mat-icon class="margin-right-8">add</mat-icon>
      <span>Add year details</span>
    </button>
  </div>
</section>
