<div
  fxLayout="row wrap"
  fxLayoutGap="40px"
  fxLayout.xs="column"
  fxLayoutGap.xs="8px"
  class="padding-top-16 padding-bottom-16"
>
  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    class="margin-bottom-24"
    fxLayoutGap="8px"
  >
    <!-- Start Name -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Name</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.name === null ||
          customerEntityDetails.name === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.name
            ? customerEntityDetails.name
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Name -->
    <!-- Start Formerly Known As (F.K.A) -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Formerly Known As (F.K.A)</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.formerlyKnownAs === null ||
          customerEntityDetails.formerlyKnownAs === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.formerlyKnownAs
            ? customerEntityDetails.formerlyKnownAs
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Formerly Known As (F.K.A) -->
    <!-- Start Company Number (New) -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Company Number (New)</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.companyNumberNew === null ||
          customerEntityDetails.companyNumberNew === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.companyNumberNew
            ? customerEntityDetails.companyNumberNew
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Company Number (New) -->
    <!-- Start Company Number (Old) -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Company Number (Old)</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.companyNumberOld === null ||
          customerEntityDetails.companyNumberOld === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.companyNumberOld
            ? customerEntityDetails.companyNumberOld
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Company Number (Old) -->
    <!-- Start Incorporation Date -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Incorporation Date</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          !customerEntityDetails.incorporationDate ? 'not-available' : ''
        "
      >
        {{
          customerEntityDetails.incorporationDate
            ? customerEntityDetails.incorporationDate
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Incorporation Date -->
    <!-- Start Year End -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Year End</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.yearEnd === null ||
          customerEntityDetails.yearEnd === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.yearEnd
            ? customerEntityDetails.yearEnd
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Year End -->
  </div>
  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    class="margin-bottom-24"
    fxLayoutGap="8px"
  >
    <!-- Start Contact Name -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Contact Name</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.contactPerson === null ||
          customerEntityDetails.contactPerson === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.contactPerson
            ? customerEntityDetails.contactPerson
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Contact Name -->
    <!-- Start Contact Phone Number -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Contact Phone Number</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.phoneNumber === null ||
          customerEntityDetails.phoneNumber === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.phoneNumber
            ? customerEntityDetails.phoneNumber
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Contact Phone Number -->
    <!-- Start Contact Email Address -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Contact Email Address</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.emailAddress === null ||
          customerEntityDetails.emailAddress === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.emailAddress
            ? customerEntityDetails.emailAddress
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Contact Email Address -->
    <!-- Start Business Address -->
    <div fxLayoutGap="8px">
      <p fxFlex="calc(50%-40px)" class="bold">Business Address</p>
      <p
        *ngIf="!isLoading"
        fxFlex="calc(50%-40px)"
        [ngClass]="
          customerEntityDetails.corresAddress === null ||
          customerEntityDetails.corresAddress === ''
            ? 'not-available'
            : ''
        "
      >
        {{
          customerEntityDetails.corresAddress
            ? customerEntityDetails.corresAddress
            : "Not Available"
        }}
      </p>
      <p class="not-available" fxFlex="calc(50%-40px)" *ngIf="isLoading">
        Loading
      </p>
    </div>
    <!-- End Business Address -->
  </div>
</div>
