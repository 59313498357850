import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Device } from 'src/app/shared/enums/device.enum';
import { CustomBreakpointService } from './../../shared/breakpoint/custom-breakpoint.service';
import { NewCustomerRequestComponent } from './new-customer-request/new-customer-request.component';

@Component({
  selector: 'app-customer-requests',
  templateUrl: './customer-requests.component.html',
  styleUrls: ['./customer-requests.component.scss'],
})
export class CustomerRequestsComponent implements OnInit {
  // Responsive
  isMobile = false;

  refreshNeeded = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private customBreakpointService: CustomBreakpointService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // Check device is mobile
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  showNewCustomerRequest(): void {
    const dialogRef = this.dialog.open(NewCustomerRequestComponent, {
      disableClose: true,
      width: this.isMobile ? '80vw' : '40vw',
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.snackBar
            .open('Request created', 'REFRESH', {
              duration: undefined,
            })
            .onAction()
            .subscribe({
              next: (_) => {
                this.refreshNeeded.next(true);
              },
            });
        }
      },
    });
  }
}
