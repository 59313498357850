import { AuthenticationService } from './../../../core/services/authentication/authentication.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { UsersService } from 'src/app/core/services/users/users.service';
import { Role } from 'src/app/shared/models/role/role.model';
import { User } from 'src/app/shared/models/user/user.model';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { UserFormConstants } from './../../../shared/constants/user-form.constants';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { Department } from 'src/app/shared/models/department/department.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup = new FormGroup({
    roles: new FormControl({ value: [], disabled: true }, Validators.required),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.email,
    ]),
    name: new FormControl('', [Validators.required]),
    branch: new FormControl({ value: 'HQ', disabled: true }, [
      Validators.required,
    ]),
    department: new FormControl({ value: '', disabled: true }),
  });
  roles: Array<Role> = [];
  departments!: Array<Department>;
  branches: Array<{ value: number; valueName: string }>;
  user!: User;
  isLoading = false;
  isAdmin = false;
  savingProfile = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userFormConstants: UserFormConstants,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    private userAccessService: UserAccessService,
    private location: Location,
    private authenticationService: AuthenticationService
  ) {
    this.branches = this.userFormConstants.BRANCHES;
    this.roles = this.userFormConstants.ROLES;
    this.departments = this.userFormConstants.DEPARTMENTS;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getUserDetails(id);
      },
    });
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          default:
            this.isAdmin = false;
            break;
        }
      },
    });
  }

  getUserDetails(userID: number): void {
    this.usersService.getUserDetails(userID).subscribe({
      next: (user: User) => {
        this.user = user;
        this.prefillForm();
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  prefillForm(): void {
    const roles: Array<string> = [];
    // Loop to get roles
    if (this.user.roles) {
      for (const role of this.user.roles) {
        const matchedRole: Role = this.roles.find(
          (x) => x.id === role.id
        ) as Role;
        roles.push(matchedRole.role as string);
      }
    }

    this.editProfileForm.patchValue({
      roles,
      email: this.user.email,
      name: this.user.name,
      department: this.user.department?.department,
    });
  }

  isOptionDisabled(role: Role): boolean {
    // Check if user is admin
    if (this.isAdmin && role.role !== 'Customer') {
      // User is admin
      if (role.role === 'Admin') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  saveProfilePressed(): void {
    if (this.editProfileForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const userMap: { [x: string]: any } = {
        role: this.editProfileForm.get('roles')?.value,
        name: this.editProfileForm.get('name')?.value,
        email: this.editProfileForm.get('email')?.value.toLowerCase(),
        branch: this.editProfileForm.get('branch')?.value,
      };

      this.savingProfile = true;
      this.editProfileForm.disable();
      this.snackBar.open('Saving profile', '', {
        duration: undefined,
      });
      this.usersService
        .updateUserDetails(userMap as Map<any, any>, this.user.id as number)
        .subscribe({
          next: (result) => {
            this.authenticationService.checkAuth();
            this.location.back();
            this.snackBar.dismiss();
            this.savingProfile = false;
            this.snackBar.open('Profile saved', '', {
              duration: 3000,
            });
          },
          error: (error) => {
            console.log(error);
            this.savingProfile = false;
            this.editProfileForm.enable();
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }
}
