<a mat-stroked-button class="width-100" [routerLink]="['details', entity.id]">
  <mat-list>
    <mat-list-item>
      <div mat-line class="title">{{ entity.name }}</div>
      <div mat-line class="description">
        <span
          *ngFor="let owner of entity.users; let isLast = last"
          class="capitalize"
          >{{ owner.name?.toLowerCase() }}{{ isLast ? "" : ", " }}</span
        >
      </div>
    </mat-list-item>
  </mat-list>
</a>
