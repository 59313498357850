<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Requests"
      subtitle="There's a lot of questions and interests here"
    ></app-page-title>
  </div>

  <app-requests-filter></app-requests-filter>

  <!-- Table -->
  <mat-table
    [fxHide]="!requests.length"
    [dataSource]="dataSource"
    matSort
    matSortActive="createdDate"
    matSortDirection="desc"
    class="margin-bottom-16"
  >
    <!-- Sender's Name -->
    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header="name" *matHeaderCellDef>
        Requester
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data"
        ><span class="ellipsis" matTooltip="{{ data.user.name }}">{{
          data.user.name
        }}</span></mat-cell
      >
    </ng-container>

    <!-- Content -->
    <ng-container matColumnDef="title">
      <mat-header-cell mat-sort-header="title" *matHeaderCellDef>
        Title
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data" class="padding-right-16"
        ><span class="ellipsis" matTooltip="{{ data.title }}">
          <span>{{ data.title }}</span>
        </span></mat-cell
      >
    </ng-container>

    <!-- Status -->
    <ng-container matColumnDef="status">
      <mat-header-cell mat-sort-header="status" *matHeaderCellDef>
        Status
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        class="padding-right-16 ellipsis capitalize"
      >
        <span
          *ngIf="data.status.id === 1"
          matTooltip="Pending"
          class="status-pending"
          >{{ data.status.id === 1 ? "Pending" : "" }}</span
        >
        <span
          *ngIf="data.status.id === 2"
          matTooltip="Assigned to Department"
          class="status-assigned-department"
          >{{ data.status.id === 2 ? "Assigned to Department" : "" }}</span
        >
        <span
          *ngIf="data.status.id === 3"
          matTooltip="Assigned to Personnel"
          class="status-assigned-personnel"
          >{{ data.status.id === 3 ? "Assigned to Personnel" : "" }}</span
        >
        <span
          *ngIf="data.status.id === 4"
          matTooltip="Completed"
          class="status-completed"
          >{{ data.status.id === 4 ? "Completed" : "" }}</span
        >
      </mat-cell>
    </ng-container>

    <!-- Received Date -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-sort-header="createdDate" *matHeaderCellDef>
        Received Date
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <span matTooltip="{{ data.createdDate | date: 'short' }}">{{
          isToday(data.createdDate)
        }}</span>
      </mat-cell>
    </ng-container>

    <!-- More info -->
    <ng-container matColumnDef="options">
      <mat-header-cell *matHeaderCellDef>
        <span class="text-align-center">More info</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['details', data.id]">
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button
            mat-menu-item
            (click)="showDeleteDialog(data)"
            *ngIf="isAdmin"
          >
            <mat-icon>delete_outline</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <!-- No Requests Message -->
  <div
    *ngIf="!requests.length"
    class="empty-table-message"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    Could not find any data
  </div>

  <!-- Paginator -->
  <mat-paginator
    #paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChanged($event)"
  >
  </mat-paginator>
</section>
