<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24 margin-bottom-40"
  fxLayoutGap="48px"
>
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    fxLayout.xs="column"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap.xs="24px"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <!-- Start Back Button -->
      <button mat-stroked-button routerLink="/entities" class="back-button">
        <mat-icon>arrow_back</mat-icon>
        <span>Entities</span>
      </button>
      <!-- End Back Button -->
      <!-- Start Title -->
      <app-page-title
        title="{{
          customerEntityDetails ? customerEntityDetails?.name : 'Not Available'
        }}"
        subtitle="More info on your company"
      ></app-page-title>
      <!-- End Title -->
    </div>

    <mat-form-field
      appearance="outline"
      [ngClass]="{ shimmer: isYearsLoading }"
      class="border-radius-8"
    >
      <mat-label>Year</mat-label>
      <mat-select
        [(ngModel)]="selectedYearID"
        (ngModelChange)="onYearChanged($event)"
      >
        <mat-option *ngFor="let year of availableYears" [value]="year.id">
          {{ year.year }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Start Service List -->
  <div fxLayout="column" fxLayoutGap="32px">
    <!-- Start Subscribe List -->
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
      *ngIf="subscribedServices"
    >
      <label>Subscribed</label>
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutGap.xs="0px"
        class="width-100"
      >
        <app-service-tile
          *ngFor="let service of subscribedServices"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="calc(45% - 16px)"
          fxFlex.md="calc(45% - 16px)"
          fxFlex.lg="33%"
          fxFlex.xl="25%"
          [title]="service.title"
          [description]="service.description"
          [iconName]="service.iconName"
          [routerLink]="[
            'files',
            entityYearDetails.id,
            service.value.toLowerCase()
          ]"
        ></app-service-tile>
      </div>
    </div>
    <!-- End Subscribe List -->
    <!-- Start Not Subscribed List -->
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
      *ngIf="notSubscribedServices"
    >
      <label>Available Services</label>
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutGap.xs="0px"
        class="width-100"
      >
        <app-service-tile
          *ngFor="let service of notSubscribedServices"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="calc(45% - 16px)"
          fxFlex.md="calc(45% - 16px)"
          fxFlex.lg="33%"
          fxFlex.xl="25%"
          [title]="service.title"
          [description]="service.description"
          [iconName]="service.iconName"
          (click)="showNotSubscribedServiceDialog(service)"
        ></app-service-tile>
      </div>
    </div>
    <!-- End Not Subscribed List -->
  </div>
  <!-- End Service List -->

  <mat-accordion multi>
    <mat-expansion-panel
      class="mat-elevation-z0 first"
      [class.last]="!entityYearDetails"
      expanded
    >
      <mat-expansion-panel-header>
        <mat-panel-title>Company</mat-panel-title>
      </mat-expansion-panel-header>

      <app-company-details
        [customerEntityDetails]="customerEntityDetails"
        [isLoading]="isLoading"
      ></app-company-details>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="mat-elevation-z0 last"
      *ngIf="entityYearDetails"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>More Company Information</mat-panel-title>
      </mat-expansion-panel-header>
      <app-entity-year-details
        [entityYearDetails]="entityYearDetails"
        [isLoading]="isYearDetailsLoading"
      ></app-entity-year-details>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Start Directors -->
  <div fxLayout="column" fxLayoutAlign="stretch" *ngIf="entityYearDetails">
    <app-section-title title="Directors"></app-section-title>
    <mat-accordion multi>
      <mat-expansion-panel
        class="mat-elevation-z0"
        *ngFor="
          let director of entityYearDetails.directors;
          let isFirst = first;
          let isLast = last
        "
        [class.first]="isFirst"
        [class.last]="isLast"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>Director — {{ director.name }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div
          fxLayout="row wrap"
          fxLayoutGap="40px"
          fxLayout.xs="column"
          fxLayoutGap.xs="8px"
          class="padding-top-16 padding-bottom-16"
        >
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Start Name -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Name</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  director.name === null || director.name === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ director.name ? director.name : "Not Available" }}
              </p>
            </div>
            <!-- End Name -->

            <!-- Start ID Type -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="director.type === null ? 'not-available' : ''"
              >
                {{ director.type ? director.type.type : "Not Available" }}
              </p>
            </div>
            <!-- End ID Type -->

            <!-- Start ID Number -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  director.identificationNumber === null ||
                  director.identificationNumber === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  director.identificationNumber
                    ? director.identificationNumber
                    : "Not Available"
                }}
              </p>
            </div>
            <!-- End ID Number -->
          </div>
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Start Email Address -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Email Address</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  director.email === null || director.email === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ director.email ? director.email : "Not Available" }}
              </p>
            </div>
            <!-- End Email Address -->
            <!-- Start Address -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Address</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  director.address === null || director.address === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ director.address ? director.address : "Not Available" }}
              </p>
            </div>
            <!-- End Address -->
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- End Directors -->

  <!-- Start Members -->
  <div fxLayout="column" fxLayoutAlign="stretch" *ngIf="entityYearDetails">
    <app-section-title title="Members"></app-section-title>
    <mat-accordion multi>
      <mat-expansion-panel
        class="mat-elevation-z0"
        *ngFor="
          let shareholder of entityYearDetails.shareholders;
          let isFirst = first;
          let isLast = last
        "
        [class.first]="isFirst"
        [class.last]="isLast"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>Member — {{ shareholder.name }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div
          fxLayout="row wrap"
          fxLayoutGap="40px"
          fxLayout.xs="column"
          fxLayoutGap.xs="8px"
          class="padding-top-16 padding-bottom-16"
        >
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Start Name -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Name</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  shareholder.name === null || shareholder.name === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ shareholder.name ? shareholder.name : "Not Available" }}
              </p>
            </div>
            <!-- End Name -->

            <!-- Start ID Type -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="shareholder.type === null ? 'not-available' : ''"
              >
                {{ shareholder.type ? shareholder.type.type : "Not Available" }}
              </p>
            </div>
            <!-- End ID Type -->

            <!-- Start ID Number -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  shareholder.identificationNumber === null ||
                  shareholder.identificationNumber === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  shareholder.identificationNumber
                    ? shareholder.identificationNumber
                    : "Not Available"
                }}
              </p>
            </div>
            <!-- End ID Number -->

            <!-- Start Address -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Address</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  shareholder.address === null || shareholder.address === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  shareholder.address ? shareholder.address : "Not Available"
                }}
              </p>
            </div>
            <!-- End Address -->
          </div>
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Start Email Address -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Email Address</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  shareholder.email === null || shareholder.email === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ shareholder.email ? shareholder.email : "Not Available" }}
              </p>
            </div>
            <!-- End Email Address -->

            <!-- Start Number of Shares -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Number of Shares</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  shareholder.numberOfShares === null ||
                  shareholder.numberOfShares === undefined
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  shareholder.numberOfShares
                    ? shareholder.numberOfShares
                    : "Not Available"
                }}
              </p>
            </div>
            <!-- End Number of Shares -->
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  <!-- End Members -->
</section>
