import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from './../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt/jwt.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private jwtService: JwtService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.jwtService.getToken();
    const isFormDataContentType: boolean = this.jwtService.contentTypeIsFormData();

    const jsonRequest = req.clone({
      headers: req.headers
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    });

    const formDataRequest = req.clone({
      headers: req.headers
        .set('Accept', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    });

    return next
      .handle(isFormDataContentType ? formDataRequest : jsonRequest)
      .pipe(
        catchError((error) => {
          console.log(error);

          if (error.status === 401 || error.status === 500) {
            if (error.error.message !== 'Bad credentials') {
              this.handleAuthError();
              return of(error);
            }
            if (error.error.message === 'Access is denied') {
              this.router.navigate(['/']);
              this.snackBar.open('Access expired. Please login again', '', {
                duration: 3000,
              });
            }
          }
          throw error;
        })
      );
  }

  private handleAuthError(): void {
    this.snackBar.dismiss();
    this.authenticationService.signOut();
    this.router.navigate(['/login']);
  }
}
