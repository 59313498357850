import { ApiService } from './../api_service/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Notification } from 'src/app/shared/models/notification/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private apiService: ApiService) {}

  getNotifications(userID: number): Observable<Notification[]> {
    return this.apiService.get(`/api/core/client/notification/${userID}`);
  }

  updateNotification(
    data: object = {},
    notificationID: number
  ): Observable<Notification> {
    return this.apiService.put(
      `/api/core/client/notification/${notificationID}`,
      data
    );
  }
}
