<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24"
  fxLayoutGap="32px"
>
  <!-- Action buttons -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
    class="width-100"
    *ngIf="!isLoading"
  >
    <button
      mat-stroked-button
      [routerLink]="['/enquiries']"
      class="back-button"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Enquiries</span>
    </button>
    <!-- Action Buttons -->
    <!-- Mobile -->
    <button mat-stroked-button [matMenuTriggerFor]="menu" fxHide fxShow.xs>
      <span>More</span>
      <mat-icon class="margin-left-8">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        class="uppercase"
        (click)="updateEnquiryStatus(!enquiry.hasRead)"
      >
        <mat-icon>{{
          enquiry.hasRead ? "mark_as_unread" : "done_all"
        }}</mat-icon>
        <span class="margin-left-8">{{
          enquiry.hasRead ? "Mark as unread" : "Mark as read"
        }}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item class="uppercase" (click)="showDeleteDialog()">
        <mat-icon>delete_outline</mat-icon>
        <span class="margin-left-8">Delete</span>
      </button>
    </mat-menu>

    <!-- Desktop -->
    <div fxLayout="row" fxLayoutGap="16px" fxHide.xs>
      <button
        mat-stroked-button
        class="uppercase"
        (click)="updateEnquiryStatus(!enquiry.hasRead)"
      >
        <mat-icon>{{
          enquiry.hasRead ? "mark_as_unread" : "done_all"
        }}</mat-icon>
        <span class="margin-left-8">{{
          enquiry.hasRead ? "Mark as unread" : "Mark as read"
        }}</span>
      </button>
      <mat-divider vertical style="height: 40px"></mat-divider>
      <button mat-stroked-button class="uppercase" (click)="showDeleteDialog()">
        <mat-icon>delete_outline</mat-icon>
        <span class="margin-left-8">Delete</span>
      </button>
    </div>
  </div>

  <!-- Received At -->
  <div>
    <p class="bold">Received At</p>
    <p>
      <span *ngIf="!isLoading">{{ enquiry.createdDate | date: "medium" }}</span>
    </p>
  </div>

  <!-- Title -->
  <div>
    <p class="bold">Title</p>
    <p>
      <span *ngIf="!isLoading">{{ enquiry.title }}</span>
    </p>
  </div>

  <!-- Sender -->
  <div>
    <p class="bold">Sender</p>
    <p>
      <span *ngIf="!isLoading">Name: {{ enquiry.name }}</span
      ><br />
      <span *ngIf="!isLoading">Email: {{ enquiry.email }}</span>
      <br />
      <span *ngIf="!isLoading">Phone Number: {{ enquiry.phone }}</span>
    </p>
  </div>

  <!-- Remarks -->
  <div>
    <p class="bold">Remarks</p>
    <p>
      <span *ngIf="!isLoading">{{ enquiry.description }}</span>
    </p>
  </div>

  <!-- Attachments -->
  <div *ngIf="!isLoading && enquiry.fileName !== null">
    <p class="bold">Attachments</p>
    <button mat-stroked-button (click)="downloadAttachmentPressed()">
      <mat-icon>download</mat-icon>
      <span class="margin-left-8">{{ enquiry.fileName }}</span>
    </button>
  </div>
</section>
