<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <!-- Start Back Button -->
    <button mat-stroked-button [routerLink]="['/articles']" class="back-button">
      <mat-icon>arrow_back</mat-icon>
      <span>Articles</span>
    </button>
    <!-- End Back Button -->
  </div>
  <!-- Start Form -->
  <app-article-form
    [articleForm]="articleForm"
    [selectedCategories]="selectedCategories"
    [pendingImages]="pendingImages"
  ></app-article-form>
  <!-- End Form -->

  <!-- Start Action Button -->
  <div
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="16px"
    class="bottom-action-bar"
  >
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      type="button"
      (click)="savePressed(false)"
    >
      <mat-icon class="margin-right-8">assignment</mat-icon>
      <span>Submit for review</span>
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      [disabled]="isCustodian"
      type="button"
      [matTooltip]="isCustodian ? 'Only admin can publish' : ''"
      (click)="savePressed(true)"
    >
      <span>Publish</span>
      <mat-icon class="margin-left-8">publish</mat-icon>
    </button>
  </div>
  <!-- End Action Button -->
</section>
