import { MatSnackBar } from '@angular/material/snack-bar';
import { RequestsService } from 'src/app/core/services/requests/requests.service';
import { Component, Input, OnInit } from '@angular/core';
import { Request } from 'src/app/shared/models/request/request.model';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { CustomerRequest } from 'src/app/shared/models/request/customer-request';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customer-request-list',
  templateUrl: './customer-request-list.component.html',
  styleUrls: ['./customer-request-list.component.scss'],
})
export class CustomerRequestListComponent implements OnInit {
  @Input() title = '';
  @Input() isActive = false;
  @Input() refreshNeeded!: BehaviorSubject<boolean>;
  @Input() limitTo!: number;

  // Loading
  isLoading = false;

  // Variables
  requests: Request[] = [];
  requestDetails!: CustomerRequest[];
  user!: User;

  // Subscriptions
  userSubscription!: Subscription;

  constructor(
    private requestsService: RequestsService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id) {
          this.getCustomerRequests(this.isActive);
        }
      },
    });
    this.refreshNeeded.subscribe({
      next: (refreshNeeded: boolean) => {
        if (refreshNeeded === true) {
          this.getCustomerRequests(this.isActive);
        }
      },
    });
  }

  getCustomerRequests(isActive: boolean): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });

    const formData = {
      page: 0,
      size: 10,
      userId: this.user.id,
      status: isActive ? 'active' : 'past',
    };

    this.requestsService.getCustomerRequests(formData).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        if (data != null) {
          if (this.limitTo) {
            this.requests = data.requests.slice(0, this.limitTo) as Request[];
          } else {
            this.requests = data.requests as Request[];
          }
          this.refreshNeeded.next(false);
        } else {
          this.requests = [] as Request[];
          this.refreshNeeded.next(false);
        }
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  getCustomerRequestDetails(request: Request): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });

    this.requestsService.getCustomerRequestDetails(request.id).subscribe({
      next: (requestDetails: CustomerRequest[]) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.requestDetails = requestDetails;
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  downloadAttachmentPressed(request: Request): void {
    window.open(
      `${environment.baseUrl}/api/core/requests/downloadFile/${request.id}`,
      '_blank'
    );
  }
}
