import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { WindowSize } from 'src/app/shared/models/window-size/window-size';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { LoginBreakpointService } from './../../shared/login-breakpoint/login-breakpoint.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // Form
  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });
  hidePassword = true;

  // Loading
  isLoggingIn = false;

  // User Role
  isAdmin = false;
  isCustodian = false;
  isPersonnel = false;
  isCustomer = false;
  hasRole = false;

  // Responsive
  contentMaxWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  contentMinHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(
    private authenticationService: AuthenticationService,
    private loginBreakpointService: LoginBreakpointService,
    private snackBar: MatSnackBar,
    private router: Router,
    private userAccessService: UserAccessService
  ) {
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            this.hasRole = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            this.hasRole = true;
            break;
          case UserRole.personnel:
            this.isPersonnel = true;
            this.hasRole = true;
            break;
          case UserRole.customer:
            this.isCustomer = true;
            this.hasRole = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            this.isPersonnel = false;
            this.hasRole = false;
            break;
        }
        this.subscribeToAuthenticationState();
      },
    });
  }

  ngOnInit(): void {
    this.loginBreakpointService.maxContentSize$.subscribe({
      next: (result: WindowSize) => {
        this.contentMaxWidth$.next(result.width);
        this.contentMinHeight$.next(result.height);
      },
    });
  }

  subscribeToAuthenticationState(): void {
    this.authenticationService.isAuthenticated.subscribe({
      next: (isAuthenticated: boolean) => {
        if (isAuthenticated && this.hasRole) {
          if (!this.isPersonnel) {
            this.router.navigate(['/dashboard']);
          } else {
            this.router.navigate(['/clients']);
          }
        }
      },
    });
  }

  loginPressed(): void {
    if (this.loginForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.isLoggingIn = true;
      this.loginForm.disable();
      this.snackBar.open('Logging in', '', {
        duration: undefined,
      });
      this.authenticationService
        .passwordSignIn(this.loginForm.value)
        .subscribe({
          next: (result) => {
            this.loginForm.reset();
            this.snackBar.dismiss();
          },
          error: (error) => {
            console.log(error);
            this.loginForm.enable();
            this.isLoggingIn = false;
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }
}
