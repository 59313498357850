import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-secretarial-clients',
  templateUrl: './secretarial-clients.component.html',
  styleUrls: ['./secretarial-clients.component.scss'],
})
export class SecretarialClientsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Client> = new MatTableDataSource<Client>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  isLoading = false;
  // Clients
  clients: Array<Client> = [];
  // Params
  filterClient = '';
  companyType = 'ALL';
  createdStart: Date | null = null;
  createdEnd: Date | null = null;
  clientType!: string;
  // Pagination
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  pageEvent!: PageEvent;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private snackBar: MatSnackBar,
    private clientsService: ClientsService,
    private datepipe: DatePipe,
    private clientTypeService: ClientTypeService
  ) {
    this.clientTypeService.getClientType();
    this.clientType = this.clientTypeService.clientTypeStr;

    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['name', 'options'];
        } else if (device === Device.tablet) {
          this.displayedColumns = [
            'id',
            'name',
            'companyNumberNew',
            'dateOfIncorp',
            'yearEnd',
            'options',
          ];
        } else {
          this.displayedColumns = [
            'id',
            'name',
            'companyNumberNew',
            'dateOfIncorp',
            'yearEnd',
            'options',
          ];
        }
      },
    });
  }

  ngOnInit(): void {
    this.getSecretarialClients();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getSecretarialClients(
    filterClient?: string,
    pageSize?: number,
    currentPage?: number,
    companyType?: number,
    createdStart?: Date,
    createdEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: undefined,
    });

    const params = {
      companyName: filterClient ?? this.filterClient,
      clientType: companyType ?? this.companyType,
      createdDateFrom: this.datepipe.transform(
        createdStart ?? this.createdStart,
        'yyy-MM-dd 00:00:00'
      ),
      createdDateTo: this.datepipe.transform(
        createdEnd ?? this.createdEnd,
        'yyy-MM-dd 23:59:59'
      ),
      page: currentPage ?? this.currentPage,
      size: pageSize ?? this.pageSize,
      department: this.clientType,
    };

    this.clientsService.getClients(params).subscribe({
      next: (data) => {
        if (data !== null) {
          this.length = data.totalItems;
          this.clients = data.clients as Client[];
        } else {
          this.length = 0;
          this.clients = [];
        }
        this.dataSource.data = this.clients;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(pageEvent: PageEvent): PageEvent {
    this.currentPage = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.getSecretarialClients();
    return pageEvent;
  }
}
