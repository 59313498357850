<section fxLayout="column" fxLayoutAlign="start stretch">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 padding-24"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/clients', clientType, 'details', client.value.id]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span class="capitalize">{{ client.value.name }}</span>
      </button>
      <app-page-title
        title="Edit Client — Secretarial"
        subtitle="{{ client.value ? client.value.name : 'Loading' }}"
      ></app-page-title>
    </div>
  </div>

  <!-- Create Forms -->
  <!-- Desktop Steps -->
  <mat-horizontal-stepper labelPosition="bottom" #stepper *ngIf="!isMobile">
    <mat-step [stepControl]="generalCompanyDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Add main details</ng-template>
      <app-client-form
        [generalCompanyDetailsForm]="generalCompanyDetailsForm"
        [contactsForm]="contactsForm"
        [branchAddressesForm]="branchAddressesForm"
        [isCreate]="isCreate"
        [client]="client"
        [useCreateAPI]="useCreateAPI"
      ></app-client-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="yearDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Company info (Additional)</ng-template>
      <!-- Form -->
      <app-year-client-details-form
        formTitle="Add Additional Details"
        [yearDetailsForm]="yearDetailsForm"
        [stepper]="stepper"
        [selectedMsicCodes]="selectedMsicCodes"
        [isSecretarial]="true"
        [isTax]="false"
        [isAudit]="false"
        [isSharedServices]="false"
        [isFreelancer]="isFreelancer"
      ></app-year-client-details-form>
      <app-banks-form [banksForm]="banksForm"></app-banks-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="directorsForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add directors</ng-template>
      <!-- Form -->
      <app-directors-form
        [directorsForm]="directorsForm"
        [disabled]="clientIsInSecretarial"
      ></app-directors-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="membersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add members</ng-template>
      <!-- Form -->
      <app-members-form
        [membersForm]="membersForm"
        [disabled]="clientIsInSecretarial"
      ></app-members-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secretariesForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add secretaries</ng-template>
      <!-- Form -->
      <app-secretary-form
        [secretariesForm]="secretariesForm"
        [disabled]="clientIsInSecretarial"
      ></app-secretary-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="managersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add manager</ng-template>
      <!-- Form -->
      <app-manager-form [managersForm]="managersForm"></app-manager-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step
      [stepControl]="auditorsForm"
      *ngIf="
        isSecretarial ||
        (isTax && !isFreelancer.value) ||
        isAudit ||
        (isSharedServices && !isFreelancer.value)
      "
    >
      <!-- Title -->
      <ng-template matStepLabel>Add auditor</ng-template>
      <!-- Form -->
      <app-auditors-form [auditorsForm]="auditorsForm"></app-auditors-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step
      [stepControl]="taxAgentsForm"
      *ngIf="isSharedServices || isSecretarial"
    >
      <!-- Title -->
      <ng-template matStepLabel>Add tax agent</ng-template>
      <!-- Form -->
      <app-tax-agents-form
        [taxAgentsForm]="taxAgentsForm"
      ></app-tax-agents-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Add files</ng-template>
      <!-- Form -->
      <app-files-form
        *ngIf="clientYearDetails"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [yearID]="clientYearDetails.id"
      ></app-files-form>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Review & Save</ng-template>
      <!-- Form -->
      <app-review-publish
        [generalCompanyDetailsForm]="generalCompanyDetailsForm"
        [contactsForm]="contactsForm"
        [branchAddressesForm]="branchAddressesForm"
        [yearDetailsForm]="yearDetailsForm"
        [directorsForm]="directorsForm"
        [membersForm]="membersForm"
        [secretariesForm]="secretariesForm"
        [managersForm]="managersForm"
        [auditorsForm]="auditorsForm"
        [banksForm]="banksForm"
        [taxAgentsForm]="taxAgentsForm"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [msicCodes]="selectedMsicCodes"
        [isSecretarial]="true"
        [isTax]="false"
        [isAudit]="false"
        [isSharedServices]="false"
        [isFreelancer]="isFreelancer"
      ></app-review-publish>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="bottom-action-bar"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" (click)="savePressed()">
          <span>Save</span>
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <!-- Mobile Steps -->
  <mat-vertical-stepper #stepper *ngIf="isMobile">
    <mat-step [stepControl]="generalCompanyDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Add main details</ng-template>
      <app-client-form
        [generalCompanyDetailsForm]="generalCompanyDetailsForm"
        [contactsForm]="contactsForm"
        [branchAddressesForm]="branchAddressesForm"
        [isCreate]="isCreate"
        [client]="client"
        [useCreateAPI]="useCreateAPI"
      ></app-client-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="yearDetailsForm">
      <!-- Title -->
      <ng-template matStepLabel>Company info (Additional)</ng-template>
      <!-- Form -->
      <app-year-client-details-form
        formTitle="Add additional details"
        [yearDetailsForm]="yearDetailsForm"
        [stepper]="stepper"
        [selectedMsicCodes]="selectedMsicCodes"
        [isSecretarial]="true"
        [isTax]="false"
        [isAudit]="false"
        [isSharedServices]="false"
        [isFreelancer]="isFreelancer"
      ></app-year-client-details-form>
      <app-banks-form [banksForm]="banksForm"></app-banks-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="directorsForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add directors</ng-template>
      <!-- Form -->
      <app-directors-form
        [directorsForm]="directorsForm"
        [disabled]="clientIsInSecretarial"
      ></app-directors-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="membersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add members</ng-template>
      <!-- Form -->
      <app-members-form
        [membersForm]="membersForm"
        [disabled]="clientIsInSecretarial"
      ></app-members-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secretariesForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add secretaries</ng-template>
      <!-- Form -->
      <app-secretary-form
        [secretariesForm]="secretariesForm"
        [disabled]="clientIsInSecretarial"
      ></app-secretary-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="managersForm" *ngIf="!isFreelancer.value">
      <!-- Title -->
      <ng-template matStepLabel>Add manager</ng-template>
      <!-- Form -->
      <app-manager-form [managersForm]="managersForm"></app-manager-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step
      [stepControl]="auditorsForm"
      *ngIf="
        isSecretarial ||
        (isTax && !isFreelancer.value) ||
        isAudit ||
        (isSharedServices && !isFreelancer.value)
      "
    >
      <!-- Title -->
      <ng-template matStepLabel>Add auditor</ng-template>
      <!-- Form -->
      <app-auditors-form [auditorsForm]="auditorsForm"></app-auditors-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step
      [stepControl]="taxAgentsForm"
      *ngIf="isSharedServices || isSecretarial"
    >
      <!-- Title -->
      <ng-template matStepLabel>Add tax agent</ng-template>
      <!-- Form -->
      <app-tax-agents-form
        [taxAgentsForm]="taxAgentsForm"
      ></app-tax-agents-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Add files</ng-template>
      <!-- Form -->
      <app-files-form
        *ngIf="clientYearDetails"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [yearID]="clientYearDetails.id"
      ></app-files-form>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" matStepperNext>
          <span>Next</span>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <!-- Title -->
      <ng-template matStepLabel>Review & Save</ng-template>
      <!-- Form -->
      <app-review-publish
        [generalCompanyDetailsForm]="generalCompanyDetailsForm"
        [contactsForm]="contactsForm"
        [branchAddressesForm]="branchAddressesForm"
        [yearDetailsForm]="yearDetailsForm"
        [directorsForm]="directorsForm"
        [membersForm]="membersForm"
        [secretariesForm]="secretariesForm"
        [managersForm]="managersForm"
        [auditorsForm]="auditorsForm"
        [banksForm]="banksForm"
        [taxAgentsForm]="taxAgentsForm"
        [filesForm]="filesForm"
        [pendingFiles]="pendingFiles"
        [msicCodes]="selectedMsicCodes"
        [isSecretarial]="true"
        [isTax]="false"
        [isAudit]="false"
        [isSharedServices]="false"
        [isFreelancer]="isFreelancer"
      ></app-review-publish>
      <mat-divider></mat-divider>
      <!-- Action Bar -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="16px"
        class="margin-top-16 margin-bottom-16"
      >
        <button mat-stroked-button matStepperPrevious>
          <span>Previous</span>
        </button>
        <button mat-stroked-button color="primary" (click)="savePressed()">
          <span>Save</span>
        </button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</section>
