<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Enquiries"
      subtitle="There's a lot of questions and interests here"
    ></app-page-title>
  </div>

  <!-- Search and Filter -->
  <app-enquiries-filter></app-enquiries-filter>

  <!-- Table -->
  <mat-table
    [fxHide]="!enquiries.length"
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="margin-bottom-16"
  >
    <!-- Sender's Name -->
    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header="name" *matHeaderCellDef>
        Sender
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="data.hasRead ? 'enquiry-read' : 'bold'"
        ><span class="ellipsis" [matTooltip]="data.name">{{
          data.name
        }}</span></mat-cell
      >
    </ng-container>

    <!-- Content -->
    <ng-container matColumnDef="title">
      <mat-header-cell mat-sort-header="title" *matHeaderCellDef>
        Content
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        class="padding-right-16"
        [ngClass]="data.hasRead ? 'enquiry-read' : ''"
        ><span
          class="ellipsis"
          matTooltip="{{ data.title }} — {{ data.description }}"
        >
          <span [ngClass]="data.hasRead ? '' : 'bold'">{{ data.title }}</span> —
          <span>{{ data.description }}</span>
        </span></mat-cell
      >
    </ng-container>

    <!-- Received Date -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-sort-header="createdDate" *matHeaderCellDef>
        Received Date
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="data.hasRead ? 'enquiry-read' : ''"
      >
        <span
          matTooltip="{{ data.createdDate | date: 'short' }}"
          [ngClass]="data.hasRead ? '' : 'bold'"
          >{{ isToday(data.createdDate) }}</span
        >
      </mat-cell>
    </ng-container>

    <!-- More info -->
    <ng-container matColumnDef="options">
      <mat-header-cell *matHeaderCellDef>
        <span class="text-align-center">More info</span>
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="data.hasRead ? 'enquiry-read' : ''"
      >
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['details', data.id]">
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-menu-item (click)="markAsReadPressed(data)">
            <mat-icon>{{
              data.hasRead ? "mark_as_unread" : "done_all"
            }}</mat-icon>
            <span>{{ data.hasRead ? "Mark as unread" : "Mark as read" }}</span>
          </button>
          <button mat-menu-item (click)="showDeleteDialog(data)">
            <mat-icon>delete_outline</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <!-- No Enquiries Message -->
  <div
    *ngIf="!enquiries.length"
    class="empty-table-message"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    Could not find any data
  </div>

  <!-- Paginator -->
  <mat-paginator
    #paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageChanged($event)"
  >
  </mat-paginator>
</section>
