import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';

@Component({
  selector: 'app-tax-agents-form',
  templateUrl: './tax-agents-form.component.html',
  styleUrls: ['./tax-agents-form.component.scss'],
})
export class TaxAgentsFormComponent implements OnInit {
  // Form
  @Input() taxAgentsForm!: FormGroup;

  // Responsive
  isMobile = true;

  constructor(
    private formBuilder: FormBuilder,
    private customBreakpointService: CustomBreakpointService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {}

  get taxAgents(): FormArray {
    return this.taxAgentsForm.get('taxAgents') as FormArray;
  }

  createTaxAgentItem(): FormGroup {
    return this.formBuilder.group({
      companyName: new FormControl('', Validators.required),
      picName: new FormControl(''),
      licenseNo: new FormControl(''),
      telNo: new FormControl('', Validators.required),
      companyEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      privateEmail: new FormControl('', Validators.email),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  addTaxAgent(): void {
    this.taxAgents.push(this.createTaxAgentItem());
  }

  removeTaxAgent(index: number): void {
    this.taxAgents.removeAt(index);
  }
}
