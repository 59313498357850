import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { Device } from 'src/app/shared/enums/device.enum';
import { Auditor } from 'src/app/shared/models/auditor/auditor.model';
import { BankItem } from 'src/app/shared/models/client/bank-item/bank-item.model';
import { BranchAddress } from 'src/app/shared/models/client/branch-address/branch-address.model';
import { Client } from 'src/app/shared/models/client/client.model';
import { ContactPerson } from 'src/app/shared/models/client/contact-person/contact-person.model';
import { Director } from 'src/app/shared/models/director/director.model';
import { Manager } from 'src/app/shared/models/manager/manager.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { Secretary } from 'src/app/shared/models/secretary/secretary.model';
import { ServiceType } from 'src/app/shared/models/service-type/service-type.model';
import { TaxAgent } from 'src/app/shared/models/tax-agent/tax-agent.model';
import { CustomBreakpointService } from './../../../../../shared/breakpoint/custom-breakpoint.service';
import { ClientYearDetails } from './../../../../../shared/models/client/client-year-details/client-year-details.model';
import { Shareholder } from './../../../../../shared/models/shareholder/shareholder.model';

@Component({
  selector: 'app-review-publish',
  templateUrl: './review-publish.component.html',
  styleUrls: ['./review-publish.component.scss'],
})
export class ReviewPublishComponent implements OnInit, OnDestroy {
  // Form
  @Input() yearForm!: FormGroup;
  @Input() generalCompanyDetailsForm!: FormGroup;
  @Input() contactsForm!: FormGroup;
  @Input() branchAddressesForm!: FormGroup;
  @Input() yearDetailsForm!: FormGroup;
  @Input() banksForm!: FormGroup;
  @Input() directorsForm!: FormGroup;
  @Input() membersForm!: FormGroup;
  @Input() secretariesForm!: FormGroup;
  @Input() managersForm!: FormGroup;
  @Input() auditorsForm!: FormGroup;
  @Input() taxAgentsForm!: FormGroup;
  @Input() filesForm!: FormGroup;
  @Input() pendingFiles!: Array<PendingFile>;
  @Input() msicCodes!: Array<{ code: string }>;
  @Input() isSecretarial = false;
  @Input() isTax = false;
  @Input() isAudit = false;
  @Input() isSharedServices = false;
  @Input() isFreelancer!: BehaviorSubject<boolean>;
  // Data
  year!: string;
  client!: Client;
  contactPersons!: Array<ContactPerson>;
  branchAddresses!: Array<BranchAddress>;
  clientYearDetails!: ClientYearDetails;
  bankItems!: Array<BankItem>;
  directors!: Array<Director>;
  members!: Array<Shareholder>;
  secretaries!: Array<Secretary>;
  managers!: Array<Manager>;
  auditors!: Array<Auditor>;
  taxAgents!: Array<TaxAgent>;

  // Subscriptions
  yearFormSubscription!: Subscription;
  generalCompanyDetailsFormSubscription!: Subscription;
  contactsFormSubscription!: Subscription;
  branchAddressesFormSubscription!: Subscription;
  yearDetailsFormSubscription!: Subscription;
  banksFormSubscription!: Subscription;
  directorsFormSubscription!: Subscription;
  membersFormSubscription!: Subscription;
  secretariesFormSubscription!: Subscription;
  managersFormSubscription!: Subscription;
  auditorsFormSubscription!: Subscription;
  taxAgentsFormSubscription!: Subscription;
  filesFormSubscription!: Subscription;

  // List
  serviceTypes: Array<{ value: string; valueName: string }>;

  // Responsive
  isMobile = true;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private clientFormConstants: ClientFormConstants
  ) {
    // Service Types
    this.serviceTypes = this.clientFormConstants.SERVICE_TYPES;

    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    if (!this.isSecretarial) {
      this.getYearFormValue();
    } else {
      this.getGeneralCompanyDetailsFormValue();
    }
    if (this.contactsForm) {
      this.getContactsFormValue();
    }
    if (this.branchAddressesForm) {
      this.getBranchAddressesFormValue();
    }
    this.getYearDetailsFormValue();
    if (this.isAudit || this.isSecretarial) {
      this.getBanksFormValue();
    }
    this.getDirectorsFormValue();
    this.getMembersFormValue();
    if (this.secretariesForm) {
      this.getSecretariesFormValue();
    }
    if (this.managersForm) {
      this.getManagersFormValue();
    }
    if (this.auditorsForm) {
      this.getAuditorsFormValue();
    }
    if (this.taxAgentsForm) {
      this.getTaxAgentsFormValue();
    }
  }

  ngOnDestroy(): void {
    if (!this.isSecretarial) {
      this.yearFormSubscription.unsubscribe();
    } else {
      this.generalCompanyDetailsFormSubscription.unsubscribe();
      this.contactsFormSubscription.unsubscribe();
    }
    this.yearDetailsFormSubscription.unsubscribe();
    if (this.isAudit) {
      this.banksFormSubscription.unsubscribe();
    }
    this.directorsFormSubscription.unsubscribe();
    this.membersFormSubscription.unsubscribe();
  }

  getYearFormValue(): void {
    this.yearFormSubscription = this.yearForm.valueChanges.subscribe({
      next: (value) => {
        this.year = value;
      },
    });
  }

  getGeneralCompanyDetailsFormValue(): void {
    this.client = this.generalCompanyDetailsForm.value;
    this.generalCompanyDetailsFormSubscription =
      this.generalCompanyDetailsForm.valueChanges.subscribe({
        next: (value) => {
          this.client = value;
        },
      });
  }

  getYearDetailsFormValue(): void {
    this.clientYearDetails = this.yearDetailsForm.value;
    this.yearDetailsFormSubscription =
      this.yearDetailsForm.valueChanges.subscribe({
        next: (value) => {
          this.clientYearDetails = value;
          this.clientYearDetails.serviceTypeRef = value.serviceType;
        },
      });
  }

  getBanksFormValue(): void {
    this.bankItems = this.banksForm.controls.banks.value;
    this.banksFormSubscription =
      this.banksForm.controls.banks.valueChanges.subscribe({
        next: (value) => {
          this.bankItems = value;
        },
      });
  }

  getContactsFormValue(): void {
    this.contactPersons = this.contactsForm.controls.oftenContactPersons.value;
    this.contactsFormSubscription =
      this.contactsForm.controls.oftenContactPersons.valueChanges.subscribe({
        next: (value) => {
          this.contactPersons = value;
        },
      });
  }

  getBranchAddressesFormValue(): void {
    this.branchAddresses =
      this.branchAddressesForm.controls.branchAddresses.value;
    this.branchAddressesFormSubscription =
      this.branchAddressesForm.controls.branchAddresses.valueChanges.subscribe({
        next: (value) => {
          this.branchAddresses = value;
        },
      });
  }

  getDirectorsFormValue(): void {
    this.directors = this.directorsForm.controls.directors.value;
    this.directorsFormSubscription =
      this.directorsForm.controls.directors.valueChanges.subscribe({
        next: (value) => {
          this.directors = value;
        },
      });
  }

  getMembersFormValue(): void {
    this.members = this.membersForm.controls.members.value;
    this.membersFormSubscription =
      this.membersForm.controls.members.valueChanges.subscribe({
        next: (value) => {
          this.members = value;
        },
      });
  }

  getSecretariesFormValue(): void {
    this.secretaries = this.secretariesForm.controls.secretaries.value;
    this.secretariesFormSubscription =
      this.secretariesForm.controls.secretaries.valueChanges.subscribe({
        next: (value) => {
          this.secretaries = value;
        },
      });
  }

  getManagersFormValue(): void {
    this.managers = this.managersForm.controls.managers.value;
    this.managersFormSubscription =
      this.managersForm.controls.managers.valueChanges.subscribe({
        next: (value) => {
          this.managers = value;
        },
      });
  }

  getAuditorsFormValue(): void {
    this.auditors = this.auditorsForm.controls.auditors.value;
    this.auditorsFormSubscription =
      this.auditorsForm.controls.auditors.valueChanges.subscribe({
        next: (value) => {
          this.auditors = value;
        },
      });
  }

  getTaxAgentsFormValue(): void {
    this.taxAgents = this.taxAgentsForm.controls.taxAgents.value;
    this.taxAgentsFormSubscription =
      this.taxAgentsForm.controls.taxAgents.valueChanges.subscribe({
        next: (value) => {
          this.taxAgents = value;
        },
      });
  }

  displayServiceType(serviceType: string): string {
    const filteredServiceTypeList: { value: string; valueName: string }[] =
      this.serviceTypes.filter(
        (serviceTypeInList) => serviceTypeInList.value === serviceType
      );
    if (filteredServiceTypeList.length) {
      return filteredServiceTypeList[0].valueName;
    } else {
      return 'Could not match service type';
    }
  }
}
