<form
  [formGroup]="branchAddressesForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title title="Add Branch Address"></app-section-title>
    <div formArrayName="branchAddresses" fxLayout="column" fxLayoutGap="40px">
      <div
        *ngFor="
          let branchAddress of branchAddresses.controls;
          let index = index;
          let last = last
        "
        [ngClass]="last ? 'border-primary-color' : 'border-grey-color'"
        class="dashed-container padding-16 margin-bottom-16"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="32px"
          fxLayoutAlign="start start"
          fxLayout.xs="column"
          fxLayoutAlign.xs="stretch"
          fxLayoutGap.xs=""
          class="width-100"
          [formGroupName]="index"
        >
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Branch Number -->
            <mat-form-field appearance="outline">
              <mat-label>No.</mat-label>
              <input
                matInput
                type="text"
                formControlName="branchNumber"
                required
              />
              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('branchNumber')
                    ?.hasError('required')
                "
              >
                No. is required
              </mat-error>

              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('branchNumber')
                    ?.hasError('pattern')
                "
              >
                Only numbers allowed
              </mat-error>
            </mat-form-field>

            <!-- Address -->
            <mat-form-field appearance="outline" fxFlex="calc(50% - 16px)">
              <mat-label>Address</mat-label>
              <textarea
                matInput
                type="text"
                rows="4"
                formControlName="address"
                required
              ></textarea>
              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('address')
                    ?.hasError('required')
                "
              >
                Address is required
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Phone Number -->
            <mat-form-field class="contact-field" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <span matPrefix>+ &nbsp;</span>
              <input type="tel" matInput formControlName="contactNumber" />
              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('contactNumber')
                    ?.hasError('required')
                "
              >
                Phone number is required
              </mat-error>
            </mat-form-field>

            <!-- Email -->
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('email')
                    ?.hasError('email')
                "
              >
                Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  branchAddresses.controls[index]
                    .get('email')
                    ?.hasError('required')
                "
              >
                Email is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button
          mat-button
          type="button"
          color="warn"
          *ngIf="branchAddresses.controls.length > 1"
          (click)="removeBranchAddress(index)"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Remove</span>
        </button>
      </div>
    </div>
    <button
      mat-button
      type="button"
      color="primary"
      (click)="addBranchAddress()"
      [disabled]="branchAddresses.controls.length >= 5"
      [matTooltip]="
        branchAddresses.controls.length >= 5 ? 'Maximum 5 addresses only' : ''
      "
    >
      <mat-icon>add</mat-icon>
      <span>Branch Address</span>
    </button>
  </div>
</form>
