import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { CustomBreakpointService } from './../../../../../shared/breakpoint/custom-breakpoint.service';

@Component({
  selector: 'app-files-form',
  templateUrl: './files-form.component.html',
  styleUrls: ['./files-form.component.scss'],
})
export class FilesFormComponent implements OnInit {
  // Form
  @Input() filesForm!: FormGroup;
  @Input() pendingFiles!: PendingFile[];
  @Input() yearID!: number;

  // Loading
  isLoading = false;

  // Variables
  clientType!: string;

  // Responsive
  isMobile = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private customBreakpointService: CustomBreakpointService,
    private clientTypeService: ClientTypeService
  ) {
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;

    // Get device type
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {}
}
