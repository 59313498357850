import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ServicesService } from 'src/app/core/services/services/services.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { Device } from 'src/app/shared/enums/device.enum';
import { ServiceItem } from 'src/app/shared/models/services/service-item/service-item.model';
import { DeleteServiceDialogComponent } from '../delete-service-dialog/delete-service-dialog.component';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
})
export class ServiceListComponent implements OnInit, AfterViewInit {
  // Page Title & Subtitle
  title = '';
  subtitle = '';

  // Table
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<ServiceItem> =
    new MatTableDataSource<ServiceItem>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();

  // Services
  services: Array<ServiceItem> = [];

  // Loading
  isLoading = false;

  // Params
  filterService = '';
  receivedStart: Date | null = null;
  receivedEnd: Date | null = null;
  clientTypeStr: string;

  // Pagination
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  pageEvent!: PageEvent;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private clientTypeService: ClientTypeService,
    private servicesService: ServicesService,
    private dialog: MatDialog
  ) {
    this.clientTypeService.getClientType();
    this.clientTypeStr = this.clientTypeService.clientTypeStr;

    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.title = 'Secretarial';
            this.subtitle =
              'The legal process used to form a corporate entity or company';
            break;
          case ClientType.tax:
            this.title = 'Tax';
            this.subtitle =
              'Imposition of compulsory levies on taxpayers to government';
            break;
          case ClientType.audit:
            this.title = 'Audit';
            this.subtitle =
              'Examination of the financial report of the organization';
            break;
          case ClientType.shared_services:
            this.title = 'Shared Services';
            this.subtitle =
              'Process of recording financial transactions pertaining to a business';
            break;
          default:
            break;
        }
      },
    });

    // Responsive
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['name', 'status', 'options'];
        } else if (device === Device.tablet) {
          this.displayedColumns = [
            'name',
            'email',
            'status',
            'phoneNumber',
            'receivedDate',
            'options',
          ];
        } else {
          this.displayedColumns = [
            'name',
            'email',
            'status',
            'phoneNumber',
            'receivedDate',
            'options',
          ];
        }
      },
    });
  }

  ngOnInit(): void {
    this.getServices();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getServices(
    filterService?: string,
    pageSize?: number,
    currentPage?: number,
    receivedStart?: Date,
    receivedEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading services', '', {
      duration: undefined,
    });

    const params = {
      remarks: filterService ?? this.filterService,
      receivedDateFrom: this.datepipe.transform(
        receivedStart ?? this.receivedStart,
        'yyy-MM-dd 00:00:00'
      ),
      receivedDateTo: this.datepipe.transform(
        receivedEnd ?? this.receivedEnd,
        'yyy-MM-dd 23:59:59'
      ),
      page: currentPage ?? this.currentPage,
      size: pageSize ?? this.pageSize,
      department: this.clientTypeStr,
    };

    this.servicesService.getServices(params).subscribe({
      next: (data) => {
        if (data !== null) {
          this.length = data.totalItems;
          this.services = data.requests as ServiceItem[];
        } else {
          this.length = 0;
          this.services = [];
        }
        this.dataSource.data = this.services;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(pageEvent: PageEvent): PageEvent {
    this.currentPage = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.getServices();
    return pageEvent;
  }

  isToday(date: string): string | null {
    const theDate: Date = new Date(date);

    const today: Date = new Date();

    if (
      theDate.getDate() === today.getDate() &&
      theDate.getMonth() === today.getMonth() &&
      theDate.getFullYear() === today.getFullYear()
    ) {
      return this.datepipe.transform(date, 'shortTime');
    } else {
      return this.datepipe.transform(date, 'mediumDate');
    }
  }

  markAsReadPressed(serviceDetails: ServiceItem): void {
    this.isLoading = true;
    const isRead = serviceDetails?.hasRead;
    this.snackBar.open(
      `Marking "${serviceDetails.name}" as ${isRead ? 'unread' : 'read'}`,
      '',
      {
        duration: undefined,
      }
    );
    const formData = {
      hasRead: !isRead,
    };

    this.servicesService.updateService(formData, serviceDetails.id).subscribe({
      next: (_) => {
        this.getServices();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(
          `"${serviceDetails.name}" marked as ${isRead ? 'unread' : 'read'}`,
          '',
          {
            duration: 3000,
          }
        );
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }

  showDeleteDialog(serviceDetails: ServiceItem): void {
    const dialogRef = this.dialog.open(DeleteServiceDialogComponent, {
      data: { serviceDetails },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteService(serviceDetails);
        }
      },
    });
  }

  deleteService(serviceDetails: ServiceItem): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${serviceDetails.name}"`, '', {
      duration: undefined,
    });
    this.servicesService.deleteService(serviceDetails.id).subscribe({
      next: (result) => {
        this.getServices();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${serviceDetails.name}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }
}
