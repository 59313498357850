<form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
  <mat-form-field appearance="outline" class="width-100">
    <button matPrefix mat-icon-button type="button" (click)="applyFilter()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-label>Search user</mat-label>
    <input matInput type="text" formControlName="filterName" />
    <button
      mat-icon-button
      matSuffix
      type="button"
      [matMenuTriggerFor]="filters"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #filters="matMenu" class="padding-16">
      <div fxLayout="column">
        <!-- Roles -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Roles</mat-label>
          <mat-select formControlName="roles" multiple>
            <mat-option *ngFor="let role of roleList" [value]="role.role">
              {{ role.role }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Status -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option>All</mat-option>
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{ status.valueName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Created Date -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Created Date (Range)</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              matStartDate
              formControlName="createdStart"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="createdEnd"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-button (click)="resetFilters()">CLEAR</button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="applyFilter()"
          >
            FILTER
          </button>
        </div>
      </div>
    </mat-menu>
  </mat-form-field>
</form>
