import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Device } from 'src/app/shared/enums/device.enum';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';
import { EnquiriesService } from './../../core/services/enquiries/enquiries.service';
import { CustomBreakpointService } from './../../shared/breakpoint/custom-breakpoint.service';
import { DeleteEnquiriesDialogComponent } from './delete-enquiries-dialog/delete-enquiries-dialog.component';

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.scss'],
})
export class EnquiriesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'title', 'createdDate', 'options'];
  dataSource: MatTableDataSource<Enquiry> = new MatTableDataSource<Enquiry>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  filterEnquiry = '';
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  enquiries: Array<Enquiry> = [];
  isLoading = false;
  status: number | null = null;
  receivedStart: Date | null = null;
  receivedEnd: Date | null = null;

  constructor(
    public dialog: MatDialog,
    private enquiriesService: EnquiriesService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private customBreakpointService: CustomBreakpointService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['title', 'options'];
        } else {
          this.displayedColumns = ['name', 'title', 'createdDate', 'options'];
        }
      },
    });
  }

  ngOnInit(): void {
    this.getEnquiries();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getEnquiries(
    filterEnquiry?: string,
    pageSize?: number,
    currentPage?: number,
    status?: number,
    receivedStart?: Date,
    receivedEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: undefined,
    });

    const params = {
      description: filterEnquiry ?? this.filterEnquiry,
      page: currentPage ?? this.currentPage,
      size: pageSize ?? this.pageSize,
      read: status ?? this.status,
      receivedDateFrom: this.datepipe.transform(
        receivedStart ?? this.receivedStart,
        'yyy-MM-dd 00:00:00'
      ),
      receivedDateTo: this.datepipe.transform(
        receivedEnd ?? this.receivedEnd,
        'yyy-MM-dd 23:59:59'
      ),
    };

    this.enquiriesService.getEnquiries(params).subscribe({
      next: (data) => {
        if (data != null) {
          this.length = data.totalItems;
          this.enquiries = data.enquiries as Enquiry[];
        } else {
          this.length = 0;
          this.enquiries = [] as Enquiry[];
        }
        this.dataSource.data = this.enquiries;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(e: PageEvent): void {
    console.log(e);
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getEnquiries();
  }

  showDeleteDialog(enquiry: Enquiry): void {
    const dialogRef = this.dialog.open(DeleteEnquiriesDialogComponent, {
      data: { enquiry },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteEnquiry(enquiry);
        }
      },
    });
  }

  markAsReadPressed(enquiry: Enquiry): void {
    this.isLoading = true;
    const isRead = enquiry.hasRead;
    this.snackBar.open(
      `Marking "${enquiry.title}" as ${isRead ? 'unread' : 'read'}`,
      '',
      {
        duration: undefined,
      }
    );
    const params: { [x: string]: any } = {
      hasRead: !isRead,
    };
    this.enquiriesService
      .updateEnquiry(params as Map<any, any>, enquiry.id as number)
      .subscribe({
        next: (result) => {
          this.getEnquiries();
          this.isLoading = false;
          this.snackBar.dismiss();
          this.snackBar.open(
            `"${enquiry.title}" marked as ${isRead ? 'unread' : 'read'}`,
            '',
            {
              duration: 3000,
            }
          );
        },
        error: (error) => {
          this.isLoading = false;
          this.snackBar.dismiss();
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
  }

  deleteEnquiry(enquiry: Enquiry): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${enquiry.title}"`, '', {
      duration: undefined,
    });
    this.enquiriesService.deleteEnquiry(enquiry.id as number).subscribe({
      next: (result) => {
        this.getEnquiries();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${enquiry.title}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }

  isToday(date: string): string | null {
    const theDate: Date = new Date(date);

    const today: Date = new Date();

    if (
      theDate.getDate() === today.getDate() &&
      theDate.getMonth() === today.getMonth() &&
      theDate.getFullYear() === today.getFullYear()
    ) {
      return this.datepipe.transform(date, 'shortTime');
    } else {
      return this.datepipe.transform(date, 'mediumDate');
    }
  }
}
