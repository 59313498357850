<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/clients', clientType, 'details', client.value.id]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span class="capitalize">{{ client.value.name }}</span>
      </button>
      <app-page-title
        title="Edit Client — Audit"
        subtitle="{{ client.value ? client.value.name : 'Loading' }}"
      ></app-page-title>
    </div>
  </div>
  <app-client-form
    [generalCompanyDetailsForm]="generalCompanyDetailsForm"
    [contactsForm]="contactsForm"
    [auditorsForm]="auditorsForm"
    [isCreate]="isCreate"
    [client]="client"
    [useCreateAPI]="useCreateAPI"
  ></app-client-form>
  <button
    ngClass="extended-fab-button floating-bottom-right"
    ngClass.xs="extended-fab-button"
    mat-fab
    color="primary"
    type="submit"
    [disabled]="clientIsSaving"
    (click)="saveClientPressed()"
  >
    <mat-icon>save_outline</mat-icon>
    <span class="extended-fab-button__text">Save</span>
  </button>
</section>
