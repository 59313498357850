import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { AuditClientsComponent } from '../audit-clients.component';

@Component({
  selector: 'app-audit-clients-filter',
  templateUrl: './audit-clients-filter.component.html',
  styleUrls: ['./audit-clients-filter.component.scss'],
})
export class AuditClientsFilterComponent implements OnInit {
  companyTypes: Array<{ value: string; valueName: string }>;
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private formBuilder: FormBuilder,
    private clientFormConstants: ClientFormConstants,
    private auditClientsComponent: AuditClientsComponent
  ) {
    this.companyTypes = this.clientFormConstants.COMPANY_TYPES;
    this.filterForm = this.formBuilder.group({
      filterClient: new FormControl(''),
      companyType: new FormControl('ALL'),
      createdStart: new FormControl(null),
      createdEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.auditClientsComponent.pageSize;
    this.currentPage = this.auditClientsComponent.currentPage;

    const filterClient = this.filterForm.get('filterClient')?.value;
    const companyType = this.filterForm.get('companyType')?.value;
    const createdStart = this.filterForm.get('createdStart')?.value;
    const createdEnd = this.filterForm.get('createdEnd')?.value;

    this.auditClientsComponent.getAuditClients(
      filterClient,
      this.pageSize,
      this.currentPage,
      companyType,
      createdStart,
      createdEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.auditClientsComponent.getAuditClients();
  }
}
