<h2 mat-dialog-title>{{ service.title }} not subscribed</h2>
<mat-dialog-content class="mat-typography">
  <h3>It seems like you have not subscribed to "{{ service.title }}".</h3>
  <p>
    Would you like to learn more about "{{ service.title }}" on our official
    website?
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    Visit
  </button>
</mat-dialog-actions>
