<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/clients', clientType]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span>Clients — </span>
        <span class="capitalize">{{
          clientType !== "shared_services" ? clientType : "Shared Services"
        }}</span>
      </button>
      <app-page-title
        title="New Client — Shared Services"
        subtitle="Manage companies, all at one place"
      ></app-page-title>
    </div>
  </div>

  <app-client-form
    [generalCompanyDetailsForm]="generalCompanyDetailsForm"
    [contactsForm]="contactsForm"
    [auditorsForm]="auditorsForm"
    [taxAgentsForm]="taxAgentsForm"
    [isCreate]="isCreate"
    [client]="client"
    [useCreateAPI]="useCreateAPI"
  ></app-client-form>
  <button
    ngClass="extended-fab-button floating-bottom-right"
    ngClass.xs="extended-fab-button"
    mat-fab
    color="primary"
    type="submit"
    [disabled]="clientIsCreating"
    (click)="createClientPressed()"
  >
    <mat-icon>add</mat-icon>
    <span class="extended-fab-button__text">Create</span>
  </button>
</section>
