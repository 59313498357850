<h2 mat-dialog-title>Delete User</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to delete user?</h3>
  <p class="dialog-info-container">
    <span>Name: {{ user.name }}</span
    ><br /><span>Email: {{ user.email }}</span>
  </p>
  <p></p>
  <p>
    The user will lose his/her access to the system. <br />
    You will not be able to retrieve this data again.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
