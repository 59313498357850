import { Component, Input, OnInit } from '@angular/core';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';

@Component({
  selector: 'app-entity-year-details',
  templateUrl: './entity-year-details.component.html',
  styleUrls: ['./entity-year-details.component.scss'],
})
export class EntityYearDetailsComponent implements OnInit {
  @Input() entityYearDetails!: ClientYearDetails;
  @Input() isLoading = false;

  constructor() {}

  ngOnInit(): void {}
}
