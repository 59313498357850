<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="24px"
  class="padding-24"
>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <!-- Start Title -->
    <app-page-title
      title="Welcome back,"
      secondLineTitle="{{ user ? user.name : 'Loading' }}"
      subtitle=""
    ></app-page-title>
    <!-- End Title -->
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px">
    <app-weekly-stats
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isAdmin"
    ></app-weekly-stats>
    <app-activities
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isAdmin"
    ></app-activities>
    <app-unread-enquiries
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isCustodian"
    ></app-unread-enquiries>
    <app-assigned-department-requests
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isCustodian"
    ></app-assigned-department-requests>
    <app-quick-actions
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isCustomer"
    ></app-quick-actions>
    <app-request-status
      fxFlex="calc(50% - 16px)"
      fxFlex.xs="calc(100%)"
      *ngIf="isCustomer"
    ></app-request-status>
  </div>
  <app-user-notifications *ngIf="isCustomer"></app-user-notifications>
</section>
