import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { IdentificationType } from 'src/app/shared/models/identification-type/identification-type.model';

@Component({
  selector: 'app-contact-person-form',
  templateUrl: './contact-person-form.component.html',
  styleUrls: ['./contact-person-form.component.scss'],
})
export class ContactPersonFormComponent implements OnInit {
  // Form
  @Input() contactsForm!: FormGroup;

  contactPersonTypesOptions: BehaviorSubject<Array<String>> =
    new BehaviorSubject<Array<String>>([]);

  // Responsive
  isMobile = true;

  constructor(
    private formBuilder: FormBuilder,
    private customBreakpointService: CustomBreakpointService,
    private clientsService: ClientsService,
    private snackBar: MatSnackBar
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.getContactPersonType();
    this.createOftenContactPersonItem();
  }

  getContactPersonType() {
    this.clientsService.getContactPersonType().subscribe({
      next: (contactPersonType: string[]) => {
        if (contactPersonType) {
          this.contactPersonTypesOptions.next(contactPersonType);
        } else {
          this.contactPersonTypesOptions.next([]);
        }
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  get oftenContactPersons(): FormArray {
    return this.contactsForm.get('oftenContactPersons') as FormArray;
  }

  createOftenContactPersonItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      businessEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      personalEmail: new FormControl('', [Validators.email]),
      contactPersonTypeStr: new FormControl('', Validators.required),
    });
  }

  addOftenContactPerson(): void {
    this.oftenContactPersons.push(this.createOftenContactPersonItem());
  }

  removeOftenContactPerson(index: number): void {
    this.oftenContactPersons.removeAt(index);
  }
}
