import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YearFile } from 'src/app/shared/models/year-file/year-file.model';

@Component({
  selector: 'app-delete-file-dialog',
  templateUrl: './delete-file-dialog.component.html',
  styleUrls: ['./delete-file-dialog.component.scss'],
})
export class DeleteFileDialogComponent implements OnInit {
  selectedFile: YearFile;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedFile = data.selectedFile;
  }

  ngOnInit(): void {}
}
