<h2 mat-dialog-title>Populate Form</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to populate form with existing client?</h3>
  <p class="dialog-info-container">
    <span>Client Name: {{ client.name }}</span>
    <br />
    <span>
      Company Number (New):
      {{ client.companyNumberNew ? client.companyNumberNew : "Not available" }}
    </span>
    <br />
    <span>
      Company Number (Old):
      {{ client.companyNumberOld ? client.companyNumberOld : "Not available" }}
    </span>
    <br />
    <span>
      Incorporation Date:
      {{ client.dateOfIncorp ? (client.dateOfIncorp | date) : "Not available" }}
    </span>
    <br />
    <span>
      Exist in department:
      {{ departmentsStr ? departmentsStr : "Not available" }}
    </span>
  </p>
  <p>
    The form will be populated with the above data. <br />
    You may reset the form by emptying any company number.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="primary">Populate</button>
</mat-dialog-actions>
