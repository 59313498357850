import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-rename-file-dialog',
  templateUrl: './rename-file-dialog.component.html',
  styleUrls: ['./rename-file-dialog.component.scss'],
})
export class RenameFileDialogComponent implements OnInit {
  formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: FormGroup) {
    this.formGroup = data;
  }

  ngOnInit(): void {}
}
