import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '../api_service/api.service';
import { Year } from 'src/app/shared/models/year/year.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerEntitiesService {
  constructor(private apiService: ApiService) {}

  getCustomerEntities(userID: number): Observable<any> {
    return this.apiService.get(`/api/core/customer/clients/${userID}`);
  }

  getCustomerEntitiesAvailableYears(clientID: number): Observable<Array<Year>> {
    return this.apiService.get(`/api/core/customer/clients/years/${clientID}`);
  }
}
