<div fxLayout="column">
  <p class="bold-500">Requests</p>
  <div
    class="border border-color border-radius-8 requests-container"
    ngClass="desktop"
    ngClass.xs="mobile"
  >
    <mat-list role="list" *ngIf="isLoading">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">Loading</p>
        <p mat-line class="subtitle">Loading</p>
        <small class="subtitle">Loading</small>
      </mat-list-item>
    </mat-list>
    <mat-list role="list" *ngIf="!isLoading && !requests.length">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">No requests available</p>
      </mat-list-item>
    </mat-list>
    <mat-nav-list *ngIf="!isLoading && requests">
      <a
        mat-list-item
        role="listitem"
        *ngFor="let request of requests; let isLast = last"
        [routerLink]="['/requests', 'details', request.id]"
      >
        <p mat-line>{{ request.user.name }}</p>
        <p mat-line>
          <span>{{ request.title }}</span>
          <span class="subtitle">
            — {{ request.remarks ? request.remarks : "Not available" }}</span
          >
        </p>
        <small
          fxFlex="20%"
          fxFlex.xs="20%"
          fxFlex.md="25%"
          fxFlex.sm="23%"
          class="text-align-right subtitle"
          >{{ getTimeAgo(request.createdDate) }}</small
        >
        <mat-divider *ngIf="!isLast"></mat-divider>
      </a>
    </mat-nav-list>
  </div>
</div>
