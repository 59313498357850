<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Clients — Shared Services"
      subtitle="Manage companies, all at one place"
    ></app-page-title>
    <button
      class="extended-fab-button"
      mat-fab
      color="primary"
      routerLink="create"
    >
      <mat-icon>add</mat-icon>
      <span class="extended-fab-button__text">Client</span>
    </button>
  </div>

  <app-shared-services-clients-filter></app-shared-services-clients-filter>

  <!-- Table -->
  <mat-table
    [fxHide]="!clients.length"
    [dataSource]="dataSource"
    matSort
    matSortActive="id"
    matSortDirection="desc"
    class="margin-bottom-16"
  >
    <!-- Client ID -->
    <ng-container matColumnDef="id">
      <mat-header-cell mat-sort-header="id" *matHeaderCellDef>
        ID
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">{{ data.id }}</mat-cell>
    </ng-container>

    <!-- Company Name -->
    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header="name" *matHeaderCellDef>
        Company Name
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">{{ data.name }}</mat-cell>
    </ng-container>

    <!-- Company Number -->
    <ng-container matColumnDef="companyNumberNew">
      <mat-header-cell mat-sort-header="companyNumberNew" *matHeaderCellDef>
        <span class="text-align-left">Company No. <br />New/ Old</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <div fxLayout="column">
          <span
            matTooltip="{{ data.companyNumberNew }}"
            [ngClass]="data.companyNumberNew ? '' : 'not-available'"
          >
            {{
              data.companyNumberNew ? data.companyNumberNew : "Not available"
            }}/
          </span>
          <small
            matTooltip="{{ data.companyNumberOld }}"
            [ngClass]="data.companyNumberOld ? '' : 'not-available'"
            >{{
              data.companyNumberOld ? data.companyNumberOld : "Not available"
            }}</small
          >
        </div>
      </mat-cell>
    </ng-container>

    <!-- Group -->
    <ng-container matColumnDef="group">
      <mat-header-cell mat-sort-header="group" *matHeaderCellDef>
        Group
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="!data.group ? 'not-available' : ''"
        class="ellipsis"
        >{{ data.group ? data.group : "Not available" }}</mat-cell
      >
    </ng-container>

    <!-- Year End -->
    <ng-container matColumnDef="yearEnd">
      <mat-header-cell mat-sort-header="yearEnd" *matHeaderCellDef>
        Year End
      </mat-header-cell>
      <mat-cell
        mat-cell
        *matCellDef="let data"
        [ngClass]="data.yearEnd == null ? 'not-available' : ''"
        >{{ data.yearEnd ? data.yearEnd : "Not available" }}</mat-cell
      >
    </ng-container>

    <!-- More info -->
    <ng-container matColumnDef="options" ngClass.xs="mobile">
      <mat-header-cell *matHeaderCellDef>
        <span class="text-align-center">More info</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (click)="getListOfAvailableYears(data.id)"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['details', data.id]">
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-menu-item [routerLink]="['edit', data.id]">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="years"
            [disabled]="yearIsLoading || !availableYears.length"
            [ngClass]="{ shimmer: yearIsLoading }"
          >
            <mat-icon>history</mat-icon>
            <span>Year Details</span>
          </button>
          <mat-divider
            *ngIf="!yearIsLoading && !clientHasCurrentYear"
          ></mat-divider>
          <button
            mat-menu-item
            *ngIf="!yearIsLoading && !clientHasCurrentYear"
            [routerLink]="['details', data.id, 'year', 'create']"
          >
            <mat-icon>add</mat-icon>
            <span>Add Year Details</span>
          </button>
        </mat-menu>

        <mat-menu #years="matMenu">
          <button
            mat-menu-item
            *ngFor="let year of availableYears"
            [routerLink]="['details', data.id, 'year', year.id]"
          >
            {{ year.year }}
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <div
    *ngIf="!clients.length"
    class="empty-table-message"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    Could not find any data
  </div>
  <mat-paginator
    #paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = onPageChanged($event)"
  >
  </mat-paginator>
</section>
