<form
  [formGroup]="userForm"
  fxLayout.xs="column"
  fxLayoutAlign="stretch"
  fxLayoutGap.xs="0px"
  fxLayout="row wrap"
  fxLayoutGap="40px grid"
>
  <!-- Account Information Section -->
  <div fxLayout="column" fxFlex.xs="100%" fxFlex="calc(50%-40px)">
    <app-section-title title="Account Information"></app-section-title>

    <!-- Roles -->
    <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
      <mat-label>Roles</mat-label>
      <mat-select formControlName="roles" multiple required>
        <mat-option
          *ngFor="let role of roleList"
          [value]="role.role"
          [disabled]="isOptionDisabled(role)"
        >
          {{ role.role }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.controls['roles'].hasError('required')">
        At least 1 role is required
      </mat-error>
    </mat-form-field>

    <!-- Email -->
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email" required />
      <mat-icon matSuffix>alternate_email</mat-icon>
      <mat-error *ngIf="userForm.controls['email'].hasError('email')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="userForm.controls['email'].hasError('required')">
        Email is required
      </mat-error>
    </mat-form-field>

    <!-- Status -->
    <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status" required>
        <mat-option *ngFor="let status of statuses" [value]="status.value">
          {{ status.valueName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.controls['status'].hasError('required')">
        Status is required
      </mat-error>
    </mat-form-field>

    <!-- Branch -->
    <mat-form-field
      appearance="outline"
      (click)="$event.stopPropagation()"
      [fxHide]="isCustomer"
    >
      <mat-label>Branch</mat-label>
      <mat-select formControlName="branch" required>
        <mat-option *ngFor="let branch of branches" [value]="branch.valueName">
          {{ branch.valueName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.controls['branch'].hasError('required')">
        Branch is required
      </mat-error>
    </mat-form-field>

    <!-- Department -->
    <mat-form-field
      appearance="outline"
      (click)="$event.stopPropagation()"
      [fxHide]="isCustomer"
    >
      <mat-label>Department</mat-label>
      <mat-select formControlName="department" [required]="!isCustomer">
        <mat-option
          *ngFor="let department of departments"
          [value]="department.value"
        >
          {{ department.valueName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="userForm.controls['department'].hasError('required')">
        Department is required
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Personal Information Section -->
  <div fxLayout="column" fxFlex.xs="100%" fxFlex="calc(50%-40px)">
    <app-section-title title="Personal Information"></app-section-title>

    <!-- Name -->
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required />
      <mat-error *ngIf="userForm.controls['name'].hasError('required')">
        Name is required
      </mat-error>
    </mat-form-field>

    <!-- Identification Type -->
    <mat-form-field
      appearance="outline"
      (click)="$event.stopPropagation()"
      [fxHide]="!isCustomer"
    >
      <mat-label>Identification Type</mat-label>
      <mat-select formControlName="identificationType" [required]="isCustomer">
        <mat-option *ngIf="!isCustomer">None</mat-option>
        <mat-option *ngFor="let idType of idTypes" [value]="idType.type">
          {{ idType.type }}
        </mat-option>
      </mat-select>
      <mat-error
        *ngIf="userForm.controls['identificationType'].hasError('required')"
      >
        Identification Type is required
      </mat-error>
    </mat-form-field>

    <!-- ID Number -->
    <mat-form-field appearance="outline" [fxHide]="!isCustomer">
      <mat-label>Identification Number</mat-label>
      <input
        matInput
        type="text"
        formControlName="identificationNumber"
        [placeholder]="idTypeIsIC ? 'eg. 123456-12-1234' : ''"
        [mask]="idTypeIsIC ? '000000-00-0000' : ''"
        [dropSpecialCharacters]="false"
        [required]="isCustomer"
      />
      <mat-error
        *ngIf="userForm.controls['identificationNumber'].hasError('required')"
      >
        Identification Number is required
      </mat-error>
      <mat-error
        *ngIf="userForm.controls['identificationNumber'].hasError('pattern')"
      >
        Identification Number is invalid
      </mat-error>
    </mat-form-field>
  </div>

  <!-- Contact Information Section -->
  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    [fxHide]="!isCustomer"
  >
    <app-section-title title="Contact Information"></app-section-title>
    <!-- Contact Number -->
    <mat-form-field class="contact-field" appearance="outline">
      <mat-label>Contact Number</mat-label>
      <span matPrefix>+ &nbsp;</span>
      <input
        type="tel"
        matInput
        formControlName="handphoneNumber"
        [required]="isCustomer"
      />
      <mat-error
        *ngIf="userForm.controls['handphoneNumber'].hasError('required')"
      >
        Contact Number is required
      </mat-error>
    </mat-form-field>
  </div>

  <div
    fxLayout="column"
    fxFlex.xs="100%"
    fxFlex="calc(50%-40px)"
    *ngIf="isCustomer"
  >
    <app-section-title title="Client Information"></app-section-title>
    <mat-form-field appearance="outline">
      <mat-label>Search Client</mat-label>
      <input
        matInput
        type="text"
        formControlName="clientAutoComplete"
        [matAutocomplete]="autoCompleteClient"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #autoCompleteClient="matAutocomplete"
      >
        <mat-option
          *ngFor="let client of clientOptions | async"
          class="multiline-options"
          (onSelectionChange)="onClientSelected(client)"
          [disabled]="client.users.length > 0"
          matTooltip="{{
            client.users.length > 0 ? 'This client has existing owner' : ''
          }}"
        >
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxFlex="80%">
              <span class="ellipsis">{{ client.name }}</span>
              <small>{{ client.companyNumberNew }}</small>
            </div>
            <small [ngClass]="client.users.length ? 'occupied' : 'available'">{{
              client.users.length ? "Has Owner" : "Available"
            }}</small>
          </div>
        </mat-option>
      </mat-autocomplete>
      <!-- Loader -->
      <mat-spinner
        matSuffix
        diameter="18"
        matTooltip="Checking client"
        *ngIf="clientIsLoading"
      ></mat-spinner>
    </mat-form-field>
    <mat-list
      role="list"
      *ngIf="selectedClients.length"
      class="border border-color border-radius-8 padding-bottom-8"
    >
      <mat-list-item
        role="listitem"
        *ngFor="
          let client of selectedClients;
          let isLast = last;
          let index = index
        "
      >
        <p mat-line>{{ client.name }}</p>
        <p mat-line class="subtitle">{{ client.companyNumberNew }}</p>
        <small mat-line *ngIf="client.users?.length" class="italic">
          <span
            *ngFor="let user of client.users; let isLast = last"
            class="linked-users"
          >
            {{ user.name }}{{ isLast ? "" : ", " }}
          </span>
        </small>
        <button
          mat-icon-button
          type="button"
          matTooltip="Remove client from user"
          (click)="removeUserLink(index)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        <mat-divider *ngIf="!isLast"></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
</form>
