import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { IdentificationType } from 'src/app/shared/models/identification-type/identification-type.model';
import { Role } from 'src/app/shared/models/role/role.model';
import { Department } from 'src/app/shared/models/department/department.model';
import { Client } from 'src/app/shared/models/client/client.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientsService } from 'src/app/core/services/clients/clients.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input() userForm: FormGroup = new FormGroup({});
  @Input() isCustomerForm!: Observable<boolean>;
  @Input() selectedClients!: Client[];

  // Options
  roleList: Array<Role>;
  departments: Array<Department>;
  statuses: Array<{ value: number; valueName: string }>;
  branches: Array<{ value: number; valueName: string }>;
  idTypes: Array<IdentificationType>;
  clientOptions: BehaviorSubject<Array<Client>> = new BehaviorSubject<
    Array<Client>
  >([]);

  // Variables
  idTypeIsIC = true;
  isCustomer = true;

  // Subscriptions
  idTypeSubscription: Subscription | undefined;
  clientAutoCompleteSubscription!: Subscription;

  // Regex
  malaysianICRegex = '';

  // Loading
  clientIsLoading = false;

  constructor(
    private userFormConstants: UserFormConstants,
    private clientsService: ClientsService
  ) {
    this.statuses = this.userFormConstants.STATUSES;
    this.departments = this.userFormConstants.DEPARTMENTS;
    this.roleList = this.userFormConstants.ROLES;
    this.idTypes = this.userFormConstants.IDTYPE;
    this.malaysianICRegex = this.userFormConstants.MALAYSIAN_IC_REGEX;
    this.branches = this.userFormConstants.BRANCHES;
  }
  ngOnDestroy(): void {
    if (this.idTypeSubscription) {
      this.idTypeSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.checkRequiredFields(this.userForm);
    this.isCustomerForm.subscribe({
      next: (isCustomerForm) => {
        this.isCustomer = isCustomerForm;
        if (isCustomerForm) {
          this.subscribeToIDType();
        }
      },
    });
    this.subscribeToClientAutoComplete();
  }

  checkRequiredFields(input: any): void {
    if (input === null) {
      throw new Error(`Attribute 'userForm' is required`);
    }
  }

  subscribeToIDType(): void {
    this.idTypeSubscription =
      this.userForm.controls.identificationType.valueChanges.subscribe({
        next: (result) => {
          if (result === 'IC') {
            this.idTypeIsIC = true;
            this.userForm.removeControl('identificationNumber');
            this.userForm.addControl(
              'identificationNumber',
              new FormControl('', [
                Validators.required,
                Validators.pattern(this.malaysianICRegex),
              ])
            );
          } else {
            this.idTypeIsIC = false;
            this.userForm.removeControl('identificationNumber');
            this.userForm.addControl(
              'identificationNumber',
              new FormControl('')
            );
          }
        },
      });
  }

  subscribeToClientAutoComplete(): void {
    this.clientAutoCompleteSubscription =
      this.userForm.controls.clientAutoComplete.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe({
          next: (value: string) => {
            this.getMatchingClients(value);
          },
        });
  }

  getMatchingClients(clientName: string): void {
    if (clientName?.length > 0) {
      this.clientIsLoading = true;
      const formData = {
        companyName: clientName,
      };
      this.clientsService.getClients(formData).subscribe({
        next: (data) => {
          if (data !== null) {
            this.clientOptions.next(data.clients as Client[]);
          } else {
            this.clientOptions.next([]);
          }
          this.clientIsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.clientIsLoading = false;
        },
      });
    } else {
      this.clientOptions.next([]);
    }
  }

  isOptionDisabled(role: Role): boolean {
    const selectedRoles: Array<string> =
      this.userForm.get('roles')?.value || [];
    if (selectedRoles !== null && selectedRoles.length) {
      // If Customer option is selected
      if (selectedRoles.includes('Customer') === false) {
        if (role.role !== 'Customer') {
          return false;
        } else {
          return true;
        }
      } else {
        // If Customer option is not selected
        if (role.role === 'Customer') {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  onClientSelected(client: Client): void {
    this.selectedClients.push(client);
  }

  removeUserLink(index: number): void {
    this.selectedClients.splice(index, 1);
  }
}
