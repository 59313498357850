import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  constructor() {}

  getToken(): string {
    const token = localStorage.getItem('jwtToken');
    return token !== null ? JSON.parse(token) : null;
  }

  getUserId(): string {
    const userId = localStorage.getItem('userId');
    return userId !== null ? JSON.parse(userId) : null;
  }

  saveToken(token: string): void {
    localStorage.setItem('jwtToken', JSON.stringify(token));
  }

  saveUserId(id: number): void {
    localStorage.setItem('userId', JSON.stringify(id));
  }

  destroyToken(): void {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
  }

  contentTypeIsFormData(): boolean {
    const contentType = localStorage.getItem('contentType');
    return contentType === 'multipart/form-data';
  }

  saveContentType(contentType: string): void {
    localStorage.setItem('contentType', contentType);
  }

  destroyContentType(): void {
    localStorage.removeItem('contentType');
  }
}
