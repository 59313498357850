import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { RequestFormConstants } from 'src/app/shared/constants/request-form.constants';
import { RequestsComponent } from '../requests.component';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-requests-filter',
  templateUrl: './requests-filter.component.html',
  styleUrls: ['./requests-filter.component.scss'],
})
export class RequestsFilterComponent implements OnInit {
  statuses: Array<{ value: string; valueName: string }>;
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private requestsComponent: RequestsComponent,
    private requestFormConstants: RequestFormConstants,
    private formBuilder: FormBuilder
  ) {
    this.statuses = this.requestFormConstants.STATUSES;
    this.filterForm = this.formBuilder.group({
      filterRequest: new FormControl(''),
      status: new FormControl(null),
      receivedStart: new FormControl(null),
      receivedEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.requestsComponent.pageSize;
    this.currentPage = this.requestsComponent.currentPage;

    const filterRequest = this.filterForm.get('filterRequest')?.value;
    const status = this.filterForm.get('status')?.value;
    const receivedStart = this.filterForm.get('receivedStart')?.value;
    const receivedEnd = this.filterForm.get('receivedEnd')?.value;
    this.requestsComponent.getRequests(
      filterRequest,
      this.pageSize,
      this.currentPage,
      status,
      receivedStart,
      receivedEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.requestsComponent.getRequests();
  }
}
