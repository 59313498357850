import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceItem } from 'src/app/shared/models/services/service-item/service-item.model';
import { ApiService } from '../api_service/api.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  constructor(private apiService: ApiService) {}

  // Get Services
  getServices(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/servicessearch', data);
  }

  getServiceDetails(serviceID: number): Observable<ServiceItem> {
    return this.apiService.get(`/api/core/services/${serviceID}`);
  }

  // Update Service
  updateService(data: object = {}, serviceID: number): Observable<ServiceItem> {
    return this.apiService.put(`/api/core/services/${serviceID}`, data);
  }

  // Delete Service
  deleteService(serviceID: number): Observable<any> {
    return this.apiService.delete(`/api/core/services/${serviceID}`);
  }
}
