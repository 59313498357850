<div
  fxFlex="calc(50% - 16px)"
  fxFlex.xs="100%"
  fxLayout="column"
  fxLayoutAlign="stretch"
>
  <p class="bold-500">{{ title ? title : "Requests" }}</p>
  <!-- Start Not Available -->
  <p *ngIf="requests.length < 1" class="not-available">
    No {{ title.toLowerCase() }} available
  </p>
  <!-- End Not Available -->
  <mat-accordion *ngIf="requests.length > 0">
    <mat-expansion-panel
      class="mat-elevation-z0"
      *ngFor="let request of requests; let isFirst = first; let isLast = last"
      [class.first]="isFirst"
      [class.last]="isLast"
      (opened)="getCustomerRequestDetails(request)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span>{{ request.title }}</span>
          <!-- Start Status -->
          <span
            *ngIf="request.status.id === 1"
            matTooltip="Pending"
            class="status-pending"
            >{{ request.status.id === 1 ? "Pending" : "" }}</span
          >
          <span
            *ngIf="request.status.id === 2"
            matTooltip="Assigned to Department"
            class="status-assigned-department"
            >{{ request.status.id === 2 ? "Assigned to Department" : "" }}</span
          >
          <span
            *ngIf="request.status.id === 3"
            matTooltip="Assigned to Personnel"
            class="status-assigned-personnel"
            >{{ request.status.id === 3 ? "Assigned to Personnel" : "" }}</span
          >
          <span
            *ngIf="request.status.id === 4"
            matTooltip="Completed"
            class="status-completed"
            >{{ request.status.id === 4 ? "Completed" : "" }}</span
          >
          <!-- End Status -->
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="margin-top-16">
        <!-- Start timeline -->
        <div
          fxLayout="row"
          class="shimmer padding-16 border-radius-8 margin-bottom-24"
          *ngIf="isLoading"
        >
          <p class="no-margin not-available">Loading</p>
        </div>
        <div
          class="
            padding-16
            margin-bottom-24
            border border-radius-8 border-color
          "
          *ngIf="!isLoading"
          fxLayout="column"
          fxLayoutAlign="stretch"
          fxLayoutGap="16px"
        >
          <p
            *ngFor="let request of requestDetails"
            class="capitalize no-margin"
          >
            {{ request.revisionDate | date }}:
            {{ request.status.split("_").join(" ").toLowerCase() }}
          </p>
        </div>
        <!-- End timeline -->
        <!-- Start Title and Remarks -->
        <div>
          <p>Title: {{ request.title }}</p>
          <p>Remarks: {{ request.remarks }}</p>
          <button
            mat-stroked-button
            *ngIf="request.fileName !== null"
            (click)="downloadAttachmentPressed(request)"
          >
            <mat-icon>download</mat-icon>
            <span class="margin-left-8">{{ request.fileName }}</span>
          </button>
        </div>
        <!-- End Title and Remarks -->
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
