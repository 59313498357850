import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() title = 'Eurogain';
  @Input() secondLineTitle = '';
  @Input() subtitle = 'Eurogain';
  @Input() titleCapitalize = false;
  @Input() subtitleCapitalize = false;

  constructor() {}

  ngOnInit(): void {}
}
