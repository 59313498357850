import { UsersComponent } from './../users.component';
import { UsersService } from './../../../core/services/users/users.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Role } from 'src/app/shared/models/role/role.model';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss'],
})
export class UsersFilterComponent implements OnInit {
  roleList: Array<Role>;
  statuses: Array<{ value: number; valueName: string }>;
  filterForm = new FormGroup({
    filterName: new FormControl(''),
    roles: new FormControl(null),
    status: new FormControl(null),
    createdStart: new FormControl(null),
    createdEnd: new FormControl(null),
  });
  pageSize = 10;
  currentPage = 0;

  constructor(
    private usersComponent: UsersComponent,
    private userFormConstants: UserFormConstants
  ) {
    this.statuses = this.userFormConstants.STATUSES;
    this.roleList = this.userFormConstants.ROLES;
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.usersComponent.pageSize;
    this.currentPage = this.usersComponent.currentPage;

    const filterName = this.filterForm.get('filterName')?.value;
    const roles = this.filterForm.get('roles')?.value;
    const status = this.filterForm.get('status')?.value;
    const createdStart = this.filterForm.get('createdStart')?.value;
    const createdEnd = this.filterForm.get('createdEnd')?.value;
    this.usersComponent.getUsers(
      filterName,
      this.pageSize,
      this.currentPage,
      roles,
      status,
      createdStart,
      createdEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.usersComponent.getUsers();
  }
}
