import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ServicesService } from 'src/app/core/services/services/services.service';
import { ServiceItem } from 'src/app/shared/models/services/service-item/service-item.model';
import { ServicesConstants } from 'src/app/shared/constants/services-constants';
import { DeleteServiceDialogComponent } from '../delete-service-dialog/delete-service-dialog.component';

@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
})
export class ServiceDetailsComponent implements OnInit {
  // Variable
  serviceID!: number;
  serviceDetails!: ServiceItem;
  statuses: Array<{ value: string; valueName: string }>;

  // Client Type
  clientTypeStr: string;

  // Forms
  receivedEnquiryForm!: FormGroup;
  staffRemarksForm!: FormGroup;

  // Loading
  isLoading = false;
  refreshNeeded = false;

  constructor(
    private formBuilder: FormBuilder,
    private clientTypeService: ClientTypeService,
    private activatedRoute: ActivatedRoute,
    private servicesConstants: ServicesConstants,
    private servicesService: ServicesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    // Get client type
    this.clientTypeService.getClientType();
    this.clientTypeStr = this.clientTypeService.clientTypeStr;

    // Get service ID
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.serviceID = params.id as number;
        this.getServiceDetails();
      },
    });

    // Populate status options
    this.statuses = this.servicesConstants.STATUSES;
  }

  ngOnInit(): void {
    this.setupReceivedEnquiryForm();
    this.setupStaffRemarksForm();
    this.markAsReadPressed(true, true);
  }

  setupReceivedEnquiryForm(): void {
    this.receivedEnquiryForm = this.formBuilder.group({
      name: new FormControl({ value: '', disabled: true }, Validators.required),
      phoneNumber: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      email: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
      remarks: new FormControl(
        { value: '', disabled: true },
        Validators.required
      ),
    });
  }

  setupStaffRemarksForm(): void {
    this.staffRemarksForm = this.formBuilder.group({
      status: new FormControl('', Validators.required),
      remarks: new FormControl(''),
    });
  }

  getServiceDetails(): void {
    this.isLoading = true;
    this.snackBar.open('Loading', '', {
      duration: undefined,
    });
    this.servicesService.getServiceDetails(this.serviceID).subscribe({
      next: (serviceDetails: ServiceItem) => {
        this.serviceDetails = serviceDetails;
        this.populateServiceDetailsForm();
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  populateServiceDetailsForm(): void {
    this.receivedEnquiryForm.patchValue({
      name: this.serviceDetails.name,
      phoneNumber: this.serviceDetails.phoneNumber,
      email: this.serviceDetails.email,
      remarks: this.serviceDetails.remarks,
    });

    this.staffRemarksForm.patchValue({
      status: this.serviceDetails.status.status,
      remarks: this.serviceDetails.staffRemarks,
    });
  }

  updatePressed(): void {
    if (this.staffRemarksForm.invalid) {
      this.staffRemarksForm.markAllAsTouched();
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const formData = {
        status: this.staffRemarksForm.get('status')?.value,
        staffRemarks: this.staffRemarksForm.get('remarks')?.value,
      };

      this.isLoading = true;
      this.staffRemarksForm.disable();
      this.snackBar.open('Updating', '', {
        duration: undefined,
      });

      this.servicesService
        .updateService(formData, this.serviceDetails.id)
        .subscribe({
          next: (serviceDetails: ServiceItem) => {
            this.refreshNeeded = true;
            this.snackBar
              .open('Service enquiry updated', 'REFRESH', {
                duration: undefined,
              })
              .onAction()
              .subscribe({
                next: (_) => {
                  this.refreshNeeded = false;
                  this.staffRemarksForm.enable();
                  this.staffRemarksForm.reset();
                  this.getServiceDetails();
                },
              });
          },
          error: (error) => {
            console.log(error);
            this.isLoading = false;
            this.snackBar.dismiss();
            this.snackBar.open(error, 'DISMISS', {
              duration: 3000,
            });
          },
        });
    }
  }

  markAsReadPressed(hasRead: boolean, isInit: boolean): void {
    this.isLoading = true;

    if (!isInit) {
      this.snackBar.open(
        `Marking "${this.serviceDetails.name}" as ${
          hasRead ? 'read' : 'unread'
        }`,
        '',
        {
          duration: undefined,
        }
      );
    }
    const formData = {
      hasRead,
    };

    this.servicesService
      .updateService(
        formData,
        this.serviceDetails ? this.serviceDetails.id : this.serviceID
      )
      .subscribe({
        next: (_) => {
          this.getServiceDetails();
          this.isLoading = false;
          this.snackBar.dismiss();
          if (!isInit) {
            this.snackBar.open(
              `"${this.serviceDetails.name}" marked as ${
                hasRead ? 'read' : 'unread'
              }`,
              '',
              {
                duration: 3000,
              }
            );
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.snackBar.dismiss();
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
  }

  showDeleteDialog(): void {
    const serviceDetails: ServiceItem = this.serviceDetails;
    const dialogRef = this.dialog.open(DeleteServiceDialogComponent, {
      data: { serviceDetails },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteService();
        }
      },
    });
  }

  deleteService(): void {
    const serviceDetails: ServiceItem = this.serviceDetails;
    this.isLoading = true;
    this.snackBar.open(`Deleting "${serviceDetails.name}"`, '', {
      duration: undefined,
    });
    this.servicesService.deleteService(serviceDetails.id).subscribe({
      next: (result) => {
        this.router.navigateByUrl(`/services/${this.clientTypeStr}`);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${serviceDetails.name}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }
}
