<form
  [formGroup]="yearDetailsForm"
  class="margin-top-16 margin-bottom-24"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title
      title="{{ formTitle }}"
      *ngIf="!isMobile"
    ></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs=""
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      fxLayoutGap="32px grid"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Secretary Name -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial || isAudit">
          <mat-label>Secretary Name</mat-label>
          <input
            matInput
            type="text"
            formControlName="secretaryName"
            [matAutocomplete]="autoCompletesecretaryName"
            required
          />
          <!-- Autocomplete -->
          <mat-autocomplete
            autoActiveFirstOption
            #autoCompletesecretaryName="matAutocomplete"
          >
            <mat-option
              *ngFor="let secretary of secretaryOptions | async"
              [value]="secretary.name"
            >
              {{ secretary.name }}
            </mat-option>
          </mat-autocomplete>
          <!-- Loader -->
          <mat-spinner
            matSuffix
            diameter="18"
            matTooltip="Checking for secretary"
            *ngIf="isMatchingSecretary"
          ></mat-spinner>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['secretaryName'].hasError('required')
            "
          >
            Secretary name is required
          </mat-error>
        </mat-form-field>

        <!-- Nature of Business -->
        <mat-form-field
          appearance="outline"
          *ngIf="
            isSecretarial ||
            isTax ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <mat-label>Nature of Business</mat-label>
          <input
            matInput
            type="text"
            formControlName="natureOfBusiness"
            [required]="!isFreelancer.value"
          />
          <mat-error
            *ngIf="
              yearDetailsForm.controls['natureOfBusiness'].hasError('required')
            "
          >
            Nature of business is required
          </mat-error>
        </mat-form-field>

        <!-- MSIC Code -->
        <mat-form-field
          appearance="outline"
          class="companyNumber"
          *ngIf="
            isSecretarial ||
            isTax ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <mat-label>MSIC Code</mat-label>
          <mat-chip-list
            #msicChipList
            aria-label="MSIC Code Selection"
            formControlName="msicCode"
            [required]="!isFreelancer.value"
          >
            <mat-chip
              *ngFor="let msic of selectedMsicCodes"
              [removable]="true"
              (removed)="removeMsicCode(msic)"
            >
              {{ msic.code }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              #msicCodeInput
              formControlName="msicCode"
              [matAutocomplete]="autoCompleteMsicCode"
              [matChipInputFor]="msicChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            />
          </mat-chip-list>
          <mat-error
            *ngIf="yearDetailsForm.controls['msicCode'].hasError('required')"
          >
            MSIC code is required
          </mat-error>

          <mat-error
            *ngIf="
              yearDetailsForm.controls['msicCode'].hasError('notAvailable')
            "
          >
            MSIC code not available
          </mat-error>
          <mat-hint>Search with MSIC code or description</mat-hint>
          <mat-autocomplete
            autoActiveFirstOption
            #autoCompleteMsicCode="matAutocomplete"
            (optionSelected)="selected($event)"
          >
            <mat-option
              *ngFor="let msic of msicOptions | async"
              [value]="msic.code"
              class="multiline-options"
              matTooltip="{{ msic.description }}"
            >
              <span>{{ msic.code }}</span
              ><br />
              <small>{{ msic.description }}</small>
            </mat-option>
          </mat-autocomplete>

          <mat-spinner
            matSuffix
            diameter="18"
            matTooltip="Searching MSIC code"
            *ngIf="isMatchingMsicCode"
          ></mat-spinner>

          <mat-icon
            matSuffix
            *ngIf="
              yearDetailsForm.controls['msicCode'].hasError('notAvailable')
            "
            class="msicCodeNotAvailable"
            matTooltip="MSIC code not available"
            >cancel</mat-icon
          >
        </mat-form-field>

        <!-- Team PIC -->
        <mat-form-field
          appearance="outline"
          *ngIf="isAudit || isSharedServices"
        >
          <mat-label>Team PIC</mat-label>
          <input matInput type="text" formControlName="teamPIC" />
          <mat-error
            *ngIf="yearDetailsForm.controls['teamPIC'].hasError('required')"
          >
            Team PIC is required
          </mat-error>
        </mat-form-field>

        <!-- Team Lead -->
        <mat-form-field appearance="outline" *ngIf="isAudit">
          <mat-label>Team Lead</mat-label>
          <input matInput type="text" formControlName="teamLead" />
          <mat-error
            *ngIf="yearDetailsForm.controls['teamLead'].hasError('required')"
          >
            Team lead is required
          </mat-error>
        </mat-form-field>

        <!-- Tax Agent's Name -->
        <mat-form-field
          appearance="outline"
          *ngIf="isSharedServices || isSecretarial"
        >
          <mat-label>Tax Agent's Name</mat-label>
          <input matInput type="text" formControlName="taxAgentName" />
          <mat-error
            *ngIf="
              yearDetailsForm.controls['taxAgentName'].hasError('required')
            "
          >
            Tax agent's name is required
          </mat-error>
        </mat-form-field>

        <!-- Last Tax Return Filed Year -->
        <mat-form-field
          appearance="outline"
          *ngIf="isTax || (isSharedServices && !isFreelancer.value)"
        >
          <mat-label>Last Tax Return Filed Year</mat-label>
          <input
            matInput
            #lastTaxReturnFiledYear
            type="number"
            min="1"
            maxlength="4"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
            formControlName="lastTaxReturnFiledYear"
          />
          <mat-hint align="end">
            {{ lastTaxReturnFiledYear.value?.length || 0 }}/4
          </mat-hint>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastTaxReturnFiledYear'].hasError(
                'maxlength'
              )
            "
            >Last tax return filed year can only contain 4 characters
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastTaxReturnFiledYear'].hasError(
                'required'
              )
            "
          >
            Last tax return filed is required
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastTaxReturnFiledYear'].hasError('min')
            "
          >
            Last tax return filed needs to be at least 1
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="isAudit">
          <mat-label>Secretary Firm Name</mat-label>
          <input matInput type="text" formControlName="secretaryFirmName" />
          <mat-error
            *ngIf="
              yearDetailsForm.controls['secretaryFirmName'].hasError('required')
            "
          >
            Secretary firm name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="isAudit">
          <mat-label>Secretary Firm Address</mat-label>
          <textarea
            matInput
            type="text"
            rows="4"
            formControlName="secretaryFirmAddress"
          ></textarea>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['secretaryFirmAddress'].hasError(
                'required'
              )
            "
          >
            Secretary firm address is required
          </mat-error>
        </mat-form-field>

        <!-- Common Seal -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial"
        >
          <mat-label>Common Seal</mat-label>
          <mat-select formControlName="commonSeal">
            <mat-option
              *ngFor="let commonSeal of commonSealOptions"
              [value]="commonSeal.value"
            >
              {{ commonSeal.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="yearDetailsForm.controls['commonSeal'].hasError('required')"
          >
            Common seal is required
          </mat-error>
        </mat-form-field>

        <!-- Register Office -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial"
        >
          <mat-label>Register Office</mat-label>
          <mat-select formControlName="registerOffice">
            <mat-option
              *ngFor="let registerOffice of registerOfficeOptions"
              [value]="registerOffice.value"
            >
              {{ registerOffice.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['registerOffice'].hasError('required')
            "
          >
            Register office is required
          </mat-error>
        </mat-form-field>

        <!-- Name Tag -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          fxFlex="50%"
          *ngIf="isSecretarial"
        >
          <mat-label>Name Tag</mat-label>
          <mat-select formControlName="nameTag">
            <mat-option
              *ngFor="let nameTag of nameTagOptions"
              [value]="nameTag.value"
            >
              {{ nameTag.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="yearDetailsForm.controls['nameTag'].hasError('required')"
          >
            Name tag is required
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Tax Return Submitted Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isTax"
        >
          <mat-label>Tax Return Submitted Date</mat-label>
          <input
            matInput
            [matDatepickerFilter]="selectablePastDates"
            [matDatepicker]="taxReturnSubmittedDateDP"
            formControlName="taxReturnSubmittedDateRef"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="taxReturnSubmittedDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #taxReturnSubmittedDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['taxReturnSubmittedDateRef'].hasError(
                'required'
              )
            "
          >
            Tax return submitted date is required
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['taxReturnSubmittedDateRef'].hasError(
                'matDatepickerFilter'
              )
            "
          >
            Tax return submitted date needs to be in the past
          </mat-error>
        </mat-form-field>

        <!-- CP500 -->
        <mat-form-field
          appearance="outline"
          *ngIf="isFreelancer.value"
          class="currency-field"
        >
          <mat-label>CP500</mat-label>
          <span matPrefix>RM &nbsp;</span>
          <input matInput type="number" min="0" formControlName="cp500" />
          <mat-error
            *ngIf="yearDetailsForm.controls['cp500'].hasError('required')"
          >
            CP500 is required
          </mat-error>
          <mat-error *ngIf="yearDetailsForm.controls['cp500'].hasError('min')">
            CP500 needs to be at least 0
          </mat-error>
        </mat-form-field>

        <!-- CP204 -->
        <mat-form-field
          appearance="outline"
          *ngIf="isTax && !isFreelancer.value"
          class="currency-field"
        >
          <mat-label>CP204</mat-label>
          <span matPrefix>RM &nbsp;</span>
          <input matInput type="number" min="0" formControlName="cp204" />
          <mat-error
            *ngIf="yearDetailsForm.controls['cp204'].hasError('required')"
          >
            CP204 is required
          </mat-error>
          <mat-error *ngIf="yearDetailsForm.controls['cp204'].hasError('min')">
            CP204 needs to be at least 0
          </mat-error>
        </mat-form-field>

        <!-- CP204 (6th) -->
        <mat-form-field
          appearance="outline"
          *ngIf="isTax && !isFreelancer.value"
          class="currency-field"
        >
          <mat-label>CP204 (6th)</mat-label>
          <span matPrefix>RM &nbsp;</span>
          <input matInput type="number" min="0" formControlName="cp204A6th" />
          <mat-error
            *ngIf="yearDetailsForm.controls['cp204A6th'].hasError('required')"
          >
            CP204 (6th) is required
          </mat-error>
          <mat-error
            *ngIf="yearDetailsForm.controls['cp204A6th'].hasError('min')"
          >
            CP204 (6th) needs to be at least 0
          </mat-error>
        </mat-form-field>

        <!-- CP204 (8th) -->
        <mat-form-field
          appearance="outline"
          *ngIf="isTax && !isFreelancer.value"
          class="currency-field"
        >
          <mat-label>CP204 (9th)</mat-label>
          <span matPrefix>RM &nbsp;</span>
          <input matInput type="number" min="0" formControlName="cp204A9th" />
          <mat-error
            *ngIf="yearDetailsForm.controls['cp204A9th'].hasError('required')"
          >
            CP204 (9th) is required
          </mat-error>
          <mat-error
            *ngIf="yearDetailsForm.controls['cp204A9th'].hasError('min')"
          >
            CP204 (9th) needs to be at least 0
          </mat-error>
        </mat-form-field>

        <!-- Take Over Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Take Over Date</mat-label>
          <input
            matInput
            [matDatepicker]="takeOverDP"
            formControlName="takeOverDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="takeOverDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #takeOverDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['takeOverDate'].hasError('required')
            "
          >
            Take over date is required
          </mat-error>
        </mat-form-field>

        <!-- Last Annual Return Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Last Annual Return Date</mat-label>
          <input
            matInput
            [matDatepickerFilter]="selectablePastDates"
            [matDatepicker]="lastAnnualReturnDateDP"
            formControlName="lastAnnualReturnDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="lastAnnualReturnDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #lastAnnualReturnDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastAnnualReturnDate'].hasError(
                'required'
              )
            "
          >
            Last annual return date is required
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastAnnualReturnDate'].hasError(
                'matDatepickerFilter'
              )
            "
          >
            Last annual return date needs to be in the past
          </mat-error>
        </mat-form-field>

        <!-- Next Annual Return Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Next Annual Return Date</mat-label>
          <input
            matInput
            [matDatepickerFilter]="selectableFutureDates"
            [matDatepicker]="nextAnnualReturnDateDP"
            formControlName="nextAnnualReturnDueDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="nextAnnualReturnDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #nextAnnualReturnDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['nextAnnualReturnDueDate'].hasError(
                'required'
              )
            "
          >
            Next annual return date is required
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['nextAnnualReturnDueDate'].hasError(
                'matDatepickerFilter'
              )
            "
          >
            Next annual return date needs to be in the future
          </mat-error>
        </mat-form-field>

        <!-- Last Audited Period -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="
            isSecretarial ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <mat-label>Last Audited Period</mat-label>
          <input
            matInput
            [matDatepickerFilter]="selectablePastDates"
            [matDatepicker]="lastAuditedPeriodDP"
            formControlName="lastAuditedPeriodDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="lastAuditedPeriodDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #lastAuditedPeriodDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastAuditedPeriodDate'].hasError(
                'required'
              )
            "
          >
            Last audited period is required
          </mat-error>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['lastAuditedPeriodDate'].hasError(
                'matDatepickerFilter'
              )
            "
          >
            Last audited period needs to be in the past
          </mat-error>
        </mat-form-field>

        <!-- Audited Accounts Submission Date (SSM) -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial || isAudit"
        >
          <mat-label>Audited Accounts Submission Date (SSM)</mat-label>
          <input
            matInput
            [matDatepicker]="auditedAccountsSubmissionDateDP"
            formControlName="auditedAccountSubmissionDateSsm"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="auditedAccountsSubmissionDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #auditedAccountsSubmissionDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls[
                'auditedAccountSubmissionDateSsm'
              ].hasError('required')
            "
          >
            Audited accounts submission date is required
          </mat-error>
        </mat-form-field>

        <!-- Management Accounts Received Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isAudit"
        >
          <mat-label>Management Accounts Received Date</mat-label>
          <input
            matInput
            [matDatepicker]="mgmtAccountReceivedDateDP"
            formControlName="mgmtAccountReceivedDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="mgmtAccountReceivedDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #mgmtAccountReceivedDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['mgmtAccountReceivedDate'].hasError(
                'required'
              )
            "
          >
            Management accounts received date is required
          </mat-error>
        </mat-form-field>

        <!-- Circulation Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial || isAudit"
        >
          <mat-label>Circulation Date</mat-label>
          <input
            matInput
            [matDatepicker]="circulationDateDP"
            formControlName="circulationDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="circulationDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #circulationDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              yearDetailsForm.controls['circulationDate'].hasError('required')
            "
          >
            Circulation date is required
          </mat-error>
        </mat-form-field>

        <!-- Stocktake -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          fxFlex="calc(50% - 16px)"
          *ngIf="isAudit"
        >
          <mat-label>Stocktake</mat-label>
          <mat-select formControlName="stocktake">
            <mat-option
              *ngFor="let stocktake of stocktakeOptions"
              [value]="stocktake.value"
            >
              {{ stocktake.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="yearDetailsForm.controls['stocktake'].hasError('required')"
          >
            Stocktake is required
          </mat-error>
        </mat-form-field>

        <!-- Service Type -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSharedServices"
        >
          <mat-label>Service Type</mat-label>
          <mat-select formControlName="serviceType">
            <mat-option
              *ngFor="let serviceType of serviceTypeOptions"
              [value]="serviceType.value"
            >
              {{ serviceType.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="yearDetailsForm.controls['serviceType'].hasError('required')"
          >
            Service type is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
