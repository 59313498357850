import { ApiService } from './../api_service/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeeklyStats } from 'src/app/shared/models/weekly-stats/weekly-stats.model';
import { Activity } from 'src/app/shared/models/activity/activity.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private apiService: ApiService) {}

  // Weekly Stats (Admin only)
  getWeeklyStats(): Observable<Array<WeeklyStats>> {
    return this.apiService.get(`/api/core/dashboard/weeklystats`);
  }

  // Activities (Admin only)
  getActivities(): Observable<Array<Activity>> {
    return this.apiService.get(`/api/core/dashboard/admin-activity`);
  }
}
