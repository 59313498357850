import { CustomBreakpointService } from './../../../../../shared/breakpoint/custom-breakpoint.service';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { IdentificationType } from 'src/app/shared/models/identification-type/identification-type.model';
import { Device } from 'src/app/shared/enums/device.enum';
import { Gender } from 'src/app/shared/models/gender/gender.model';
import { ShareType } from 'src/app/shared/models/share-type/share-type.model';
import { EventType } from 'src/app/shared/models/event-type/event-type.model';

@Component({
  selector: 'app-members-form',
  templateUrl: './members-form.component.html',
  styleUrls: ['./members-form.component.scss'],
})
export class MembersFormComponent implements OnInit {
  // Form
  @Input() membersForm!: FormGroup;
  @Input() disabled!: boolean;

  // Dropdown Lists
  idTypes: Array<IdentificationType>;
  genders: Array<Gender>;
  typesOfShare: Array<ShareType>;
  typesOfEvent: Array<EventType>;

  // ID Types: Is IC or not
  listOfIdTypes: Array<boolean> = [];

  // Subscriptions
  idTypeSubscription: Subscription | undefined;

  // Responsive
  isMobile = true;

  constructor(
    private formBuilder: FormBuilder,
    private userFormConstants: UserFormConstants,
    private customBreakpointService: CustomBreakpointService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.idTypes = this.userFormConstants.IDTYPE;
    this.genders = this.userFormConstants.GENDER;
    this.typesOfShare = this.userFormConstants.TYPES_OF_SHARE;
    this.typesOfEvent = this.userFormConstants.TYPES_OF_EVENT;
  }

  ngOnInit(): void {
    this.subscribeToMembers();
    this.createMemberItem();
    this.listOfIdTypes.push(true);
  }

  subscribeToMembers(): void {
    this.membersForm.controls.members.valueChanges.subscribe({
      next: (memberForms) => {
        for (let index = 0; index < memberForms.length; index++) {
          this.subscribeToIDType(index);
        }
      },
    });
  }

  subscribeToIDType(index: number): void {
    this.idTypeSubscription = this.members.controls[index]
      .get('identificationTypeStr')
      ?.valueChanges.subscribe({
        next: (result) => {
          const memberForm: FormGroup = this.members.controls[
            index
          ] as FormGroup;

          if (result === 'IC') {
            this.listOfIdTypes[index] = true;
            memberForm.controls.identificationNumber.setValidators([
              Validators.required,
              Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
            ]);
          } else {
            this.listOfIdTypes[index] = false;
            memberForm.controls.identificationNumber.setValidators([
              Validators.required,
            ]);
          }
          memberForm.updateValueAndValidity();
        },
      });
  }

  get members(): FormArray {
    return this.membersForm.get('members') as FormArray;
  }

  createMemberItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      numberOfShares: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      isMale: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      phoneNumber: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
      eventTypeStr: new FormControl(''),
      upToDateNumberOfShares: new FormControl('', [
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      isCash: new FormControl(null),
      placeOfIncorporation: new FormControl(''),
    });
  }

  addMember(): void {
    this.members.push(this.createMemberItem());
    this.listOfIdTypes.push(true);
  }

  removeMember(index: number): void {
    this.members.removeAt(index);
    this.listOfIdTypes.splice(index, 1);
  }
}
