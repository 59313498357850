<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        mat-stroked-button
        [routerLink]="['/clients', clientType]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span>Clients — </span>
        <span class="capitalize">{{
          clientType !== "shared_services" ? clientType : "Shared Services"
        }}</span>
      </button>
      <app-page-title
        title="Clients Details"
        subtitle="
        {{ client ? client.name : 'Not Available' }}
      "
      ></app-page-title>
    </div>

    <!-- Action Buttons -->
    <!-- Mobile -->
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      fxHide
      [fxShow.xs]="!isSecretarial"
    >
      <span>More</span>
      <mat-icon class="margin-left-8">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu" fxHide [fxShow]="!isSecretarial">
      <button mat-menu-item [matMenuTriggerFor]="addYear">
        <mat-icon class="margin-right-8">add</mat-icon>
        <span>Add Year Details</span>
      </button>
      <mat-divider></mat-divider>
      <button
        mat-menu-item
        [matMenuTriggerFor]="years"
        [disabled]="yearIsLoading || !availableYears.length"
        [ngClass]="{ shimmer: yearIsLoading }"
      >
        <mat-icon class="margin-right-8">history</mat-icon>
        <span>View Year Details</span>
      </button>
    </mat-menu>

    <!-- Desktop -->
    <div fxLayout="row" fxLayoutGap="16px" fxHide.xs [fxHide]="isSecretarial">
      <button mat-raised-button color="primary" [matMenuTriggerFor]="addYear">
        <mat-icon class="margin-right-8">add</mat-icon>
        <span>Add Year Details</span>
      </button>
      <button
        mat-stroked-button
        [matMenuTriggerFor]="years"
        [disabled]="yearIsLoading || !availableYears.length"
        [ngClass]="{ shimmer: yearIsLoading }"
      >
        <mat-icon class="margin-right-8">history</mat-icon>
        <span>View Year Details</span>
      </button>

      <!-- Add new year details -->
      <mat-menu #addYear>
        <button mat-menu-item routerLink="year/create">
          <mat-icon class="margin-right-8">add</mat-icon>
          <span>New year details</span>
        </button>
        <mat-divider></mat-divider>
        <button
          mat-menu-item
          [disabled]="
            yearIsLoading || clientHasCurrentYear || !availableYears.length
          "
          (click)="showConfirmDuplicateDialog()"
          [ngClass]="{ shimmer: yearIsLoading }"
        >
          <mat-icon class="margin-right-8">content_copy</mat-icon>
          <span>Copy from last available year</span>
        </button>
      </mat-menu>

      <!-- List of years -->
      <mat-menu #years="matMenu">
        <button
          mat-menu-item
          *ngFor="let year of availableYears"
          [routerLink]="['year', year.id]"
        >
          {{ year.year }}
        </button>
      </mat-menu>
    </div>
  </div>

  <!-- General Company Details -->
  <div
    fxLayout="row wrap"
    fxLayoutGap="40px"
    fxLayout.xs="column"
    fxLayoutGap.xs="8px"
    *ngIf="!clientIsLoading"
    class="margin-bottom-40"
  >
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <!-- Name -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Name</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.name === null || client.name === '' ? 'not-available' : ''
          "
        >
          {{ client.name ? client.name : "Not Available" }}
        </p>
      </div>

      <!-- Formerly Known As -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Formerly Known As (F.K.A)</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.formerlyKnownAs === null || client.formerlyKnownAs === ''
              ? 'not-available'
              : ''
          "
        >
          {{
            client.formerlyKnownAs ? client.formerlyKnownAs : "Not Available"
          }}
        </p>
      </div>

      <!-- Company Number New -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isAudit || isTax || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Company Number (New)</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.companyNumberNew === null || client.companyNumberNew === ''
              ? 'not-available'
              : ''
          "
        >
          {{
            client.companyNumberNew ? client.companyNumberNew : "Not Available"
          }}
        </p>
      </div>

      <!-- Company Number Old -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isAudit || isTax || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Company Number (Old)</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.companyNumberOld === null || client.companyNumberOld === ''
              ? 'not-available'
              : ''
          "
        >
          {{
            client.companyNumberOld ? client.companyNumberOld : "Not Available"
          }}
        </p>
      </div>

      <!-- Identification Type -->
      <div
        fxLayoutGap="8px"
        *ngIf="(isTax || isSharedServices) && client.identityTypeRef"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Identification Type</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.identityTypeRef === null || client.identityTypeRef === ''
              ? 'not-available'
              : ''
          "
        >
          {{
            client.identityTypeRef ? client.identityTypeRef : "Not Available"
          }}
        </p>
      </div>

      <!-- Identification Number -->
      <div
        fxLayoutGap="8px"
        *ngIf="(isTax || isSharedServices) && client.identityNumber"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Identification Number</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.identityNumber === null || client.identityNumber === ''
              ? 'not-available'
              : ''
          "
        >
          {{ client.identityNumber ? client.identityNumber : "Not Available" }}
        </p>
      </div>

      <!-- Group -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Group</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.group === '' || client.group === null ? 'not-available' : ''
          "
        >
          {{ client.group ? client.group : "Not Available" }}
        </p>
      </div>

      <!-- Branch/Franchisees Group -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Branch/Franchisees Group</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.clientSubGroup ? 'not-available' : ''"
        >
          {{ client.clientSubGroup ? client.clientSubGroup : "Not Available" }}
        </p>
      </div>

      <!-- Client Type -->
      <div fxLayoutGap="8px" *ngIf="isTax">
        <p fxFlex="calc(50%-40px)" class="bold">Client Type</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.clientTaxType ? 'not-available' : ''"
          class="capitalize"
        >
          {{
            client.clientTaxType
              ? client.clientTaxType.type.toLowerCase()
              : "Not Available"
          }}
        </p>
      </div>

      <!-- File Reference -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">File Reference</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.fileRef === '' || client.fileRef === null
              ? 'not-available'
              : ''
          "
        >
          {{ client.fileRef ? client.fileRef : "Not Available" }}
        </p>
      </div>

      <!-- Incorporated by Eurogain -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Incorporated by Eurogain</p>
        <p fxFlex="calc(50%-40px)">
          {{ client.incorpByUs === true ? "Yes" : "No" }}
        </p>
      </div>

      <!-- Start Secretarial Only -->
      <!-- Nature of Business -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Nature of Business</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!clientYearDetails.natureOfBusiness ? 'not-available' : ''"
          class="capitalize"
        >
          {{
            clientYearDetails.natureOfBusiness
              ? clientYearDetails.natureOfBusiness
              : "Not Available"
          }}
        </p>
      </div>

      <!-- MSIC Code -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">MSIC Code</p>
        <div
          fxFlex="calc(50%-40px)"
          *ngIf="
            clientYearDetails.msicCodes &&
            clientYearDetails.msicCodes.length > 0
          "
          fxLayout="row wrap"
          fxLayoutAlign="start center"
        >
          <div
            *ngFor="
              let msicCode of clientYearDetails.msicCodes;
              let isLast = last
            "
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <span>
              {{ msicCode.code }}
            </span>
            <mat-icon
              class="msic-help margin-left-4"
              [matTooltip]="msicCode.description"
              >help_outline</mat-icon
            >
            <span class="margin-right-8">{{ isLast ? "" : ", " }}</span>
          </div>
        </div>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="
            !clientYearDetails.msicCodes ||
            clientYearDetails.msicCodes.length < 1
          "
          class="not-available"
        >
          Not available
        </p>
      </div>

      <!-- Common Seal -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Common Seal</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.commonSeal ? '' : 'not-available'"
        >
          {{
            clientYearDetails.commonSeal
              ? clientYearDetails.commonSeal === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Register Office -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Register Office</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.registerOffice ? '' : 'not-available'"
        >
          {{
            clientYearDetails.registerOffice
              ? clientYearDetails.registerOffice === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Name Tag -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Name Tag</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.nameTag ? '' : 'not-available'"
        >
          {{
            clientYearDetails.nameTag
              ? clientYearDetails.nameTag === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Company Office Number -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Company Office Number</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.companyOfficeNo ? '' : 'not-available'"
        >
          {{
            client.companyOfficeNo ? client.companyOfficeNo : "Not Available"
          }}
        </p>
      </div>

      <!-- Company Email -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.companyEmail ? '' : 'not-available'"
        >
          {{ client.companyEmail ? client.companyEmail : "Not Available" }}
        </p>
      </div>

      <!-- Company Website -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Company Website</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.companyWebsite ? '' : 'not-available'"
        >
          {{ client.companyWebsite ? client.companyWebsite : "Not Available" }}
        </p>
      </div>

      <!-- Referred By -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Referred By</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.referredBy ? '' : 'not-available'"
        >
          {{ client.referredBy ? client.referredBy : "Not Available" }}
        </p>
      </div>
      <!-- End Secretarial Only -->
    </div>
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <!-- Employer's Number -->
      <div fxLayoutGap="8px" *ngIf="isTax">
        <p fxFlex="calc(50%-40px)" class="bold">Employer's Number</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.employerNumber ? 'not-available' : ''"
        >
          {{ client.employerNumber ? client.employerNumber : "Not Available" }}
        </p>
      </div>

      <!-- Tax Reference Number -->
      <div fxLayoutGap="8px" *ngIf="isTax">
        <p fxFlex="calc(50%-40px)" class="bold">Tax Reference Number</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.taxRefNumber ? 'not-available' : ''"
        >
          {{ client.taxRefNumber ? client.taxRefNumber : "Not Available" }}
        </p>
      </div>

      <!-- Registered Address -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Registered Address</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.registeredAddress ? '' : 'not-available'"
        >
          {{
            client.registeredAddress
              ? client.registeredAddress
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Business Address -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Business Address</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.corresAddress ? '' : 'not-available'"
        >
          {{ client.corresAddress ? client.corresAddress : "Not Available" }}
        </p>
      </div>

      <!-- Registered Book Kept Address -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Registered Book Kept Address</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.registeredBookKeptAddress ? '' : 'not-available'"
        >
          {{
            client.registeredBookKeptAddress
              ? client.registeredBookKeptAddress
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Financial Records Kept Address -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">
          Financial Records Kept Address
        </p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="client.financialRecordsKeptAddress ? '' : 'not-available'"
        >
          {{
            client.financialRecordsKeptAddress
              ? client.financialRecordsKeptAddress
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Incorporation Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Incorporation Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.dateOfIncorp">
          {{ client.dateOfIncorp | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.dateOfIncorp"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Start AFS - Circulation Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">AFS - Circulation Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.circulationDate">
          {{ client.circulationDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.circulationDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>
      <!-- Start AFS - Circulation Date -->

      <!-- Start Charge/ Discharge -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Charge/ Discharge</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.chargeOrDischarge ? 'not-available' : ''"
        >
          {{
            client.chargeOrDischarge
              ? client.chargeOrDischarge
              : "Not Available"
          }}
        </p>
      </div>
      <!-- End Charge/ Discharge -->

      <!-- Start Charge Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Charge Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.chargeDate">
          {{ client.chargeDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.chargeDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>
      <!-- Start Charge Date -->

      <!-- Start Discharge Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Discharge Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.dischargeDate">
          {{ client.dischargeDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.dischargeDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>
      <!-- Start Discharge Date -->

      <!-- Year End -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Year End</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            client.yearEnd === null || client.yearEnd === ''
              ? 'not-available'
              : ''
          "
        >
          {{ client.yearEnd ? client.yearEnd : "Not Available" }}
        </p>
      </div>

      <!-- Status -->
      <div fxLayoutGap="8px" *ngIf="isTax">
        <p fxFlex="calc(50%-40px)" class="bold">Status</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!client.clientStatus ? 'not-available' : ''"
        >
          {{ client.clientStatus ? client.clientStatus : "Not Available" }}
        </p>
      </div>

      <!-- Start Secretarial Only -->
      <!-- Take Over Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Take Over Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="clientYearDetails.takeOverDate">
          {{ clientYearDetails.takeOverDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.takeOverDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Last Annual Return Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Last Annual Return Date</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.lastAnnualReturnDate"
        >
          {{ clientYearDetails.lastAnnualReturnDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.lastAnnualReturnDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Next Annual Return Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Next Annual Return Due Date</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.nextAnnualReturnDueDate"
        >
          {{ clientYearDetails.nextAnnualReturnDueDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.nextAnnualReturnDueDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Last Account Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Last Account Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.dateOfLastAccount">
          {{ client.dateOfLastAccount | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.dateOfLastAccount"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Next Account Due Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Next Account Due Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.dateOfNextAccountDue">
          {{ client.dateOfNextAccountDue | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!client.dateOfNextAccountDue"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Last Audited Period -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Last Audited Period</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.lastAuditedPeriodDate"
        >
          {{ clientYearDetails.lastAuditedPeriodDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.lastAuditedPeriodDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Audited Accounts Submission Date (SSM) -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">
          Audited Accounts Submission Date (SSM)
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.auditedAccountSubmissionDateSsm"
        >
          {{ clientYearDetails.auditedAccountSubmissionDateSsm | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.auditedAccountSubmissionDateSsm"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Circulation Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial && clientYearDetails">
        <p fxFlex="calc(50%-40px)" class="bold">Circulation Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="clientYearDetails.circulationDate">
          {{ clientYearDetails.circulationDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.circulationDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>
      <!-- End Secretarial Only -->

      <!-- Created Date -->
      <div
        fxLayoutGap="8px"
        *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Created On</p>
        <p fxFlex="calc(50%-40px)" *ngIf="client.createdDate !== null">
          {{ client.createdDate | date: "medium" }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="client.createdDate === null"
          class="not-available"
        >
          Not Available
        </p>
      </div>
    </div>
  </div>

  <!-- Contact Person -->
  <app-section-title
    title="Often Contact Person"
    *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
  >
  </app-section-title>
  <div
    *ngIf="!clientIsLoading && !client.oftenContactPersons.length"
    class="margin-bottom-40"
  >
    <p class="not-available">No contact persons available</p>
  </div>
  <div
    *ngIf="
      !clientIsLoading &&
      (isSecretarial || isTax || isAudit || isSharedServices)
    "
  >
    <div
      fxLayout="row wrap"
      fxLayoutGap="40px"
      fxLayout.xs="column"
      fxLayoutGap.xs="8px"
      class="margin-bottom-40"
      *ngFor="let contactPerson of client.oftenContactPersons"
    >
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Name -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              contactPerson.name === null || contactPerson.name === ''
                ? 'not-available'
                : ''
            "
          >
            {{ contactPerson.name ? contactPerson.name : "Not Available" }}
          </p>
        </div>

        <!-- Phone Number -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              contactPerson.phoneNumber === null ||
              contactPerson.phoneNumber === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              contactPerson.phoneNumber
                ? contactPerson.phoneNumber
                : "Not Available"
            }}
          </p>
        </div>

        <!-- Contact Person Type -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Contact Person Type</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              contactPerson.contactPersonTypeStr === null ||
              contactPerson.contactPersonTypeStr === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              contactPerson.contactPersonTypeStr
                ? contactPerson.contactPersonTypeStr
                : "Not Available"
            }}
          </p>
        </div>
      </div>
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Business Email -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Business Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              contactPerson.businessEmail === null ||
              contactPerson.businessEmail === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              contactPerson.businessEmail
                ? contactPerson.businessEmail
                : "Not Available"
            }}
          </p>
        </div>

        <!-- Personal Email -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Personal Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              contactPerson.personalEmail === null ||
              contactPerson.personalEmail === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              contactPerson.personalEmail
                ? contactPerson.personalEmail
                : "Not Available"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Start Auditor -->
  <app-section-title
    title="Auditor"
    *ngIf="
      isSecretarial ||
      (isTax && !isFreelancer.value) ||
      isAudit ||
      (isSharedServices && !isFreelancer.value)
    "
  >
  </app-section-title>
  <div
    *ngIf="!clientIsLoading && !client.auditors.length"
    class="margin-bottom-40"
  >
    <p class="not-available">No auditors available</p>
  </div>
  <div
    *ngIf="
      !clientIsLoading &&
      (isSecretarial ||
        (isTax && !isFreelancer.value) ||
        isAudit ||
        (isSharedServices && !isFreelancer.value))
    "
  >
    <div
      fxLayout="row wrap"
      fxLayoutGap="40px"
      fxLayout.xs="column"
      fxLayoutGap.xs="8px"
      class="margin-bottom-40"
      *ngFor="let auditor of client.auditors"
    >
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Start Firm Name -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Firm Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.firmName === null || auditor.firmName === ''
                ? 'not-available'
                : ''
            "
          >
            {{ auditor.firmName ? auditor.firmName : "Not Available" }}
          </p>
        </div>
        <!-- End Firm Name -->

        <!-- Start Firm No. -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Firm No.</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.firmNo === null || auditor.firmNo === ''
                ? 'not-available'
                : ''
            "
          >
            {{ auditor.firmNo ? auditor.firmNo : "Not Available" }}
          </p>
        </div>
        <!-- End Firm No. -->

        <!-- Start Phone Number -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.firmContactNumber === null ||
              auditor.firmContactNumber === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              auditor.firmContactNumber
                ? auditor.firmContactNumber
                : "Not Available"
            }}
          </p>
        </div>
        <!-- End Phone Number -->

        <!-- Start Firm Email -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Firm Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.firmEmail === null || auditor.firmEmail === ''
                ? 'not-available'
                : ''
            "
          >
            {{ auditor.firmEmail ? auditor.firmEmail : "Not Available" }}
          </p>
        </div>
        <!-- End Firm Email -->

        <!-- Start PIC Name -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">PIC Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.picName === null || auditor.picName === ''
                ? 'not-available'
                : ''
            "
          >
            {{ auditor.picName ? auditor.picName : "Not Available" }}
          </p>
        </div>
        <!-- End PIC Name -->
      </div>
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Start Firm Address -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Firm Address</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              auditor.firmAddress === null || auditor.firmAddress === ''
                ? 'not-available'
                : ''
            "
          >
            {{ auditor.firmAddress ? auditor.firmAddress : "Not Available" }}
          </p>
        </div>
        <!-- End Firm Address -->

        <!-- Start Appointment On -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
          <p fxFlex="calc(50%-40px)" *ngIf="auditor.appointmentOnDate">
            {{ auditor.appointmentOnDate | date }}
          </p>
          <p
            fxFlex="calc(50%-40px)"
            *ngIf="!auditor.appointmentOnDate"
            class="not-available"
          >
            Not Available
          </p>
        </div>
        <!-- End Appointment On -->

        <!-- Start Resignation On -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
          <p fxFlex="calc(50%-40px)" *ngIf="auditor.resignationOnDate">
            {{ auditor.resignationOnDate | date }}
          </p>
          <p
            fxFlex="calc(50%-40px)"
            *ngIf="!auditor.resignationOnDate"
            class="not-available"
          >
            Not Available
          </p>
        </div>
        <!-- End Resignation On -->
      </div>
    </div>
  </div>
  <!-- End Auditor -->

  <!-- Start Tax Agents -->
  <app-section-title
    title="Tax Agent"
    *ngIf="isSecretarial || isSharedServices"
  >
  </app-section-title>
  <div
    *ngIf="
      !clientIsLoading &&
      !client.taxAgents.length &&
      (isSecretarial || isSharedServices)
    "
    class="margin-bottom-40"
  >
    <p class="not-available">No tax agents available</p>
  </div>
  <div *ngIf="!clientIsLoading && (isSecretarial || isSharedServices)">
    <div
      fxLayout="row wrap"
      fxLayoutGap="40px"
      fxLayout.xs="column"
      fxLayoutGap.xs="8px"
      class="margin-bottom-40"
      *ngFor="let taxAgent of client.taxAgents"
    >
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Start Company Name -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Company Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.companyName === null || taxAgent.companyName === ''
                ? 'not-available'
                : ''
            "
          >
            {{ taxAgent.companyName ? taxAgent.companyName : "Not Available" }}
          </p>
        </div>
        <!-- End Company Name -->

        <!-- Start PIC Name -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">PIC Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.picName === null || taxAgent.picName === ''
                ? 'not-available'
                : ''
            "
          >
            {{ taxAgent.picName ? taxAgent.picName : "Not Available" }}
          </p>
        </div>
        <!-- End PIC Name -->

        <!-- Start License No. -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">License No.</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.licenseNo === null || taxAgent.licenseNo === ''
                ? 'not-available'
                : ''
            "
          >
            {{ taxAgent.licenseNo ? taxAgent.licenseNo : "Not Available" }}
          </p>
        </div>
        <!-- End License No. -->

        <!-- Start Phone Number -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.telNo === null || taxAgent.telNo === ''
                ? 'not-available'
                : ''
            "
          >
            {{ taxAgent.telNo ? taxAgent.telNo : "Not Available" }}
          </p>
        </div>
        <!-- End Phone Number -->
      </div>
      <div
        fxLayout="column"
        fxFlex.xs="100%"
        fxFlex="calc(50%-40px)"
        class="margin-bottom-24"
        fxLayoutGap="8px"
      >
        <!-- Start Company Email -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.companyEmail === null || taxAgent.companyEmail === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              taxAgent.companyEmail ? taxAgent.companyEmail : "Not Available"
            }}
          </p>
        </div>
        <!-- End Company Email -->

        <!-- Start Private Email -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="
              taxAgent.privateEmail === null || taxAgent.privateEmail === ''
                ? 'not-available'
                : ''
            "
          >
            {{
              taxAgent.privateEmail ? taxAgent.privateEmail : "Not Available"
            }}
          </p>
        </div>
        <!-- End Private Email -->

        <!-- Start Appointment On -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
          <p fxFlex="calc(50%-40px)" *ngIf="taxAgent.appointmentOnDate">
            {{ taxAgent.appointmentOnDate | date }}
          </p>
          <p
            fxFlex="calc(50%-40px)"
            *ngIf="!taxAgent.appointmentOnDate"
            class="not-available"
          >
            Not Available
          </p>
        </div>
        <!-- End Appointment On -->

        <!-- Start Resignation On -->
        <div fxLayoutGap="8px">
          <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
          <p fxFlex="calc(50%-40px)" *ngIf="taxAgent.resignationOnDate">
            {{ taxAgent.resignationOnDate | date }}
          </p>
          <p
            fxFlex="calc(50%-40px)"
            *ngIf="!taxAgent.resignationOnDate"
            class="not-available"
          >
            Not Available
          </p>
        </div>
        <!-- End Resignation On -->
      </div>
    </div>
  </div>
  <!-- End Tax Agents -->

  <!-- Branch Addresses -->
  <div *ngIf="isSecretarial">
    <app-section-title
      title="Branch Addresses"
      *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
    >
    </app-section-title>
    <div
      *ngIf="!clientIsLoading && !client.branchAddresses.length"
      class="margin-bottom-40"
    >
      <p class="not-available">No branch address available</p>
    </div>
    <div
      *ngIf="
        !clientIsLoading &&
        (isSecretarial || isTax || isAudit || isSharedServices)
      "
    >
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
        class="margin-bottom-40"
        *ngFor="let branchAddress of client.branchAddresses"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">No.</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="!branchAddress.branchNumber ? 'not-available' : ''"
            >
              {{
                branchAddress.branchNumber
                  ? branchAddress.branchNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                branchAddress.address === null || branchAddress.address === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                branchAddress.address ? branchAddress.address : "Not Available"
              }}
            </p>
          </div>
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                branchAddress.contactNumber === null ||
                branchAddress.contactNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                branchAddress.contactNumber
                  ? branchAddress.contactNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                branchAddress.email === null || branchAddress.email === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ branchAddress.email ? branchAddress.email : "Not Available" }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-section-title title="Remarks"></app-section-title>
  <div
    fxLayout="row wrap"
    fxLayoutGap="40px"
    fxLayout.xs="column"
    fxLayoutGap.xs="8px"
    *ngIf="!clientIsLoading"
    class="margin-bottom-40"
  >
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <div fxLayoutGap="8px">
        <p fxFlex="calc(50%-40px)" class="bold">Remarks</p>
        <!-- Secretarial Remarks -->
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientType === 'secretarial'"
          [ngClass]="!client.remarksSecretary ? 'not-available' : ''"
        >
          {{
            client.remarksSecretary ? client.remarksSecretary : "Not Available"
          }}
        </p>
        <!-- Tax Remarks -->
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientType === 'tax'"
          [ngClass]="!client.remarksTax ? 'not-available' : ''"
        >
          {{ client.remarksTax ? client.remarksTax : "Not Available" }}
        </p>
        <!-- Audit Remarks -->
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientType === 'audit'"
          [ngClass]="!client.remarksAudit ? 'not-available' : ''"
        >
          {{ client.remarksAudit ? client.remarksAudit : "Not Available" }}
        </p>
        <!-- Shared Services Remarks -->
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientType === 'shared_services'"
          [ngClass]="!client.remarksSharedServices ? 'not-available' : ''"
        >
          {{
            client.remarksSharedServices
              ? client.remarksSharedServices
              : "Not Available"
          }}
        </p>
      </div>
    </div>
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    ></div>
  </div>

  <!-- Start Secretarial Only -->
  <!-- Banks -->
  <div *ngIf="isSecretarial" class="margin-bottom-40">
    <app-section-title title="Banks"></app-section-title>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      class="not-available-container"
      *ngIf="!clientIsLoading && !clientYearDetails?.banks"
    >
      <p class="no-margin">Not Available</p>
    </div>
    <mat-accordion *ngIf="!clientIsLoading && clientYearDetails?.banks">
      <mat-expansion-panel
        class="mat-elevation-z0 expansion-panel"
        *ngFor="let bankItem of clientYearDetails.banks; let first = first"
        [ngClass]="{ first: first }"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="panel-title">
            Bank — {{ bankItem.bank.name }}</mat-panel-title
          >
        </mat-expansion-panel-header>
        <div
          fxLayout="row wrap"
          fxLayoutGap="40px"
          fxLayout.xs="column"
          fxLayoutGap.xs="8px"
        >
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Bank Name -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Bank Name</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  bankItem.bank.name === null || bankItem.bank.name === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{ bankItem.bank.name ? bankItem.bank.name : "Not Available" }}
              </p>
            </div>

            <!-- Branch -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Branch</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="bankItem.branch === null ? 'not-available' : ''"
              >
                {{ bankItem.branch ? bankItem.branch : "Not Available" }}
              </p>
            </div>

            <!-- Branch Address -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Branch Address</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  bankItem.branchAddress === null ||
                  bankItem.branchAddress === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  bankItem.branchAddress
                    ? bankItem.branchAddress
                    : "Not Available"
                }}
              </p>
            </div>
          </div>
          <div
            fxLayout="column"
            fxFlex.xs="100%"
            fxFlex="calc(50%-40px)"
            class="margin-bottom-24"
            fxLayoutGap="8px"
          >
            <!-- Account Type -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Account Type</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="!bankItem.accountType ? 'not-available' : ''"
                class="capitalize"
              >
                {{
                  bankItem.accountType
                    ? bankItem.accountType.type.toLowerCase()
                    : "Not Available"
                }}
              </p>
            </div>

            <!-- Account Number -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Account Number</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  bankItem.accountNumber === null ||
                  bankItem.accountNumber === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  bankItem.accountNumber
                    ? bankItem.accountNumber
                    : "Not Available"
                }}
              </p>
            </div>

            <!-- Account Usage -->
            <div fxLayoutGap="8px">
              <p fxFlex="calc(50%-40px)" class="bold">Account Usage</p>
              <p
                fxFlex="calc(50%-40px)"
                [ngClass]="
                  bankItem.accountUsage === null || bankItem.accountUsage === ''
                    ? 'not-available'
                    : ''
                "
              >
                {{
                  bankItem.accountUsage
                    ? bankItem.accountUsage
                    : "Not Available"
                }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- Directors -->
  <app-section-title
    title="Directors"
    *ngIf="isSecretarial"
  ></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container margin-bottom-40"
    *ngIf="!clientIsLoading && !clientYearDetails?.directors && isSecretarial"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion
    class="margin-bottom-40"
    *ngIf="!clientIsLoading && isSecretarial && clientYearDetails"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let director of clientYearDetails.directors; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Director — {{ director.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.name === null || director.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.name ? director.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="director.type === null ? 'not-available' : ''"
            >
              {{ director.type ? director.type.type : "Not Available" }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.identificationNumber === null ||
                director.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.identificationNumber
                  ? director.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.nationality === null || director.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.nationality ? director.nationality : "Not Available"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.race === null || director.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.race ? director.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="director.male === null ? 'not-available' : ''"
            >
              {{
                director.male
                  ? director.male == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.phoneNumber === null || director.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.phoneNumber ? director.phoneNumber : "Not Available"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.email === null || director.email === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.email ? director.email : "Not Available" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.privateEmail === null || director.privateEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.privateEmail ? director.privateEmail : "Not Available"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Alternative Director -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Alternative Director</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.alternativeDirector === null ||
                director.alternativeDirector === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.alternativeDirector
                  ? director.alternativeDirector
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Alternative Director -->

          <!-- Start Service/ Business Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.address === null || director.address === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.address ? director.address : "Not Available" }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.residentialAddress === null ||
                director.residentialAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.residentialAddress
                  ? director.residentialAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfPassportExpiry">
              {{ director.dateOfPassportExpiry | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfPassportExpiry"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfBirth">
              {{ director.dateOfBirth | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfBirth"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfAppointment">
              {{ director.dateOfAppointment | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfAppointment"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfResignation">
              {{ director.dateOfResignation | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfResignation"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Members -->
  <app-section-title title="Members" *ngIf="isSecretarial"></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container margin-bottom-40"
    *ngIf="
      !clientIsLoading && !clientYearDetails?.shareholders && isSecretarial
    "
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion
    class="margin-bottom-40"
    *ngIf="!clientIsLoading && isSecretarial && clientYearDetails"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="
        let shareholder of clientYearDetails.shareholders;
        let first = first
      "
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Member — {{ shareholder.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.name === null || shareholder.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.name ? shareholder.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.type === null ? 'not-available' : ''"
            >
              {{ shareholder.type ? shareholder.type.type : "Not Available" }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.identificationNumber === null ||
                shareholder.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.identificationNumber
                  ? shareholder.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.nationality === null ||
                shareholder.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.nationality
                  ? shareholder.nationality
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.race === null || shareholder.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.race ? shareholder.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.isMale == null ? 'not-available' : ''"
            >
              {{
                shareholder.isMale !== null
                  ? shareholder.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.phoneNumber === null ||
                shareholder.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.phoneNumber
                  ? shareholder.phoneNumber
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.email === null || shareholder.email === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.email ? shareholder.email : "Not Available" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.privateEmail === null ||
                shareholder.privateEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.privateEmail
                  ? shareholder.privateEmail
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.address === null || shareholder.address === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.address ? shareholder.address : "Not Available" }}
            </p>
          </div>
          <!-- End Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="shareholder.dateOfPassportExpiryRef"
            >
              {{ shareholder.dateOfPassportExpiryRef | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!shareholder.dateOfPassportExpiryRef"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="shareholder.dateOfBirthRef">
              {{ shareholder.dateOfBirthRef | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!shareholder.dateOfBirthRef"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Event Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Event Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="!shareholder.eventType ? 'not-available' : ''"
            >
              {{
                shareholder.eventType
                  ? shareholder.eventType?.type
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Event Type -->

          <!-- Start Type of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Type of Shares</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.isCash == null ? 'not-available' : ''"
            >
              {{
                shareholder.isCash !== null
                  ? shareholder.isCash == true
                    ? "Cash"
                    : "Non-Cash"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Type of Shares -->

          <!-- Start Up to Date Number of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Up to Date Number of Shares
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.upToDateNumberOfShares === null ||
                shareholder.upToDateNumberOfShares === undefined
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.upToDateNumberOfShares
                  ? shareholder.upToDateNumberOfShares
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Up to Date Number of Shares -->

          <!-- Start Number of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Number of Shares</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.numberOfShares === null ||
                shareholder.numberOfShares === undefined
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.numberOfShares
                  ? shareholder.numberOfShares
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Number of Shares -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Start Secretaries -->
  <app-section-title
    title="Secretaries"
    *ngIf="isSecretarial && client"
  ></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container margin-bottom-40"
    *ngIf="!clientIsLoading && !client.secretaries.length && isSecretarial"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion
    class="margin-bottom-40"
    *ngIf="!clientIsLoading && isSecretarial && client"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let secretary of client.secretaries; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Secretary — {{ secretary.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.name === null || secretary.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ secretary.name ? secretary.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.identificationType === null ? 'not-available' : ''
              "
            >
              {{
                secretary.identificationType
                  ? secretary.identificationType.type
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.identificationNumber === null ||
                secretary.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.identificationNumber
                  ? secretary.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.nationality === null || secretary.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.nationality ? secretary.nationality : "Not Available"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.race === null || secretary.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ secretary.race ? secretary.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="secretary.isMale === null ? 'not-available' : ''"
            >
              {{
                secretary.isMale
                  ? secretary.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.phoneNumber === null || secretary.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.phoneNumber ? secretary.phoneNumber : "Not Available"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.companyEmail === null || secretary.companyEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.companyEmail
                  ? secretary.companyEmail
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Professional Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Professional Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.professionalTypeStr === null ||
                secretary.professionalTypeStr === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.professionalTypeStr
                  ? secretary.professionalTypeStr
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Professional Type -->

          <!-- Start Licensed Secretary No. or Membership No. -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Licensed Secretary No. or Membership No.
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.secretaryOrMembershipNo === null ||
                secretary.secretaryOrMembershipNo === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.secretaryOrMembershipNo
                  ? secretary.secretaryOrMembershipNo
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Licensed Secretary No. or Membership No. -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Service/ Business Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.businessAddress === null ||
                secretary.businessAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.businessAddress
                  ? secretary.businessAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                secretary.residentialAddress === null ||
                secretary.residentialAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                secretary.residentialAddress
                  ? secretary.residentialAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p fxFlex="calc(50%-40px)" *ngIf="secretary.dateOfPassportExpiry">
              {{ secretary.dateOfPassportExpiry | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!secretary.dateOfPassportExpiry"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="secretary.dateOfBirth">
              {{ secretary.dateOfBirth | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!secretary.dateOfBirth"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="secretary.dateOfAppointment">
              {{ secretary.dateOfAppointment | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!secretary.dateOfAppointment"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="secretary.dateOfResignation">
              {{ secretary.dateOfResignation | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!secretary.dateOfResignation"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Secretaries -->

  <!-- Start Managers -->
  <app-section-title
    title="Managers"
    *ngIf="isSecretarial && client"
  ></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container margin-bottom-40"
    *ngIf="!clientIsLoading && !client.managers.length && isSecretarial"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion
    class="margin-bottom-40"
    *ngIf="!clientIsLoading && isSecretarial && client"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let manager of client.managers; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Manager — {{ manager.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.name === null || manager.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ manager.name ? manager.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.identificationType === null ? 'not-available' : ''
              "
            >
              {{
                manager.identificationType
                  ? manager.identificationType.type
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.identificationNumber === null ||
                manager.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                manager.identificationNumber
                  ? manager.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.nationality === null || manager.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ manager.nationality ? manager.nationality : "Not Available" }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.race === null || manager.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ manager.race ? manager.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="manager.isMale === null ? 'not-available' : ''"
            >
              {{
                manager.isMale
                  ? manager.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.phoneNumber === null || manager.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ manager.phoneNumber ? manager.phoneNumber : "Not Available" }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.companyEmail === null || manager.companyEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                manager.companyEmail ? manager.companyEmail : "Not Available"
              }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.privateEmail === null || manager.privateEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                manager.privateEmail ? manager.privateEmail : "Not Available"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Service/ Business Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.businessAddress === null ||
                manager.businessAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                manager.businessAddress
                  ? manager.businessAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                manager.residentialAddress === null ||
                manager.residentialAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                manager.residentialAddress
                  ? manager.residentialAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p fxFlex="calc(50%-40px)" *ngIf="manager.dateOfPassportExpiry">
              {{ manager.dateOfPassportExpiry | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!manager.dateOfPassportExpiry"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="manager.dateOfBirth">
              {{ manager.dateOfBirth | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!manager.dateOfBirth"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="manager.dateOfAppointmentRef">
              {{ manager.dateOfAppointmentRef | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!manager.dateOfAppointmentRef"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div fxLayoutGap="8px" *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="manager.dateOfResignation">
              {{ manager.dateOfResignation | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!manager.dateOfResignation"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <!-- End Managers -->

  <app-section-title
    title="Files"
    *ngIf="isSecretarial && clientYearDetails"
  ></app-section-title>
  <app-file-organizer
    class="margin-bottom-40"
    [clientType]="clientType"
    [yearID]="clientYearDetails.id"
    [isCreateUpdate]="false"
    *ngIf="isSecretarial && clientYearDetails"
  ></app-file-organizer>
  <!-- End Secretarial Only -->

  <app-section-title title="Manage Client"></app-section-title>
  <div
    fxLayout="column"
    fxLayoutAlign="stretch"
    *ngIf="!clientIsLoading"
    class="manage-client-container"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="space-between start"
      fxLayoutGap="16px"
      class="padding-16"
    >
      <p class="no-margin" fxFlex.xs="calc(80%-16px)">
        Edit and update "{{ client.name }}" to keep information up to date
      </p>
      <button
        fxFlex.xs="calc(20%-16px)"
        mat-stroked-button
        color="primary"
        routerLink="/clients/{{ clientType }}/edit/{{ clientID }}"
      >
        Edit
      </button>
    </div>
    <mat-divider></mat-divider>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutAlign.xs="space-between start"
      fxLayoutGap="16px"
      class="padding-16"
    >
      <p class="no-margin" fxFlex.xs="calc(80%-16px)">
        <span
          >Delete "{{ client.name }}" and all it's related information.</span
        >
        <br />
        <span class="italic"
          >You will not be able to restore anything related to this
          client.</span
        >
      </p>
      <button
        fxFlex.xs="calc(20%-16px)"
        mat-stroked-button
        color="warn"
        (click)="showConfirmDeleteDialog()"
      >
        Delete
      </button>
    </div>
  </div>
</section>
