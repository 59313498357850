import { Component, Input, OnInit } from '@angular/core';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { Article } from 'src/app/shared/models/article/article.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';

@Component({
  selector: 'app-create-article-success',
  templateUrl: './create-article-success.component.html',
  styleUrls: ['./create-article-success.component.scss'],
})
export class CreateArticleSuccessComponent implements OnInit {
  @Input() pendingImages: PendingFile[] = [];

  // Variables
  article!: Article;

  // Loading
  isUploading = false;

  // Boolean
  isUploaded = false;

  constructor(
    private jwtService: JwtService,
    private articlesService: ArticlesService
  ) {}

  ngOnInit(): void {
    this.article = history.state.article;
    this.pendingImages = history.state.images;

    if (this.pendingImages?.length > 0) {
      this.uploadFiles();
    }
  }

  uploadFiles(): void {
    this.isUploading = true;
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingImages.length; index++) {
      const file: PendingFile = this.pendingImages[index];
      formData.append(file.folderName, file.file);
    }

    this.articlesService
      .uploadArticleImage(formData, this.article.id)
      .subscribe({
        next: (_: Article) => {
          this.isUploading = false;
          this.isUploaded = true;
          this.jwtService.destroyContentType();
        },
        error: (error) => {
          console.log(error);
          this.isUploading = false;
          this.isUploaded = false;
          this.jwtService.destroyContentType();
        },
      });
  }
}
