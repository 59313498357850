import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { Client } from 'src/app/shared/models/client/client.model';
import { YearFile } from 'src/app/shared/models/year-file/year-file.model';
import { Year } from 'src/app/shared/models/year/year.model';
import { ApiService } from './../api_service/api.service';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private apiService: ApiService) {}

  // Files
  getFolders(department: string): Observable<any> {
    return this.apiService.get(`/api/core/clients/subfolderlist/${department}`);
  }

  getFiles(
    folderName: string,
    yearID: number,
    clientType: string
  ): Observable<Array<YearFile>> {
    return this.apiService.get(
      `/api/core/clients/${yearID}/${clientType}/${folderName}/files`
    );
  }

  uploadClientYearFiles(
    data: FormData,
    yearID: number,
    clientType: string
  ): Observable<any> {
    return this.apiService.post(
      `/api/core/clients/details/${yearID}/files/${clientType}`,
      data,
      true
    );
  }

  renameFile(data: object = {}, fileID: number): Observable<YearFile> {
    return this.apiService.put(`/api/core/clients/files/${fileID}`, data);
  }

  deleteFile(yearID: number, fileID: number): Observable<any> {
    return this.apiService.delete(
      `/api/core/clients/files/${yearID}/delete/${fileID}`
    );
  }

  // Clients
  getClients(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/clientssearch', data);
  }

  // Client Details
  getClientDetails(companyID: number): Observable<Client> {
    return this.apiService.get(`/api/core/clients/${companyID}`);
  }

  deleteClient(clientID: number, clientType: string): Observable<any> {
    return this.apiService.delete(
      `/api/core/clients/${clientID}/${clientType}`
    );
  }

  getContactPersonType(): Observable<any> {
    return this.apiService.get(`/api/core/clients/contacttype`);
  }

  // Year Details
  getClientDetailsForLatestYear(
    clientID: number
  ): Observable<ClientYearDetails> {
    return this.apiService.get(`/api/core/clientsdetails/latest/${clientID}`);
  }

  getClientDetailsForSpecificYear(
    yearID: number
  ): Observable<ClientYearDetails> {
    return this.apiService.get(`/api/core/clientsdetails/${yearID}`);
  }

  getClientDetailsForSpecificYearWithYearStr(
    clientID: number,
    yearID: number,
    clientType: string
  ): Observable<ClientYearDetails> {
    return this.apiService.get(
      `/api/core/clientsdetails/${clientID}/${yearID}/${clientType}`
    );
  }

  getClientAvailableYears(
    companyID: number,
    clientType: string
  ): Observable<Array<Year>> {
    return this.apiService.get(
      `/api/core/clients/years/${companyID}/${clientType}`
    );
  }

  deleteYearDetails(
    clientID: number,
    yearID: number,
    clientType: string
  ): Observable<any> {
    return this.apiService.delete(
      `/api/core/clients/${clientID}/details/${yearID}/${clientType}`
    );
  }

  // Client Form
  duplicateYearDetails(
    clientID: number,
    year: number,
    clientType: string
  ): Observable<any> {
    return this.apiService.post(
      `/api/core/clients/${clientID}/duplicate/${year}/${clientType}`
    );
  }

  checkClientExist(companyNumber: string): Observable<any> {
    return this.apiService.get(`/api/core/clients/validate/${companyNumber}`);
  }

  checkIcPassportExist(idNumber: string): Observable<any> {
    return this.apiService.get(
      `/api/core/clients/validateEnterprise/${idNumber}`
    );
  }

  createGeneralCompanyDetails(data: object = {}): Observable<Client> {
    return this.apiService.post(`/api/core/clients`, data);
  }

  saveGeneralCompanyDetails(
    data: object = {},
    clientID: number
  ): Observable<any> {
    return this.apiService.put(`/api/core/clients/${clientID}`, data);
  }

  getMsicCode(msic: string): Observable<any> {
    return this.apiService.get(`/api/core/clients/msiccodes/${msic}`);
  }

  createClientYearDetails(
    data: object = {},
    clientID: number
  ): Observable<any> {
    return this.apiService.post(`/api/core/clients/${clientID}/details`, data);
  }

  saveClientYearDetails(
    data: object = {},
    yearID: number
  ): Observable<ClientYearDetails> {
    return this.apiService.put(`/api/core/clients/details/${yearID}`, data);
  }

  getBanks(name: string): Observable<any> {
    return this.apiService.get(`/api/core/clients/banks/${name}`);
  }

  getMatchingSecretaryName(secretaryName: string): Observable<any> {
    return this.apiService.get(`/api/core/clients/secretary/${secretaryName}`);
  }
}
