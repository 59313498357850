import { JwtService } from './../jwt/jwt.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';
import { ApiService } from './../api_service/api.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnquiriesService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private jwtService: JwtService
  ) {}

  getEnquiries(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/enquiriessearch', data);
  }

  getEnquiry(id: number): Observable<Enquiry> {
    return this.apiService.get(`/api/core/enquiries/${id}`);
  }

  updateEnquiry(data: Map<any, any>, id: number): Observable<any> {
    return this.apiService.put(`/api/core/enquiries/${id}`, data);
  }

  deleteEnquiry(id: number): Observable<any> {
    return this.apiService.delete(`/api/core/enquiries/${id}`);
  }
}
