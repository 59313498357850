import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { Article } from 'src/app/shared/models/article/article.model';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { User } from 'src/app/shared/models/user/user.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-draft-articles',
  templateUrl: './draft-articles.component.html',
  styleUrls: ['./draft-articles.component.scss'],
})
export class DraftArticlesComponent implements OnInit, OnDestroy {
  // Boolean
  isLoading = false;

  // Variables
  articles!: Array<Article>;
  user!: User;

  // Subscriptions
  userSubscription: Subscription;

  constructor(
    private articlesService: ArticlesService,
    private snackBar: MatSnackBar,
    private authenticationService: AuthenticationService
  ) {
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id) {
          this.getArticles();
        }
      },
    });
  }
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  getArticles(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.articlesService.getDraftArticles(this.user.id as number).subscribe({
      next: (articles: Array<Article>) => {
        this.articles = articles;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }
}
