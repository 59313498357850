import { UserAccessService } from './../../shared/user-access/user-access.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LogOutDialogComponent } from './log-out-dialog/log-out-dialog.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { UserRole } from 'src/app/shared/enums/user-role.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user!: User;
  userSubscription: Subscription;

  // User Role
  isAdmin = false;
  isCustodian = false;
  isPersonnel = false;
  isCustomer = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private userAccessService: UserAccessService
  ) {
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
  }
  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          case UserRole.personnel:
            this.isPersonnel = true;
            break;
          case UserRole.customer:
            this.isCustomer = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            this.isPersonnel = false;
            this.isCustomer = false;
            break;
        }
      },
    });
  }

  showLogOutDialog(): void {
    const dialogRef = this.dialog.open(LogOutDialogComponent);

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.signOutPressed();
        }
      },
    });
  }

  showChangePasswordDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent);

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.dialog.closeAll();
        }
      },
    });
  }

  signOutPressed(): void {
    this.authenticationService.signOut();
    this.router.navigate(['.']);
  }
}
