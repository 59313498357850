import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { SharedServicesClientsComponent } from '../shared-services-clients.component';

@Component({
  selector: 'app-shared-services-clients-filter',
  templateUrl: './shared-services-clients-filter.component.html',
  styleUrls: ['./shared-services-clients-filter.component.scss'],
})
export class SharedServicesClientsFilterComponent implements OnInit {
  companyTypes: Array<{ value: string; valueName: string }>;
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private formBuilder: FormBuilder,
    private clientFormConstants: ClientFormConstants,
    private sharedServicesClientsComponent: SharedServicesClientsComponent
  ) {
    this.companyTypes = this.clientFormConstants.COMPANY_TYPES;
    this.filterForm = this.formBuilder.group({
      filterClient: new FormControl(''),
      companyType: new FormControl('ALL'),
      createdStart: new FormControl(null),
      createdEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.sharedServicesClientsComponent.pageSize;
    this.currentPage = this.sharedServicesClientsComponent.currentPage;

    const filterClient = this.filterForm.get('filterClient')?.value;
    const companyType = this.filterForm.get('companyType')?.value;
    const createdStart = this.filterForm.get('createdStart')?.value;
    const createdEnd = this.filterForm.get('createdEnd')?.value;

    this.sharedServicesClientsComponent.getSharedServicesClients(
      filterClient,
      this.pageSize,
      this.currentPage,
      companyType,
      createdStart,
      createdEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.sharedServicesClientsComponent.getSharedServicesClients();
  }
}
