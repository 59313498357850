import { LayoutModule } from '@angular/cdk/layout';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpTokenInterceptor } from './core/interceptors/http-token.interceptor';
import { ArticlesComponent } from './modules/articles/articles.component';
import { AuditClientsFilterComponent } from './modules/clients/audit-clients/audit-clients-filter/audit-clients-filter.component';
import { AuditClientsComponent } from './modules/clients/audit-clients/audit-clients.component';
import { EditAuditClientComponent } from './modules/clients/audit-clients/edit-audit-client/edit-audit-client.component';
import { NewAuditClientComponent } from './modules/clients/audit-clients/new-audit-client/new-audit-client.component';
import { ClientDetailsComponent } from './modules/clients/client-details/client-details.component';
import { CopyYearDialogComponent } from './modules/clients/client-details/copy-year-dialog/copy-year-dialog.component';
import { DeleteYearDialogComponent } from './modules/clients/client-details/delete-year-dialog/delete-year-dialog.component';
import { EditYearDetailsComponent } from './modules/clients/client-details/edit-year-details/edit-year-details.component';
import { CreateYearDetailsSuccessComponent } from './modules/clients/client-details/new-year-details/create-year-details-success/create-year-details-success.component';
import { NewYearDetailsComponent } from './modules/clients/client-details/new-year-details/new-year-details.component';
import { YearDetailsComponent } from './modules/clients/client-details/year-details/year-details.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { CreateClientSuccessComponent } from './modules/clients/create-client-success/create-client-success.component';
import { DeleteClientDialogComponent } from './modules/clients/delete-client-dialog/delete-client-dialog.component';
import { DeleteFileDialogComponent } from './modules/clients/file-organizer/delete-file-dialog/delete-file-dialog.component';
import { FileOrganizerComponent } from './modules/clients/file-organizer/file-organizer.component';
import { RenameFileDialogComponent } from './modules/clients/file-organizer/rename-file-dialog/rename-file-dialog.component';
import { ClientFormComponent } from './modules/clients/forms/client-form/client-form/client-form.component';
import { PopulateFormDialogComponent } from './modules/clients/forms/client-form/populate-form-dialog/populate-form-dialog.component';
import { BanksFormComponent } from './modules/clients/forms/year-details-form/banks-form/banks-form.component';
import { DirectorsFormComponent } from './modules/clients/forms/year-details-form/directors-form/directors-form.component';
import { FilesFormComponent } from './modules/clients/forms/year-details-form/files-form/files-form.component';
import { MembersFormComponent } from './modules/clients/forms/year-details-form/members-form/members-form.component';
import { ReviewPublishComponent } from './modules/clients/forms/year-details-form/review-publish/review-publish.component';
import { YearClientDetailsFormComponent } from './modules/clients/forms/year-details-form/year-client-details-form/year-client-details-form.component';
import { YearFormComponent } from './modules/clients/forms/year-details-form/year-form/year-form.component';
import { EditSecretarialClientComponent } from './modules/clients/secretarial-clients/edit-secretarial-client/edit-secretarial-client.component';
import { NewSecretarialClientComponent } from './modules/clients/secretarial-clients/new-secretarial-client/new-secretarial-client.component';
import { SecretarialClientsFilterComponent } from './modules/clients/secretarial-clients/secretarial-clients-filter/secretarial-clients-filter.component';
import { SecretarialClientsComponent } from './modules/clients/secretarial-clients/secretarial-clients.component';
import { EditSharedServicesClientComponent } from './modules/clients/shared-services-clients/edit-shared-services-client/edit-shared-services-client.component';
import { NewSharedServicesClientComponent } from './modules/clients/shared-services-clients/new-shared-services-client/new-shared-services-client.component';
import { SharedServicesClientsFilterComponent } from './modules/clients/shared-services-clients/shared-services-clients-filter/shared-services-clients-filter.component';
import { SharedServicesClientsComponent } from './modules/clients/shared-services-clients/shared-services-clients.component';
import { EditTaxClientComponent } from './modules/clients/tax-clients/edit-tax-client/edit-tax-client.component';
import { NewTaxClientComponent } from './modules/clients/tax-clients/new-tax-client/new-tax-client.component';
import { TaxClientsFilterComponent } from './modules/clients/tax-clients/tax-clients-filter/tax-clients-filter.component';
import { TaxClientsComponent } from './modules/clients/tax-clients/tax-clients.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DeleteEnquiriesDialogComponent } from './modules/enquiries/delete-enquiries-dialog/delete-enquiries-dialog.component';
import { EnquiriesFilterComponent } from './modules/enquiries/enquiries-filter/enquiries-filter.component';
import { EnquiriesComponent } from './modules/enquiries/enquiries.component';
import { EnquiryDetailsComponent } from './modules/enquiries/enquiry-details/enquiry-details.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/login/login.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { PageTitleComponent } from './modules/page-title/page-title.component';
import { ChangePasswordDialogComponent } from './modules/profile/change-password-dialog/change-password-dialog.component';
import { EditProfileComponent } from './modules/profile/edit-profile/edit-profile.component';
import { LogOutDialogComponent } from './modules/profile/log-out-dialog/log-out-dialog.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { DeleteRequestDialogComponent } from './modules/requests/delete-request-dialog/delete-request-dialog.component';
import { RequestDetailsComponent } from './modules/requests/request-details/request-details.component';
import { RequestsFilterComponent } from './modules/requests/requests-filter/requests-filter.component';
import { RequestsComponent } from './modules/requests/requests.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { SectionTitleComponent } from './modules/section-title/section-title.component';
import { ServiceTileComponent } from './modules/service-tile/service-tile.component';
import { ServicesComponent } from './modules/services/services.component';
import { SideNavComponent } from './modules/side-nav/side-nav/side-nav.component';
import { UserFormComponent } from './modules/user-form/user-form.component';
import { AddUserComponent } from './modules/users/add-user/add-user.component';
import { DeleteDialogComponent } from './modules/users/delete-dialog/delete-dialog.component';
import { EditUserComponent } from './modules/users/edit-user/edit-user.component';
import { UsersFilterComponent } from './modules/users/users-filter/users-filter.component';
import { UsersComponent } from './modules/users/users.component';
import { CustomBreakpointService } from './shared/breakpoint/custom-breakpoint.service';
import { ClientTypeService } from './shared/client-type/client-type.service';
import { LoginBreakpointService } from './shared/login-breakpoint/login-breakpoint.service';
import { UserAccessService } from './shared/user-access/user-access.service';
import { ServiceListComponent } from './modules/services/service-list/service-list.component';
import { ServicesListFilterComponent } from './modules/services/service-list/services-list-filter/services-list-filter.component';
import { ServiceDetailsComponent } from './modules/services/service-details/service-details.component';
import { DeleteServiceDialogComponent } from './modules/services/delete-service-dialog/delete-service-dialog.component';
import { ArticlesFilterComponent } from './modules/articles/articles-filter/articles-filter.component';
import { NewArticleComponent } from './modules/articles/new-article/new-article.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ArticleFormComponent } from './modules/articles/form/article-form/article-form.component';
import { CreateArticleSuccessComponent } from './modules/articles/new-article/create-article-success/create-article-success.component';
import { EditArticleComponent } from './modules/articles/edit-article/edit-article.component';
import { QuillModule } from 'ngx-quill';
import { DraftArticlesComponent } from './modules/articles/draft-articles/draft-articles.component';
import { DeleteArticleDialogComponent } from './modules/articles/delete-article-dialog/delete-article-dialog.component';
import { ArticleDetailsComponent } from './modules/articles/article-details/article-details.component';
import { SafePipe } from './safe.pipe';
import { WeeklyStatsComponent } from './modules/dashboard/weekly-stats/weekly-stats.component';
import { ActivitiesComponent } from './modules/dashboard/activities/activities.component';
import { UnreadEnquiriesComponent } from './modules/dashboard/unread-enquiries/unread-enquiries.component';
import { AssignedDepartmentRequestsComponent } from './modules/dashboard/assigned-department-requests/assigned-department-requests.component';
import { CustomerRequestsComponent } from './modules/customer-requests/customer-requests.component';
import { CustomerRequestListComponent } from './modules/customer-requests/customer-request-list/customer-request-list.component';
import { NewCustomerRequestComponent } from './modules/customer-requests/new-customer-request/new-customer-request.component';
import { CustomerEntitiesComponent } from './modules/customer-entities/customer-entities.component';
import { EntityTileComponent } from './modules/customer-entities/entity-tile/entity-tile.component';
import { CustomerEntityDetailsComponent } from './modules/customer-entities/customer-entity-details/customer-entity-details.component';
import { CompanyDetailsComponent } from './modules/customer-entities/customer-entity-details/company-details/company-details.component';
import { EntityYearDetailsComponent } from './modules/customer-entities/customer-entity-details/entity-year-details/entity-year-details.component';
import { CustomerEntityFilesComponent } from './modules/customer-entities/customer-entity-files/customer-entity-files.component';
import { NotSubscribedServiceDialogComponent } from './modules/customer-entities/customer-entity-details/not-subscribed-service-dialog/not-subscribed-service-dialog.component';
import { QuickActionsComponent } from './modules/dashboard/quick-actions/quick-actions.component';
import { RequestStatusComponent } from './modules/dashboard/request-status/request-status.component';
import { UserNotificationsComponent } from './modules/dashboard/user-notifications/user-notifications.component';
import { ContactPersonFormComponent } from './modules/clients/forms/client-form/contact-person-form/contact-person-form.component';
import { BranchAddressesFormComponent } from './modules/clients/forms/year-details-form/branch-addresses-form/branch-addresses-form.component';
import { SecretaryFormComponent } from './modules/clients/forms/client-form/secretary-form/secretary-form.component';
import { ManagerFormComponent } from './modules/clients/forms/client-form/manager-form/manager-form.component';
import { AuditorsFormComponent } from './modules/clients/forms/year-details-form/auditors-form/auditors-form.component';
import { TaxAgentsFormComponent } from './modules/clients/forms/client-form/tax-agents-form/tax-agents-form.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SideNavComponent,
    DashboardComponent,
    ClientsComponent,
    EnquiriesComponent,
    RequestsComponent,
    ServicesComponent,
    UsersComponent,
    ArticlesComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NotFoundComponent,
    PageTitleComponent,
    UsersFilterComponent,
    AddUserComponent,
    SectionTitleComponent,
    UserFormComponent,
    DeleteDialogComponent,
    EditUserComponent,
    EnquiriesFilterComponent,
    DeleteEnquiriesDialogComponent,
    EnquiryDetailsComponent,
    LogOutDialogComponent,
    ChangePasswordDialogComponent,
    EditProfileComponent,
    RequestsFilterComponent,
    DeleteRequestDialogComponent,
    RequestDetailsComponent,
    ServiceTileComponent,
    SecretarialClientsComponent,
    NewSecretarialClientComponent,
    SecretarialClientsFilterComponent,
    DirectorsFormComponent,
    MembersFormComponent,
    CreateClientSuccessComponent,
    ClientDetailsComponent,
    YearDetailsComponent,
    NewYearDetailsComponent,
    YearFormComponent,
    YearClientDetailsFormComponent,
    FilesFormComponent,
    ReviewPublishComponent,
    CreateYearDetailsSuccessComponent,
    CopyYearDialogComponent,
    FileOrganizerComponent,
    ClientFormComponent,
    EditSecretarialClientComponent,
    DeleteClientDialogComponent,
    EditYearDetailsComponent,
    DeleteYearDialogComponent,
    RenameFileDialogComponent,
    DeleteFileDialogComponent,
    TaxClientsComponent,
    TaxClientsFilterComponent,
    NewTaxClientComponent,
    PopulateFormDialogComponent,
    EditTaxClientComponent,
    AuditClientsComponent,
    AuditClientsFilterComponent,
    NewAuditClientComponent,
    EditAuditClientComponent,
    BanksFormComponent,
    SharedServicesClientsComponent,
    SharedServicesClientsFilterComponent,
    NewSharedServicesClientComponent,
    EditSharedServicesClientComponent,
    ServiceListComponent,
    ServicesListFilterComponent,
    ServiceDetailsComponent,
    DeleteServiceDialogComponent,
    ArticlesFilterComponent,
    NewArticleComponent,
    ArticleFormComponent,
    CreateArticleSuccessComponent,
    EditArticleComponent,
    DraftArticlesComponent,
    DeleteArticleDialogComponent,
    ArticleDetailsComponent,
    SafePipe,
    WeeklyStatsComponent,
    ActivitiesComponent,
    UnreadEnquiriesComponent,
    AssignedDepartmentRequestsComponent,
    CustomerRequestsComponent,
    CustomerRequestListComponent,
    NewCustomerRequestComponent,
    CustomerEntitiesComponent,
    EntityTileComponent,
    CustomerEntityDetailsComponent,
    CompanyDetailsComponent,
    EntityYearDetailsComponent,
    CustomerEntityFilesComponent,
    NotSubscribedServiceDialogComponent,
    QuickActionsComponent,
    RequestStatusComponent,
    UserNotificationsComponent,
    ContactPersonFormComponent,
    BranchAddressesFormComponent,
    SecretaryFormComponent,
    ManagerFormComponent,
    AuditorsFormComponent,
    TaxAgentsFormComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
    // Material Design
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatDialogModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatGridListModule,
    MatRippleModule,
    MatRadioModule,
    MatChipsModule,
    MatCheckboxModule,
  ],
  providers: [
    MatDatepickerModule,
    DatePipe,
    MatNativeDateModule,
    CustomBreakpointService,
    LoginBreakpointService,
    UserAccessService,
    ClientTypeService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
