<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="24px"
  class="padding-24"
>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <!-- Start Title -->
    <app-page-title
      title="Articles"
      subtitle="They say, billionaires read a lot right?"
    ></app-page-title>
    <!-- End Title -->
    <!-- Start Action Button -->
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center center">
      <button
        mat-button
        color="primary"
        routerLink="drafts"
        class="uppercase"
        fxHide.xs
      >
        Saved Drafts
      </button>

      <button
        class="extended-fab-button"
        mat-fab
        color="primary"
        routerLink="create"
      >
        <mat-icon>add</mat-icon>
        <span class="extended-fab-button__text">Article</span>
      </button>
    </div>
    <!-- End Action Button -->
  </div>
  <!-- Start Saved Drafts -->
  <button
    mat-stroked-button
    color="primary"
    routerLink="drafts"
    class="uppercase"
    fxHide
    fxShow.xs
  >
    Saved Drafts
  </button>
  <!-- End Saved Drafts -->
  <!-- Start Search Bar and Filter -->
  <app-articles-filter></app-articles-filter>
  <!-- End Search Bar and Filter -->

  <!-- Start Table -->
  <mat-table
    [fxHide]="!articles?.length"
    [dataSource]="dataSource"
    matSort
    matSortActive="updatedAt"
    matSortDirection="desc"
    class="margin-bottom-16"
  >
    <!-- Start Article ID -->
    <ng-container matColumnDef="id">
      <mat-header-cell mat-sort-header="id" *matHeaderCellDef>
        ID
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">{{ data.id }}</mat-cell>
    </ng-container>
    <!-- End Article ID -->

    <!-- Start Title -->
    <ng-container matColumnDef="mainTitle">
      <mat-header-cell mat-sort-header="mainTitle" *matHeaderCellDef>
        Title
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data" class="ellipsis">
        <div fxLayout="column">
          <span>{{ data.mainTitle }}</span>
          <small class="categories">
            <span
              class="capitalize"
              *ngFor="
                let department of getCategoriesStr(data.categories);
                let isLast = last
              "
            >
              {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
            </span>
          </small>
        </div>
      </mat-cell>
    </ng-container>
    <!-- End Title -->

    <!-- Start Created Date -->
    <ng-container matColumnDef="createdDate">
      <mat-header-cell mat-sort-header="createdDate" *matHeaderCellDef>
        Created At
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <span matTooltip="{{ data.createdDate | date: 'short' }}">{{
          data.createdDate | date
        }}</span>
      </mat-cell>
    </ng-container>
    <!-- End Created Date -->

    <!-- Start Published Date -->
    <ng-container matColumnDef="publishedDate">
      <mat-header-cell mat-sort-header="publishedDate" *matHeaderCellDef>
        Published At
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <span
          matTooltip="{{ data.publishedDate | date: 'short' }}"
          *ngIf="data.publish === true"
          >{{ data.publishedDate | date }}
        </span>
        <span *ngIf="data.publish === false" class="not-available"
          >Not Published</span
        >
      </mat-cell>
    </ng-container>
    <!-- End Published Date -->

    <!-- Start Updated Date -->
    <ng-container matColumnDef="lastModifiedDate">
      <mat-header-cell mat-sort-header="lastModifiedDate" *matHeaderCellDef>
        Updated At
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <span matTooltip="{{ data.lastModifiedDate | date: 'short' }}">{{
          data.lastModifiedDate | date
        }}</span>
      </mat-cell>
    </ng-container>
    <!-- End Updated Date -->

    <!-- Start More Info -->
    <ng-container matColumnDef="options" ngClass.xs="mobile">
      <mat-header-cell *matHeaderCellDef>
        <span class="text-align-center">More info</span>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let data">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['details', data.id]">
            <mat-icon>visibility</mat-icon>
            <span>View</span>
          </button>
          <button mat-menu-item [routerLink]="['edit', data.id]">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>
    <!-- End More Info -->

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <!-- End Table -->

  <!-- Start Empty Table Message -->
  <div
    *ngIf="!articles?.length"
    class="empty-table-message"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    Could not find any data
  </div>
  <!-- End Empty Table Message -->
  <!-- Start Pagination -->
  <mat-paginator
    #paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageEvent = onPageChanged($event)"
  >
  </mat-paginator>
  <!-- End Pagination -->
</section>
