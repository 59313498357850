import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { Device } from 'src/app/shared/enums/device.enum';
import { Gender } from 'src/app/shared/models/gender/gender.model';
import { IdentificationType } from 'src/app/shared/models/identification-type/identification-type.model';
import { CustomBreakpointService } from '../../../../../shared/breakpoint/custom-breakpoint.service';
import { UserFormConstants } from '../../../../../shared/constants/user-form.constants';

@Component({
  selector: 'app-directors-form',
  templateUrl: './directors-form.component.html',
  styleUrls: ['./directors-form.component.scss'],
})
export class DirectorsFormComponent implements OnInit, OnDestroy {
  // Form
  @Input() directorsForm!: FormGroup;
  @Input() disabled!: boolean;

  // Dropdown Lists
  idTypes: Array<IdentificationType>;
  genders: Array<Gender>;

  // ID Types: Is IC or not
  listOfIdTypes: Array<boolean> = [];

  // Subscriptions
  idTypeSubscription: Subscription | undefined;

  // Responsive
  isMobile = true;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;
  clientType: string;

  constructor(
    private userFormConstants: UserFormConstants,
    private formBuilder: FormBuilder,
    private customBreakpointService: CustomBreakpointService,
    private clientTypeService: ClientTypeService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.clientTypeService.getClientType();
    this.subscribeToClientType();
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;
    this.idTypes = this.userFormConstants.IDTYPE;
    this.genders = this.userFormConstants.GENDER;
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.idTypeSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToDirectors();
    this.createDirectorItem();
    this.listOfIdTypes.push(true);
  }

  subscribeToDirectors(): void {
    this.directorsForm.controls.directors.valueChanges.subscribe({
      next: (directorForms) => {
        for (let index = 0; index < directorForms.length; index++) {
          this.subscribeToIDType(index);
        }
      },
    });
  }

  subscribeToIDType(index: number): void {
    this.idTypeSubscription = this.directors.controls[index]
      .get('identificationTypeStr')
      ?.valueChanges.subscribe({
        next: (result) => {
          const directorForm: FormGroup = this.directors.controls[
            index
          ] as FormGroup;

          if (result === 'IC') {
            this.listOfIdTypes[index] = true;
            directorForm.controls.identificationNumber.setValidators([
              Validators.required,
              Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
            ]);
          } else {
            this.listOfIdTypes[index] = false;
            directorForm.controls.identificationNumber.setValidators([
              Validators.required,
            ]);
          }
          directorForm.updateValueAndValidity();
        },
      });
  }

  get directors(): FormArray {
    return this.directorsForm.get('directors') as FormArray;
  }

  createDirectorItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      male: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      residentialAddress: new FormControl(''),
      phoneNumber: new FormControl(''),
      dateOfAppointmentRef: new FormControl(null),
      dateOfResignationRef: new FormControl(null),
      alternativeDirector: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
    });
  }

  addDirector(): void {
    this.directors.push(this.createDirectorItem());
    this.listOfIdTypes.push(true);
  }

  removeDirector(index: number): void {
    this.directors.removeAt(index);
    this.listOfIdTypes.splice(index, 1);
  }
}
