<form fxLayout="column" fxLayoutAlign="start stretch" [formGroup]="articleForm">
  <app-section-title title="Article Image"></app-section-title>
  <!-- Start Image Picker -->
  <div class="margin-bottom-32" *ngIf="!existingImageUrl">
    <input
      formControlName="imageFile"
      type="file"
      class="file-input"
      (change)="uploadFilePressed($event)"
      accept="image/*"
      #fileUpload
    />
    <button
      (click)="fileUpload.click()"
      mat-stroked-button
      type="button"
      [color]="pendingImages.length ? 'normal' : 'primary'"
    >
      <mat-icon class="material-icons-outlined">{{
        pendingImages.length ? "image" : "upload"
      }}</mat-icon>
      <span class="margin-left-8">{{
        pendingImages.length ? pendingImages[0].file.name : "Upload Image"
      }}</span>
    </button>
  </div>
  <!-- End Image Picker -->
  <!-- Start Existing Article Image -->
  <div
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.xs="start stretch"
    fxLayoutGap="16px"
    class="margin-bottom-40"
    *ngIf="existingImageUrl"
  >
    <div
      fxLayout="column"
      fxLayoutGap="16px"
      class="existing-image-container"
      ngClass="width-50"
      ngClass.xs="width-100"
    >
      <img
        [src]="existingImageUrl"
        alt=""
        *ngIf="existingImageUrl"
        class="existing-image width-100"
      />
      <mat-divider></mat-divider>
      <button mat-stroked-button color="warn" (click)="deleteImagePressed()">
        Delete
      </button>
    </div>
  </div>
  <!-- End Existing Article Image -->

  <!-- Start Section Title -->
  <app-section-title title="Article Details"></app-section-title>
  <!-- End Section Title -->

  <div fxLayout="column" fxLayoutGap="16px">
    <!-- Start Tags -->
    <mat-form-field appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-chip-list
        #categoryList
        aria-label="Tags Selection"
        formControlName="categories"
        required
      >
        <mat-chip
          *ngFor="let category of selectedCategories"
          [selectable]="true"
          [removable]="true"
          (removed)="removeCategory(category)"
          class="capitalize"
        >
          {{ category.toLowerCase() }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          #categoriesInput
          formControlName="categories"
          [matAutocomplete]="autoCompleteCategories"
          [matChipInputFor]="categoryList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addCategory($event)"
        />
      </mat-chip-list>
      <mat-error
        *ngIf="articleForm.controls['categories'].hasError('required')"
      >
        At least 1 tag is required
      </mat-error>

      <mat-error
        *ngIf="articleForm.controls['categories'].hasError('notAvailable')"
      >
        Tags not available
      </mat-error>
      <mat-autocomplete
        autoActiveFirstOption
        #autoCompleteCategories="matAutocomplete"
        (optionSelected)="selectedCategory($event)"
      >
        <mat-option
          *ngFor="let category of filteredCategoryOptions | async"
          [value]="category.name"
          class="capitalize"
          matTooltip="{{ category.name }}"
        >
          <span>{{ category.name.toLowerCase() }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!-- End Tags -->

    <!-- Start Title -->
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input
        matInput
        type="text"
        formControlName="mainTitle"
        #mainTitle
        maxlength="254"
        required
      />
      <mat-hint align="end">{{ mainTitle.value?.length || 0 }}/254</mat-hint>
      <mat-error *ngIf="articleForm.controls['mainTitle'].hasError('required')">
        Title is required
      </mat-error>
    </mat-form-field>
    <!-- End Title -->

    <!-- Start Short Description -->
    <mat-form-field appearance="outline">
      <mat-label>Short Description</mat-label>
      <textarea
        matInput
        type="text"
        rows="4"
        formControlName="shortDescription"
        #shortDescription
        maxlength="254"
        required
      ></textarea>
      <mat-hint align="end"
        >{{ shortDescription.value?.length || 0 }}/254</mat-hint
      >
      <mat-error
        *ngIf="articleForm.controls['shortDescription'].hasError('required')"
      >
        Short description is required
      </mat-error>
    </mat-form-field>
    <!-- End Short Description -->

    <!-- Start Full Description -->
    <quill-editor
      formControlName="fullDescription"
      [modules]="modules"
      (onContentChanged)="textChanged($event)"
    ></quill-editor>
    <mat-error
      class="richtext-editor-error"
      *ngIf="articleForm.controls['fullDescription'].hasError('required')"
    >
      Content is required
    </mat-error>
    <mat-error
      class="richtext-editor-error"
      *ngIf="articleForm.controls['fullDescription'].hasError('maxlength')"
    >
      Content must not exceed 11,000 characters
    </mat-error>
    <!-- End Full Description -->

    <div class="margin-top-32 margin-bottom-32">
      <mat-divider></mat-divider>
    </div>

    <!-- Start Section Title -->
    <app-section-title title="Article Engagement"></app-section-title>
    <!-- End Section Title -->

    <!-- Start Article Engagement Title -->
    <mat-form-field appearance="outline">
      <mat-label>Title</mat-label>
      <input
        matInput
        type="text"
        formControlName="engagementTitle"
        #engagementTitle
        maxlength="254"
        required
      />
      <mat-hint align="end"
        >{{ engagementTitle.value?.length || 0 }}/254</mat-hint
      >
      <mat-error
        *ngIf="articleForm.controls['engagementTitle'].hasError('required')"
      >
        Title is required
      </mat-error>
    </mat-form-field>
    <!-- End Article Engagement Title -->

    <!-- Start Article Engagement Description -->
    <mat-form-field appearance="outline">
      <mat-label>Description</mat-label>
      <input
        matInput
        type="text"
        formControlName="engagementDescription"
        #engagementDescription
        maxlength="254"
        required
      />
      <mat-hint align="end"
        >{{ engagementDescription.value?.length || 0 }}/254</mat-hint
      >
      <mat-error
        *ngIf="
          articleForm.controls['engagementDescription'].hasError('required')
        "
      >
        Description is required
      </mat-error>
    </mat-form-field>
    <!-- End Article Engagement Description -->

    <!-- Start Article Engagement Button Label -->
    <mat-form-field appearance="outline">
      <mat-label>Button Label</mat-label>
      <input
        matInput
        type="text"
        formControlName="engagementButtonLabel"
        #engagementButtonLabel
        maxlength="50"
        required
      />
      <mat-hint align="end"
        >{{ engagementButtonLabel.value?.length || 0 }}/50</mat-hint
      >
      <mat-error
        *ngIf="
          articleForm.controls['engagementButtonLabel'].hasError('maxlength')
        "
      >
        Button label must not be more than 50 characters
      </mat-error>
      <mat-error
        *ngIf="
          articleForm.controls['engagementButtonLabel'].hasError('required')
        "
      >
        Button label is required
      </mat-error>
    </mat-form-field>
    <!-- End Article Engagement Button Label -->

    <div class="margin-top-32 margin-bottom-32">
      <mat-divider></mat-divider>
    </div>

    <app-section-title title="Reference"></app-section-title>

    <!-- Start Reference -->
    <mat-form-field appearance="outline">
      <mat-label>Reference Link</mat-label>
      <input
        matInput
        type="url"
        formControlName="referenceLink"
        #referenceLink
        maxlength="200"
      />
      <mat-hint align="end"
        >{{ referenceLink.value?.length || 0 }}/200</mat-hint
      >
      <mat-error
        *ngIf="articleForm.controls['referenceLink'].hasError('maxlength')"
      >
        Reference Link must not be more than 200 characters
      </mat-error>
      <mat-error
        *ngIf="articleForm.controls['referenceLink'].hasError('required')"
      >
        Reference Link is required
      </mat-error>
      <mat-error
        *ngIf="articleForm.controls['referenceLink'].hasError('pattern')"
      >
        This does not look like an URL
      </mat-error>
    </mat-form-field>
    <!-- End Reference -->
  </div>
</form>
