import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { Article } from 'src/app/shared/models/article/article.model';

@Component({
  selector: 'app-article-form',
  templateUrl: './article-form.component.html',
  styleUrls: ['./article-form.component.scss'],
})
export class ArticleFormComponent implements OnInit, OnDestroy {
  // Form Group
  @Input() articleForm!: FormGroup;
  @Input() selectedCategories: Array<string> = [];
  @Input() pendingImages!: Array<PendingFile>;
  @Input() existingImageUrl!: string;
  @Input() article!: Article;

  // HTML Element Ref
  @ViewChild('categoriesInput') categoriesInput!: ElementRef<HTMLInputElement>;

  // Chips
  separatorKeysCodes: number[] = [ENTER];

  // Loading
  isLoading = false;
  isDeleting = false;

  // Dropdown List
  categoryOptions: BehaviorSubject<Array<ArticleCategory>> =
    new BehaviorSubject<Array<ArticleCategory>>([]);
  filteredCategoryOptions!: Observable<Array<ArticleCategory>>;

  // Editor
  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],
  };
  MAX_CONTENT_LENGTH = 11000;
  CURRENT_CONTENT_LENGTH = 0;

  constructor(
    private snackBar: MatSnackBar,
    private articlesService: ArticlesService
  ) {}

  ngOnInit(): void {
    this.getAllCategories();
  }

  ngOnDestroy(): void {}

  getAllCategories(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: 3000,
    });

    this.articlesService.getAllArticleCategories().subscribe({
      next: (articleCategories: Array<ArticleCategory>) => {
        articleCategories.sort((a, b) => a.name.localeCompare(b.name));
        this.categoryOptions.next(articleCategories);
        this.subscribeToArticleCategories();
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  subscribeToArticleCategories(): void {
    this.filteredCategoryOptions =
      this.articleForm.controls.categories.valueChanges.pipe(
        startWith(''),
        map((name) =>
          name ? this._filter(name) : this.categoryOptions.value.slice()
        )
      );
  }

  uploadFilePressed(event: any): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const pendingFile = {
      folderName: 'file',
      file: files[0],
    } as PendingFile;
    this.pendingImages.push(pendingFile);
  }

  addCategory(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.selectedCategories.push(value);
    }

    // Clear the input value
    this.categoriesInput.nativeElement.value = '';
    if (this.selectedCategories.length > 0) {
      this.articleForm.patchValue({
        categories: '',
      });
      this.articleForm.controls.categories.setErrors(null);
    }
  }

  selectedCategory(event: MatAutocompleteSelectedEvent): void {
    const existingCategory = this.selectedCategories.some(
      (selectedCategory) => selectedCategory === event.option.value
    );

    if (!existingCategory) {
      this.selectedCategories.push(event.option.value);
    }

    // Clear the input value
    this.categoriesInput.nativeElement.value = '';
    if (this.selectedCategories.length > 0) {
      this.articleForm.patchValue({
        categories: '',
      });
      this.articleForm.controls.categories.setErrors(null);
    }
  }

  removeCategory(category: string): void {
    const index = this.selectedCategories.indexOf(category);

    if (index >= 0) {
      this.selectedCategories.splice(index, 1);
    }

    if (this.selectedCategories.length < 1) {
      this.articleForm.patchValue({
        categories: null,
      });
    }
  }

  private _filter(category: string): ArticleCategory[] {
    const filterValue = category.toLowerCase();

    return this.categoryOptions.value.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  deleteImagePressed(): void {
    this.isDeleting = true;
    this.snackBar.open('Deleting article image', '', { duration: 3000 });
    this.articlesService.deleteArticleImage(this.article.id).subscribe({
      next: (result) => {
        console.log(result);

        this.isDeleting = false;
        this.snackBar.open('Article image deleted', '', { duration: 3000 });
        this.existingImageUrl = '';
      },
      error: (error) => {
        console.log(error);
        this.isDeleting = false;
        this.snackBar.dismiss();
      },
    });
  }

  textChanged($event: any) {
    this.CURRENT_CONTENT_LENGTH = $event.editor.getLength();
    if ($event.editor.getLength() > this.MAX_CONTENT_LENGTH) {
      $event.editor.deleteText(
        this.MAX_CONTENT_LENGTH,
        $event.editor.getLength()
      );
    }
  }
}
