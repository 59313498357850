<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24"
  fxFlex
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-40"
  >
    <app-page-title
      title="Add User"
      subtitle="Create users at your finger tips"
    ></app-page-title>
  </div>
  <app-user-form
    class="margin-bottom-40"
    [userForm]="addUserForm"
    [isCustomerForm]="isCustomerForm$"
    [selectedClients]="selectedClients"
  ></app-user-form>
  <div class="bottom-action-bar" fxLayout="row" fxLayoutAlign="end center">
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      (click)="addUserPressed()"
    >
      Save
    </button>
  </div>
</section>
