<div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px">
  <app-customer-request-list
    fxFlex="calc(50% - 24px)"
    fxFlex.xs="100%"
    title="Request Status"
    [isActive]="true"
    [refreshNeeded]="refreshNeeded"
    [limitTo]="2"
  ></app-customer-request-list>
  <div fxLayout="row" fxLayoutAlign="center center">
    <a mat-stroked-button color="primary" routerLink="/user-requests"
      >VIEW ALL</a
    >
  </div>
</div>
