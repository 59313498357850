import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { Auditor } from 'src/app/shared/models/auditor/auditor.model';
import { Client } from 'src/app/shared/models/client/client.model';
import { ContactPerson } from 'src/app/shared/models/client/contact-person/contact-person.model';

@Component({
  selector: 'app-edit-audit-client',
  templateUrl: './edit-audit-client.component.html',
  styleUrls: ['./edit-audit-client.component.scss'],
})
export class EditAuditClientComponent implements OnInit {
  // Form
  generalCompanyDetailsForm!: FormGroup;
  contactsForm!: FormGroup;
  auditorsForm!: FormGroup;

  // Client Type
  clientType: string;

  // Variable
  client: BehaviorSubject<Client> = new BehaviorSubject<Client>({} as Client);

  // Loading
  clientIsSaving = false;
  isLoading = false;

  // Value
  isCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  useCreateAPI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private router: Router,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private clientTypeService: ClientTypeService
  ) {
    this.clientTypeService.getClientType();
    this.clientType = this.clientTypeService.clientTypeStr;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getClientDetails(id);
      },
    });
    this.setupGeneralCompanyDetailsForm();
    this.setupContactsForm();
    this.setupAuditorsForm();
  }

  setupGeneralCompanyDetailsForm(): void {
    this.generalCompanyDetailsForm = this.formBuilder.group({
      companyName: new FormControl('', Validators.required),
      companyNumberNew: new FormControl('', Validators.required),
      companyNumberOld: new FormControl('', Validators.required),
      group: new FormControl(''),
      fileRef: new FormControl(''),
      correspondentAddress: new FormControl('', Validators.required),
      yearEndDay: new FormControl('', [Validators.min(1), Validators.max(31)]),
      yearEndMonth: new FormControl('', [
        Validators.min(1),
        Validators.max(12),
      ]),
      companyType: new FormControl('CORPORATE'),
      remarks: new FormControl(''),
    });
  }

  setupContactsForm(): void {
    this.contactsForm = this.formBuilder.group({
      oftenContactPersons: this.formBuilder.array([]),
    });
    this.oftenContactPersons.push(this.createOftenContactPersonItem());
  }

  setupAuditorsForm(): void {
    this.auditorsForm = this.formBuilder.group({
      auditors: this.formBuilder.array([]),
    });
  }

  get oftenContactPersons(): FormArray {
    return this.contactsForm.get('oftenContactPersons') as FormArray;
  }

  createOftenContactPersonItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      businessEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      personalEmail: new FormControl('', [Validators.email]),
      contactPersonTypeStr: new FormControl('', Validators.required),
    });
  }

  getClientDetails(clientID: number): void {
    this.isLoading = true;
    this.snackBar.open('Getting client', '', {
      duration: undefined,
    });
    this.clientsService.getClientDetails(clientID).subscribe({
      next: (client: Client) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.client.next(client);
        this.prefillForm(client);
        this.prefillContactsForm(client);
        this.prefillAuditorsForm(client);
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  prefillForm(client: Client): void {
    let yearEndDate: string[] = [];
    if (client.yearEnd) {
      yearEndDate = this.convertStringDate(client.yearEnd);
    } else {
      yearEndDate = [];
    }
    this.generalCompanyDetailsForm.patchValue({
      companyName: client.name,
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      group: client.group,
      fileRef: client.fileRef,
      correspondentAddress: client.corresAddress,
      yearEndDay: Number(yearEndDate[0]),
      yearEndMonth: Number(yearEndDate[1]),
      companyType: client.clientTaxType?.type,
      remarks: client.remarksAudit,
    });
  }

  prefillContactsForm(client: Client) {
    this.oftenContactPersons.clear();
    if (client.oftenContactPersons?.length > 0) {
      client.oftenContactPersons.forEach(
        (contactPerson: ContactPerson, index: number) => {
          this.oftenContactPersons.push(this.createOftenContactPersonItem());
          const data = {
            name: contactPerson.name,
            phoneNumber: contactPerson.phoneNumber,
            businessEmail: contactPerson.businessEmail.toLowerCase(),
            personalEmail: contactPerson.personalEmail.toLowerCase(),
            contactPersonTypeStr: contactPerson.contactPersonTypeStr,
          };

          const contactFormGroup: FormGroup = this.oftenContactPersons.controls[
            index
          ] as FormGroup;
          contactFormGroup.patchValue(data);
        }
      );
    } else {
      this.oftenContactPersons.push(this.createOftenContactPersonItem());
    }
  }

  convertStringDate(stringDate: string): string[] {
    return stringDate.split('/');
  }

  get auditors(): FormArray {
    return this.auditorsForm.get('auditors') as FormArray;
  }

  createAuditorItem(): FormGroup {
    return this.formBuilder.group({
      firmName: new FormControl('', Validators.required),
      firmNo: new FormControl('', Validators.required),
      firmAddress: new FormControl(''),
      firmContactNumber: new FormControl('', Validators.required),
      firmEmail: new FormControl('', Validators.email),
      picName: new FormControl(''),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  prefillAuditorsForm(client: Client): void {
    if (client.auditors?.length > 0) {
      this.auditors.clear();
      client.auditors.forEach((auditor: Auditor, index: number) => {
        this.auditors.push(this.createAuditorItem());
        const data = {
          firmName: auditor.firmName,
          firmNo: auditor.firmNo,
          firmAddress: auditor.firmAddress,
          firmContactNumber: auditor.firmContactNumber,
          firmEmail: auditor.firmEmail,
          picName: auditor.picName,
          appointmentOnDateStr: auditor.appointmentOnDate
            ? new Date(auditor.appointmentOnDate)
            : auditor.appointmentOnDate,
          resignationOnDateStr: auditor.resignationOnDate
            ? new Date(auditor.resignationOnDate)
            : auditor.resignationOnDate,
        };

        const auditorsFormGroup: FormGroup = this.auditors.controls[
          index
        ] as FormGroup;
        auditorsFormGroup.patchValue(data);
      });
    } else {
      this.auditors.clear();
    }
  }

  saveClientPressed(): void {
    if (this.generalCompanyDetailsForm.invalid) {
      this.generalCompanyDetailsForm.markAllAsTouched();
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.clientIsSaving = true;
      this.generalCompanyDetailsForm.disable();
      this.snackBar.open('Saving client', '', {
        duration: undefined,
      });

      const companyNumberNew =
        this.generalCompanyDetailsForm.get('companyNumberNew')?.value;
      const companyNumberOld =
        this.generalCompanyDetailsForm.get('companyNumberOld')?.value;
      const auditors = this.auditors.value.map((data: any) => {
        // Transform dates to string
        data.appointmentOnDateStr = this.transformDateToString(
          data.appointmentOnDateStr
        );
        data.resignationOnDateStr = this.transformDateToString(
          data.resignationOnDateStr
        );
        return data;
      });

      const params = {
        companyNumberNew: companyNumberNew !== '' ? companyNumberNew : null,
        companyNumberOld: companyNumberOld !== '' ? companyNumberOld : null,
        name: this.generalCompanyDetailsForm.get('companyName')?.value,
        group: this.generalCompanyDetailsForm.get('group')?.value,
        fileRef: this.generalCompanyDetailsForm.get('fileRef')?.value,
        corresAddress: this.generalCompanyDetailsForm.get(
          'correspondentAddress'
        )?.value,
        yearEnd: this.getYearEndField(),
        remarksAudit: this.generalCompanyDetailsForm.get('remarks')?.value,
        oftenContactPersons: this.contactsForm.get('oftenContactPersons')
          ?.value,
        departmentRef: this.clientType,
        clientTaxTypeRef:
          this.generalCompanyDetailsForm.get('companyType')?.value,
        taxAgents: null,
        auditors,
        branchAddresses: null,
        secretaries: null,
        banks: null,
        managers: null,
      };

      this.clientsService
        .saveGeneralCompanyDetails(params, this.client.value.id)
        .subscribe({
          next: (savedClient: Client) => {
            this.generalCompanyDetailsForm.reset();
            this.clientIsSaving = false;
            this.router.navigateByUrl(
              `/clients/${this.clientType}/details/${this.client.value.id}`
            );
            this.snackBar.open('Client saved', '', {
              duration: 3000,
            });
          },
          error: (error) => {
            console.log(error);
            this.generalCompanyDetailsForm.enable();
            this.clientIsSaving = false;
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }

  getYearEndField(): string | null {
    if (
      this.generalCompanyDetailsForm.get('yearEndDay')?.value &&
      this.generalCompanyDetailsForm.get('yearEndMonth')?.value
    ) {
      return (
        this.generalCompanyDetailsForm.get('yearEndDay')?.value +
        '/' +
        this.generalCompanyDetailsForm.get('yearEndMonth')?.value
      );
    }
    return null;
  }

  transformDateToString(date: Date): string | null {
    return this.datepipe.transform(date, 'yyy-MM-dd 00:00:00');
  }
}
