import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-out-dialog',
  templateUrl: './log-out-dialog.component.html',
  styleUrls: ['./log-out-dialog.component.scss']
})
export class LogOutDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
