import { DeleteFileDialogComponent } from './delete-file-dialog/delete-file-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RenameFileDialogComponent } from './rename-file-dialog/rename-file-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { FormControl, FormGroup } from '@angular/forms';
import { YearFile } from 'src/app/shared/models/year-file/year-file.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-organizer',
  templateUrl: './file-organizer.component.html',
  styleUrls: ['./file-organizer.component.scss'],
})
export class FileOrganizerComponent implements OnInit {
  @Input() filesForm!: FormGroup;
  @Input() pendingFiles!: PendingFile[];
  @Input() isCreateUpdate = false;
  @Input() clientType!: string;
  @Input() yearID!: number;

  // Breadcrumbs
  crumbs: string[] = ['DOCUMENTS'];
  currentFolder = 'DOCUMENTS';

  // Folder  List
  folders: string[] = [];
  backupMainFolders: string[] = [];

  // Files
  files: YearFile[] = [];
  showFileActions = false;
  selectedFile!: YearFile | null;

  // Variables
  $inFolder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedItems: Array<boolean> = [];

  // Responsive
  isMobile = true;

  // Loading
  isLoading = false;

  // FormGroup
  fileNameFormGroup: FormGroup = new FormGroup({
    fileName: new FormControl(''),
  });

  constructor(
    private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private customBreakpointService: CustomBreakpointService,
    private dialog: MatDialog
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.getFolders();
    this.getFilesInAFolder();
  }

  navigateToFolder(folderName: string): void {
    // Can be configured to get folders in a folder with folder's ID
    this.currentFolder = folderName;
    this.crumbs.push(folderName);
    this.$inFolder.next(true);
    this.backupMainFolders = this.folders;
    this.folders = [];
    this.getFilesInAFolder();
  }

  reverseToCrumb(index: number): void {
    this.crumbs.length = index + 1;
    this.$inFolder.next(true);
    this.getFilesInAFolder();
  }

  resetCrumbs(): void {
    this.crumbs = ['DOCUMENTS'];
    this.currentFolder = this.crumbs[0];
    this.folders = this.backupMainFolders;
    this.$inFolder.next(false);
    this.files = [];
    this.getFilesInAFolder();
  }

  getFolders(): void {
    this.isLoading = true;
    this.clientsService.getFolders(this.clientType).subscribe({
      next: (result: any) => {
        this.folders = result;
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.isLoading = false;
      },
    });
  }

  getFilesInAFolder(): void {
    if (this.yearID && this.currentFolder !== this.crumbs[0]) {
      this.isLoading = true;
      this.clientsService
        .getFiles(this.currentFolder, this.yearID, this.clientType)
        .subscribe({
          next: (files: Array<YearFile>) => {
            this.files = files;
            this.isLoading = false;
          },
          error: (error) => {
            console.log(error);
            this.snackBar.open(error, '', { duration: 3000 });
            this.isLoading = false;
          },
        });
    }
  }

  removePendingFile(index: number): void {
    this.pendingFiles.splice(index, 1);

    // Check if filesForm is available and,
    // Check if pending files still have files or not
    if (this.filesForm && !this.pendingFiles.length) {
      this.filesForm.reset();
    }
  }

  uploadFilesPressed(event: any): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;

    for (let index = 0; index < files.length; index++) {
      const pendingFile = {
        folderName: this.currentFolder,
        file: files[index],
      } as PendingFile;
      this.pendingFiles.push(pendingFile);
    }
  }

  fileSelected(yearFile: YearFile, index: number): void {
    this.selectedItems[index] = true;
    this.showFileActions = true;
    this.selectedFile = yearFile;
  }

  fileUnselected(index: number): void {
    this.selectedItems[index] = false;
    this.showFileActions = false;
    this.selectedFile = null;
  }

  downloadFilePressed(): void {
    window.open(
      `${environment.baseUrl}/api/core/clients/downloadFile/${this.selectedFile?.id}`,
      '_blank'
    );
  }

  showRenameDialog(): void {
    this.fileNameFormGroup.patchValue({
      fileName: this.selectedFile?.name,
    });

    const dialogRef = this.dialog.open(RenameFileDialogComponent, {
      data: this.fileNameFormGroup,
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.renameFile();
        }
      },
    });
  }

  renameFile(): void {
    this.isLoading = true;
    this.snackBar.open('Renaming file', '', { duration: 3000 });
    const data = {
      name: this.fileNameFormGroup.get('fileName')?.value,
    };
    this.clientsService
      .renameFile(data, Number(this.selectedFile?.id))
      .subscribe({
        next: (updatedFile: YearFile) => {
          this.getFilesInAFolder();
          this.snackBar.open('File renamed', '', { duration: 3000 });
          this.isLoading = false;
          this.fileNameFormGroup.reset();
          this.selectedItems.forEach((element: boolean, index: number) => {
            this.fileUnselected(index);
          });
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isLoading = false;
        },
      });
  }

  showConfirmDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteFileDialogComponent, {
      data: { selectedFile: this.selectedFile },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteFile();
        }
      },
    });
  }

  deleteFile(): void {
    this.isLoading = true;
    this.snackBar.open('Deleting file', '', { duration: 3000 });
    this.clientsService
      .deleteFile(this.yearID, Number(this.selectedFile?.id))
      .subscribe({
        next: (_) => {
          this.getFilesInAFolder();
          this.snackBar.open('File deleted', '', { duration: 3000 });
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isLoading = false;
        },
      });
  }
}
