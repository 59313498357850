import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { Device } from 'src/app/shared/enums/device.enum';
import { BankItem } from 'src/app/shared/models/client/bank-item/bank-item.model';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { Client } from 'src/app/shared/models/client/client.model';
import { Department } from 'src/app/shared/models/department/department.model';
import { MsicCode } from 'src/app/shared/models/msic-code/msic-code.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { Shareholder } from 'src/app/shared/models/shareholder/shareholder.model';
import { UserFormConstants } from './../../../../shared/constants/user-form.constants';
import { Director } from './../../../../shared/models/director/director.model';

@Component({
  selector: 'app-edit-year-details',
  templateUrl: './edit-year-details.component.html',
  styleUrls: ['./edit-year-details.component.scss'],
})
export class EditYearDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper!: MatStepper;
  // Client Details
  client!: Client;
  clientYearDetails!: ClientYearDetails;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;

  // Steps
  isLinear = true;

  // Loading
  clientIsLoading = false;
  savingYearDetails = false;

  // Responsive
  isMobile = true;

  // Variables
  selectableYears = [];
  clientType!: string;
  pendingFiles: PendingFile[] = [];
  yearID!: number;
  selectedMsicCodes: Array<{ code: string }> = [];
  clientIsInSecretarial = false;

  // Form
  yearForm: FormGroup = new FormGroup({});
  yearDetailsForm: FormGroup = new FormGroup({});
  banksForm: FormGroup = new FormGroup({});
  directorsForm: FormGroup = new FormGroup({});
  membersForm: FormGroup = new FormGroup({});
  filesForm: FormGroup = new FormGroup({});

  // Subscription
  yearSubscription!: Subscription;

  // Value
  isFreelancer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private customBreakpointService: CustomBreakpointService,
    private datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private jwtService: JwtService,
    private router: Router,
    private userFormConstants: UserFormConstants,
    private clientTypeService: ClientTypeService
  ) {
    // Get client type
    this.clientTypeService.getClientType();
    this.clientType = this.clientTypeService.clientTypeStr;
    this.subscribeToClientType();

    // Get client ID
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.yearID = params.year;
        this.getGeneralClientDetails(params.id);
        this.getClientYearDetails(params.year);
      },
    });

    // Check device is mobile
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            this.setupSecretarialYearDetailsFormGroup();
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            this.setupTaxYearDetailsFormGroup();
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            this.setupAuditYearDetailsFormGroup();
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            this.setupSharedServicesYearDetailsFormGroup();
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  subscribeToFreelancer(): void {
    this.isFreelancer.subscribe({
      next: (isFreelancer: boolean) => {
        switch (isFreelancer) {
          case true:
            if (this.isTax) {
              this.yearDetailsForm.controls.natureOfBusiness.setValidators(
                null
              );
              this.yearDetailsForm.controls.msicCode.setValidators(null);
              this.yearDetailsForm.addControl(
                'cp500',
                new FormControl('', Validators.min(0))
              );
            } else if (this.isSharedServices) {
              this.yearDetailsForm.addControl(
                'cp500',
                new FormControl('', Validators.min(0))
              );
              this.yearDetailsForm.removeControl('lastTaxReturnFiledYear');
              this.yearDetailsForm.removeControl('lastAuditedPeriodDate');
              this.yearDetailsForm.removeControl('natureOfBusiness');
              this.yearDetailsForm.removeControl('msicCode');
            }
            this.directorsForm.removeControl('directors');
            this.membersForm.removeControl('members');
            break;

          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.setupYearFormGroup();
    if (this.isAudit) {
      this.setupBanksForm();
    }
    if (!this.isFreelancer.value) {
      this.setupDirectorsForm();
      this.setupMembersForm();
    }
    this.setupFilesForm();
  }

  ngOnDestroy(): void {
    this.yearSubscription.unsubscribe();
  }

  setupYearFormGroup(): void {
    this.yearForm = this.formBuilder.group({
      year: new FormControl('', Validators.required),
    });
    this.subscribeToYear();
  }

  setupSharedServicesYearDetailsFormGroup(): void {
    this.yearDetailsForm = this.formBuilder.group({
      natureOfBusiness: new FormControl('', Validators.required),
      msicCode: new FormControl(null, Validators.required),
      taxAgentName: new FormControl(''),
      lastAuditedPeriodDate: new FormControl(null),
      lastTaxReturnFiledYear: new FormControl('', [
        Validators.min(1),
        Validators.maxLength(4),
      ]),
      teamPIC: new FormControl(''),
      serviceType: new FormControl(''),
    });
  }

  setupAuditYearDetailsFormGroup(): void {
    this.yearDetailsForm = this.formBuilder.group({
      secretaryName: new FormControl('', Validators.required),
      natureOfBusiness: new FormControl('', Validators.required),
      msicCode: new FormControl(null, Validators.required),
      lastAuditedPeriodDate: new FormControl(null),
      auditedAccountSubmissionDateSsm: new FormControl(null),
      teamPIC: new FormControl(''),
      circulationDate: new FormControl(null),
      stocktake: new FormControl(null),
      mgmtAccountReceivedDate: new FormControl(null),
      secretaryFirmName: new FormControl(''),
      secretaryFirmAddress: new FormControl(''),
      teamLead: new FormControl(''),
    });
  }

  setupTaxYearDetailsFormGroup(): void {
    this.yearDetailsForm = this.formBuilder.group({
      natureOfBusiness: new FormControl('', Validators.required),
      msicCode: new FormControl(null, Validators.required),
      cp204: new FormControl('', Validators.min(0)),
      cp204A6th: new FormControl('', Validators.min(0)),
      cp204A9th: new FormControl('', Validators.min(0)),
      lastTaxReturnFiledYear: new FormControl('', [
        Validators.min(1),
        Validators.maxLength(4),
      ]),
      taxReturnSubmittedDateRef: new FormControl(''),
      teamPIC: new FormControl(''),
    });
  }

  setupSecretarialYearDetailsFormGroup(): void {
    this.yearDetailsForm = this.formBuilder.group({
      secretaryName: new FormControl('', Validators.required),
      natureOfBusiness: new FormControl('', Validators.required),
      msicCode: new FormControl(null, Validators.required),
      commonSeal: new FormControl(null),
      registerOffice: new FormControl(null),
      nameTag: new FormControl(null),
      taxAgentName: new FormControl(''),
      takeOverDate: new FormControl(null),
      lastAnnualReturnDate: new FormControl(null),
      nextAnnualReturnDueDate: new FormControl(null),
      lastAuditedPeriodDate: new FormControl(null),
      auditedAccountSubmissionDateSsm: new FormControl(null),
      circulationDate: new FormControl(null),
    });
  }

  setupBanksForm(): void {
    this.banksForm = this.formBuilder.group({
      banks: this.formBuilder.array([]),
    });
  }

  setupDirectorsForm(): void {
    this.directorsForm = this.formBuilder.group({
      directors: this.formBuilder.array([]),
    });
  }

  setupMembersForm(): void {
    this.membersForm = this.formBuilder.group({
      members: this.formBuilder.array([]),
    });
  }

  setupFilesForm(): void {
    this.filesForm = this.formBuilder.group({
      files: new FormControl(''),
    });
  }

  prefillYearForm(): void {
    this.yearForm.patchValue({
      year: this.clientYearDetails.year,
    });
  }

  prefillYearDetailsForm(): void {
    if (this.clientYearDetails.msicCodes?.length) {
      const msicCodes: Array<{ code: string }> = [];
      this.clientYearDetails.msicCodes.forEach((msicCode: MsicCode) => {
        msicCodes.push({ code: msicCode.code });
      });

      this.selectedMsicCodes = msicCodes;

      this.yearDetailsForm.controls.msicCode.setErrors(null);
    }

    this.yearDetailsForm.patchValue({
      natureOfBusiness: this.clientYearDetails.natureOfBusiness,
      cp500: this.clientYearDetails.cp500,
      cp204: this.clientYearDetails.cp204,
      cp204A6th: this.clientYearDetails.cp204A6th,
      cp204A9th: this.clientYearDetails.cp204A9th,
      lastTaxReturnFiledYear: this.clientYearDetails.lastTaxReturnFiledYear,
      taxReturnSubmittedDateRef: this.clientYearDetails.taxReturnSubmittedDate,
      teamPIC: this.clientYearDetails.teamPIC,
      teamLead: this.clientYearDetails.teamLead,
      secretaryFirmName: this.clientYearDetails.secretaryFirmName,
      secretaryFirmAddress: this.clientYearDetails.secretaryFirmAddress,
      secretaryName: this.clientYearDetails.secretaryName,
      commonSeal: this.clientYearDetails.commonSeal,
      registerOffice: this.clientYearDetails.registerOffice,
      nameTag: this.clientYearDetails.nameTag,
      taxAgentName: this.clientYearDetails.taxAgentName,
      takeOverDate: this.clientYearDetails.takeOverDate
        ? new Date(this.clientYearDetails.takeOverDate)
        : null,
      lastAnnualReturnDate: this.clientYearDetails.lastAnnualReturnDate
        ? new Date(this.clientYearDetails.lastAnnualReturnDate)
        : null,
      nextAnnualReturnDueDate: this.clientYearDetails.nextAnnualReturnDueDate
        ? new Date(this.clientYearDetails.nextAnnualReturnDueDate)
        : null,
      lastAuditedPeriodDate: this.clientYearDetails.lastAuditedPeriodDate
        ? new Date(this.clientYearDetails.lastAuditedPeriodDate)
        : null,
      auditedAccountSubmissionDateSsm: this.clientYearDetails
        .auditedAccountSubmissionDateSsm
        ? new Date(this.clientYearDetails.auditedAccountSubmissionDateSsm)
        : null,
      circulationDate: this.clientYearDetails.circulationDate
        ? new Date(this.clientYearDetails.circulationDate)
        : null,
      mgmtAccountReceivedDate: this.clientYearDetails.mgmtAccountReceivedDate
        ? new Date(this.clientYearDetails.mgmtAccountReceivedDate)
        : null,
      stocktake: this.clientYearDetails.stocktake,
      serviceType: this.clientYearDetails.serviceType?.type,
    });
  }

  get banks(): FormArray {
    return this.banksForm.get('banks') as FormArray;
  }

  prefillBanksForm(): void {
    if (this.clientYearDetails.banks) {
      this.banks.clear();
      this.clientYearDetails.banks.forEach(
        (bankItem: BankItem, index: number) => {
          this.banks.push(this.createBankItem());
          const data = {
            bankNameStr: bankItem.bank.name,
            branch: bankItem.branch,
            branchAddress: bankItem.branchAddress,
            bankAccountTypeStr: bankItem.accountType.type.toLowerCase(),
            accountNumber: bankItem.accountNumber,
            accountUsage: bankItem.accountUsage,
          };

          const bankFormGroup: FormGroup = this.banks.controls[
            index
          ] as FormGroup;
          bankFormGroup.patchValue(data);
        }
      );
    } else {
      this.banks.clear();
    }
  }

  createBankItem(): FormGroup {
    return this.formBuilder.group({
      bankNameStr: new FormControl('', Validators.required),
      branch: new FormControl('', Validators.required),
      branchAddress: new FormControl('', Validators.required),
      bankAccountTypeStr: new FormControl('', Validators.required),
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
      ]),
      accountUsage: new FormControl('', Validators.required),
    });
  }

  get directors(): FormArray {
    return this.directorsForm.get('directors') as FormArray;
  }

  prefillDirectorsForm(): void {
    this.directors.clear();
    this.clientYearDetails.directors.forEach(
      (director: Director, index: number) => {
        this.directors.push(this.createDirectorItem());
        const data = {
          name: director.name,
          identificationTypeStr: director.type.type,
          identificationNumber: director.identificationNumber,
          email: director.email.toLowerCase(),
          address: director.address,
          dateOfPassportExpiryRef: director.dateOfPassportExpiry
            ? new Date(director.dateOfPassportExpiry)
            : director.dateOfPassportExpiry,
          nationality: director.nationality,
          race: director.race,
          male: director.male,
          dateOfBirthRef: director.dateOfBirth
            ? new Date(director.dateOfBirth)
            : director.dateOfBirth,
          residentialAddress: director.residentialAddress,
          phoneNumber: director.phoneNumber,
          dateOfAppointmentRef: director.dateOfAppointment
            ? new Date(director.dateOfAppointment)
            : director.dateOfAppointment,
          dateOfResignationRef: director.dateOfResignation
            ? new Date(director.dateOfResignation)
            : director.dateOfResignation,
          alternativeDirector: director.alternativeDirector,
          privateEmail: director.privateEmail?.toLowerCase(),
        };

        const directorFormGroup: FormGroup = this.directors.controls[
          index
        ] as FormGroup;
        directorFormGroup.patchValue(data);
      }
    );
  }

  createDirectorItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      male: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      residentialAddress: new FormControl(''),
      phoneNumber: new FormControl(''),
      dateOfAppointmentRef: new FormControl(null),
      dateOfResignationRef: new FormControl(null),
      alternativeDirector: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
    });
  }

  get members(): FormArray {
    return this.membersForm.get('members') as FormArray;
  }

  prefillMembersForm(): void {
    this.members.clear();
    this.clientYearDetails.shareholders.forEach(
      (member: Shareholder, index: number) => {
        this.members.push(this.createMemberItem());
        const data = {
          name: member.name,
          identificationTypeStr: member.type.type,
          identificationNumber: member.identificationNumber,
          email: member.email.toLowerCase(),
          address: member.address,
          numberOfShares: member.numberOfShares,
          dateOfPassportExpiryRef: member.dateOfPassportExpiryRef
            ? new Date(member.dateOfPassportExpiryRef)
            : member.dateOfPassportExpiryRef,
          nationality: member.nationality,
          race: member.race,
          isMale: member.isMale,
          dateOfBirthRef: member.dateOfBirthRef
            ? new Date(member.dateOfBirthRef)
            : member.dateOfBirthRef,
          phoneNumber: member.phoneNumber,
          privateEmail: member.privateEmail?.toLowerCase(),
          eventTypeStr: member.eventTypeStr,
          upToDateNumberOfShares: member.upToDateNumberOfShares,
          isCash: member.isCash,
          placeOfIncorporation: member.placeOfIncorporation,
        };

        const memberFormGroup: FormGroup = this.members.controls[
          index
        ] as FormGroup;
        memberFormGroup.patchValue(data);
      }
    );
  }

  createMemberItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      numberOfShares: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      isMale: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      phoneNumber: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
      eventTypeStr: new FormControl(''),
      upToDateNumberOfShares: new FormControl('', [
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      isCash: new FormControl(null),
      placeOfIncorporation: new FormControl(''),
    });
  }

  getGeneralClientDetails(clientID: number): void {
    this.clientIsLoading = true;
    this.clientsService.getClientDetails(clientID).subscribe({
      next: (client: Client) => {
        this.client = client;
        this.subscribeToFreelancer();
        if (client?.type?.type && client.identityNumber) {
          this.isFreelancer.next(true);
        }
        this.clientIsLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.clientIsLoading = false;
      },
    });
  }

  nextFormPressed(): void {
    this.stepper.next();
  }

  previousFormPressed(): void {
    this.stepper.previous();
  }

  subscribeToYear(): void {
    this.yearSubscription = this.yearForm.controls.year.valueChanges.subscribe({
      next: (year: string) => {
        if (year) {
          this.isLinear = false;
        } else {
          this.isLinear = true;
        }
      },
    });
  }

  uploadFiles(clientYearDetails: ClientYearDetails): void {
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingFiles.length; index++) {
      const file: PendingFile = this.pendingFiles[index];
      formData.append(file.folderName, file.file);
    }

    this.clientsService
      .uploadClientYearFiles(formData, clientYearDetails.id, this.clientType)
      .subscribe({
        next: (_: ClientYearDetails) => {
          this.yearForm.reset();
          this.yearDetailsForm.reset();
          this.directorsForm.reset();
          this.membersForm.reset();
          this.filesForm.reset();
          this.savingYearDetails = false;
          this.snackBar.open(`${clientYearDetails.year} saved`, '', {
            duration: 3000,
          });
          this.router.navigateByUrl(
            `/clients/${this.clientType}/details/${this.client.id}/year/${this.clientYearDetails.id}`
          );
          this.jwtService.destroyContentType();
        },
        error: (error) => {
          console.log(error);
          this.yearForm.enable();
          this.yearDetailsForm.enable();
          this.directorsForm.enable();
          this.membersForm.enable();
          this.filesForm.enable();
          this.savingYearDetails = false;
          this.snackBar.open(error, '', {
            duration: 3000,
          });
          this.jwtService.destroyContentType();
        },
      });
  }

  getClientYearDetails(yearID: number): void {
    this.clientIsLoading = true;
    this.clientsService.getClientDetailsForSpecificYear(yearID).subscribe({
      next: (clientYearDetails: ClientYearDetails) => {
        this.clientYearDetails = clientYearDetails;
        this.clientIsLoading = false;
        this.prefillYearForm();
        this.prefillYearDetailsForm();
        if (this.isAudit) {
          this.prefillBanksForm();
        }
        if (!this.isFreelancer.value) {
          this.prefillDirectorsForm();
          this.prefillMembersForm();
        }
        // Business Logic
        // This is to disable Directors and Members form
        // if client exist in Secretarial
        if (
          this.client.departments.some(
            (department: Department) =>
              department.department?.toLowerCase() === 'secretarial'
          )
        ) {
          if (!this.isSecretarial) {
            this.clientIsInSecretarial = true;
            this.directorsForm.disable();
            this.membersForm.disable();
          }
        }
      },
      error: (error) => {
        console.log(error);
        this.clientIsLoading = false;
      },
    });
  }

  savePressed(): void {
    let data;

    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            data = this.createSecretarialObject();
            break;
          case ClientType.tax:
            data = this.createTaxObject();
            break;
          case ClientType.audit:
            data = this.createAuditObject();
            break;
          case ClientType.shared_services:
            data = this.createSharedServicesObject();
            break;
          default:
            break;
        }
      },
    });

    console.log(data);

    if (
      this.yearForm.invalid ||
      this.yearDetailsForm.invalid ||
      this.directorsForm.invalid ||
      this.membersForm.invalid
    ) {
      this.snackBar.open('Please fill in the required field', '', {
        duration: 3000,
      });
    } else {
      this.savingYearDetails = true;
      this.yearForm.disable();
      this.yearDetailsForm.disable();
      this.directorsForm.disable();
      this.membersForm.disable();
      this.filesForm.disable();
      this.snackBar.open(`Saving year ${this.clientYearDetails.year}`, '', {
        duration: undefined,
      });

      this.clientsService
        .saveClientYearDetails(data, this.clientYearDetails.id)
        .subscribe({
          next: (clientYearDetails: ClientYearDetails) => {
            if (this.pendingFiles.length > 0) {
              this.uploadFiles(clientYearDetails);
            } else {
              this.yearForm.reset();
              this.yearDetailsForm.reset();
              this.directorsForm.reset();
              this.membersForm.reset();
              this.filesForm.reset();
              this.savingYearDetails = false;
              this.snackBar.open(`${clientYearDetails.year} saved`, '', {
                duration: 3000,
              });
              this.router.navigateByUrl(
                `/clients/${this.clientType}/details/${this.client.id}/year/${clientYearDetails.id}`
              );
            }
          },
          error: (error) => {
            console.log(error);
            this.yearForm.enable();
            this.yearDetailsForm.enable();
            this.directorsForm.enable();
            this.membersForm.enable();
            this.filesForm.enable();
            this.savingYearDetails = false;
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }

  createSecretarialObject(): object {
    const directors = this.directors.value.map((data: any) => {
      // Transform dates to string
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfAppointmentRef
      );
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      data.dateOfResignationRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      return data;
    });
    const shareholders = this.members.value.map((data: any) => {
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);

      return data;
    });

    return {
      year: this.yearForm.get('year')?.value,
      initiateDeptRef: this.clientType,
      commonSeal: this.yearDetailsForm.get('commonSeal')?.value,
      registerOffice: this.yearDetailsForm.get('registerOffice')?.value,
      nameTag: this.yearDetailsForm.get('nameTag')?.value,
      clientStatusRef: 'active',
      lastAuditedPeriodDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('lastAuditedPeriodDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      lastAnnualReturnDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('lastAnnualReturnDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      nextAnnualReturnDueDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('nextAnnualReturnDueDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      natureOfBusiness: this.yearDetailsForm.get('natureOfBusiness')?.value,
      msicCodes: this.selectedMsicCodes,
      secretaryName: this.yearDetailsForm.get('secretaryName')?.value,
      taxAgentName: this.yearDetailsForm.get('taxAgentName')?.value,
      takeOverDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('takeOverDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      circulationDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('circulationDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      auditedAccountSubmissionDateSsmRef: this.datepipe.transform(
        this.yearDetailsForm.get('auditedAccountSubmissionDateSsm')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      directors: directors,
      shareholders: shareholders,
      toBeDeletedFiles: [],
    };
  }

  createTaxObject(): object {
    const directors = this.directors.value.map((data: any) => {
      // Transform dates to string
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfAppointmentRef
      );
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      data.dateOfResignationRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      return data;
    });
    const shareholders = this.members.value.map((data: any) => {
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);

      return data;
    });

    return {
      year: this.yearForm.get('year')?.value,
      initiateDeptRef: this.clientType,
      natureOfBusiness: this.yearDetailsForm.get('natureOfBusiness')?.value,
      msicCodes: this.selectedMsicCodes,
      cp500: this.yearDetailsForm.get('cp500')?.value,
      cp204: this.yearDetailsForm.get('cp204')?.value,
      cp204A6th: this.yearDetailsForm.get('cp204A6th')?.value,
      cp204A9th: this.yearDetailsForm.get('cp204A9th')?.value,
      lastTaxReturnFiledYear: this.yearDetailsForm.get('lastTaxReturnFiledYear')
        ?.value,
      taxReturnSubmittedDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('taxReturnSubmittedDateRef')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      teamPIC: this.yearDetailsForm.get('teamPIC')?.value,
      directors: directors,
      shareholders: shareholders,
      toBeDeletedFiles: [],
    };
  }

  createAuditObject(): object {
    const directors = this.directors.value.map((data: any) => {
      // Transform dates to string
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfAppointmentRef
      );
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      data.dateOfResignationRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      return data;
    });
    const shareholders = this.members.value.map((data: any) => {
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);

      return data;
    });

    return {
      year: this.yearForm.get('year')?.value,
      initiateDeptRef: this.clientType,
      clientStatusRef: 'active',
      lastAuditedPeriodDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('lastAuditedPeriodDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      natureOfBusiness: this.yearDetailsForm.get('natureOfBusiness')?.value,
      msicCodes: this.selectedMsicCodes,
      secretaryName: this.yearDetailsForm.get('secretaryName')?.value,
      circulationDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('circulationDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      auditedAccountSubmissionDateSsmRef: this.datepipe.transform(
        this.yearDetailsForm.get('auditedAccountSubmissionDateSsm')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      teamPIC: this.yearDetailsForm.get('teamPIC')?.value,
      stocktake: this.yearDetailsForm.get('stocktake')?.value,
      mgmtAccountReceivedDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('mgmtAccountReceivedDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      secretaryFirmName: this.yearDetailsForm.get('secretaryFirmName')?.value,
      secretaryFirmAddress: this.yearDetailsForm.get('secretaryFirmAddress')
        ?.value,
      teamLead: this.yearDetailsForm.get('teamLead')?.value,
      banks: this.banksForm.get('banks')?.value,
      directors: directors,
      shareholders: shareholders,
    };
  }

  createSharedServicesObject(): object {
    const directors = this.directors.value.map((data: any) => {
      // Transform dates to string
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfAppointmentRef
      );
      data.dateOfAppointmentRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      data.dateOfResignationRef = this.transformDateToString(
        data.dateOfResignationRef
      );
      return data;
    });
    const shareholders = this.members.value.map((data: any) => {
      data.dateOfPassportExpiryRef = this.transformDateToString(
        data.dateOfPassportExpiryRef
      );
      data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);

      return data;
    });

    return {
      year: this.yearForm.get('year')?.value,
      initiateDeptRef: this.clientType,
      clientStatusRef: 'active',
      natureOfBusiness: this.yearDetailsForm.get('natureOfBusiness')?.value,
      msicCodes: this.selectedMsicCodes,
      cp500: this.yearDetailsForm.get('cp500')?.value,
      taxAgentName: this.yearDetailsForm.get('taxAgentName')?.value,
      lastAuditedPeriodDateRef: this.datepipe.transform(
        this.yearDetailsForm.get('lastAuditedPeriodDate')?.value,
        'yyy-MM-dd 00:00:00'
      ),
      lastTaxReturnFiledYear: this.yearDetailsForm.get('lastTaxReturnFiledYear')
        ?.value,
      teamPIC: this.yearDetailsForm.get('teamPIC')?.value,
      serviceTypeRef: this.yearDetailsForm.get('serviceType')?.value,
      directors: directors,
      shareholders: shareholders,
    };
  }

  transformDateToString(date: Date): string | null {
    return this.datepipe.transform(date, 'yyy-MM-dd 00:00:00');
  }
}
