import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { Device } from 'src/app/shared/enums/device.enum';

@Component({
  selector: 'app-auditors-form',
  templateUrl: './auditors-form.component.html',
  styleUrls: ['./auditors-form.component.scss'],
})
export class AuditorsFormComponent implements OnInit {
  // Form
  @Input() auditorsForm!: FormGroup;
  @Input() disabled!: boolean;

  // Responsive
  isMobile = true;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;
  clientType: string;

  constructor(
    private formBuilder: FormBuilder,
    private customBreakpointService: CustomBreakpointService,
    private clientTypeService: ClientTypeService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.clientTypeService.getClientType();
    this.subscribeToClientType();
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;
  }

  ngOnInit(): void {
    this.createAuditorItem();
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  get auditors(): FormArray {
    return this.auditorsForm.get('auditors') as FormArray;
  }

  createAuditorItem(): FormGroup {
    return this.formBuilder.group({
      firmName: new FormControl('', Validators.required),
      firmNo: new FormControl('', Validators.required),
      firmAddress: new FormControl(''),
      firmContactNumber: new FormControl('', Validators.required),
      firmEmail: new FormControl('', Validators.email),
      picName: new FormControl(''),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  addAuditor(): void {
    this.auditors.push(this.createAuditorItem());
  }

  removeAuditor(index: number): void {
    this.auditors.removeAt(index);
  }
}
