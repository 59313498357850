import { EnquiriesComponent } from './../enquiries.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EnquiryFormConstants } from 'src/app/shared/constants/enquiry-form.constants';

@Component({
  selector: 'app-enquiries-filter',
  templateUrl: './enquiries-filter.component.html',
  styleUrls: ['./enquiries-filter.component.scss'],
})
export class EnquiriesFilterComponent implements OnInit {
  statuses: Array<{ value: number; valueName: string }>;
  filterForm = new FormGroup({
    filterEnquiry: new FormControl(''),
    status: new FormControl(null),
    receivedStart: new FormControl(null),
    receivedEnd: new FormControl(null),
  });
  pageSize = 10;
  currentPage = 0;

  constructor(
    private enquiriesComponent: EnquiriesComponent,
    private enquiryFormConstants: EnquiryFormConstants
  ) {
    this.statuses = this.enquiryFormConstants.STATUSES;
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.enquiriesComponent.pageSize;
    this.currentPage = this.enquiriesComponent.currentPage;

    const filterEnquiry = this.filterForm.get('filterEnquiry')?.value;
    const status = this.filterForm.get('status')?.value;
    const receivedStart = this.filterForm.get('receivedStart')?.value;
    const receivedEnd = this.filterForm.get('receivedEnd')?.value;
    this.enquiriesComponent.getEnquiries(
      filterEnquiry,
      this.pageSize,
      this.currentPage,
      status,
      receivedStart,
      receivedEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.enquiriesComponent.getEnquiries();
  }
}
