<form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
  <mat-form-field appearance="outline" class="width-100">
    <button matPrefix mat-icon-button type="button" (click)="applyFilter()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-label>Search services</mat-label>
    <input matInput type="text" formControlName="filterService" />
    <button
      mat-icon-button
      matSuffix
      type="button"
      [matMenuTriggerFor]="filters"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #filters="matMenu" class="padding-16">
      <!-- Received Date -->
      <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
        <mat-label>Received Date (Range)</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="receivedStart"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="receivedEnd"
            placeholder="End date"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button (click)="resetFilters()">CLEAR</button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="applyFilter()"
        >
          FILTER
        </button>
      </div>
    </mat-menu>
  </mat-form-field>
</form>
