import { AuthenticationService } from './../../../core/services/authentication/authentication.service';
import { UsersService } from './../../../core/services/users/users.service';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PasswordValidator } from 'src/app/shared/validators/password.validator';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  changePasswordForm: FormGroup;
  hidePassword = true;
  hideConfirmPassword = true;
  isChangingPassword = false;
  user!: User;
  passwordChanged = false;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private usersService: UsersService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
    this.changePasswordForm = this.formBuilder.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(8),
          PasswordValidator.strong,
        ]),
        confirmPassword: new FormControl(''),
      },
      {
        validator: PasswordValidator.passwordMatchValidator,
      } as AbstractControlOptions
    );
  }

  ngOnInit(): void {}

  changePasswordPressed(): void {
    if (this.changePasswordForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const params: { [x: string]: any } = {
        password: this.changePasswordForm.get('password')?.value,
      };

      this.isChangingPassword = true;
      this.changePasswordForm.disable();
      this.snackBar.open('Changing password', '', {
        duration: undefined,
      });
      this.usersService
        .updateUserDetails(params as Map<any, any>, this.user.id as number)
        .subscribe({
          next: (result) => {
            this.changePasswordForm.reset();
            this.snackBar.dismiss();
            this.isChangingPassword = false;
            this.passwordChanged = true;
            this.snackBar.open('Password changed', '', {
              duration: 3000,
            });
          },
          error: (error) => {
            console.log(error);
            this.isChangingPassword = false;
            this.changePasswordForm.enable();
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }
}
