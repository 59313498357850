import { CustomerEntityFilesComponent } from './modules/customer-entities/customer-entity-files/customer-entity-files.component';
import { CustomerEntityDetailsComponent } from './modules/customer-entities/customer-entity-details/customer-entity-details.component';
import { CustomerEntitiesComponent } from './modules/customer-entities/customer-entities.component';
import { ArticleDetailsComponent } from './modules/articles/article-details/article-details.component';
import { DraftArticlesComponent } from './modules/articles/draft-articles/draft-articles.component';
import { CreateArticleSuccessComponent } from './modules/articles/new-article/create-article-success/create-article-success.component';
import { NewTaxClientComponent } from './modules/clients/tax-clients/new-tax-client/new-tax-client.component';
import { TaxClientsComponent } from './modules/clients/tax-clients/tax-clients.component';
import { EditYearDetailsComponent } from './modules/clients/client-details/edit-year-details/edit-year-details.component';
import { CreateYearDetailsSuccessComponent } from './modules/clients/client-details/new-year-details/create-year-details-success/create-year-details-success.component';
import { CreateClientSuccessComponent } from './modules/clients/create-client-success/create-client-success.component';
import { NewSecretarialClientComponent } from './modules/clients/secretarial-clients/new-secretarial-client/new-secretarial-client.component';
import { SecretarialClientsComponent } from './modules/clients/secretarial-clients/secretarial-clients.component';
import { RequestDetailsComponent } from './modules/requests/request-details/request-details.component';
import { EditProfileComponent } from './modules/profile/edit-profile/edit-profile.component';
import { EnquiryDetailsComponent } from './modules/enquiries/enquiry-details/enquiry-details.component';
import { AddUserComponent } from './modules/users/add-user/add-user.component';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth_guard/auth.guard';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ArticlesComponent } from './modules/articles/articles.component';
import { ClientsComponent } from './modules/clients/clients.component';
import { EnquiriesComponent } from './modules/enquiries/enquiries.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/login/login.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { RequestsComponent } from './modules/requests/requests.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { ServicesComponent } from './modules/services/services.component';
import { SideNavComponent } from './modules/side-nav/side-nav/side-nav.component';
import { UsersComponent } from './modules/users/users.component';
import { EditUserComponent } from './modules/users/edit-user/edit-user.component';
import { ClientDetailsComponent } from './modules/clients/client-details/client-details.component';
import { YearDetailsComponent } from './modules/clients/client-details/year-details/year-details.component';
import { NewYearDetailsComponent } from './modules/clients/client-details/new-year-details/new-year-details.component';
import { EditSecretarialClientComponent } from './modules/clients/secretarial-clients/edit-secretarial-client/edit-secretarial-client.component';
import { EditTaxClientComponent } from './modules/clients/tax-clients/edit-tax-client/edit-tax-client.component';
import { AuditClientsComponent } from './modules/clients/audit-clients/audit-clients.component';
import { NewAuditClientComponent } from './modules/clients/audit-clients/new-audit-client/new-audit-client.component';
import { EditAuditClientComponent } from './modules/clients/audit-clients/edit-audit-client/edit-audit-client.component';
import { SharedServicesClientsComponent } from './modules/clients/shared-services-clients/shared-services-clients.component';
import { NewSharedServicesClientComponent } from './modules/clients/shared-services-clients/new-shared-services-client/new-shared-services-client.component';
import { EditSharedServicesClientComponent } from './modules/clients/shared-services-clients/edit-shared-services-client/edit-shared-services-client.component';
import { ServiceListComponent } from './modules/services/service-list/service-list.component';
import { ServiceDetailsComponent } from './modules/services/service-details/service-details.component';
import { NewArticleComponent } from './modules/articles/new-article/new-article.component';
import { EditArticleComponent } from './modules/articles/edit-article/edit-article.component';
import { CustomerRequestsComponent } from './modules/customer-requests/customer-requests.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: '',
    component: SideNavComponent,
    children: [
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'clients',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ClientsComponent,
          },
          // Start Secretarial
          {
            path: 'secretarial',
            children: [
              {
                path: '',
                component: SecretarialClientsComponent,
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    component: NewSecretarialClientComponent,
                  },
                  {
                    path: 'success',
                    component: CreateClientSuccessComponent,
                  },
                ],
              },
              {
                path: 'edit/:id',
                component: EditSecretarialClientComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    component: ClientDetailsComponent,
                  },
                  {
                    path: 'year/create',
                    children: [
                      {
                        path: '',
                        component: NewYearDetailsComponent,
                      },
                      {
                        path: 'success',
                        component: CreateYearDetailsSuccessComponent,
                      },
                    ],
                  },
                  {
                    path: '',
                    children: [
                      {
                        path: 'year/:year',
                        component: YearDetailsComponent,
                      },
                      {
                        path: 'year/:year/edit',
                        component: EditYearDetailsComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // End Secretarial
          // Start Tax
          {
            path: 'tax',
            children: [
              {
                path: '',
                component: TaxClientsComponent,
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    component: NewTaxClientComponent,
                  },
                  {
                    path: 'success',
                    component: CreateClientSuccessComponent,
                  },
                ],
              },
              {
                path: 'edit/:id',
                component: EditTaxClientComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    component: ClientDetailsComponent,
                  },
                  {
                    path: 'year/create',
                    children: [
                      {
                        path: '',
                        component: NewYearDetailsComponent,
                      },
                      {
                        path: 'success',
                        component: CreateYearDetailsSuccessComponent,
                      },
                    ],
                  },
                  {
                    path: '',
                    children: [
                      {
                        path: 'year/:year',
                        component: YearDetailsComponent,
                      },
                      {
                        path: 'year/:year/edit',
                        component: EditYearDetailsComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // End Tax
          // Start Audit
          {
            path: 'audit',
            children: [
              {
                path: '',
                component: AuditClientsComponent,
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    component: NewAuditClientComponent,
                  },
                  {
                    path: 'success',
                    component: CreateClientSuccessComponent,
                  },
                ],
              },
              {
                path: 'edit/:id',
                component: EditAuditClientComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    component: ClientDetailsComponent,
                  },
                  {
                    path: 'year/create',
                    children: [
                      {
                        path: '',
                        component: NewYearDetailsComponent,
                      },
                      {
                        path: 'success',
                        component: CreateYearDetailsSuccessComponent,
                      },
                    ],
                  },
                  {
                    path: '',
                    children: [
                      {
                        path: 'year/:year',
                        component: YearDetailsComponent,
                      },
                      {
                        path: 'year/:year/edit',
                        component: EditYearDetailsComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // End Audit
          // Start Shared Services
          {
            path: 'shared_services',
            children: [
              {
                path: '',
                component: SharedServicesClientsComponent,
              },
              {
                path: 'create',
                children: [
                  {
                    path: '',
                    component: NewSharedServicesClientComponent,
                  },
                  {
                    path: 'success',
                    component: CreateClientSuccessComponent,
                  },
                ],
              },
              {
                path: 'edit/:id',
                component: EditSharedServicesClientComponent,
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    component: ClientDetailsComponent,
                  },
                  {
                    path: 'year/create',
                    children: [
                      {
                        path: '',
                        component: NewYearDetailsComponent,
                      },
                      {
                        path: 'success',
                        component: CreateYearDetailsSuccessComponent,
                      },
                    ],
                  },
                  {
                    path: '',
                    children: [
                      {
                        path: 'year/:year',
                        component: YearDetailsComponent,
                      },
                      {
                        path: 'year/:year/edit',
                        component: EditYearDetailsComponent,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          // End Shared Services
        ],
      },
      {
        path: 'services',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ServicesComponent,
          },
          {
            path: 'tax',
            children: [
              {
                path: '',
                component: ServiceListComponent,
              },
              {
                path: 'details/:id',
                component: ServiceDetailsComponent,
              },
            ],
          },
          {
            path: 'secretarial',
            children: [
              {
                path: '',
                component: ServiceListComponent,
              },
              {
                path: 'details/:id',
                component: ServiceDetailsComponent,
              },
            ],
          },
          {
            path: 'shared_services',
            children: [
              {
                path: '',
                component: ServiceListComponent,
              },
              {
                path: 'details/:id',
                component: ServiceDetailsComponent,
              },
            ],
          },
          {
            path: 'audit',
            children: [
              {
                path: '',
                component: ServiceListComponent,
              },
              {
                path: 'details/:id',
                component: ServiceDetailsComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'enquiries',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: EnquiriesComponent,
          },
          {
            path: 'details/:id',
            component: EnquiryDetailsComponent,
          },
        ],
      },
      {
        path: 'requests',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: RequestsComponent,
          },
          {
            path: 'details/:id',
            component: RequestDetailsComponent,
          },
        ],
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: UsersComponent,
          },
          {
            path: 'create',
            component: AddUserComponent,
          },
          {
            path: 'edit/:id',
            component: EditUserComponent,
          },
        ],
      },
      {
        path: 'articles',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: ArticlesComponent,
          },
          {
            path: 'create',
            children: [
              {
                path: '',
                component: NewArticleComponent,
              },
              {
                path: 'success',
                component: CreateArticleSuccessComponent,
              },
            ],
          },
          {
            path: 'edit/:id',
            component: EditArticleComponent,
          },
          {
            path: 'details/:id',
            component: ArticleDetailsComponent,
          },
          {
            path: 'drafts',
            component: DraftArticlesComponent,
          },
        ],
      },
      {
        path: 'user-requests',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: CustomerRequestsComponent,
          },
        ],
      },
      {
        path: 'entities',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: CustomerEntitiesComponent,
          },
          {
            path: 'details/:id',
            children: [
              {
                path: '',
                component: CustomerEntityDetailsComponent,
              },
              {
                path: 'files/:yearId/:service',
                component: CustomerEntityFilesComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        children: [
          { path: '', component: ProfileComponent },
          { path: 'edit/:id', component: EditProfileComponent },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/404' },
  { path: '404', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
