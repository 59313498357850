<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="24px"
  class="padding-24"
>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <!-- Start Title -->
    <app-page-title
      title="Requests"
      subtitle="Let us know if you want to change information"
    ></app-page-title>
    <!-- End Title -->
    <!-- Start Add Request -->
    <button
      class="extended-fab-button"
      mat-fab
      color="primary"
      (click)="showNewCustomerRequest()"
    >
      <mat-icon>add</mat-icon>
      <span class="extended-fab-button__text">Request</span>
    </button>
    <!-- End Add Request -->
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between start"
    fxLayoutAlign.xs="stretch"
    fxLayoutGap="24px"
    fxLayoutGap.xs="40px"
  >
    <!-- Start Active Requests -->
    <app-customer-request-list
      fxFlex="calc(50% - 24px)"
      fxFlex.xs="100%"
      title="Active Requests"
      [isActive]="true"
      [refreshNeeded]="refreshNeeded"
    ></app-customer-request-list>
    <!-- End Active Requests -->

    <!-- Start Past Requests -->
    <app-customer-request-list
      fxFlex="calc(50% - 24px)"
      fxFlex.xs="100%"
      title="Past Requests"
      [isActive]="false"
      [refreshNeeded]="refreshNeeded"
    ></app-customer-request-list>
    <!-- End Past Requests -->
  </div>
</section>
