import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { Article } from 'src/app/shared/models/article/article.model';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Article> = new MatTableDataSource<Article>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  isLoading = false;

  // Articles
  articles: Array<Article> = [];

  // Params
  filterArticle = '';
  category = 'ALL';
  createdStart: Date | null = null;
  createdEnd: Date | null = null;
  publishedStart: Date | null = null;
  publishedEnd: Date | null = null;
  updatedStart: Date | null = null;
  updatedEnd: Date | null = null;

  // Pagination
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  pageEvent!: PageEvent;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private articlesService: ArticlesService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['mainTitle', 'publishedDate', 'options'];
        } else if (device === Device.tablet) {
          this.displayedColumns = [
            'id',
            'mainTitle',
            'createdDate',
            'publishedDate',
            'lastModifiedDate',
            'options',
          ];
        } else {
          this.displayedColumns = [
            'id',
            'mainTitle',
            'createdDate',
            'publishedDate',
            'lastModifiedDate',
            'options',
          ];
        }
      },
    });
  }

  ngOnInit(): void {
    this.getArticles();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getArticles(
    filterArticle?: string,
    pageSize?: number,
    currentPage?: number,
    category?: string,
    createdStart?: Date,
    createdEnd?: Date,
    publishedStart?: Date,
    publishedEnd?: Date,
    updatedStart?: Date,
    updatedEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: undefined,
    });

    const formData = {
      title: filterArticle ?? this.filterArticle,
      category: this.getCategoryValue(category),
      createdDateFrom: this.datepipe.transform(
        createdStart ?? this.createdStart,
        'yyy-MM-dd 00:00:00'
      ),
      createdDateTo: this.datepipe.transform(
        createdEnd ?? this.createdEnd,
        'yyy-MM-dd 23:59:59'
      ),
      publishedDateFrom: this.datepipe.transform(
        publishedStart ?? this.publishedStart,
        'yyy-MM-dd 00:00:00'
      ),
      publishedDateTo: this.datepipe.transform(
        publishedEnd ?? this.publishedEnd,
        'yyy-MM-dd 23:59:59'
      ),
      updatedDateFrom: this.datepipe.transform(
        updatedStart ?? this.updatedStart,
        'yyy-MM-dd 00:00:00'
      ),
      updatedDateTo: this.datepipe.transform(
        updatedEnd ?? this.updatedEnd,
        'yyy-MM-dd 23:59:59'
      ),
      page: currentPage ?? this.currentPage,
      size: pageSize ?? this.pageSize,
    };

    this.articlesService.getArticles(formData).subscribe({
      next: (data) => {
        if (data !== null) {
          this.length = data.totalItems;
          this.articles = data.articles as Article[];
        } else {
          this.length = 0;
          this.articles = [];
        }
        this.dataSource.data = this.articles;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(pageEvent: PageEvent): PageEvent {
    this.currentPage = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.getArticles();
    return pageEvent;
  }

  getCategoriesStr(articleCategories: Array<ArticleCategory>): string[] {
    const categoriesStr: Array<string> = [];
    for (const articleCategory of articleCategories) {
      categoriesStr.push(articleCategory.name.toLowerCase());
    }
    return categoriesStr;
  }

  getCategoryValue(category?: string): string | null {
    if (category) {
      if (category === 'ALL') {
        return null;
      } else {
        return category;
      }
    } else {
      return null;
    }
  }
}
