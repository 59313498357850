import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientFormConstants {
  public COMPANY_TYPES = [
    { value: 'CORPORATE', valueName: 'Corporate' },
    { value: 'INDIVIDUAL', valueName: 'Individual' },
    { value: 'LLP', valueName: 'Limited Liability Partnership' },
    { value: 'PARTNERSHIP', valueName: 'Partnership' },
    { value: 'OTHERS', valueName: 'Others' },
  ];
  public STOCKTAKE = [
    { value: true, valueName: 'Yes' },
    { value: false, valueName: 'No' },
  ];
  public ACCOUNT_TYPES = [
    { value: 'savings', valueName: 'Savings' },
    { value: 'current', valueName: 'Current' },
    { value: 'loan', valueName: 'Loan' },
  ];
  public COMMON_SEAL = [
    { value: true, valueName: 'Yes' },
    { value: false, valueName: 'No' },
  ];
  public REGISTER_OFFICE = [
    { value: true, valueName: 'Yes' },
    { value: false, valueName: 'No' },
  ];
  public NAME_TAG = [
    { value: true, valueName: 'Yes' },
    { value: false, valueName: 'No' },
  ];
  public INCORPORATED_BY_US = [
    { value: true, valueName: 'Yes' },
    { value: false, valueName: 'No' },
  ];
  public RACES = [
    { value: 'CHINESE', valueName: 'Chinese' },
    { value: 'INDIAN', valueName: 'Indian' },
    { value: 'MALAY', valueName: 'Malay' },
    { value: 'NATIVE', valueName: 'Native' },
    { value: 'OTHERS', valueName: 'Others' },
  ];
  public SERVICE_TYPES = [
    { value: 'ACCOUNTS_OUTSOURCE', valueName: 'Accounts Outsource' },
    { value: 'PAYROLL_OUTSOURCE', valueName: 'Payroll Outsource' },
    { value: 'REVIEW_AND_FINALISATION', valueName: 'Review and Finalisation' },
    { value: 'OUTSOURCE_CFO', valueName: 'Outsource CFO' },
    { value: 'OTHERS', valueName: 'Others' },
  ];
}
