import { CustomBreakpointService } from './../../../../../shared/breakpoint/custom-breakpoint.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ClientsService } from './../../../../../core/services/clients/clients.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Year } from 'src/app/shared/models/year/year.model';
import { MatStepper } from '@angular/material/stepper';
import { Device } from 'src/app/shared/enums/device.enum';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';

@Component({
  selector: 'app-year-form',
  templateUrl: './year-form.component.html',
  styleUrls: ['./year-form.component.scss'],
})
export class YearFormComponent implements OnInit {
  // Form
  @Input() yearForm!: FormGroup;
  @Input() stepper!: MatStepper;

  // Variables
  unavailableYears: Array<Year> = [];
  years: Array<number> = [];

  // Client Type
  clientType: string;

  // Loading
  yearIsLoading = false;

  // Responsive
  isMobile = true;

  constructor(
    private clientsService: ClientsService,
    private activatedRoute: ActivatedRoute,
    private customBreakpointService: CustomBreakpointService,
    private clientTypeService: ClientTypeService
  ) {
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;

    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.getListOfUnavailableYears(params.id);
      },
    });
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.generateSelectableYears();
  }

  getListOfUnavailableYears(companyID: number): void {
    this.yearIsLoading = true;
    this.unavailableYears = [];
    this.clientsService
      .getClientAvailableYears(companyID, this.clientType)
      .subscribe({
        next: (years: Array<Year>) => {
          if (years) {
            const sortedYear = years.sort((a: Year, b: Year) =>
              b.year.localeCompare(a.year)
            );
            this.unavailableYears = sortedYear;
          }
          this.yearIsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.yearIsLoading = false;
        },
      });
  }

  generateSelectableYears(): void {
    const today: Date = new Date();
    const currentYear = today.getFullYear();
    const years: Array<number> = [];
    for (let year = 2018; year <= currentYear; year++) {
      years.push(year);
    }
    this.years = years.sort((a: number, b: number) =>
      b.toString().localeCompare(a.toString())
    );
  }

  yearIsNotAvailable(year: number): boolean {
    return this.unavailableYears.some((x) => x.year === year.toString());
  }

  yearSelected(year: number): void {
    this.yearForm.patchValue({
      year: year.toString(),
    });
    this.isSelectedYear(year);
  }

  isSelectedYear(year: number): boolean {
    return this.yearForm.get('year')?.value === year.toString();
  }
}
