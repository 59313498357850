import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { Device } from '../enums/device.enum';
import { WindowSize } from '../models/window-size/window-size';

@Injectable({
  providedIn: 'root',
})
export class LoginBreakpointService {
  isDevice$: BehaviorSubject<Device> = new BehaviorSubject<Device>(
    Device.mobile
  );
  columns$: BehaviorSubject<number> = new BehaviorSubject<number>(4);
  // For Window Resize purpose
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  maxContentSize$: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>(
    {
      width: 599.98,
      height: 568,
    }
  );
  windowSize$: BehaviorSubject<WindowSize> = new BehaviorSubject<WindowSize>({
    width: 599.98,
    height: 568,
  });

  constructor(public breakpointObserver: BreakpointObserver) {
    // Get initial window size
    this.windowSize$.next({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    // Observe for breakpoints
    this.subscribeBreakpoint();

    // Subscribe to resize of window
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe({
      next: (event: Event) => {
        const resizedWindowEvent = event.target as Window;
        this.windowSize$.next({
          width: resizedWindowEvent.innerWidth,
          height: resizedWindowEvent.innerHeight,
        });
        this.subscribeBreakpoint();
      },
    });
  }

  subscribeBreakpoint(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
        Breakpoints.HandsetLandscape,
        Breakpoints.TabletPortrait,
        Breakpoints.TabletLandscape,
      ])
      .subscribe({
        next: (result) => {
          if (result.breakpoints[Breakpoints.XSmall]) {
            // XSmall
            this.columns$.next(4);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 4,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.mobile);
          } else if (result.breakpoints[Breakpoints.Small]) {
            // Small
            this.columns$.next(4);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 2,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.mobile);
          } else if (
            result.breakpoints[Breakpoints.Small] &&
            result.breakpoints[Breakpoints.TabletPortrait]
          ) {
            // Small and Tablet Portrait
            this.columns$.next(8);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 4,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.tablet);
          } else if (
            result.breakpoints[Breakpoints.Medium] &&
            result.breakpoints[Breakpoints.TabletLandscape]
          ) {
            // Medium and Tablet Landscape
            this.columns$.next(12);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 4,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.tablet);
          } else if (result.breakpoints[Breakpoints.Large]) {
            // Large
            this.columns$.next(12);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 4,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.largeScreen);
          } else {
            // XLarge
            this.columns$.next(12);
            this.maxContentSize$.next({
              width: (this.windowSize$.value.width / this.columns$.value) * 4,
              height: this.windowSize$.value.height,
            });
            this.isDevice$.next(Device.XLargeScreen);
          }
        },
      });
  }
}
