import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ArticleFormConstants {
  public CATEGORY_TYPES = [
    { value: 'ACCOUNTING', valueName: 'Accounting' },
    { value: 'AUDIT', valueName: 'Audit' },
    { value: 'FINANCE', valueName: 'Finance' },
    { value: 'HEALTH', valueName: 'Health' },
    { value: 'INCORPORATION', valueName: 'Incorporation' },
    { value: 'SECRETARY', valueName: 'Secretary' },
  ];
}
