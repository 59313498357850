<section
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.min-height.px]="contentMinHeight$ | async"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="56px"
    class="padding-24"
    [style.width.px]="contentMaxWidth$ | async"
  >
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxLayoutAlign="start center" class="padding-16">
        <img
          src="assets/images/eurogain-logo.png"
          alt="Eurogain Logo"
          width="50%"
        />
        <h1 class="no-margin">
          {{
            passwordResetSuccess ? "Password Changed" : "Reset Your Password"
          }}
        </h1>
        <p class="text-align-center" *ngIf="!passwordResetSuccess">
          Enter new password for <b>{{ email }}</b>
        </p>
        <p class="text-align-center" *ngIf="passwordResetSuccess">
          Your password has been updated. Head back to login to proceed.
        </p>
      </div>
      <form
        fxLayout="column"
        fxLayoutAlign="start stretch"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPasswordPressed()"
        *ngIf="!passwordResetSuccess"
      >
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            required
          />
          <mat-error
            *ngIf="resetPasswordForm.controls['password'].hasError('required')"
            >Password is required</mat-error
          >
          <mat-error
            *ngIf="resetPasswordForm.controls['password'].hasError('minlength')"
            >Password needs to be at least 8 character long</mat-error
          >
          <mat-error
            *ngIf="
              resetPasswordForm.controls['password'].hasError('minlength') ==
                false &&
              resetPasswordForm.controls['password'].hasError('required') ==
                false &&
              resetPasswordForm.controls['password'].hasError('strong')
            "
            >Password needs to have at least a number, uppercase letter, and
            lowercase letter</mat-error
          >
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="hidePassword = !hidePassword"
          >
            <mat-icon>{{
              hidePassword ? "visibility" : "visibility_off"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field appearance="outline" class="margin-bottom-16">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            required
          />
          <mat-error
            *ngIf="
              resetPasswordForm.controls['confirmPassword'].hasError('required')
            "
            >Confirm Password is required</mat-error
          ><mat-error
            *ngIf="
              resetPasswordForm.controls['confirmPassword'].hasError(
                'NoPassswordMatch'
              )
            "
            >Password does not match</mat-error
          >
          <button
            mat-icon-button
            matSuffix
            type="button"
            (click)="hideConfirmPassword = !hideConfirmPassword"
          >
            <mat-icon>{{
              hideConfirmPassword ? "visibility" : "visibility_off"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="isResetting"
        >
          Reset Password
        </button>
      </form>

      <button
        mat-raised-button
        color="primary"
        *ngIf="passwordResetSuccess"
        (click)="backToLoginPressed()"
      >
        Back to Login
      </button>
    </div>
  </div>
</section>
