<ng-template #disabled>
  <a
    mat-stroked-button
    (click)="$event.stopPropagation()"
    matTooltip="You do not have access"
  >
    <mat-list>
      <mat-list-item>
        <mat-icon mat-list-icon> {{ iconName }}</mat-icon>
        <div mat-line class="title">{{ title }}</div>
        <div mat-line class="description">{{ description }}</div>
      </mat-list-item>
    </mat-list>
  </a>
</ng-template>

<a
  *ngIf="enabled; else disabled"
  mat-stroked-button
  [routerLink]="routerLink"
  class="width-100"
>
  <mat-list>
    <mat-list-item>
      <mat-icon mat-list-icon> {{ iconName }}</mat-icon>
      <div mat-line class="title">{{ title }}</div>
      <div mat-line class="description">{{ description }}</div>
    </mat-list-item>
  </mat-list>
</a>
