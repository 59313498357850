import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { Device } from 'src/app/shared/enums/device.enum';
import { Bank } from 'src/app/shared/models/client/bank/bank.model';

@Component({
  selector: 'app-banks-form',
  templateUrl: './banks-form.component.html',
  styleUrls: ['./banks-form.component.scss'],
})
export class BanksFormComponent implements OnInit, OnDestroy {
  // Form
  @Input() banksForm!: FormGroup;

  // Dropdown List
  accountTypeOptions: Array<{ value: string; valueName: string }>;
  bankOptions: BehaviorSubject<Array<Bank>> = new BehaviorSubject<Array<Bank>>(
    []
  );
  // Subscriptions
  bankNameSubscription!: Subscription | undefined;

  // Responsive
  isMobile = true;

  // Loading
  isBankLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private customBreakpointService: CustomBreakpointService,
    private clientFormConstants: ClientFormConstants,
    private snackBar: MatSnackBar
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.accountTypeOptions = this.clientFormConstants.ACCOUNT_TYPES;
  }
  ngOnDestroy(): void {
    this.bankNameSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToBanks();
  }

  subscribeToBanks(): void {
    this.banksForm.controls.banks.valueChanges.subscribe({
      next: (memberForms) => {
        for (let index = 0; index < memberForms.length; index++) {
          this.subscribeToBankName(index);
        }
      },
    });
  }

  subscribeToBankName(index: number): void {
    this.bankNameSubscription = this.banks.controls[index]
      .get('bankNameStr')
      ?.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe({
        next: (result) => {
          if (!this.isBankLoading) {
            const bankForm: FormGroup = this.banks.controls[index] as FormGroup;
            this.getBanks(result, bankForm);
          }
        },
      });
  }

  get banks(): FormArray {
    return this.banksForm.get('banks') as FormArray;
  }

  createBankItem(): FormGroup {
    return this.formBuilder.group({
      bankNameStr: new FormControl('', Validators.required),
      branch: new FormControl('', Validators.required),
      branchAddress: new FormControl('', Validators.required),
      bankAccountTypeStr: new FormControl('', Validators.required),
      accountNumber: new FormControl('', Validators.required),
      accountUsage: new FormControl('', Validators.required),
    });
  }

  getBanks(bankName: string, bankForm: FormGroup): void {
    this.isBankLoading = true;
    if (bankName?.length > 0) {
      this.clientsService.getBanks(bankName).subscribe({
        next: (result) => {
          this.isBankLoading = false;
          if (result) {
            this.bankOptions.next(result.banks);
          } else {
            this.bankOptions.next([]);
            bankForm.controls.bankNameStr.setErrors({
              notAvailable: true,
            });
          }
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isBankLoading = false;
        },
      });
    } else {
      this.isBankLoading = false;
      this.bankOptions.next([]);
    }
  }

  addBank(): void {
    this.banks.push(this.createBankItem());
    this.bankOptions.next([]);
  }

  removeBank(index: number): void {
    this.banks.removeAt(index);
  }
}
