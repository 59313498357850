<form
  [formGroup]="generalCompanyDetailsForm"
  class="margin-bottom-24"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div
    class="width-100"
    *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
  >
    <app-section-title title="Choose ID Type"></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs=""
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="32px"
      class="width-100 margin-bottom-16 relative"
    >
      <mat-radio-group fxLayout="column" fxLayoutGap="8px">
        <mat-radio-button
          *ngFor="let companyNumberType of companyNumberTypes"
          [value]="companyNumberType.value"
          (change)="onCompanyNumberTypeChange(companyNumberType)"
          [checked]="initialCompanyNumberType === companyNumberType.value"
        >
          {{ companyNumberType.valueName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="width-100">
    <app-section-title title="Add Client Details"></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs="0px"
      fxLayout="row wrap"
      fxLayoutAlign="stretch start"
      fxLayoutGap="32px grid"
      class="width-100 margin-bottom-16 relative"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- New Company Number -->
        <mat-form-field
          appearance="outline"
          class="companyNumber"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
          [fxHide]="selectedCompanyNumberType === 2"
        >
          <mat-label>Company Number <small>(New)</small></mat-label>
          <input
            matInput
            type="text"
            formControlName="companyNumberNew"
            [matAutocomplete]="autoCompleteCompanyNumberNew"
            [required]="selectedCompanyNumberType === 0"
          />
          <!-- Autocomplete -->
          <mat-autocomplete
            autoActiveFirstOption
            #autoCompleteCompanyNumberNew="matAutocomplete"
          >
            <mat-option
              *ngFor="let client of clientNewOptions | async"
              [value]="client.companyNumberNew"
              class="multiline-options"
              (onSelectionChange)="onCompanySelected(client, true)"
            >
              <span>{{ client.name }}</span
              ><br />
              <small>{{ client.companyNumberNew }}</small>
            </mat-option>
          </mat-autocomplete>
          <!-- Loader -->
          <mat-spinner
            matSuffix
            diameter="18"
            matTooltip="Checking company number"
            *ngIf="companyNumberNewIsLoading"
          ></mat-spinner>

          <!-- Populate Form button (new) -->
          <button
            mat-button
            type="button"
            matSuffix
            color="primary"
            class="companyExistButton"
            *ngIf="
              companyExistNew !== null &&
              companyExistNew == true &&
              generalCompanyDetailsForm.controls['companyNumberNew'].hasError(
                'exist'
              ) === false &&
              clientNew !== undefined &&
              isFormPopulated === false &&
              !companyNumberNewIsLoading
            "
            (click)="showPopulateFormDialog($event, true)"
            matTooltip="Copy company details from other department"
          >
            <div fxLayout="row" fxLayoutAlign=" center end" fxLayoutGap="4px">
              <span>Populate Form</span>
              <mat-icon>help_outline</mat-icon>
            </div>
          </button>
          <!-- Available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              (companyExistNew !== null &&
                companyExistNew === false &&
                !companyNumberNewIsLoading) ||
              companyNumberNewAvailable === true
            "
            class="companyNotExisting"
            matTooltip="Company number is available"
            >check_circle</mat-icon
          >

          <!-- Not available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberNew'].hasError(
                'exist'
              ) && !companyNumberNewIsLoading
            "
            class="companyExistIcon"
            matTooltip="Company exist"
            >cancel</mat-icon
          >

          <!-- Error Messages -->
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberNew'].hasError(
                'required'
              )
            "
          >
            Company number (new) is required
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberNew'].hasError(
                'exist'
              )
            "
          >
            Company number (new) existed
          </mat-error>

          <!-- Hints -->
          <mat-hint
            *ngIf="
              companyExistNew !== null &&
              companyExistNew == true &&
              clientNew !== undefined
            "
          >
            Company number (new) existed in
            <span class="ellipsis">
              <span
                class="capitalize"
                *ngFor="
                  let department of clientNewInClientType;
                  let isLast = last
                "
              >
                {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
              </span>
            </span>
            department
          </mat-hint>
        </mat-form-field>

        <!-- Old Company Number -->
        <mat-form-field
          appearance="outline"
          class="companyNumber"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
          [fxHide]="selectedCompanyNumberType === 2"
        >
          <mat-label>Company Number <small>(Old)</small></mat-label>
          <input
            matInput
            type="text"
            formControlName="companyNumberOld"
            [matAutocomplete]="autoCompleteCompanyNumberOld"
            [required]="selectedCompanyNumberType === 1"
          />
          <!-- Autocomplete -->
          <mat-autocomplete
            autoActiveFirstOption
            #autoCompleteCompanyNumberOld="matAutocomplete"
          >
            <mat-option
              *ngFor="let client of clientOldOptions | async"
              [value]="client.companyNumberOld"
              class="multiline-options"
              (onSelectionChange)="onCompanySelected(client, false)"
            >
              <span>{{ client.name }}</span
              ><br />
              <small>{{ client.companyNumberOld }}</small>
            </mat-option>
          </mat-autocomplete>
          <!-- Loader -->
          <mat-spinner
            matSuffix
            diameter="18"
            matTooltip="Checking company number"
            *ngIf="companyNumberOldIsLoading"
          ></mat-spinner>

          <!-- Populate Form button (old) -->
          <button
            mat-button
            type="button"
            matSuffix
            color="primary"
            class="companyExistButton"
            *ngIf="
              companyExistOld !== null &&
              companyExistOld == true &&
              generalCompanyDetailsForm.controls['companyNumberOld'].hasError(
                'exist'
              ) === false &&
              clientOld !== undefined &&
              isFormPopulated === false &&
              !companyNumberOldIsLoading
            "
            (click)="showPopulateFormDialog($event, false)"
            matTooltip="Copy company details from other department"
          >
            <div fxLayout="row" fxLayoutAlign=" center end" fxLayoutGap="4px">
              <span>Populate Form</span>
              <mat-icon>help_outline</mat-icon>
            </div>
          </button>

          <!-- Available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              (companyExistOld !== null &&
                companyExistOld == false &&
                !companyNumberOldIsLoading) ||
              companyNumberOldAvailable === true
            "
            class="companyNotExisting"
            matTooltip="Company number is available"
            >check_circle</mat-icon
          >

          <!-- Not available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberOld'].hasError(
                'exist'
              ) && !companyNumberOldIsLoading
            "
            class="companyExistIcon"
            matTooltip="Company exist"
            >cancel</mat-icon
          >

          <!-- Error Messages -->
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberOld'].hasError(
                'required'
              )
            "
          >
            Company number (old) is required
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyNumberOld'].hasError(
                'exist'
              )
            "
          >
            Company number (Old) existed
          </mat-error>

          <!-- Hints -->
          <mat-hint
            *ngIf="
              companyExistOld !== null &&
              companyExistOld == true &&
              clientOld !== undefined
            "
          >
            Company number (old) existed in
            <span class="ellipsis">
              <span
                class="capitalize"
                *ngFor="
                  let department of clientOldInClientType;
                  let isLast = last
                "
              >
                {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
              </span>
            </span>
            department
          </mat-hint>
        </mat-form-field>

        <!-- ID Type -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isTax || isSharedServices"
          [fxHide]="
            selectedCompanyNumberType === 0 || selectedCompanyNumberType === 1
          "
        >
          <mat-label>Identification Type</mat-label>
          <mat-select
            formControlName="identityTypeRef"
            [required]="selectedCompanyNumberType === 2"
          >
            <mat-option *ngFor="let idType of idTypes" [value]="idType.value">
              {{ idType.type }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['identityTypeRef'].hasError(
                'required'
              )
            "
          >
            Identification Type is required
          </mat-error>
        </mat-form-field>

        <!-- ID Number -->
        <mat-form-field
          appearance="outline"
          class="companyNumber"
          *ngIf="isTax || isSharedServices"
          [fxHide]="
            selectedCompanyNumberType === 0 || selectedCompanyNumberType === 1
          "
        >
          <mat-label>Identification Number</mat-label>
          <input
            matInput
            #idNumberInput
            [matAutocomplete]="autoCompleteIcPassport"
            type="text"
            formControlName="identityNumber"
            [placeholder]="isIC ? 'eg. 123456121234' : ''"
            [maxlength]="isIC ? 12 : ''"
            [required]="selectedCompanyNumberType === 2"
          />
          <!-- Autocomplete -->
          <mat-autocomplete
            autoActiveFirstOption
            #autoCompleteIcPassport="matAutocomplete"
          >
            <mat-option
              *ngFor="let client of clientIcPassportOptions | async"
              [value]="client.identityNumber"
              class="multiline-options"
              (onSelectionChange)="onCompanySelected(client, false, true)"
            >
              <span>{{ client.name }}</span
              ><br />
              <small>{{ client.identityNumber }}</small>
            </mat-option>
          </mat-autocomplete>
          <!-- Loader -->
          <mat-spinner
            matSuffix
            diameter="18"
            matTooltip="Checking identification number"
            *ngIf="icPassportIsLoading"
          ></mat-spinner>

          <!-- Populate Form button (IC/ Passport) -->
          <button
            mat-button
            type="button"
            matSuffix
            color="primary"
            class="companyExistButton"
            *ngIf="
              icPassportExist !== null &&
              icPassportExist == true &&
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'exist'
              ) === false &&
              clientIcPassport !== undefined &&
              isFormPopulated === false &&
              !icPassportIsLoading
            "
            (click)="showPopulateFormDialog($event, false, true)"
            matTooltip="Copy client details from other department"
          >
            <div fxLayout="row" fxLayoutAlign=" center end" fxLayoutGap="4px">
              <span>Populate Form</span>
              <mat-icon>help_outline</mat-icon>
            </div>
          </button>

          <!-- Available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              (icPassportExist !== null &&
                icPassportExist === false &&
                !icPassportIsLoading) ||
              icPassportAvailable === true
            "
            class="companyNotExisting"
            matTooltip="Identification number is available"
            >check_circle</mat-icon
          >

          <!-- Not available icon -->
          <mat-icon
            matSuffix
            *ngIf="
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'exist'
              ) && !icPassportIsLoading
            "
            class="companyExistIcon"
            matTooltip="Client exist"
            >cancel</mat-icon
          >
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'required'
              )
            "
          >
            Identification Number is required
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'maxlength'
              ) ||
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'minlength'
              )
            "
          >
            Identification Number needs to be exactly 12 characters
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'pattern'
              )
            "
          >
            Identification Number is invalid
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['identityNumber'].hasError(
                'exist'
              )
            "
          >
            Identification number existed
          </mat-error>
          <!-- Hints -->
          <mat-hint
            *ngIf="
              icPassportExist !== null &&
              icPassportExist == true &&
              clientIcPassport !== undefined
            "
          >
            Identification number existed in
            <span class="ellipsis">
              <span
                class="capitalize"
                *ngFor="
                  let department of clientIcPassportInClientType;
                  let isLast = last
                "
              >
                {{ department.toLowerCase() }}{{ isLast ? "" : ", " }}
              </span>
            </span>
            department
          </mat-hint>
        </mat-form-field>

        <!-- Company Name -->
        <mat-form-field
          appearance="outline"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
        >
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="companyName" required />
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyName'].hasError(
                'required'
              )
            "
          >
            Name is required
          </mat-error>
        </mat-form-field>

        <!-- Formerly Known As (F.K.A) -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Formerly Known As (F.K.A)</mat-label>
          <input matInput type="text" formControlName="formerlyKnownAs" />
        </mat-form-field>

        <!-- Group -->
        <mat-form-field
          appearance="outline"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
        >
          <mat-label>Group</mat-label>
          <input matInput type="text" formControlName="group" />
        </mat-form-field>

        <!-- Branch/Franchisees Group -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Branch/Franchisees Group</mat-label>
          <input
            matInput
            #clientSubGroup
            type="text"
            formControlName="clientSubGroup"
            maxlength="100"
          />
          <mat-hint align="end"
            >{{ clientSubGroup.value?.length || 0 }}/100</mat-hint
          >
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['clientSubGroup'].hasError(
                'maxlength'
              )
            "
            >Branch/franchisees group can only contain 100 characters
          </mat-error>
        </mat-form-field>

        <!-- File Reference -->
        <mat-form-field
          appearance="outline"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
        >
          <mat-label>File Reference</mat-label>
          <input matInput type="text" formControlName="fileRef" />
        </mat-form-field>

        <!-- Incorporated by us -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Incorporated by us</mat-label>
          <mat-select formControlName="incorporatedByUs" required>
            <mat-option
              *ngFor="let incorporatedByUs of incorporatedByUsOptions"
              [value]="incorporatedByUs.value"
            >
              {{ incorporatedByUs.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['incorporatedByUs'].hasError(
                'required'
              )
            "
          >
            Incorporated by us is required
          </mat-error>
        </mat-form-field>

        <!-- Company Office Number -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Company Office Number</mat-label>
          <input matInput type="text" formControlName="companyOfficeNo" />
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyOfficeNo'].hasError(
                'required'
              )
            "
          >
            Company Office Number is required
          </mat-error>
        </mat-form-field>

        <!-- Company Email -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Company Email</mat-label>
          <input matInput type="text" formControlName="companyEmail" />
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyEmail'].hasError(
                'required'
              )
            "
          >
            Company Email is required
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyEmail'].hasError(
                'email'
              )
            "
          >
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <!-- Company Website -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Company Website</mat-label>
          <input matInput type="text" formControlName="companyWebsite" />
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyWebsite'].hasError(
                'required'
              )
            "
          >
            Company Website is required
          </mat-error>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['companyWebsite'].hasError(
                'pattern'
              )
            "
          >
            This does not look like an URL
          </mat-error>
        </mat-form-field>

        <!-- Referred by -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Referred by</mat-label>
          <input matInput type="text" formControlName="referredBy" />
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['referredBy'].hasError(
                'required'
              )
            "
          >
            Referred by is required
          </mat-error>
        </mat-form-field>

        <!-- Company Type -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isTax"
        >
          <mat-label>Company Type</mat-label>
          <mat-select formControlName="companyType">
            <mat-option
              *ngFor="let companyType of companyTypes"
              [value]="companyType.value"
            >
              {{ companyType.valueName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Employer's Number -->
        <mat-form-field appearance="outline" *ngIf="isTax">
          <mat-label>Employer Number</mat-label>
          <input matInput type="text" formControlName="employerNumber" />
        </mat-form-field>

        <!-- Tax Reference Number -->
        <mat-form-field appearance="outline" *ngIf="isTax">
          <mat-label>Tax Reference Number</mat-label>
          <input matInput type="text" formControlName="taxRefNumber" />
        </mat-form-field>

        <!-- Registered Address -->
        <mat-form-field
          appearance="outline"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial"
        >
          <mat-label>Registered Address</mat-label>
          <textarea
            matInput
            type="text"
            rows="4"
            formControlName="registeredAddress"
            required
          ></textarea>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['registeredAddress'].hasError(
                'required'
              )
            "
          >
            Registered address is required
          </mat-error>
        </mat-form-field>

        <!-- Business Address -->
        <mat-form-field
          appearance="outline"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
        >
          <mat-label>Business Address</mat-label>
          <textarea
            matInput
            type="text"
            rows="4"
            formControlName="correspondentAddress"
            required
          ></textarea>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls[
                'correspondentAddress'
              ].hasError('required')
            "
          >
            Business address is required
          </mat-error>
        </mat-form-field>

        <!-- Registered Book Kept Address -->
        <mat-form-field
          appearance="outline"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial"
        >
          <mat-label>Registered Book Kept Address</mat-label>
          <textarea
            matInput
            type="text"
            rows="4"
            formControlName="registeredBookKeptAddress"
            required
          ></textarea>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls[
                'registeredBookKeptAddress'
              ].hasError('required')
            "
          >
            Business book kept address is required
          </mat-error>
        </mat-form-field>

        <!-- Financial Records Kept Address -->
        <mat-form-field
          appearance="outline"
          fxFlex="calc(50% - 16px)"
          *ngIf="isSecretarial"
        >
          <mat-label>Financial Records Kept Address</mat-label>
          <textarea
            matInput
            type="text"
            rows="4"
            formControlName="financialRecordsKeptAddress"
            required
          ></textarea>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls[
                'financialRecordsKeptAddress'
              ].hasError('required')
            "
          >
            Financial records kept address is required
          </mat-error>
        </mat-form-field>

        <!-- Last Account Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Last Account Date</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfLastAccountDP"
            formControlName="dateOfLastAccount"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfLastAccountDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfLastAccountDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['dateOfLastAccount'].hasError(
                'required'
              )
            "
          >
            Last account date is required
          </mat-error>
        </mat-form-field>

        <!-- Next Account Due Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Next Account Due Date</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfNextAccountDueDP"
            formControlName="dateOfNextAccountDue"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfNextAccountDueDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfNextAccountDueDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls[
                'dateOfNextAccountDue'
              ].hasError('required')
            "
          >
            Next account due date is required
          </mat-error>
        </mat-form-field>

        <!-- Incorporation Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Incorporation Date</mat-label>
          <input
            matInput
            [matDatepicker]="incorporationDateDP"
            formControlName="incorporationDate"
            placeholder="dd/mm/yyyy"
            required
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="incorporationDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #incorporationDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['incorporationDate'].hasError(
                'required'
              )
            "
          >
            Incorporation date is required
          </mat-error>
        </mat-form-field>

        <!-- Start AFS - Circulation Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>AFS - Circulation Date</mat-label>
          <input
            matInput
            [matDatepicker]="afsCirculationDateDP"
            formControlName="circulationDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="afsCirculationDateDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #afsCirculationDateDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['circulationDate'].hasError(
                'required'
              )
            "
          >
            AFS - Circulation date is required
          </mat-error>
        </mat-form-field>
        <!-- End AFS - Circulation Date -->

        <!-- Charge/ Discharge -->
        <mat-form-field appearance="outline" *ngIf="isSecretarial">
          <mat-label>Charge/ Discharge</mat-label>
          <input matInput type="text" formControlName="chargeOrDischarge" />
        </mat-form-field>

        <!-- Start Charge Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Charge Date</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfChargeDP"
            formControlName="chargeDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfChargeDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfChargeDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['chargeDate'].hasError(
                'required'
              )
            "
          >
            Charge date is required
          </mat-error>
        </mat-form-field>
        <!-- End Charge Date -->

        <!-- Start Discharge Date -->
        <mat-form-field
          appearance="outline"
          (click)="$event.stopPropagation()"
          *ngIf="isSecretarial"
        >
          <mat-label>Discharge Date</mat-label>
          <input
            matInput
            [matDatepicker]="dateOfDischargeDP"
            formControlName="dischargeDate"
            placeholder="dd/mm/yyyy"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="dateOfDischargeDP"
          ></mat-datepicker-toggle>
          <mat-datepicker #dateOfDischargeDP></mat-datepicker>
          <mat-error
            *ngIf="
              generalCompanyDetailsForm.controls['dischargeDate'].hasError(
                'required'
              )
            "
          >
            Discharge date is required
          </mat-error>
        </mat-form-field>
        <!-- End Discharge Date -->

        <!-- Year End -->
        <p
          class="bold"
          *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
        >
          Year End
        </p>
        <div fxLayout="row wrap" fxLayoutGap="16px grid">
          <!-- Day -->
          <mat-form-field
            appearance="outline"
            fxFlex="calc(50% - 16px)"
            *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
          >
            <mat-label>Day</mat-label>
            <input
              matInput
              type="text"
              formControlName="yearEndDay"
              mask="90"
              min="1"
              max="31"
            />
            <mat-error
              *ngIf="
                generalCompanyDetailsForm.controls['yearEndDay'].hasError(
                  'max'
                ) ||
                generalCompanyDetailsForm.controls['yearEndDay'].hasError('min')
              "
            >
              Day needs to be within 1 to 31
            </mat-error>
          </mat-form-field>

          <!-- Month -->
          <mat-form-field
            appearance="outline"
            fxFlex="calc(50% - 16px)"
            *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
          >
            <mat-label>Month</mat-label>
            <input
              matInput
              type="text"
              formControlName="yearEndMonth"
              mask="90"
              min="1"
              max="12"
            />
            <mat-error
              *ngIf="
                generalCompanyDetailsForm.controls['yearEndMonth'].hasError(
                  'max'
                ) ||
                generalCompanyDetailsForm.controls['yearEndMonth'].hasError(
                  'min'
                )
              "
            >
              Month needs to be within 1 to 12
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Start Status -->
        <mat-form-field appearance="outline" *ngIf="isTax">
          <mat-label>Status</mat-label>
          <input matInput type="text" formControlName="clientStatus" />
        </mat-form-field>
        <!-- End Status -->
      </div>
    </div>
  </div>

  <!-- Start Add Contact Person -->
  <div
    class="width-100"
    *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
  >
    <app-contact-person-form
      [contactsForm]="contactsForm"
    ></app-contact-person-form>
  </div>
  <!-- End Add Contact Person -->

  <!-- Start Add Auditors -->
  <div class="width-100" *ngIf="!isSecretarial">
    <app-auditors-form [auditorsForm]="auditorsForm"></app-auditors-form>
  </div>
  <!-- End Add Auditors -->

  <!-- Add Branch Address -->
  <div class="width-100" *ngIf="isSecretarial">
    <app-branch-addresses-form
      [branchAddressesForm]="branchAddressesForm"
    ></app-branch-addresses-form>
  </div>

  <div class="width-100" *ngIf="taxAgentsForm">
    <app-tax-agents-form [taxAgentsForm]="taxAgentsForm"></app-tax-agents-form>
  </div>

  <div
    class="width-100"
    *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
  >
    <app-section-title title="Remarks/ KYC"></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs=""
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="32px"
      class="width-100 margin-bottom-16 relative"
      *ngIf="isSecretarial || isTax || isAudit || isSharedServices"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Remarks -->
        <mat-form-field appearance="outline">
          <mat-label>Remarks</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="remarks"
            rows="4"
            maxlength="1000"
            #remarks
          ></textarea>
          <mat-hint *ngIf="isSecretarial" align="end"
            >{{ remarks.value?.length || 0 }}/1000</mat-hint
          >
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%"></div>
    </div>
  </div>
</form>
