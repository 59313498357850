<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <button
        *ngIf="clientYearDetails"
        mat-stroked-button
        [routerLink]="[
          '/clients',
          clientType,
          'details',
          clientYearDetails.clientBean.id
        ]"
        class="back-button"
      >
        <mat-icon>arrow_back</mat-icon>
        <span class="capitalize">{{ clientYearDetails.clientBean.name }}</span>
      </button>
      <app-page-title
        title="Year {{
          clientYearDetails ? clientYearDetails.year : 'Loading'
        }}"
        subtitle="{{
          clientYearDetails ? clientYearDetails.clientBean.name : 'Loading'
        }}"
      ></app-page-title>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
      <button mat-stroked-button [routerLink]="['edit']">Edit</button>
      <mat-divider vertical style="height: 40px"></mat-divider>
      <button
        mat-stroked-button
        color="warn"
        (click)="showConfirmDeleteDialog()"
      >
        Delete
      </button>
    </div>
  </div>

  <div
    fxLayout="row wrap"
    fxLayoutGap="40px"
    fxLayout.xs="column"
    fxLayoutGap.xs="8px"
    class="margin-bottom-40"
    *ngIf="!clientYearDetailsIsLoading"
  >
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <!-- Secretary Name -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial || isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Secretary Name</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.secretaryName === null ||
            clientYearDetails.secretaryName === ''
              ? 'not-available'
              : ''
          "
        >
          {{
            clientYearDetails.secretaryName
              ? clientYearDetails.secretaryName
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Nature of Business -->
      <div
        fxLayoutGap="8px"
        *ngIf="
          isSecretarial ||
          isTax ||
          isAudit ||
          (isSharedServices && !isFreelancer.value)
        "
      >
        <p fxFlex="calc(50%-40px)" class="bold">Nature of Business</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="!clientYearDetails.natureOfBusiness ? 'not-available' : ''"
          class="capitalize"
        >
          {{
            clientYearDetails.natureOfBusiness
              ? clientYearDetails.natureOfBusiness
              : "Not Available"
          }}
        </p>
      </div>

      <!-- MSIC Code -->
      <div
        fxLayoutGap="8px"
        *ngIf="
          isSecretarial ||
          isTax ||
          isAudit ||
          (isSharedServices && !isFreelancer.value)
        "
      >
        <p fxFlex="calc(50%-40px)" class="bold">MSIC Code</p>
        <div
          fxFlex="calc(50%-40px)"
          *ngIf="
            clientYearDetails.msicCodes &&
            clientYearDetails.msicCodes.length > 0
          "
          fxLayout="row wrap"
          fxLayoutAlign="start center"
        >
          <div
            *ngFor="
              let msicCode of clientYearDetails.msicCodes;
              let isLast = last
            "
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <span>
              {{ msicCode.code }}
            </span>
            <mat-icon
              class="msic-help margin-left-4"
              [matTooltip]="msicCode.description"
              >help_outline</mat-icon
            >
            <span class="margin-right-8">{{ isLast ? "" : ", " }}</span>
          </div>
        </div>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="
            !clientYearDetails.msicCodes ||
            clientYearDetails.msicCodes.length < 1
          "
          class="not-available"
        >
          Not available
        </p>
      </div>

      <!-- Common Seal -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Common Seal</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.commonSeal ? '' : 'not-available'"
        >
          {{
            clientYearDetails.commonSeal
              ? clientYearDetails.commonSeal === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Register Office -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Register Office</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.registerOffice ? '' : 'not-available'"
        >
          {{
            clientYearDetails.registerOffice
              ? clientYearDetails.registerOffice === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Name Tag -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Name Tag</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.nameTag ? '' : 'not-available'"
        >
          {{
            clientYearDetails.nameTag
              ? clientYearDetails.nameTag === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Team PIC -->
      <div fxLayoutGap="8px" *ngIf="isAudit || isSharedServices">
        <p fxFlex="calc(50%-40px)" class="bold">Team PIC</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.teamPIC ? '' : 'not-available'"
        >
          {{
            clientYearDetails.teamPIC
              ? clientYearDetails.teamPIC
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Team Lead -->
      <div fxLayoutGap="8px" *ngIf="isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Team Lead</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.teamLead ? '' : 'not-available'"
        >
          {{
            clientYearDetails.teamLead
              ? clientYearDetails.teamLead
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Secretary Firm Name -->
      <div fxLayoutGap="8px" *ngIf="isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Secretary Firm Name</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.secretaryFirmName ? '' : 'not-available'"
        >
          {{
            clientYearDetails.secretaryFirmName
              ? clientYearDetails.secretaryFirmName
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Secretary Firm Address -->
      <div fxLayoutGap="8px" *ngIf="isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Secretary Firm Address</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.secretaryFirmAddress ? '' : 'not-available'
          "
        >
          {{
            clientYearDetails.secretaryFirmAddress
              ? clientYearDetails.secretaryFirmAddress
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Tax Agent -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial || isSharedServices">
        <p fxFlex="calc(50%-40px)" class="bold">Tax Agent</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.taxAgentName ? '' : 'not-available'"
        >
          {{
            clientYearDetails.taxAgentName
              ? clientYearDetails.taxAgentName
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Last Tax Return Filed Year -->
      <div
        fxLayoutGap="8px"
        *ngIf="isTax || (isSharedServices && !isFreelancer.value)"
      >
        <p fxFlex="calc(50%-40px)" class="bold">Last Tax Return Filed Year</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.lastTaxReturnFiledYear ? '' : 'not-available'
          "
        >
          {{
            clientYearDetails.lastTaxReturnFiledYear
              ? clientYearDetails.lastTaxReturnFiledYear
              : "Not Available"
          }}
        </p>
      </div>
    </div>

    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <!-- Tax Return Submitted Date -->
      <div fxLayoutGap="8px" *ngIf="isTax">
        <p fxFlex="calc(50%-40px)" class="bold">Tax Return Submitted Date</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.taxReturnSubmittedDate"
        >
          {{ clientYearDetails.taxReturnSubmittedDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.taxReturnSubmittedDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- CP500 -->
      <div
        fxLayoutGap="8px"
        *ngIf="(isTax || isSharedServices) && isFreelancer.value"
      >
        <p fxFlex="calc(50%-40px)" class="bold">CP500</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.cp500 === null ||
            clientYearDetails.cp500 === undefined
              ? 'not-available'
              : ''
          "
        >
          {{
            clientYearDetails.cp500 !== null &&
            clientYearDetails.cp500 !== undefined
              ? "RM" + clientYearDetails.cp500
              : "Not Available"
          }}
        </p>
      </div>

      <!-- CP204 -->
      <div fxLayoutGap="8px" *ngIf="isTax && !isFreelancer.value">
        <p fxFlex="calc(50%-40px)" class="bold">CP204</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.cp204 === null ||
            clientYearDetails.cp204 === undefined
              ? 'not-available'
              : ''
          "
        >
          {{
            clientYearDetails.cp204 !== null &&
            clientYearDetails.cp204 !== undefined
              ? "RM" + clientYearDetails.cp204
              : "Not Available"
          }}
        </p>
      </div>

      <!-- CP204A (6th) -->
      <div fxLayoutGap="8px" *ngIf="isTax && !isFreelancer.value">
        <p fxFlex="calc(50%-40px)" class="bold">CP204A (6th)</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.cp204A6th === null ||
            clientYearDetails.cp204A6th === undefined
              ? 'not-available'
              : ''
          "
        >
          {{
            clientYearDetails.cp204A6th !== null &&
            clientYearDetails.cp204A6th !== undefined
              ? "RM" + clientYearDetails.cp204A6th
              : "Not Available"
          }}
        </p>
      </div>

      <!-- CP204A (9th) -->
      <div fxLayoutGap="8px" *ngIf="isTax && !isFreelancer.value">
        <p fxFlex="calc(50%-40px)" class="bold">CP204A (9th)</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="
            clientYearDetails.cp204A9th === null ||
            clientYearDetails.cp204A9th === undefined
              ? 'not-available'
              : ''
          "
        >
          {{
            clientYearDetails.cp204A9th !== null &&
            clientYearDetails.cp204A9th !== undefined
              ? "RM" + clientYearDetails.cp204A9th
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Take Over Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Take Over Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="clientYearDetails.takeOverDate">
          {{ clientYearDetails.takeOverDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.takeOverDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Last Annual Return Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Last Annual Return Date</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.lastAnnualReturnDate"
        >
          {{ clientYearDetails.lastAnnualReturnDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.lastAnnualReturnDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Next Annual Return Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial">
        <p fxFlex="calc(50%-40px)" class="bold">Next Annual Return Due Date</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.nextAnnualReturnDueDate"
        >
          {{ clientYearDetails.nextAnnualReturnDueDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.nextAnnualReturnDueDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Last Audited Period -->
      <div
        fxLayoutGap="8px"
        *ngIf="
          isSecretarial || isAudit || (isSharedServices && !isFreelancer.value)
        "
      >
        <p fxFlex="calc(50%-40px)" class="bold">Last Audited Period</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.lastAuditedPeriodDate"
        >
          {{ clientYearDetails.lastAuditedPeriodDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.lastAuditedPeriodDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Audited Accounts Submission Date (SSM) -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial || isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">
          Audited Accounts Submission Date (SSM)
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.auditedAccountSubmissionDateSsm"
        >
          {{ clientYearDetails.auditedAccountSubmissionDateSsm | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.auditedAccountSubmissionDateSsm"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Management Accounts Received Date -->
      <div fxLayoutGap="8px" *ngIf="isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">
          Management Accounts Received Date
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.mgmtAccountReceivedDate"
        >
          {{ clientYearDetails.mgmtAccountReceivedDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.mgmtAccountReceivedDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Circulation Date -->
      <div fxLayoutGap="8px" *ngIf="isSecretarial || isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Circulation Date</p>
        <p fxFlex="calc(50%-40px)" *ngIf="clientYearDetails.circulationDate">
          {{ clientYearDetails.circulationDate | date }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="!clientYearDetails.circulationDate"
          class="not-available"
        >
          Not Available
        </p>
      </div>

      <!-- Service Type -->
      <div fxLayoutGap="8px" *ngIf="isSharedServices">
        <p fxFlex="calc(50%-40px)" class="bold">Service Type</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.serviceType ? '' : 'not-available'"
        >
          {{
            clientYearDetails.serviceType
              ? displayServiceType(clientYearDetails.serviceType)
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Stocktake -->
      <div fxLayoutGap="8px" *ngIf="isAudit">
        <p fxFlex="calc(50%-40px)" class="bold">Stocktake</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="clientYearDetails.stocktake ? '' : 'not-available'"
        >
          {{
            clientYearDetails.stocktake
              ? clientYearDetails.stocktake === true
                ? "Yes"
                : "No"
              : "Not Available"
          }}
        </p>
      </div>

      <!-- Created Date -->
      <div *ngIf="isSecretarial || isTax || isAudit || isSharedServices">
        <p fxFlex="calc(50%-40px)" class="bold">Created On</p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.createdDate !== null"
        >
          {{ clientYearDetails.createdDate | date: "medium" }}
        </p>
        <p
          fxFlex="calc(50%-40px)"
          *ngIf="clientYearDetails.createdDate === null"
          class="not-available"
        >
          Not Available
        </p>
      </div>
    </div>
  </div>

  <!-- Banks -->
  <app-section-title title="Banks" *ngIf="isAudit"></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container"
    *ngIf="!clientYearDetailsIsLoading && !clientYearDetails.banks && isAudit"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion
    class="margin-bottom-40"
    *ngIf="!clientYearDetailsIsLoading && isAudit"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let bankItem of clientYearDetails.banks; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Bank — {{ bankItem.bank.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Bank Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Bank Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                bankItem.bank.name === null || bankItem.bank.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ bankItem.bank.name ? bankItem.bank.name : "Not Available" }}
            </p>
          </div>

          <!-- Branch -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Branch</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="bankItem.branch === null ? 'not-available' : ''"
            >
              {{ bankItem.branch ? bankItem.branch : "Not Available" }}
            </p>
          </div>

          <!-- Branch Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Branch Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                bankItem.branchAddress === null || bankItem.branchAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                bankItem.branchAddress
                  ? bankItem.branchAddress
                  : "Not Available"
              }}
            </p>
          </div>
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Account Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Account Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="!bankItem.accountType ? 'not-available' : ''"
              class="capitalize"
            >
              {{
                bankItem.accountType
                  ? bankItem.accountType.type.toLowerCase()
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Account Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Account Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                bankItem.accountNumber === null || bankItem.accountNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                bankItem.accountNumber
                  ? bankItem.accountNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Account Usage -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Account Usage</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                bankItem.accountUsage === null || bankItem.accountUsage === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                bankItem.accountUsage ? bankItem.accountUsage : "Not Available"
              }}
            </p>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Directors -->
  <app-section-title title="Directors"></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container"
    *ngIf="!clientYearDetailsIsLoading && !clientYearDetails.directors"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion class="margin-bottom-40" *ngIf="!clientYearDetailsIsLoading">
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="let director of clientYearDetails.directors; let first = first"
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Director — {{ director.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.name === null || director.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.name ? director.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="director.type === null ? 'not-available' : ''"
            >
              {{ director.type ? director.type.type : "Not Available" }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.identificationNumber === null ||
                director.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.identificationNumber
                  ? director.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.nationality === null || director.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.nationality ? director.nationality : "Not Available"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.race === null || director.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.race ? director.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="director.male === null ? 'not-available' : ''"
            >
              {{
                director.male
                  ? director.male == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.phoneNumber === null || director.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.phoneNumber ? director.phoneNumber : "Not Available"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.email === null || director.email === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.email ? director.email : "Not Available" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.privateEmail === null || director.privateEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.privateEmail ? director.privateEmail : "Not Available"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Alternative Director -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Alternative Director</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.alternativeDirector === null ||
                director.alternativeDirector === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.alternativeDirector
                  ? director.alternativeDirector
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Alternative Director -->

          <!-- Start Service/ Business Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.address === null || director.address === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ director.address ? director.address : "Not Available" }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                director.residentialAddress === null ||
                director.residentialAddress === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                director.residentialAddress
                  ? director.residentialAddress
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfPassportExpiry">
              {{ director.dateOfPassportExpiry | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfPassportExpiry"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfBirth">
              {{ director.dateOfBirth | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfBirth"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfAppointment">
              {{ director.dateOfAppointment | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfAppointment"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p fxFlex="calc(50%-40px)" *ngIf="director.dateOfResignation">
              {{ director.dateOfResignation | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!director.dateOfResignation"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <!-- Members -->
  <app-section-title title="Members"></app-section-title>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    class="not-available-container"
    *ngIf="!clientYearDetailsIsLoading && !clientYearDetails.shareholders"
  >
    <p class="no-margin">Not Available</p>
  </div>
  <mat-accordion class="margin-bottom-40" *ngIf="!clientYearDetailsIsLoading">
    <mat-expansion-panel
      class="mat-elevation-z0 expansion-panel"
      *ngFor="
        let shareholder of clientYearDetails.shareholders;
        let first = first
      "
      [ngClass]="{ first: first }"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="panel-title">
          Member — {{ shareholder.name }}</mat-panel-title
        >
      </mat-expansion-panel-header>
      <div
        fxLayout="row wrap"
        fxLayoutGap="40px"
        fxLayout.xs="column"
        fxLayoutGap.xs="8px"
      >
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Name -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.name === null || shareholder.name === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.name ? shareholder.name : "Not Available" }}
            </p>
          </div>

          <!-- ID Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.type === null ? 'not-available' : ''"
            >
              {{ shareholder.type ? shareholder.type.type : "Not Available" }}
            </p>
          </div>

          <!-- ID Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.identificationNumber === null ||
                shareholder.identificationNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.identificationNumber
                  ? shareholder.identificationNumber
                  : "Not Available"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.nationality === null ||
                shareholder.nationality === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.nationality
                  ? shareholder.nationality
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.race === null || shareholder.race === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.race ? shareholder.race : "Not Available" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.isMale == null ? 'not-available' : ''"
            >
              {{
                shareholder.isMale !== null
                  ? shareholder.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.phoneNumber === null ||
                shareholder.phoneNumber === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.phoneNumber
                  ? shareholder.phoneNumber
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.email === null || shareholder.email === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.email ? shareholder.email : "Not Available" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.privateEmail === null ||
                shareholder.privateEmail === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.privateEmail
                  ? shareholder.privateEmail
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div
          fxLayout="column"
          fxFlex.xs="100%"
          fxFlex="calc(50%-40px)"
          class="margin-bottom-24"
          fxLayoutGap="8px"
        >
          <!-- Start Address -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.address === null || shareholder.address === ''
                  ? 'not-available'
                  : ''
              "
            >
              {{ shareholder.address ? shareholder.address : "Not Available" }}
            </p>
          </div>
          <!-- End Address -->

          <!-- Start Passport Expiry Date -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p fxFlex="calc(50%-40px)" *ngIf="shareholder.dateOfPassportExpiry">
              {{ shareholder.dateOfPassportExpiry | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!shareholder.dateOfPassportExpiry"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p fxFlex="calc(50%-40px)" *ngIf="shareholder.dateOfBirthRef">
              {{ shareholder.dateOfBirthRef | date }}
            </p>
            <p
              fxFlex="calc(50%-40px)"
              *ngIf="!shareholder.dateOfBirthRef"
              class="not-available"
            >
              Not Available
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Event Type -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Event Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="!shareholder.eventType ? 'not-available' : ''"
            >
              {{
                shareholder.eventType
                  ? shareholder.eventType?.type
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Event Type -->

          <!-- Start Type of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Type of Shares</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="shareholder.isCash == null ? 'not-available' : ''"
            >
              {{
                shareholder.isCash !== null
                  ? shareholder.isCash == true
                    ? "Cash"
                    : "Non-Cash"
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Type of Shares -->

          <!-- Start Up to Date Number of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">
              Up to Date Number of Shares
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.upToDateNumberOfShares === null ||
                shareholder.upToDateNumberOfShares === undefined
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.upToDateNumberOfShares
                  ? shareholder.upToDateNumberOfShares
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Up to Date Number of Shares -->

          <!-- Start Number of Shares -->
          <div fxLayoutGap="8px">
            <p fxFlex="calc(50%-40px)" class="bold">Number of Shares</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="
                shareholder.numberOfShares === null ||
                shareholder.numberOfShares === undefined
                  ? 'not-available'
                  : ''
              "
            >
              {{
                shareholder.numberOfShares
                  ? shareholder.numberOfShares
                  : "Not Available"
              }}
            </p>
          </div>
          <!-- End Number of Shares -->
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <app-section-title title="Files"></app-section-title>
  <app-file-organizer
    [clientType]="clientType"
    [yearID]="yearID"
    [isCreateUpdate]="false"
  ></app-file-organizer>
</section>
