import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArticlesService } from 'src/app/core/services/articles/articles.service';
import { Article } from 'src/app/shared/models/article/article.model';
import { ArticleCategory } from 'src/app/shared/models/article-category/article-category.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
})
export class ArticleDetailsComponent implements OnInit {
  // Loading
  isLoading = false;

  // Variable
  article!: Article;
  imageUrl!: string;

  constructor(
    private snackBar: MatSnackBar,
    private articlesService: ArticlesService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getArticle(id);
      },
    });
  }

  ngOnInit(): void {}

  getArticle(id: number): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: 3000 });
    this.articlesService.getArticleDetails(id).subscribe({
      next: (article: Article) => {
        this.isLoading = false;
        this.article = article;
        this.snackBar.dismiss();
        this.getImageUrl();
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.isLoading = false;
      },
    });
  }

  getCategoriesStr(articleCategories: Array<ArticleCategory>): string[] {
    const categoriesStr: Array<string> = [];
    for (const articleCategory of articleCategories) {
      categoriesStr.push(articleCategory.name.toLowerCase());
    }
    return categoriesStr;
  }

  getImageUrl(): void {
    if (this.article.fileUrl) {
      this.imageUrl = `${environment.baseUrl}/api/core/articles/downloadFile/${this.article.id}`;
    }
  }
}
