<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="margin-top-16 margin-bottom-24"
>
  <!-- Main Details -->
  <div class="margin-bottom-16" *ngIf="isSecretarial">
    <app-section-title title="Main Details"></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs=""
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      fxLayoutGap="32px grid"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Company Number (New) -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Company Number (New)</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyNumberNew
            }"
          >
            {{
              client.companyNumberNew ? client.companyNumberNew : "Not filled"
            }}
          </p>
        </div>

        <!-- Company Number (Old) -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Company Number (Old)</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyNumberOld
            }"
          >
            {{
              client.companyNumberOld ? client.companyNumberOld : "Not filled"
            }}
          </p>
        </div>

        <!-- Name -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyName
            }"
          >
            {{ client.companyName ? client.companyName : "Not filled" }}
          </p>
        </div>

        <!-- Formerly Known As (F.K.A) -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Formerly Known As (F.K.A)</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.formerlyKnownAs
            }"
          >
            {{ client.formerlyKnownAs ? client.formerlyKnownAs : "Not filled" }}
          </p>
        </div>

        <!-- Group -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Group</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.group
            }"
          >
            {{ client.group ? client.group : "Not filled" }}
          </p>
        </div>

        <!-- Branch/Franchisees Group -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Branch/Franchisees Group</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.clientSubGroup
            }"
          >
            {{ client.clientSubGroup ? client.clientSubGroup : "Not filled" }}
          </p>
        </div>

        <!-- File Reference -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">File Reference</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.fileRef
            }"
          >
            {{ client.fileRef ? client.fileRef : "Not filled" }}
          </p>
        </div>

        <!-- Incorporated by us -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Incorporated by us</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.incorporatedByUs
            }"
          >
            {{
              client.incorporatedByUs
                ? client.incorporatedByUs === true
                  ? "Yes"
                  : "No"
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Company Office Number -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Company Office Number</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyOfficeNo
            }"
          >
            {{ client.companyOfficeNo ? client.companyOfficeNo : "Not filled" }}
          </p>
        </div>

        <!-- Company Email -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyEmail
            }"
          >
            {{ client.companyEmail ? client.companyEmail : "Not filled" }}
          </p>
        </div>

        <!-- Company Website -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Company Website</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.companyWebsite
            }"
          >
            {{ client.companyWebsite ? client.companyWebsite : "Not filled" }}
          </p>
        </div>

        <!-- Referred by -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Referred by</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.referredBy
            }"
          >
            {{ client.referredBy ? client.referredBy : "Not filled" }}
          </p>
        </div>
      </div>
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Registered Address -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Registered Address</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': client.registeredAddress === ''
            }"
          >
            {{
              client.registeredAddress !== ""
                ? client.registeredAddress
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Business Address -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Business Address</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': client.correspondentAddress === ''
            }"
          >
            {{
              client.correspondentAddress !== ""
                ? client.correspondentAddress
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Registered Book Kept Address -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">
            Registered Book Kept Address
          </p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': client.registeredBookKeptAddress === ''
            }"
          >
            {{
              client.registeredBookKeptAddress !== ""
                ? client.registeredBookKeptAddress
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Financial Records Kept Address -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">
            Financial Records Kept Address
          </p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': client.financialRecordsKeptAddress === ''
            }"
          >
            {{
              client.financialRecordsKeptAddress !== ""
                ? client.financialRecordsKeptAddress
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Last Account Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Last Account Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.dateOfLastAccount
            }"
          >
            {{
              client.dateOfLastAccount
                ? (client.dateOfLastAccount | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Next Account Due Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Next Account Due Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.dateOfNextAccountDue
            }"
          >
            {{
              client.dateOfNextAccountDue
                ? (client.dateOfNextAccountDue | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Incorporation Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Incorporation Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.incorporationDate
            }"
          >
            {{
              client.incorporationDate
                ? (client.incorporationDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Start AFS - Circulation Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">AFS - Circulation Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.circulationDate
            }"
          >
            {{
              client.circulationDate
                ? (client.circulationDate | date)
                : "Not filled"
            }}
          </p>
        </div>
        <!-- End AFS - Circulation Date -->

        <!-- Start Charge/ Discharge -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Charge/ Discharge</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.chargeOrDischarge
            }"
          >
            {{
              client.chargeOrDischarge ? client.chargeOrDischarge : "Not filled"
            }}
          </p>
        </div>
        <!-- End Charge/ Discharge -->

        <!-- Start Charge Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Charge Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.chargeDate
            }"
          >
            {{ client.chargeDate ? (client.chargeDate | date) : "Not filled" }}
          </p>
        </div>
        <!-- End Charge Date -->

        <!-- Start Discharge Date -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Discharge Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.dischargeDate
            }"
          >
            {{
              client.dischargeDate
                ? (client.dischargeDate | date)
                : "Not filled"
            }}
          </p>
        </div>
        <!-- End Discharge Date -->

        <!-- Year End -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Year End</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.yearEndDay || !client.yearEndMonth
            }"
          >
            {{
              client.yearEndDay && client.yearEndMonth
                ? client.yearEndDay + "/" + client.yearEndMonth
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Remarks -->
        <div>
          <p fxFlex="calc(50%-40px)" class="bold">Remarks</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !client.remarks
            }"
          >
            {{ client.remarks ? client.remarks : "Not filled" }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-divider *ngIf="isSecretarial"></mat-divider>

  <!-- Contact Details -->
  <div class="margin-top-16 margin-bottom-16" *ngIf="contactPersons">
    <app-section-title title="Often Contact Persons"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let contactPerson of contactPersons"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': contactPerson.name === ''
              }"
            >
              {{
                contactPerson.name !== "" ? contactPerson.name : "Not filled"
              }}
            </p>
          </div>

          <!-- Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': contactPerson.phoneNumber === ''
              }"
            >
              {{
                contactPerson.phoneNumber !== ""
                  ? contactPerson.phoneNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Contact Person Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Contact Person Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': contactPerson.contactPersonTypeStr === ''
              }"
            >
              {{
                contactPerson.contactPersonTypeStr !== ""
                  ? contactPerson.contactPersonTypeStr
                  : "Not filled"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Business Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Business Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': contactPerson.businessEmail === ''
              }"
              class="ellipsis"
            >
              {{
                contactPerson.businessEmail !== ""
                  ? contactPerson.businessEmail
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Personal Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Personal Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': contactPerson.personalEmail === ''
              }"
              class="ellipsis"
            >
              {{
                contactPerson.personalEmail !== ""
                  ? contactPerson.personalEmail
                  : "Not filled"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-divider *ngIf="contactPersons"></mat-divider>

  <!-- Start Branch Addresses -->
  <div class="margin-top-16 margin-bottom-16" *ngIf="branchAddresses">
    <app-section-title title="Branch Addresses"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let branchAddress of branchAddresses"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Branch Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !branchAddress.branchNumber
              }"
            >
              {{
                branchAddress.branchNumber
                  ? branchAddress.branchNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': branchAddress.address === ''
              }"
            >
              {{
                branchAddress.address !== ""
                  ? branchAddress.address
                  : "Not filled"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': branchAddress.contactNumber === ''
              }"
            >
              {{
                branchAddress.contactNumber !== ""
                  ? branchAddress.contactNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': branchAddress.email === ''
              }"
              class="ellipsis"
            >
              {{
                branchAddress.email !== "" ? branchAddress.email : "Not filled"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Branch Addresses -->

  <mat-divider *ngIf="isSecretarial"></mat-divider>

  <!-- Year Details -->
  <div class="margin-bottom-16" [ngClass]="{ 'margin-top-16': isSecretarial }">
    <app-section-title
      [title]="isSecretarial ? 'Additional Details' : 'Year Details'"
    ></app-section-title>
    <div
      fxLayout.xs="column"
      fxLayoutAlign.xs="stretch"
      fxLayoutGap.xs=""
      fxLayout="row wrap"
      fxLayoutAlign="start start"
      fxLayoutGap="32px grid"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Secretary Name -->
        <div *ngIf="isSecretarial || isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Secretary Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.secretaryName
            }"
          >
            {{
              clientYearDetails.secretaryName
                ? clientYearDetails.secretaryName
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Nature of Business -->
        <div
          *ngIf="
            isSecretarial ||
            isTax ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <p fxFlex="calc(50%-40px)" class="bold">Nature of Business</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.natureOfBusiness
            }"
          >
            {{
              clientYearDetails.natureOfBusiness
                ? clientYearDetails.natureOfBusiness
                : "Not filled"
            }}
          </p>
        </div>

        <!-- MSIC Code -->
        <div
          *ngIf="
            isSecretarial ||
            isTax ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <p fxFlex="calc(50%-40px)" class="bold">MSIC Code</p>
          <div
            fxFlex="calc(50%-40px)"
            *ngIf="msicCodes.length"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
          >
            <div *ngFor="let msicCode of msicCodes; let isLast = last">
              <span>
                {{ msicCode.code }}
              </span>
              <span class="margin-right-8">{{ isLast ? "" : ", " }}</span>
            </div>
          </div>
          <p
            fxFlex="calc(50%-40px)"
            *ngIf="msicCodes.length < 1"
            class="not-available"
          >
            Not filled
          </p>
        </div>

        <!-- Common Seal -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Common Seal</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.commonSeal === null
            }"
          >
            {{
              clientYearDetails.commonSeal !== null
                ? clientYearDetails.commonSeal
                  ? "Yes"
                  : "No"
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Register Office -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Register Office</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.registerOffice === null
            }"
          >
            {{
              clientYearDetails.registerOffice !== null
                ? clientYearDetails.registerOffice
                  ? "Yes"
                  : "No"
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Name Tag -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Name Tag</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.nameTag === null
            }"
          >
            {{
              clientYearDetails.nameTag !== null
                ? clientYearDetails.nameTag
                  ? "Yes"
                  : "No"
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Team PIC -->
        <div *ngIf="isAudit || isSharedServices">
          <p fxFlex="calc(50%-40px)" class="bold">Team PIC</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.teamPIC
            }"
          >
            {{
              clientYearDetails.teamPIC
                ? clientYearDetails.teamPIC
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Tax Agent's Name -->
        <div *ngIf="isSecretarial || isSharedServices">
          <p fxFlex="calc(50%-40px)" class="bold">Tax Agent's Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.taxAgentName
            }"
          >
            {{
              clientYearDetails.taxAgentName
                ? clientYearDetails.taxAgentName
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Team Lead -->
        <div *ngIf="isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Team Lead</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.teamLead
            }"
          >
            {{
              clientYearDetails.teamLead
                ? clientYearDetails.teamPIC
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Secretary Firm Name -->
        <div *ngIf="isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Secretary Firm Name</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.secretaryFirmName
            }"
          >
            {{
              clientYearDetails.secretaryFirmName
                ? clientYearDetails.secretaryFirmName
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Secretary Firm Address -->
        <div *ngIf="isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Secretary Firm Address</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.secretaryFirmAddress
            }"
          >
            {{
              clientYearDetails.secretaryFirmAddress
                ? clientYearDetails.secretaryFirmAddress
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Last Tax Return Filed Year -->
        <div *ngIf="isTax || (isSharedServices && !isFreelancer.value)">
          <p fxFlex="calc(50%-40px)" class="bold">Last Tax Return Filed Year</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.lastTaxReturnFiledYear
            }"
          >
            {{
              clientYearDetails.lastTaxReturnFiledYear
                ? clientYearDetails.lastTaxReturnFiledYear
                : "Not filled"
            }}
          </p>
        </div>
      </div>
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Tax Return Submitted Date -->
        <div *ngIf="isTax">
          <p fxFlex="calc(50%-40px)" class="bold">Tax Return Submitted Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.taxReturnSubmittedDateRef
            }"
          >
            {{
              clientYearDetails.taxReturnSubmittedDateRef
                ? (clientYearDetails.taxReturnSubmittedDateRef | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Take Over Date -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Take Over Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.takeOverDate === null
            }"
          >
            {{
              clientYearDetails.takeOverDate !== null
                ? (clientYearDetails.takeOverDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Last Annual Return Date -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Last Annual Return Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.lastAnnualReturnDate === null
            }"
          >
            {{
              clientYearDetails.lastAnnualReturnDate !== null
                ? (clientYearDetails.lastAnnualReturnDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Next Annual Return Date -->
        <div *ngIf="isSecretarial">
          <p fxFlex="calc(50%-40px)" class="bold">Next Annual Return Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available':
                clientYearDetails.nextAnnualReturnDueDate === null
            }"
          >
            {{
              clientYearDetails.nextAnnualReturnDueDate !== null
                ? (clientYearDetails.nextAnnualReturnDueDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Last Audited Period -->
        <div
          *ngIf="
            isSecretarial ||
            isAudit ||
            (isSharedServices && !isFreelancer.value)
          "
        >
          <p fxFlex="calc(50%-40px)" class="bold">Last Audited Period</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.lastAuditedPeriodDate === null
            }"
          >
            {{
              clientYearDetails.lastAuditedPeriodDate !== null
                ? (clientYearDetails.lastAuditedPeriodDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Audited Accounts Submission Date (SSM) -->
        <div *ngIf="isSecretarial || isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">
            Audited Accounts Submission Date (SSM)
          </p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available':
                clientYearDetails.auditedAccountSubmissionDateSsm === null
            }"
          >
            {{
              clientYearDetails.auditedAccountSubmissionDateSsm !== null
                ? (clientYearDetails.auditedAccountSubmissionDateSsm | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Circulation Date -->
        <div *ngIf="isSecretarial || isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Circulation Date</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.circulationDate === null
            }"
          >
            {{
              clientYearDetails.circulationDate !== null
                ? (clientYearDetails.circulationDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- CP500 -->
        <div *ngIf="isFreelancer.value">
          <p fxFlex="calc(50%-40px)" class="bold">CP500</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.cp500 === null
            }"
          >
            {{
              clientYearDetails.cp500 !== null
                ? clientYearDetails.cp500
                : "Not filled"
            }}
          </p>
        </div>

        <!-- CP204 -->
        <div *ngIf="isTax && !isFreelancer.value">
          <p fxFlex="calc(50%-40px)" class="bold">CP204</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.cp204
            }"
          >
            {{
              clientYearDetails.cp204 ? clientYearDetails.cp204 : "Not filled"
            }}
          </p>
        </div>

        <!-- CP204 (6th) -->
        <div *ngIf="isTax && !isFreelancer.value">
          <p fxFlex="calc(50%-40px)" class="bold">CP204 (6th)</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.cp204A6th
            }"
          >
            {{
              clientYearDetails.cp204A6th
                ? clientYearDetails.cp204A6th
                : "Not filled"
            }}
          </p>
        </div>

        <!-- CP204 (9th) -->
        <div *ngIf="isTax && !isFreelancer.value">
          <p fxFlex="calc(50%-40px)" class="bold">CP204 (9th)</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': !clientYearDetails.cp204A9th
            }"
          >
            {{
              clientYearDetails.cp204A9th
                ? clientYearDetails.cp204A9th
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Management Accounts Received Date -->
        <div *ngIf="isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">
            Management Accounts Received Date
          </p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available':
                clientYearDetails.mgmtAccountReceivedDate === null
            }"
          >
            {{
              clientYearDetails.mgmtAccountReceivedDate !== null
                ? (clientYearDetails.mgmtAccountReceivedDate | date)
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Stocktake -->
        <div *ngIf="isAudit">
          <p fxFlex="calc(50%-40px)" class="bold">Stocktake</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.stocktake === null
            }"
          >
            {{
              clientYearDetails.stocktake !== null
                ? clientYearDetails.stocktake
                  ? "Yes"
                  : "No"
                : "Not filled"
            }}
          </p>
        </div>

        <!-- Service Type -->
        <div *ngIf="isSharedServices">
          <p fxFlex="calc(50%-40px)" class="bold">Service Type</p>
          <p
            fxFlex="calc(50%-40px)"
            [ngClass]="{
              'not-available': clientYearDetails.serviceType === null
            }"
          >
            {{
              clientYearDetails.serviceType !== null
                ? displayServiceType(clientYearDetails.serviceTypeRef)
                : "Not filled"
            }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <mat-divider *ngIf="isAudit || isSecretarial"></mat-divider>

  <!-- Bank Details -->
  <div
    class="margin-top-16 margin-bottom-16"
    *ngIf="(isAudit || isSecretarial) && banksForm"
  >
    <app-section-title title="Banks"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let bankItem of bankItems"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Bank Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !bankItem.bankNameStr
              }"
            >
              {{ bankItem.bankNameStr ? bankItem.bankNameStr : "Not filled" }}
            </p>
          </div>

          <!-- Branch -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Branch</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': bankItem.branch === ''
              }"
            >
              {{ bankItem.branch !== "" ? bankItem.branch : "Not filled" }}
            </p>
          </div>

          <!-- Branch Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Branch Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': bankItem.branchAddress === ''
              }"
            >
              {{
                bankItem.branchAddress !== ""
                  ? bankItem.branchAddress
                  : "Not filled"
              }}
            </p>
          </div>
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Account Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Account Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': bankItem.bankAccountTypeStr === ''
              }"
              class="capitalize"
            >
              {{
                bankItem.bankAccountTypeStr !== ""
                  ? bankItem.bankAccountTypeStr
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Account Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Account Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': bankItem.accountNumber === ''
              }"
            >
              {{
                bankItem.accountNumber !== ""
                  ? bankItem.accountNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Account Usage -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Account Usage</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': bankItem.accountUsage === ''
              }"
            >
              {{
                bankItem.accountUsage !== ""
                  ? bankItem.accountUsage
                  : "Not filled"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="banksForm && bankItems.length < 1">
      No Banks added
    </p>
  </div>

  <mat-divider *ngIf="!isFreelancer.value"></mat-divider>

  <!-- Start Directors Details -->
  <div class="margin-top-16 margin-bottom-16" *ngIf="!isFreelancer.value">
    <app-section-title title="Directors"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let director of directors"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.name
              }"
            >
              {{ director.name ? director.name : "Not filled" }}
            </p>
          </div>

          <!-- Identification Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.identificationTypeStr
              }"
            >
              {{
                director.identificationTypeStr
                  ? director.identificationTypeStr
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Identification Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.identificationNumber
              }"
            >
              {{
                director.identificationNumber
                  ? director.identificationNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.nationality
              }"
              class="ellipsis"
            >
              {{ director.nationality ? director.nationality : "Not filled" }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.race
              }"
              class="ellipsis"
            >
              {{ director.race ? director.race : "Not filled" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': director.male === null
              }"
              class="ellipsis"
            >
              {{
                director.male !== null
                  ? director.male == true
                    ? "Male"
                    : "Female"
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.phoneNumber
              }"
              class="ellipsis"
            >
              {{ director.phoneNumber ? director.phoneNumber : "Not filled" }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Company Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.email
              }"
              class="ellipsis"
            >
              {{ director.email ? director.email : "Not filled" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.privateEmail
              }"
              class="ellipsis"
            >
              {{ director.privateEmail ? director.privateEmail : "Not filled" }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Alternative Director -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Alternative Director</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.alternativeDirector
              }"
              class="ellipsis"
            >
              {{
                director.alternativeDirector
                  ? director.alternativeDirector
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Alternative Director -->

          <!-- Start Service/ Business Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.address
              }"
            >
              {{ director.address ? director.address : "Not filled" }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.residentialAddress
              }"
            >
              {{
                director.residentialAddress
                  ? director.residentialAddress
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.dateOfPassportExpiryRef
              }"
            >
              {{
                director.dateOfPassportExpiryRef
                  ? (director.dateOfPassportExpiryRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.dateOfBirthRef
              }"
            >
              {{
                director.dateOfBirthRef
                  ? (director.dateOfBirthRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.dateOfAppointmentRef
              }"
            >
              {{
                director.dateOfAppointmentRef
                  ? (director.dateOfAppointmentRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !director.dateOfResignationRef
              }"
            >
              {{
                director.dateOfResignationRef
                  ? (director.dateOfResignationRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Directors Details -->

  <mat-divider *ngIf="!isFreelancer.value"></mat-divider>

  <!-- Members Details -->
  <div class="margin-top-16 margin-bottom-16" *ngIf="!isFreelancer.value">
    <app-section-title title="Members"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let member of members"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.name
              }"
            >
              {{ member.name ? member.name : "Not filled" }}
            </p>
          </div>

          <!-- Identification Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.identificationTypeStr
              }"
            >
              {{
                member.identificationTypeStr
                  ? member.identificationTypeStr
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Identification Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.identificationNumber
              }"
            >
              {{
                member.identificationNumber
                  ? member.identificationNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.nationality
              }"
              class="ellipsis"
            >
              {{ member.nationality ? member.nationality : "Not filled" }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.race
              }"
              class="ellipsis"
            >
              {{ member.race ? member.race : "Not filled" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': member.isMale === null
              }"
              class="ellipsis"
            >
              {{
                member.isMale !== null
                  ? member.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.phoneNumber
              }"
              class="ellipsis"
            >
              {{ member.phoneNumber ? member.phoneNumber : "Not filled" }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.email
              }"
              class="ellipsis"
            >
              {{ member.email ? member.email : "Not filled" }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.privateEmail
              }"
              class="ellipsis"
            >
              {{ member.privateEmail ? member.privateEmail : "Not filled" }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.address
              }"
            >
              {{ member.address ? member.address : "Not filled" }}
            </p>
          </div>

          <!-- Start Passport Expiry Date -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.dateOfPassportExpiryRef
              }"
            >
              {{
                member.dateOfPassportExpiryRef
                  ? (member.dateOfPassportExpiryRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.dateOfBirthRef
              }"
            >
              {{
                member.dateOfBirthRef
                  ? (member.dateOfBirthRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Event Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Event Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.eventTypeStr
              }"
              class="ellipsis"
            >
              {{ member.eventTypeStr ? member.eventTypeStr : "Not filled" }}
            </p>
          </div>
          <!-- End Event Type -->

          <!-- Start Types of Share -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Types of Share</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.isCash
              }"
              class="ellipsis"
            >
              {{
                member.isCash
                  ? member.isCash == true
                    ? "Cash"
                    : "Non-Cash"
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Types of Share -->

          <!-- Start Up to Date Numbers of Shares -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Up to Date Numbers of Shares
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.upToDateNumberOfShares
              }"
            >
              {{
                member.upToDateNumberOfShares
                  ? member.upToDateNumberOfShares
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Up to Date Numbers of Shares -->

          <!-- Start Share Amount in Unit -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Share Amount in Unit (RM)
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !member.numberOfShares
              }"
            >
              {{ member.numberOfShares ? member.numberOfShares : "Not filled" }}
            </p>
          </div>
          <!-- End Share Amount in Unit -->
        </div>
      </div>
    </div>
  </div>

  <mat-divider *ngIf="!isFreelancer.value && secretariesForm"></mat-divider>

  <!-- Start Secretaries Details -->
  <div
    class="margin-top-16 margin-bottom-16"
    *ngIf="!isFreelancer.value && secretariesForm"
  >
    <app-section-title title="Secretaries"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let secretary of secretaries"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.name === ''
              }"
            >
              {{ secretary.name !== "" ? secretary.name : "Not filled" }}
            </p>
          </div>

          <!-- Identification Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.identificationTypeStr === ''
              }"
            >
              {{
                secretary.identificationTypeStr !== ""
                  ? secretary.identificationTypeStr
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Identification Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.identificationNumber === ''
              }"
            >
              {{
                secretary.identificationNumber !== ""
                  ? secretary.identificationNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.nationality === ''
              }"
              class="ellipsis"
            >
              {{
                secretary.nationality !== ""
                  ? secretary.nationality
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.race === ''
              }"
              class="ellipsis"
            >
              {{ secretary.race !== "" ? secretary.race : "Not filled" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.isMale == null
              }"
              class="ellipsis"
            >
              {{
                secretary.isMale !== null
                  ? secretary.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.phoneNumber
              }"
              class="ellipsis"
            >
              {{ secretary.phoneNumber ? secretary.phoneNumber : "Not filled" }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Company Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.companyEmail === ''
              }"
              class="ellipsis"
            >
              {{
                secretary.companyEmail !== ""
                  ? secretary.companyEmail
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Professional Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Professional Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.professionalTypeStr
              }"
              class="ellipsis"
            >
              {{
                secretary.professionalTypeStr
                  ? secretary.professionalTypeStr
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Professional Type -->

          <!-- Start Licensed Secretary No. or Membership No. -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Licensed Secretary No. or Membership No.
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.secretaryOrMembershipNo === ''
              }"
              class="ellipsis"
            >
              {{
                secretary.secretaryOrMembershipNo !== ""
                  ? secretary.secretaryOrMembershipNo
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Licensed Secretary No. or Membership No. -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Service/ Business Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.businessAddress === ''
              }"
            >
              {{
                secretary.businessAddress !== ""
                  ? secretary.businessAddress
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': secretary.residentialAddress === ''
              }"
            >
              {{
                secretary.residentialAddress !== ""
                  ? secretary.residentialAddress
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.dateOfPassportExpiryRef
              }"
            >
              {{
                secretary.dateOfPassportExpiryRef
                  ? (secretary.dateOfPassportExpiryRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.dateOfBirthRef
              }"
            >
              {{
                secretary.dateOfBirthRef
                  ? (secretary.dateOfBirthRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.dateOfAppointmentRef
              }"
            >
              {{
                secretary.dateOfAppointmentRef
                  ? (secretary.dateOfAppointmentRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !secretary.dateOfResignationRef
              }"
            >
              {{
                secretary.dateOfResignationRef
                  ? (secretary.dateOfResignationRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="secretariesForm && secretaries.length < 1">
      No secretaries added
    </p>
  </div>
  <!-- End Secretaries Details -->

  <mat-divider *ngIf="!isFreelancer.value && managersForm"></mat-divider>

  <!-- Start Managers Details -->
  <div
    class="margin-top-16 margin-bottom-16"
    *ngIf="!isFreelancer.value && managersForm"
  >
    <app-section-title title="Managers"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let manager of managers"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.name === ''
              }"
            >
              {{ manager.name !== "" ? manager.name : "Not filled" }}
            </p>
          </div>

          <!-- Identification Type -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Type</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.identificationTypeStr === ''
              }"
            >
              {{
                manager.identificationTypeStr !== ""
                  ? manager.identificationTypeStr
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Identification Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Identification Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.identificationNumber === ''
              }"
            >
              {{
                manager.identificationNumber !== ""
                  ? manager.identificationNumber
                  : "Not filled"
              }}
            </p>
          </div>

          <!-- Start Nationality -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Nationality</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.nationality === ''
              }"
              class="ellipsis"
            >
              {{
                manager.nationality !== "" ? manager.nationality : "Not filled"
              }}
            </p>
          </div>
          <!-- End Nationality -->

          <!-- Start Race -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Race</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.race === ''
              }"
              class="ellipsis"
            >
              {{ manager.race !== "" ? manager.race : "Not filled" }}
            </p>
          </div>
          <!-- End Race -->

          <!-- Start Gender -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Gender</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.isMale == null
              }"
              class="ellipsis"
            >
              {{
                manager.isMale !== null
                  ? manager.isMale == true
                    ? "Male"
                    : "Female"
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Phone Number -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !manager.phoneNumber
              }"
              class="ellipsis"
            >
              {{ manager.phoneNumber ? manager.phoneNumber : "Not filled" }}
            </p>
          </div>
          <!-- End Gender -->

          <!-- Start Company Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.companyEmail === ''
              }"
              class="ellipsis"
            >
              {{
                manager.companyEmail !== ""
                  ? manager.companyEmail
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.privateEmail === ''
              }"
              class="ellipsis"
            >
              {{
                manager.privateEmail !== ""
                  ? manager.privateEmail
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Private Email -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Service/ Business Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">
              Service/ Business Address
            </p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.businessAddress === ''
              }"
            >
              {{
                manager.businessAddress !== ""
                  ? manager.businessAddress
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Service/ Business Address -->

          <!-- Start Residential Address -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Residential Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': manager.residentialAddress === ''
              }"
            >
              {{
                manager.residentialAddress !== ""
                  ? manager.residentialAddress
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Residential Address -->

          <!-- Start Passport Expiry Date -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Passport Expiry Date</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !manager.dateOfPassportExpiryRef
              }"
            >
              {{
                manager.dateOfPassportExpiryRef
                  ? (manager.dateOfPassportExpiryRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Passport Expiry Date -->

          <!-- Start Date of Birth -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Date of Birth</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !manager.dateOfBirthRef
              }"
            >
              {{
                manager.dateOfBirthRef
                  ? (manager.dateOfBirthRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Date of Birth -->

          <!-- Start Appointment On -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !manager.dateOfAppointmentRef
              }"
            >
              {{
                manager.dateOfAppointmentRef
                  ? (manager.dateOfAppointmentRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div *ngIf="isSecretarial">
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !manager.dateOfResignationRef
              }"
            >
              {{
                manager.dateOfResignationRef
                  ? (manager.dateOfResignationRef | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="managersForm && managers.length < 1">
      No managers added
    </p>
  </div>
  <!-- End Managers Details -->

  <mat-divider *ngIf="!isFreelancer.value && auditorsForm"></mat-divider>

  <!-- Start Auditors Details -->
  <div
    class="margin-top-16 margin-bottom-16"
    *ngIf="!isFreelancer.value && auditorsForm"
  >
    <app-section-title title="Auditors"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let auditor of auditors"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Firm Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Firm Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': auditor.firmName === ''
              }"
            >
              {{ auditor.firmName !== "" ? auditor.firmName : "Not filled" }}
            </p>
          </div>
          <!-- End Firm Name -->

          <!-- Start Firm No -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Firm No.</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': auditor.firmNo === ''
              }"
            >
              {{ auditor.firmNo !== "" ? auditor.firmNo : "Not filled" }}
            </p>
          </div>
          <!-- End Firm No -->

          <!-- Start Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !auditor.firmContactNumber
              }"
              class="ellipsis"
            >
              {{
                auditor.firmContactNumber
                  ? auditor.firmContactNumber
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Phone Number -->

          <!-- Start Firm Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Firm Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': auditor.firmEmail === ''
              }"
              class="ellipsis"
            >
              {{ auditor.firmEmail !== "" ? auditor.firmEmail : "Not filled" }}
            </p>
          </div>
          <!-- End Firm Email -->

          <!-- Start PIC Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">PIC Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': auditor.picName === ''
              }"
              class="ellipsis"
            >
              {{ auditor.picName !== "" ? auditor.picName : "Not filled" }}
            </p>
          </div>
          <!-- End PIC Name -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Firm Address -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Firm Address</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': auditor.firmAddress === ''
              }"
            >
              {{
                auditor.firmAddress !== "" ? auditor.firmAddress : "Not filled"
              }}
            </p>
          </div>
          <!-- End Firm Address -->
          <!-- Start Appointment On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !auditor.appointmentOnDateStr
              }"
            >
              {{
                auditor.appointmentOnDateStr
                  ? (auditor.appointmentOnDateStr | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !auditor.resignationOnDateStr
              }"
            >
              {{
                auditor.resignationOnDateStr
                  ? (auditor.resignationOnDateStr | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="auditorsForm && auditors.length < 1">
      No auditors added
    </p>
  </div>
  <!-- End Auditors Details -->

  <mat-divider
    *ngIf="(isSharedServices || isSecretarial) && taxAgentsForm"
  ></mat-divider>

  <!-- Start Tax Agents Details -->
  <div
    class="margin-top-16 margin-bottom-16"
    *ngIf="(isSharedServices || isSecretarial) && taxAgentsForm"
  >
    <app-section-title title="Tax Agents"></app-section-title>
    <div fxLayout="column" fxLayoutGap="24px">
      <div
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs=""
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="32px grid"
        *ngFor="let taxAgent of taxAgents"
      >
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Company Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': taxAgent.companyName === ''
              }"
            >
              {{
                taxAgent.companyName !== ""
                  ? taxAgent.companyName
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Company Name -->

          <!-- Start PIC Name -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">PIC Name</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': taxAgent.picName === ''
              }"
            >
              {{ taxAgent.picName !== "" ? taxAgent.picName : "Not filled" }}
            </p>
          </div>
          <!-- End PIC Name -->

          <!-- Start License No -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">License No.</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': taxAgent.licenseNo === ''
              }"
            >
              {{
                taxAgent.licenseNo !== "" ? taxAgent.licenseNo : "Not filled"
              }}
            </p>
          </div>
          <!-- End License No. -->

          <!-- Start Phone Number -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Phone Number</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !taxAgent.telNo
              }"
              class="ellipsis"
            >
              {{ taxAgent.telNo ? taxAgent.telNo : "Not filled" }}
            </p>
          </div>
          <!-- End Phone Number -->
        </div>
        <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
          <!-- Start Company Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Company Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': taxAgent.companyEmail === ''
              }"
              class="ellipsis"
            >
              {{
                taxAgent.companyEmail !== ""
                  ? taxAgent.companyEmail
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Company Email -->

          <!-- Start Private Email -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Private Email</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': taxAgent.privateEmail === ''
              }"
              class="ellipsis"
            >
              {{
                taxAgent.privateEmail !== ""
                  ? taxAgent.privateEmail
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Private Email -->

          <!-- Start Appointment On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Appointment On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !taxAgent.appointmentOnDateStr
              }"
            >
              {{
                taxAgent.appointmentOnDateStr
                  ? (taxAgent.appointmentOnDateStr | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Appointment On -->

          <!-- Start Resignation On -->
          <div>
            <p fxFlex="calc(50%-40px)" class="bold">Resignation On</p>
            <p
              fxFlex="calc(50%-40px)"
              [ngClass]="{
                'not-available': !taxAgent.resignationOnDateStr
              }"
            >
              {{
                taxAgent.resignationOnDateStr
                  ? (taxAgent.resignationOnDateStr | date)
                  : "Not filled"
              }}
            </p>
          </div>
          <!-- End Resignation On -->
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="taxAgentsForm && taxAgents.length < 1">
      No Tax Agents added
    </p>
  </div>
  <!-- End Tax Agents Details -->

  <mat-divider></mat-divider>

  <div class="margin-top-16 margin-bottom-16">
    <app-section-title title="Files"></app-section-title>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="16px">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let file of pendingFiles; let index = index"
        class="pending-file"
        ngClass="margin-bottom-16"
        ngClass.xs=""
      >
        <div fxLayout="column">
          <p class="bold no-margin ellipsis">
            {{ file.file.name }}
          </p>
          <small class="ellipsis">{{ file.folderName }}</small>
          <small>{{ file.file.size / 1000000 | number: "1.2-2" }}mb</small>
        </div>
      </div>
    </div>
    <p class="not-available" *ngIf="!pendingFiles.length">No files selected</p>
  </div>
</section>
