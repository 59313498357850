<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <!-- Start Back Button -->
    <button mat-stroked-button [routerLink]="['/articles']" class="back-button">
      <mat-icon>arrow_back</mat-icon>
      <span>Articles</span>
    </button>
    <!-- End Back Button -->
  </div>

  <!-- Start Title -->
  <app-page-title
    title="Saved Drafts"
    subtitle="Articles here are not published"
  ></app-page-title>
  <!-- End Title -->

  <mat-nav-list role="list" class="margin-top-40">
    <mat-divider></mat-divider>
    <a
      mat-list-item
      [routerLink]="['/articles', 'details', article.id]"
      role="listitem"
      *ngFor="let article of articles; let isLast = last"
    >
      <h3 mat-line>{{ article.mainTitle }} — {{ article.author.email }}</h3>
      <p mat-line class="subtitle">
        Submitted at {{ article.createdDate | date: "medium" }}
      </p>
      <mat-divider *ngIf="!isLast"></mat-divider>
    </a>
  </mat-nav-list>
</section>
