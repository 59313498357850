<form
  [formGroup]="secretariesForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title
      title="Add Secretary"
      *ngIf="!isMobile"
    ></app-section-title>
    <div formArrayName="secretaries" fxLayout="column" fxLayoutGap="40px">
      <div
        *ngFor="
          let secretary of secretaries.controls;
          let index = index;
          let last = last
        "
        [ngClass]="last ? 'border-primary-color' : 'border-grey-color'"
        class="dashed-container padding-16 margin-bottom-16"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="32px"
          fxLayoutAlign="start start"
          fxLayout.xs="column"
          fxLayoutAlign.xs="stretch"
          fxLayoutGap.xs=""
          class="width-100"
          [formGroupName]="index"
        >
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Start Name -->
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" required />
              <mat-error
                *ngIf="
                  secretaries.controls[index].get('name')?.hasError('required')
                "
              >
                Name is required
              </mat-error>
            </mat-form-field>
            <!-- End Name -->

            <!-- Start ID Type -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
            >
              <mat-label>Identification Type</mat-label>
              <mat-select formControlName="identificationTypeStr" required>
                <mat-option
                  *ngFor="let idType of idTypes"
                  [value]="idType.value"
                >
                  {{ idType.type }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('identificationTypeStr')
                    ?.hasError('required')
                "
              >
                Identification Type is required
              </mat-error>
            </mat-form-field>
            <!-- End ID Type -->

            <!-- Start ID Number -->
            <mat-form-field appearance="outline">
              <mat-label>Identification Number</mat-label>
              <input
                matInput
                type="text"
                formControlName="identificationNumber"
                [placeholder]="listOfIdTypes[index] ? 'eg. 123456-12-1234' : ''"
                [mask]="listOfIdTypes[index] ? '000000-00-0000' : ''"
                [dropSpecialCharacters]="false"
                required
              />
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('identificationNumber')
                    ?.hasError('required')
                "
              >
                Identification Number is required
              </mat-error>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('identificationType')
                    ?.hasError('pattern')
                "
              >
                Identification Number is invalid
              </mat-error>
            </mat-form-field>
            <!-- End ID Number -->

            <!-- Start Nationality -->
            <mat-form-field appearance="outline" *ngIf="isSecretarial">
              <mat-label>Nationality</mat-label>
              <input matInput type="text" formControlName="nationality" />
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('nationality')
                    ?.hasError('required')
                "
              >
                Nationality is required
              </mat-error>
            </mat-form-field>
            <!-- End Nationality -->

            <!-- Start Race -->
            <mat-form-field appearance="outline" *ngIf="isSecretarial">
              <mat-label>Race</mat-label>
              <input matInput type="text" formControlName="race" />
              <mat-error
                *ngIf="
                  secretaries.controls[index].get('race')?.hasError('required')
                "
              >
                Race is required
              </mat-error>
            </mat-form-field>
            <!-- End Race -->

            <!-- Start Gender -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              *ngIf="isSecretarial"
            >
              <mat-label>Gender</mat-label>
              <mat-select formControlName="isMale">
                <mat-option
                  *ngFor="let gender of genders"
                  [value]="gender.value"
                >
                  {{ gender.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('isMale')
                    ?.hasError('required')
                "
              >
                Gender is required
              </mat-error>
            </mat-form-field>
            <!-- End Gender -->

            <!-- Start Phone Number -->
            <mat-form-field
              class="contact-field"
              appearance="outline"
              *ngIf="isSecretarial"
            >
              <mat-label>Phone Number</mat-label>
              <span matPrefix>+ &nbsp;</span>
              <input type="tel" matInput formControlName="phoneNumber" />
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('phoneNumber')
                    ?.hasError('required')
                "
              >
                Phone number is required
              </mat-error>
            </mat-form-field>
            <!-- End Phone Number -->

            <!-- Start Company Email -->
            <mat-form-field appearance="outline">
              <mat-label>Company Email</mat-label>
              <input matInput type="email" formControlName="companyEmail" />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('companyEmail')
                    ?.hasError('email')
                "
              >
                Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('companyEmail')
                    ?.hasError('required')
                "
              >
                Company email is required
              </mat-error>
            </mat-form-field>
            <!-- End Company Email -->

            <!-- Start Professional Type -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
            >
              <mat-label>Professional Type</mat-label>
              <mat-select formControlName="professionalTypeStr">
                <mat-option
                  *ngFor="let professionalType of professionalTypes"
                  [value]="professionalType.value"
                >
                  {{ professionalType.type }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('professionalTypeStr')
                    ?.hasError('required')
                "
              >
                Professional Type is required
              </mat-error>
            </mat-form-field>
            <!-- End Professional Type -->

            <!-- Start Licensed Secretary No. or Membership No. -->
            <mat-form-field appearance="outline">
              <mat-label>Licensed Secretary No. or Membership No.</mat-label>
              <input
                matInput
                type="text"
                formControlName="secretaryOrMembershipNo"
              />
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('secretaryOrMembershipNo')
                    ?.hasError('required')
                "
              >
                Licensed Secretary No. or Membership No. is required
              </mat-error>
            </mat-form-field>
            <!-- End Licensed Secretary No. or Membership No. -->
          </div>
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Start Service/Business Address -->
            <mat-form-field appearance="outline" fxFlex="calc(50% - 16px)">
              <mat-label>Service/Business Address</mat-label>
              <textarea
                matInput
                type="text"
                rows="4"
                formControlName="businessAddress"
              ></textarea>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('businessAddress')
                    ?.hasError('required')
                "
              >
                Service/Business Address is required
              </mat-error>
            </mat-form-field>
            <!-- End Service/Business Address -->

            <!-- Start Residential Address -->
            <mat-form-field
              appearance="outline"
              fxFlex="calc(50% - 16px)"
              *ngIf="isSecretarial"
            >
              <mat-label>Residential Address</mat-label>
              <textarea
                matInput
                type="text"
                rows="4"
                formControlName="residentialAddress"
              ></textarea>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('residentialAddress')
                    ?.hasError('required')
                "
              >
                Residential Address is required
              </mat-error>
            </mat-form-field>
            <!-- End Residential Address -->

            <!-- Start Passport Expiry Date -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              *ngIf="isSecretarial"
            >
              <mat-label>Passport Expiry Date</mat-label>
              <input
                matInput
                [matDatepicker]="dateOfPassportExpiryDP"
                formControlName="dateOfPassportExpiryRef"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dateOfPassportExpiryDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateOfPassportExpiryDP></mat-datepicker>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('dateOfPassportExpiryRef')
                    ?.hasError('required')
                "
              >
                Passport expiry date is required
              </mat-error>
            </mat-form-field>
            <!-- End Passport Expiry Date -->

            <!-- Start Date of Birth -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              *ngIf="isSecretarial"
            >
              <mat-label>Date of Birth</mat-label>
              <input
                matInput
                [matDatepicker]="dateOfBirthDP"
                formControlName="dateOfBirthRef"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dateOfBirthDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateOfBirthDP></mat-datepicker>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('dateOfBirthRef')
                    ?.hasError('required')
                "
              >
                Date of birth is required
              </mat-error>
            </mat-form-field>
            <!-- End Date of Birth -->

            <!-- Start Appointment On -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              *ngIf="isSecretarial"
            >
              <mat-label>Appointment On</mat-label>
              <input
                matInput
                [matDatepicker]="dateOfAppointmentDP"
                formControlName="dateOfAppointmentRef"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dateOfAppointmentDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateOfAppointmentDP></mat-datepicker>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('dateOfAppointmentRef')
                    ?.hasError('required')
                "
              >
                Appointment On is required
              </mat-error>
            </mat-form-field>
            <!-- End Date of Birth -->

            <!-- Start Resignation On -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              *ngIf="isSecretarial"
            >
              <mat-label>Resignation On</mat-label>
              <input
                matInput
                [matDatepicker]="dateOfResignationDP"
                formControlName="dateOfResignationRef"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="dateOfResignationDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #dateOfResignationDP></mat-datepicker>
              <mat-error
                *ngIf="
                  secretaries.controls[index]
                    .get('dateOfResignationRef')
                    ?.hasError('required')
                "
              >
                Resignation On is required
              </mat-error>
            </mat-form-field>
            <!-- End Resignation On -->
          </div>
        </div>
        <button
          mat-button
          type="button"
          color="warn"
          (click)="removeSecretary(index)"
          [disabled]="disabled"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Remove</span>
        </button>
      </div>
    </div>
    <button
      mat-button
      type="button"
      color="primary"
      (click)="addSecretary()"
      [disabled]="disabled"
    >
      <mat-icon>add</mat-icon>
      <span>Secretary</span>
    </button>
  </div>
</form>
