<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between start"
    class="width-100 margin-bottom-40"
  >
    <!-- Start Back Button -->
    <button
      mat-stroked-button
      [routerLink]="['/articles', 'details', article.id]"
      class="back-button"
      *ngIf="!isLoading"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Article Details</span>
    </button>
    <!-- End Back Button -->
    <!-- Start Delete Button -->
    <button
      mat-stroked-button
      color="warn"
      (click)="showDeleteArticleDialog()"
      [matTooltip]="!isAuthor ? 'Only author & admin can delete' : ''"
      [disabled]="isCustodian && !isAuthor"
    >
      <mat-icon class="margin-right-8">delete_outline</mat-icon>
      <span>Delete</span>
    </button>
    <!-- End Delete Button -->
  </div>
  <!-- Start Form -->
  <app-article-form
    [articleForm]="articleForm"
    [selectedCategories]="selectedCategories"
    [pendingImages]="pendingImages"
    [existingImageUrl]="existingImageUrl"
    [article]="article"
  ></app-article-form>
  <!-- End Form -->

  <!-- Start Action Button -->
  <div
    fxLayout="row"
    [fxLayoutAlign]="
      article?.publish === false ? 'end center' : 'space-between center'
    "
    fxLayoutGap="16px"
    class="bottom-action-bar"
    fxHide.xs
  >
    <button
      mat-stroked-button
      color="warn"
      class="uppercase"
      (click)="savePressed(false)"
      *ngIf="article?.publish === true"
    >
      Unpublish
    </button>
    <div fxLayout="row" fxLayoutGap="16px">
      <button
        mat-stroked-button
        color="primary"
        class="uppercase"
        type="button"
        [disabled]="isLoading || isSaving || isDeleting"
        (click)="savePressed(false)"
        *ngIf="article?.publish === false"
      >
        <mat-icon class="margin-right-8">save</mat-icon>
        <span>Save & Submit for Review</span>
      </button>
      <button
        mat-stroked-button
        color="primary"
        class="uppercase"
        [disabled]="isCustodian || isLoading || isSaving || isDeleting"
        type="button"
        [matTooltip]="isCustodian ? 'Only admin can publish' : ''"
        (click)="savePressed(true)"
      >
        <span>Save & Publish</span>
        <mat-icon class="margin-left-8">publish</mat-icon>
      </button>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayoutGap="16px"
    fxHide
    fxShow.xs
    class="margin-top-32 margin-bottom-32"
  >
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      type="button"
      [disabled]="isLoading || isSaving || isDeleting"
      (click)="savePressed(false)"
      *ngIf="article?.publish === false"
    >
      <span>Save & Submit for Review</span>
      <mat-icon class="margin-left-8">save</mat-icon>
    </button>
    <button
      mat-stroked-button
      color="primary"
      class="uppercase"
      [disabled]="isCustodian || isLoading || isSaving || isDeleting"
      type="button"
      [matTooltip]="isCustodian ? 'Only admin can publish' : ''"
      (click)="savePressed(true)"
    >
      <span>Save & Publish</span>
      <mat-icon class="margin-left-8">publish</mat-icon>
    </button>
    <button
      mat-stroked-button
      color="warn"
      class="uppercase"
      (click)="savePressed(false)"
      *ngIf="article?.publish === true"
    >
      Unpublish
    </button>
  </div>
  <!-- End Action Button -->
</section>
