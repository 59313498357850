import { map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user/user.model';
import { ApiService } from './../api_service/api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService) {}

  getUsers(params: Map<any, any>): Observable<any> {
    return this.apiService.post('/api/core/usersearch/', params);
  }

  getUserDetails(userID: number): Observable<User> {
    return this.apiService.get(`/api/core/users/${userID}`);
  }

  addUser(credentials: object = {}): Observable<any> {
    return this.apiService.post('/api/core/users', credentials);
  }

  updateUserDetails(
    credentials: Map<any, any>,
    userID: number
  ): Observable<any> {
    return this.apiService.put(`/api/core/users/${userID}`, credentials);
  }

  deleteUser(userID: number): Observable<any> {
    return this.apiService.delete(`/api/core/users/${userID}`);
  }
}
