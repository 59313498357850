import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerRequest } from 'src/app/shared/models/request/customer-request';
import { Request } from 'src/app/shared/models/request/request.model';
import { ApiService } from './../api_service/api.service';

@Injectable({
  providedIn: 'root',
})
export class RequestsService {
  constructor(private apiService: ApiService) {}

  getRequests(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/requestssearch', data);
  }

  getRequest(id: number): Observable<Request> {
    return this.apiService.get(`/api/core/requests/${id}`);
  }

  updateRequest(data: Map<any, any>, id: number): Observable<any> {
    return this.apiService.put(`/api/core/requests/${id}`, data);
  }

  deleteRequest(id: number): Observable<any> {
    return this.apiService.delete(`/api/core/requests/${id}`);
  }

  // Customers
  getCustomerRequests(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/customer/requests', data);
  }

  getCustomerRequestDetails(id: number): Observable<CustomerRequest[]> {
    return this.apiService.get(`/api/core/customer/requests/details/${id}`);
  }

  createCustomerRequest(data: object = {}): Observable<Request> {
    return this.apiService.post('/api/core/requests', data);
  }

  uploadRequestFile(data: FormData, requestID: number): Observable<any> {
    return this.apiService.post(
      `/api/core/requests/${requestID}/file`,
      data,
      true
    );
  }
}
