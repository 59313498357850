import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { WindowSize } from 'src/app/shared/models/window-size/window-size';
import { LoginBreakpointService } from './../../shared/login-breakpoint/login-breakpoint.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });
  hidePassword = true;
  showEmailSent = false;
  isSubmitting = false;

  contentMaxWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  contentMinHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  email: string | undefined;
  isMobile = true;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService,
    private location: Location,
    private loginBreakpointService: LoginBreakpointService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loginBreakpointService.maxContentSize$.subscribe({
      next: (result: WindowSize) => {
        this.contentMaxWidth$.next(result.width);
        this.contentMinHeight$.next(result.height);
      },
    });

    this.loginBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      },
    });
  }

  backButtonPressed(): void {
    this.location.back();
  }

  sendResetPassword(): void {
    if (this.forgotPasswordForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.isSubmitting = true;
      this.forgotPasswordForm.disable();
      this.snackBar.open('Submitting', '', {
        duration: undefined,
      });
      const email: string = this.forgotPasswordForm
        .get('email')
        ?.value.toLowerCase();
      this.authenticationService.requestForgotPasswordEmail(email).subscribe({
        next: (result) => {
          this.email = email;
          this.showEmailSent = true;
          this.forgotPasswordForm.reset();
          this.isSubmitting = false;
          this.snackBar.dismiss();
        },
        error: (error) => {
          console.log(error);
          this.forgotPasswordForm.enable();
          this.isSubmitting = false;
          this.snackBar.open(error, 'DISMISS', {
            duration: 3000,
          });
        },
      });
    }
  }
}
