import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginBreakpointService } from 'src/app/shared/login-breakpoint/login-breakpoint.service';
import { WindowSize } from 'src/app/shared/models/window-size/window-size';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  contentMaxWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  contentMinHeight$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private loginBreakpointService: LoginBreakpointService) {}

  ngOnInit(): void {
    this.loginBreakpointService.maxContentSize$.subscribe({
      next: (result: WindowSize) => {
        this.contentMaxWidth$.next(result.width);
        this.contentMinHeight$.next(result.height);
      },
    });
  }
}
