<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Profile"
      subtitle="Everything about you"
      ngClass.xs="margin-bottom-16"
    ></app-page-title>

    <div fxLayout="row" fxLayout.xs="row-reverse" fxLayoutGap="16px">
      <button mat-stroked-button class="uppercase" (click)="showLogOutDialog()">
        Log Out
      </button>
      <mat-divider vertical style="height: 40px" *ngIf="isAdmin"></mat-divider>
      <button
        mat-stroked-button
        class="uppercase"
        *ngIf="isAdmin"
        [routerLink]="['edit', user.id]"
      >
        <mat-icon>edit</mat-icon>
        <span class="margin-left-8">Edit Profile</span>
      </button>
    </div>
  </div>

  <div fxLayout="row wrap" fxLayoutGap="40px" fxLayoutGap.xs="0px">
    <!-- Account Information Section -->
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
      fxLayoutGap="8px"
    >
      <app-section-title title="Account Information"></app-section-title>
      <!-- Role -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel">
        <p fxFlex="calc(50%-40px)" class="bold">Role</p>
        <p fxFlex="calc(50%-40px)">
          <span
            class="capitalize"
            *ngFor="let role of user.roles; let isLast = last"
            >{{ role.role?.toLowerCase() }}{{ isLast ? "" : ", " }}</span
          >
        </p>
      </div>

      <!-- Branch -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel">
        <p fxFlex="calc(50%-40px)" class="bold">Branch</p>
        <p fxFlex="calc(50%-40px)">
          {{
            user.branch !== null ? user.branch?.branch : "No branch assigned"
          }}
        </p>
      </div>

      <!-- Department -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel">
        <p fxFlex="calc(50%-40px)" class="bold">Department</p>
        <p fxFlex="calc(50%-40px)">
          <span *ngIf="user.department === null">No department assigned</span>
          <span *ngIf="user.department?.department === 'SHARED_SERVICES'"
            >Shared Services</span
          ><span *ngIf="user.department?.department === 'SECRETARIAL'"
            >Secretarial</span
          ><span *ngIf="user.department?.department === 'TAX'">Tax</span
          ><span *ngIf="user.department?.department === 'AUDIT'">Audit</span>
        </p>
      </div>

      <!-- Email -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel || isCustomer">
        <p fxFlex="calc(50%-40px)" class="bold">Email</p>
        <p fxFlex="calc(50%-40px)">
          {{ user.email }}
        </p>
      </div>

      <!-- Password -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel || isCustomer">
        <p fxFlex="calc(50%-40px)" class="bold">Password</p>
        <div fxFlex="calc(50%-40px)">
          <button
            mat-stroked-button
            color="primary"
            class="uppercase"
            (click)="showChangePasswordDialog()"
          >
            Change Password
          </button>
        </div>
      </div>
    </div>

    <!-- Personal Information Section -->
    <div
      fxLayout="column"
      fxFlex.xs="100%"
      fxFlex="calc(50%-40px)"
      class="margin-bottom-24"
    >
      <app-section-title title="Personal Information"></app-section-title>
      <!-- Name -->
      <div *ngIf="isAdmin || isCustodian || isPersonnel || isCustomer">
        <p fxFlex="calc(50%-40px)" class="bold">Name</p>
        <p fxFlex="calc(50%-40px)">
          {{ user.name }}
        </p>
      </div>

      <!-- ID Type -->
      <div *ngIf="isCustomer">
        <p fxFlex="calc(50%-40px)" class="bold">ID Type</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="{ 'not-available': !user.identificationType }"
        >
          {{
            user.identificationType
              ? user.identificationType.identificationType
              : "Not Available"
          }}
        </p>
      </div>

      <!-- ID Number -->
      <div *ngIf="isCustomer">
        <p fxFlex="calc(50%-40px)" class="bold">ID Number</p>
        <p
          fxFlex="calc(50%-40px)"
          [ngClass]="{ 'not-available': !user.identificationType }"
        >
          {{
            user.identificationNumber
              ? user.identificationNumber
              : "Not Available"
          }}
        </p>
      </div>
    </div>
  </div>
</section>
