<form
  [formGroup]="filesForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title title="Add Files" *ngIf="!isMobile"></app-section-title>
    <app-file-organizer
      [pendingFiles]="pendingFiles"
      [isCreateUpdate]="true"
      [clientType]="clientType"
      [filesForm]="filesForm"
      [yearID]="yearID"
    ></app-file-organizer>
  </div>
</form>
