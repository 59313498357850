import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';
import { environment } from 'src/environments/environment';
import { DeleteEnquiriesDialogComponent } from '../delete-enquiries-dialog/delete-enquiries-dialog.component';
import { EnquiriesService } from './../../../core/services/enquiries/enquiries.service';

@Component({
  selector: 'app-enquiry-details',
  templateUrl: './enquiry-details.component.html',
  styleUrls: ['./enquiry-details.component.scss'],
})
export class EnquiryDetailsComponent implements OnInit {
  enquiryID!: number;
  isLoading = false;
  enquiry!: Enquiry;

  constructor(
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private enquiriesService: EnquiriesService,
    private snackBar: MatSnackBar,
    private location: Location
  ) {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.enquiryID = params.id;
        this.updateEnquiryStatus(true);
      },
    });
  }

  ngOnInit(): void {}

  updateEnquiryStatus(hasRead: boolean): void {
    this.isLoading = true;
    this.snackBar.open('Loading', '', {
      duration: undefined,
    });
    const params: { [x: string]: any } = {
      hasRead,
    };
    this.enquiriesService
      .updateEnquiry(params as Map<any, any>, this.enquiryID)
      .subscribe({
        next: (enquiry: Enquiry) => {
          this.enquiry = enquiry;
          this.isLoading = false;
          this.snackBar.dismiss();
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
          this.snackBar.dismiss();
          this.snackBar.open(error, 'DISMISS', {
            duration: 3000,
          });
        },
      });
  }

  downloadAttachmentPressed(): void {
    window.open(
      `${environment.baseUrl}/api/core/enquiries/downloadFile/${this.enquiry.id}`,
      '_blank'
    );
  }

  showDeleteDialog(): void {
    const enquiry = this.enquiry;
    const dialogRef = this.dialog.open(DeleteEnquiriesDialogComponent, {
      data: { enquiry },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteEnquiry(enquiry);
        }
      },
    });
  }

  deleteEnquiry(enquiry: Enquiry): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${enquiry.title}"`, '', {
      duration: undefined,
    });
    this.enquiriesService.deleteEnquiry(enquiry.id as number).subscribe({
      next: (result) => {
        this.location.back();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${enquiry.title}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }
}
