import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-service-tile',
  templateUrl: './service-tile.component.html',
  styleUrls: ['./service-tile.component.scss'],
})
export class ServiceTileComponent implements OnInit {
  @Input() title = 'Secretarial';
  @Input() description =
    'The legal process used to form a corporate entity or company';
  @Input() iconName = 'calculate';
  @Input() routerLink!: any[];
  @Input() enabled = true;

  constructor() {}

  ngOnInit(): void {}
}
