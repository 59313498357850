<mat-list-item>
  <div mat-line class="title" [ngClass]="{ capitalize: titleCapitalize }">
    {{ title }} <br *ngIf="secondLineTitle" /><span *ngIf="secondLineTitle">{{
      secondLineTitle
    }}</span>
  </div>
  <div mat-line class="subtitle" [ngClass]="{ capitalize: subtitleCapitalize }">
    {{ subtitle }}
  </div>
</mat-list-item>
