import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { RequestFormConstants } from 'src/app/shared/constants/request-form.constants';
import { Device } from 'src/app/shared/enums/device.enum';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { Department } from 'src/app/shared/models/department/department.model';
import { Request } from 'src/app/shared/models/request/request.model';
import { Status } from 'src/app/shared/models/status/status.model';
import { User } from 'src/app/shared/models/user/user.model';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { environment } from 'src/environments/environment';
import { RequestsService } from './../../../core/services/requests/requests.service';
import { UsersService } from './../../../core/services/users/users.service';
import { CustomBreakpointService } from './../../../shared/breakpoint/custom-breakpoint.service';
import { UserFormConstants } from './../../../shared/constants/user-form.constants';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnInit, OnDestroy {
  requestID!: number;
  isLoading = false;
  request!: Request;
  statuses: Array<{ value: string; valueName: string }>;
  departments: Array<Department>;
  isMobile = true;
  requestStatusForm: FormGroup = new FormGroup({
    status: new FormControl(null, [Validators.required]),
    department: new FormControl(null),
    personnel: new FormControl(null),
  });
  user!: User;
  personnels!: Array<User>;
  statusSubscription!: Subscription;
  departmentSubscription!: Subscription;
  status!: Status;
  statusIsPending = true;
  statusIsAssignedDepartment = true;
  userRole!: UserRole;
  refreshNeeded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private requestsService: RequestsService,
    private snackBar: MatSnackBar,
    private location: Location,
    private requestFormConstants: RequestFormConstants,
    private customBreakpointService: CustomBreakpointService,
    private userAccessService: UserAccessService,
    private authenticationService: AuthenticationService,
    private userFormConstants: UserFormConstants,
    private usersService: UsersService
  ) {
    this.statuses = this.requestFormConstants.STATUSES;
    this.departments = this.userFormConstants.DEPARTMENTS;
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.requestID = params.id;
        this.getRequestDetails();
      },
    });
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        this.userRole = userRole;
      },
    });
  }

  ngOnDestroy(): void {
    this.statusSubscription.unsubscribe();
    this.departmentSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscribeToStatus();
  }

  prefillForm(): void {
    this.requestStatusForm.patchValue({
      status: this.request.status?.status,
      department: this.request.department?.department,
      personnel: this.request.assignedUser?.id,
    });
  }

  getDepartmentUsers(department: string): void {
    this.isLoading = true;
    this.snackBar.open('Loading', '', {
      duration: undefined,
    });
    const params: { [x: string]: any } = {
      size: 100,
      page: 0,
      department,
      role: ['PERSONNEL'],
      active: 1,
    };

    this.usersService.getUsers(params as Map<any, any>).subscribe({
      next: (data) => {
        if (data !== null) {
          this.personnels = data.users as User[];
        } else {
          this.personnels = [];
        }
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  getRequestDetails(): void {
    this.isLoading = true;
    this.snackBar.open('Loading', '', {
      duration: undefined,
    });
    this.requestsService.getRequest(this.requestID).subscribe({
      next: (request: Request) => {
        this.request = request;
        this.prefillForm();
        if (this.request.department) {
          this.getDepartmentUsers(
            this.request.department?.department as string
          );
        }
        this.isDepartmentFieldDisabled();
        this.isPersonnelFieldDisabled();
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  subscribeToStatus(): void {
    this.statusSubscription =
      this.requestStatusForm.controls.status.valueChanges.subscribe({
        next: (result) => {
          switch (result) {
            case 'PENDING':
              this.statusIsPending = true;
              this.statusIsAssignedDepartment = false;
              this.requestStatusForm.controls.department.setValidators(null);
              this.requestStatusForm.controls.personnel.setValidators(null);
              this.requestStatusForm.patchValue({
                department: '',
                personnel: '',
              });
              this.subscribeToDepartment();
              break;
            case 'ASSIGNED_TO_DEPARTMENT':
              this.statusIsPending = false;
              this.statusIsAssignedDepartment = true;
              this.requestStatusForm.controls.department.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.controls.personnel.setValidators(null);
              this.requestStatusForm.patchValue({
                department: '',
                personnel: '',
              });
              this.subscribeToDepartment();
              break;
            case 'ASSIGNED_TO_PERSONNEL':
              this.statusIsPending = false;
              this.statusIsAssignedDepartment = false;
              this.requestStatusForm.controls.department.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.controls.personnel.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.patchValue({
                personnel: '',
              });
              this.subscribeToDepartment();
              break;
            case 'COMPLETED':
              this.statusIsPending = false;
              this.statusIsAssignedDepartment = false;
              this.requestStatusForm.controls.department.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.controls.personnel.setValidators([
                Validators.required,
              ]);
              this.subscribeToDepartment();
              break;
            default:
              this.statusIsPending = false;
              this.statusIsAssignedDepartment = false;
              this.requestStatusForm.controls.department.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.controls.personnel.setValidators([
                Validators.required,
              ]);
              this.requestStatusForm.patchValue({
                personnel: '',
              });
              this.subscribeToDepartment();
              break;
          }
        },
      });
  }

  subscribeToDepartment(): void {
    this.departmentSubscription =
      this.requestStatusForm.controls.department.valueChanges.subscribe({
        next: (result) => {
          this.requestStatusForm.patchValue({
            personnel: '',
          });
          this.getDepartmentUsers(result);
        },
      });
  }

  isStatusOptionDisabled(status: {
    value: string;
    valueName: string;
  }): boolean {
    if (this.userRole === UserRole.admin) {
      // All options available
      return false;
    } else if (
      // If Custodian and Secretarial
      this.userRole === UserRole.custodian &&
      this.user.department?.department === 'SECRETARIAL'
    ) {
      // All options available
      return false;
    } else if (
      // If Custodian and not Secretarial
      this.userRole === UserRole.custodian &&
      this.user.department?.department !== 'SECRETARIAL'
    ) {
      // Only ASSIGNED_TO_DEPARTMENT and ASSIGNED_TO_PERSONNEL available
      switch (status.value) {
        case 'PENDING':
          return true;
        case 'ASSIGNED_TO_DEPARTMENT':
          return false;
        case 'ASSIGNED_TO_PERSONNEL':
          return false;
        default:
          return true;
      }
    } else if (this.userRole === UserRole.personnel) {
      // Check if role is Personnel
      if (status.value === 'COMPLETED') {
        // Check if status is Completed
        return false;
      } else {
        return true;
      }
    } else {
      // No options available
      return true;
    }
  }

  isDepartmentOptionDisabled(department: {
    value: string;
    valueName: string;
  }): boolean {
    if (this.userRole === UserRole.admin) {
      // All options available
      return false;
    } else if (
      // If Custodian and Secretarial
      this.userRole === UserRole.custodian &&
      this.user.department?.department === 'SECRETARIAL'
    ) {
      // All options available
      return false;
    } else if (
      // If Custodian and not Secretarial
      this.userRole === UserRole.custodian &&
      this.user.department?.department !== 'SECRETARIAL'
    ) {
      if (department.value !== this.user.department?.department) {
        // Do not match user's department
        return true;
      } else {
        // Matches user's department
        return false;
      }
    } else {
      // No options available
      return true;
    }
  }

  isDepartmentFieldDisabled(): void {
    if (this.userRole === UserRole.personnel) {
      this.requestStatusForm.controls.department.disable();
    }
  }

  isPersonnelFieldDisabled(): void {
    if (this.userRole === UserRole.personnel) {
      this.requestStatusForm.controls.personnel.disable();
    }
  }

  downloadAttachmentPressed(): void {
    window.open(
      `${environment.baseUrl}/api/core/requests/downloadFile/${this.request.id}`,
      '_blank'
    );
  }

  updateStatusPressed(): void {
    if (this.requestStatusForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const params: { [x: string]: any } = {
        requestStatusRef: this.requestStatusForm.get('status')?.value,
        departmentRef: this.requestStatusForm.get('department')?.value,
        assignedUserRefId: this.requestStatusForm.get('personnel')?.value,
      };
      this.isLoading = true;
      this.requestStatusForm.disable();
      this.snackBar.open('Updating', '', {
        duration: undefined,
      });
      this.requestsService
        .updateRequest(params as Map<any, any>, this.requestID)
        .subscribe({
          next: (result) => {
            this.isLoading = false;
            this.refreshNeeded = true;
            this.snackBar
              .open('Updated', 'REFRESH', {
                duration: undefined,
              })
              .onAction()
              .subscribe({
                next: (_) => {
                  this.requestStatusForm.enable();
                  this.requestStatusForm.reset();
                  this.getRequestDetails();
                  this.refreshNeeded = false;
                },
              });
          },
          error: (error) => {
            console.log(error);
            this.isLoading = false;
            this.requestStatusForm.enable();
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }
}
