import { Injectable } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ClientType } from '../enums/client-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ClientTypeService {
  clientTypeSubject$: BehaviorSubject<ClientType> =
    new BehaviorSubject<ClientType>(ClientType.none);
  public clientType$ = this.clientTypeSubject$
    .asObservable()
    .pipe(distinctUntilChanged());
  clientTypeStr!: string;

  constructor(private router: Router) {
    this.getClientType();
  }

  getClientType(): void {
    const urlSegment: string = this.router
      .parseUrl(this.router.url)
      .root.children.primary.segments[1].toString();
    this.changeClientType(urlSegment);
  }

  changeClientType(clientType: string): void {
    switch (clientType.toLowerCase()) {
      case 'secretarial':
        this.clientTypeSubject$.next(ClientType.secretarial);
        this.clientTypeStr = clientType.toLowerCase();
        break;
      case 'tax':
        this.clientTypeSubject$.next(ClientType.tax);
        this.clientTypeStr = clientType.toLowerCase();
        break;
      case 'shared_services':
        this.clientTypeSubject$.next(ClientType.shared_services);
        this.clientTypeStr = clientType.toLowerCase();
        break;
      case 'audit':
        this.clientTypeSubject$.next(ClientType.audit);
        this.clientTypeStr = clientType.toLowerCase();
        break;
      default:
        this.clientTypeSubject$.next(ClientType.none);
        this.clientTypeStr = '';
        break;
    }
  }
}
