<div fxLayout="column">
  <p class="bold-500">Activities</p>
  <div
    class="border border-color border-radius-8 activities-container"
    ngClass="desktop"
    ngClass.xs="mobile"
  >
    <mat-list role="list" *ngIf="isLoading">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">Loading</p>
        <p mat-line class="subtitle">Loading</p>
        <small class="subtitle">Loading</small>
      </mat-list-item>
    </mat-list>
    <mat-list role="list" *ngIf="!isLoading && !activities">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">No activities available</p>
      </mat-list-item>
    </mat-list>
    <mat-nav-list *ngIf="!isLoading && activities">
      <a
        mat-list-item
        role="listitem"
        *ngFor="let activity of activities; let isLast = last"
      >
        <p mat-line [ngClass]="{ 'not-available': !activity.authorName }">
          {{ activity.authorName ? activity.authorName : "Unknown" }}
        </p>
        <small mat-line class="subtitle">
          <span
            >{{ activity.revisionType === "MOD" ? "Edited" : "Added" }}
          </span>
          <span class="lowercase">{{ activity.moduleType }} </span>
          <span class="bold">"{{ activity.moduleTitle }}"</span>
        </small>
        <small
          fxFlex="20%"
          fxFlex.xs="20%"
          fxFlex.md="25%"
          fxFlex.sm="23%"
          class="text-align-right subtitle"
          >{{ getTimeAgo(activity.revisionDate) }}</small
        >
        <mat-divider *ngIf="!isLast"></mat-divider>
      </a>
    </mat-nav-list>
  </div>
</div>
