<h2 mat-dialog-title>Delete Enquiry</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to delete enquiry?</h3>
  <p class="dialog-info-container">
    <span>Name: {{ enquiry.name }}</span
    ><br /><span>Title: {{ enquiry.title }}</span>
  </p>
  <p></p>
  <p>
    This enquiry will be permanently deleted and will not be able to retrieve
    again.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
