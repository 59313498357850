import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { Client } from 'src/app/shared/models/client/client.model';
import { Role } from 'src/app/shared/models/role/role.model';
import { User } from 'src/app/shared/models/user/user.model';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { UsersService } from './../../../core/services/users/users.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  // Form
  editUserForm: FormGroup = new FormGroup({
    roles: new FormControl([], Validators.required),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.email,
    ]),
    status: new FormControl(1, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    identificationType: new FormControl('', [Validators.required]),
    identificationNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
    ]),
    handphoneNumber: new FormControl('', [Validators.required]),
    branch: new FormControl({ value: 'HQ', disabled: true }),
    department: new FormControl(null),
    clientAutoComplete: new FormControl(null),
  });
  isCustomerFormSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  isCustomerForm$ = this.isCustomerFormSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  // Options
  roles: Array<Role> = [];

  // Variables
  user!: User;
  selectedClients: Client[] = [];

  // Loading
  isLoading = false;
  savingUser = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userFormConstants: UserFormConstants,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.roles = this.userFormConstants.ROLES;
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getUserDetails(id);
      },
    });
  }

  getUserDetails(userID: number): void {
    this.usersService.getUserDetails(userID).subscribe({
      next: (user: User) => {
        this.user = user;
        this.subscribeToRoles();
        this.prefillForm();
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  prefillForm(): void {
    const roles: Array<string> = [];
    // Loop to get roles
    if (this.user.roles) {
      for (const role of this.user.roles) {
        const matchedRole: Role = this.roles.find(
          (x) => x.id === role.id
        ) as Role;
        roles.push(matchedRole.role as string);
      }
    }

    this.selectedClients = [...this.user.clients];

    this.editUserForm.patchValue({
      roles,
      email: this.user.email,
      status: this.user.active ? 1 : 0,
      name: this.user.name,
      identificationType: this.user.identificationType?.identificationType,
      identificationNumber: this.user.identificationNumber,
      handphoneNumber: this.user.handphoneNumber,
      department: this.user.department?.department,
    });
  }

  subscribeToRoles(): void {
    this.editUserForm.controls.roles.valueChanges.subscribe({
      next: (selectedRoles) => {
        if (selectedRoles !== null) {
          if (selectedRoles.length) {
            // If it is Admin/ Custodian/ Personnel
            if (selectedRoles.includes('Customer') === false) {
              this.isCustomerFormSubject.next(false);
              this.editUserForm.patchValue({
                identificationType: '',
                identificationNumber: '',
                handphoneNumber: '',
                branch: 'HQ',
                department: this.user.department?.department,
              });
              this.editUserForm.controls.identificationType.setValidators(null);
              this.editUserForm.controls.identificationNumber.setValidators(
                null
              );
              this.editUserForm.controls.handphoneNumber.setValidators(null);
              this.editUserForm.controls.branch.setValidators([
                Validators.required,
              ]);
              this.editUserForm.controls.department.setValidators([
                Validators.required,
              ]);
            } else {
              this.isCustomerFormSubject.next(true);
              this.editUserForm.patchValue({
                identificationType: 'IC',
                identificationNumber: '',
                handphoneNumber: '',
                branch: '',
                department: null,
              });
              this.editUserForm.controls.identificationType.setValidators([
                Validators.required,
              ]);
              this.editUserForm.controls.identificationNumber.setValidators([
                Validators.required,
                Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
              ]);
              this.editUserForm.controls.handphoneNumber.setValidators([
                Validators.required,
              ]);
              this.editUserForm.controls.branch.setValidators(null);
              this.editUserForm.controls.department.setValidators(null);
            }

            this.editUserForm.updateValueAndValidity();
          }
        }
      },
    });
  }

  saveUserPressed(): void {
    if (this.editUserForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const selectedClientIds = [];

      for (const client of this.selectedClients) {
        selectedClientIds.push(client.id);
      }

      const userMap: { [x: string]: any } = {
        name: this.editUserForm.get('name')?.value,
        email: this.editUserForm.get('email')?.value.toLowerCase(),
        branch: this.editUserForm.get('branch')?.value,
        department: this.editUserForm.get('department')?.value,
        active: this.editUserForm.get('status')?.value,
        role: this.editUserForm.get('roles')?.value,
        identificationType: this.editUserForm.get('identificationType')?.value,
        identificationNumber: this.editUserForm.get('identificationNumber')
          ?.value,
        handphoneNumber: this.editUserForm.get('handphoneNumber')?.value,
        clients: selectedClientIds.length ? selectedClientIds : null,
      };

      this.savingUser = true;
      this.editUserForm.disable();
      this.snackBar.open('Saving user', '', {
        duration: undefined,
      });
      this.usersService
        .updateUserDetails(userMap as Map<any, any>, this.user.id as number)
        .subscribe({
          next: (result) => {
            this.editUserForm.reset();
            this.snackBar.dismiss();
            this.location.back();
          },
          error: (error) => {
            console.log(error);
            this.editUserForm.enable();
            this.savingUser = false;
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }

  showDeleteDialog(): void {
    const user: User = this.user;
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { user },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteUser(user);
        }
      },
    });
  }

  deleteUser(user: User): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${user.name}"`, '', {
      duration: undefined,
    });
    this.usersService.deleteUser(user.id as number).subscribe({
      next: (result) => {
        this.location.back();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${user.name}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }
}
