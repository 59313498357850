import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './../../core/services/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { User } from '../models/user/user.model';
import { UserRole } from '../enums/user-role.enum';

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {
  isLoadingUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userRole$: BehaviorSubject<UserRole> = new BehaviorSubject<UserRole>(
    UserRole.none
  );

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.isLoading$.subscribe({
      next: (isLoading: boolean) => {
        this.isLoadingUser.next(isLoading);
      },
    });
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        if (user.roles) {
          for (const role of user.roles) {
            switch (role.id) {
              case 1:
                if (this.userRole$.value > UserRole.admin) {
                  this.userRole$.next(UserRole.admin);
                }
                break;
              case 2:
                if (this.userRole$.value > UserRole.custodian) {
                  this.userRole$.next(UserRole.custodian);
                }
                break;
              case 3:
                if (this.userRole$.value > UserRole.personnel) {
                  this.userRole$.next(UserRole.personnel);
                }
                break;
              case 4:
                this.userRole$.next(UserRole.customer);
                break;
              default:
                break;
            }
          }
        } else {
          this.userRole$.next(UserRole.none);
        }
      },
    });
  }
}
