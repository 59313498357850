<div class="dashed-container border-primary-color margin-bottom-16">
  <!-- Breadcrumbs -->
  <div
    fxLayout="row"
    fxLayout="start center"
    class="breadcrumbs-container width-100 padding-16"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngFor="
        let crumb of crumbs;
        let last = last;
        let first = first;
        let index = index
      "
    >
      <button
        mat-button
        (click)="first ? resetCrumbs() : last ? null : reverseToCrumb(index)"
      >
        {{ crumb }}
      </button>
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </div>
  </div>

  <!-- Folders/ Files -->
  <div fxLayout="column" class="relative">
    <!-- Folders -->
    <div *ngIf="folders?.length" class="padding-16" style="min-height: 300px">
      <p class="bold">Folders</p>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs="16px"
      >
        <div *ngFor="let folder of folders" ngClass.xs="width-100">
          <button
            mat-stroked-button
            fxFlex.xs="calc(100%)"
            (click)="navigateToFolder(folder)"
          >
            <div fxLayout="row" fxLayoutAlign="start center" class="ellipsis">
              <mat-icon class="margin-right-8">folder</mat-icon>
              <span>{{ folder }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Files -->
    <div
      class="padding-16"
      style="min-height: 300px"
      *ngIf="files?.length || ($inFolder | async)"
    >
      <p class="bold">Files</p>
      <p class="not-available" *ngIf="!files?.length && !isLoading">
        No files uploaded
      </p>
      <div
        *ngIf="files?.length"
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutAlign.xs="stretch"
        fxLayoutGap.xs="16px"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          *ngFor="let file of files; let index = index"
        >
          <div
            fxLayout="column"
            class="file"
            [attr.data-selected]="false"
            tabindex="1"
            (focus)="fileSelected(file, index)"
            (focusout)="fileUnselected(index)"
            [class.selected]="selectedItems[index]"
            matTooltip="Updated at {{ file.lastModifiedDate | date: 'medium' }}"
          >
            <p class="bold no-margin ellipsis">
              {{ file.name }}
            </p>
            <small>{{ file.lastModifiedDate | date: "short" }}</small>
          </div>
        </div>
      </div>
      <div
        *ngIf="isLoading && !files.length"
        class="file shimmer"
        fxLayout="column"
      >
        <p class="no-margin ellipsis">Loading</p>
        <small>Loading</small>
      </div>
    </div>

    <form
      class="file-upload-form"
      [formGroup]="filesForm"
      fxHide
      [fxShow]="isCreateUpdate && ($inFolder | async)"
    >
      <input
        formControlName="files"
        type="file"
        class="file-input"
        (change)="uploadFilesPressed($event)"
        multiple
        accept=".pdf, .doc, .docx, .txt, .xls,.xlxs"
        #fileUpload
      />
      <button
        (click)="fileUpload.click()"
        *ngIf="isCreateUpdate"
        class="extended-fab-button"
        mat-fab
        color="primary"
      >
        <mat-icon>upload</mat-icon>
        <span class="extended-fab-button__text">Upload</span>
      </button>
    </form>
  </div>

  <!-- File Action Bar -->
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="8px"
    class="file-action-bar width-100"
    *ngIf="showFileActions && !isLoading"
    (mousedown)="$event.preventDefault()"
  >
    <!-- Dummy div to make download lean towards right -->
    <div *ngIf="!isCreateUpdate"></div>
    <button
      mat-stroked-button
      color="warn"
      *ngIf="isCreateUpdate"
      (click)="showConfirmDeleteDialog()"
    >
      <mat-icon class="margin-right-8">delete_outline</mat-icon>
      <span>Delete</span>
    </button>
    <div fxLayoutGap="16px">
      <button
        mat-stroked-button
        color="primary"
        *ngIf="isCreateUpdate"
        (click)="showRenameDialog()"
      >
        <mat-icon class="margin-right-8">drive_file_rename_outline</mat-icon>
        <span>Rename</span>
      </button>
      <button
        mat-stroked-button
        color="primary"
        (click)="downloadFilePressed()"
      >
        <mat-icon class="margin-right-8">file_download</mat-icon>
        <span>Download</span>
      </button>
    </div>
  </div>

  <!-- Pending Files -->
  <div
    fxLayout="column"
    fxLayoutAlign="stretch"
    class="pending-upload-files-container"
    *ngIf="pendingFiles?.length && isCreateUpdate"
  >
    <p class="bold padding-left-16 padding-top-16">Pending to upload</p>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutGap="16px"
      ngClass="desktop"
      ngClass.xs="mobile"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let file of pendingFiles; let index = index"
        class="pending-file"
      >
        <div fxLayout="column">
          <p class="bold no-margin ellipsis">
            {{ file.file.name }}
          </p>
          <small class="ellipsis">{{ file.folderName }}</small>
          <small>{{ file.file.size / 1000000 | number: "1.2-2" }}mb</small>
        </div>
        <button
          mat-icon-button
          (click)="removePendingFile(index)"
          matTooltip="Remove file"
        >
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
