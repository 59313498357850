import { UserAccessService } from './../../shared/user-access/user-access.service';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RequestsService } from 'src/app/core/services/requests/requests.service';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { Request } from 'src/app/shared/models/request/request.model';
import { DeleteRequestDialogComponent } from './delete-request-dialog/delete-request-dialog.component';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { UserRole } from 'src/app/shared/enums/user-role.enum';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
})
export class RequestsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'name',
    'title',
    'status',
    'createdDate',
    'options',
  ];
  dataSource: MatTableDataSource<Request> = new MatTableDataSource<Request>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  filterRequest = '';
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  requests: Array<Request> = [];
  isLoading = false;
  status: number | null = null;
  receivedStart: Date | null = null;
  receivedEnd: Date | null = null;
  isAdmin = false;
  isCustodian = false;
  isPersonnel = false;
  user!: User;

  constructor(
    public dialog: MatDialog,
    private requestsService: RequestsService,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private customBreakpointService: CustomBreakpointService,
    private userAccessService: UserAccessService,
    private authenticationService: AuthenticationService
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['title', 'options'];
        } else {
          this.displayedColumns = [
            'name',
            'title',
            'status',
            'createdDate',
            'options',
          ];
        }
      },
    });
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          case UserRole.personnel:
            this.isPersonnel = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            this.isPersonnel = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id) {
          this.getRequests();
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getRequests(
    filterRequest?: string,
    pageSize?: number,
    currentPage?: number,
    status?: number,
    receivedStart?: Date,
    receivedEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: undefined,
    });

    const params = {
      remarks: filterRequest ?? this.filterRequest,
      page: currentPage ?? this.currentPage,
      size: pageSize ?? this.pageSize,
      status: status ?? this.status,
      receivedDateFrom: this.datepipe.transform(
        receivedStart ?? this.receivedStart,
        'yyy-MM-dd 00:00:00'
      ),
      receivedDateTo: this.datepipe.transform(
        receivedEnd ?? this.receivedEnd,
        'yyy-MM-dd 23:59:59'
      ),
      department: this.assignedDepartment(),
      assignedUser: this.assignedPersonnel(),
    };

    this.requestsService.getRequests(params).subscribe({
      next: (data) => {
        if (data != null) {
          this.length = data.totalItems;
          this.requests = data.requests as Request[];
        } else {
          this.length = 0;
          this.requests = [] as Request[];
        }
        this.dataSource.data = this.requests;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(e: PageEvent): void {
    console.log(e);
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getRequests();
  }

  assignedDepartment(): string | undefined {
    if (
      this.isCustodian === true &&
      this.isPersonnel === false &&
      this.isAdmin === false
    ) {
      return this.user.department?.department;
    } else {
      return undefined;
    }
  }

  assignedPersonnel(): number | undefined {
    if (
      this.isPersonnel === true &&
      this.isAdmin === false &&
      this.isCustodian === false
    ) {
      return this.user.id;
    } else {
      return undefined;
    }
  }

  showDeleteDialog(request: Request): void {
    const dialogRef = this.dialog.open(DeleteRequestDialogComponent, {
      data: { request },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteRequest(request);
        }
      },
    });
  }

  deleteRequest(request: Request): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${request.title}"`, '', {
      duration: undefined,
    });
    this.requestsService.deleteRequest(request.id as number).subscribe({
      next: (result) => {
        this.getRequests();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${request.title}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }

  isToday(date: string): string | null {
    const theDate: Date = new Date(date);

    const today: Date = new Date();

    if (
      theDate.getDate() === today.getDate() &&
      theDate.getMonth() === today.getMonth() &&
      theDate.getFullYear() === today.getFullYear()
    ) {
      return this.datepipe.transform(date, 'shortTime');
    } else {
      return this.datepipe.transform(date, 'mediumDate');
    }
  }
}
