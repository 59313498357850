import { DatePipe } from '@angular/common';
import { EnquiriesService } from './../../../core/services/enquiries/enquiries.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Enquiry } from 'src/app/shared/models/enquiry/enquiry.model';
import * as moment from 'moment';

@Component({
  selector: 'app-unread-enquiries',
  templateUrl: './unread-enquiries.component.html',
  styleUrls: ['./unread-enquiries.component.scss'],
})
export class UnreadEnquiriesComponent implements OnInit {
  // Loading
  isLoading = false;

  // Variables
  enquiries!: Array<Enquiry>;

  constructor(
    private snackBar: MatSnackBar,
    private enquiriesService: EnquiriesService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.getUnreadEnquiries();
  }

  getUnreadEnquiries(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });

    const formData = {
      description: '',
      page: 0,
      size: 10,
      read: false,
      receivedDateFrom: null,
      receivedDateTo: null,
    };

    this.enquiriesService.getEnquiries(formData).subscribe({
      next: (data) => {
        if (data != null) {
          this.enquiries = data.enquiries as Enquiry[];
          this.enquiries.sort(
            (a: Enquiry, b: Enquiry) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          );
        } else {
          this.enquiries = [] as Enquiry[];
        }

        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  getTimeAgo(date: Date): string {
    return moment(date).fromNow();
  }
}
