<h2 mat-dialog-title>Delete Article?</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to delete this article?</h3>
  <p class="dialog-info-container">
    <span>ID: {{ article.id }}</span>
    <br />
    <span>Title: {{ article.mainTitle }}</span>
    <br />
    <span
      >Created by: {{ article.author.name }} — {{ article.author.email }}
    </span>
    <br />
    <span>Published: {{ article.publish ? "Yes" : "No" }}</span>
  </p>
  <p>
    This article will be permanently deleted and will not be able to retrieve
    again.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
