import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { Auditor } from 'src/app/shared/models/auditor/auditor.model';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-new-shared-services-client',
  templateUrl: './new-shared-services-client.component.html',
  styleUrls: ['./new-shared-services-client.component.scss'],
})
export class NewSharedServicesClientComponent implements OnInit {
  // Form
  generalCompanyDetailsForm!: FormGroup;
  contactsForm!: FormGroup;
  auditorsForm!: FormGroup;
  taxAgentsForm!: FormGroup;

  // Client Type
  clientType: string;

  // Loading
  clientIsCreating = false;

  // Value
  isCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  useCreateAPI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  client: BehaviorSubject<Client> = new BehaviorSubject<Client>({} as Client);

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private clientsService: ClientsService,
    private clientTypeService: ClientTypeService,
    private datepipe: DatePipe
  ) {
    this.clientTypeService.getClientType();
    this.clientType = this.clientTypeService.clientTypeStr;
  }

  ngOnInit(): void {
    this.setupGeneralCompanyDetailsForm();
    this.setupContactsForm();
    this.setupAuditorsForm();
    this.setupTaxAgentsForm();
  }

  setupGeneralCompanyDetailsForm(): void {
    this.generalCompanyDetailsForm = this.formBuilder.group({
      companyNumberNew: new FormControl(null),
      companyNumberOld: new FormControl(null),
      identityTypeRef: new FormControl(null),
      identityNumber: new FormControl(null),
      companyName: new FormControl('', Validators.required),
      group: new FormControl(''),
      fileRef: new FormControl(''),
      correspondentAddress: new FormControl('', Validators.required),
      yearEndDay: new FormControl('', [Validators.min(1), Validators.max(31)]),
      yearEndMonth: new FormControl('', [
        Validators.min(1),
        Validators.max(12),
      ]),
      companyType: new FormControl('CORPORATE'),
      remarks: new FormControl(''),
    });
  }

  setupContactsForm(): void {
    this.contactsForm = this.formBuilder.group({
      oftenContactPersons: this.formBuilder.array([]),
    });
    this.oftenContactPersons.push(this.createOftenContactPersonItem());
  }

  setupAuditorsForm(): void {
    this.auditorsForm = this.formBuilder.group({
      auditors: this.formBuilder.array([]),
    });
  }

  setupTaxAgentsForm(): void {
    this.taxAgentsForm = this.formBuilder.group({
      taxAgents: this.formBuilder.array([]),
    });
  }

  get oftenContactPersons(): FormArray {
    return this.contactsForm.get('oftenContactPersons') as FormArray;
  }

  createOftenContactPersonItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      businessEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      personalEmail: new FormControl('', [Validators.email]),
      contactPersonTypeStr: new FormControl('', Validators.required),
    });
  }

  get auditors(): FormArray {
    return this.auditorsForm.get('auditors') as FormArray;
  }

  get taxAgents(): FormArray {
    return this.taxAgentsForm.get('taxAgents') as FormArray;
  }

  createClientPressed(): void {
    let clientService: Observable<Client>;

    if (
      this.generalCompanyDetailsForm.invalid ||
      this.contactsForm.invalid ||
      this.auditorsForm.invalid ||
      this.taxAgentsForm.invalid
    ) {
      this.generalCompanyDetailsForm.markAllAsTouched();
      this.contactsForm.markAllAsTouched();
      this.auditorsForm.markAllAsTouched();
      this.taxAgentsForm.markAllAsTouched();
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      this.clientIsCreating = true;
      this.generalCompanyDetailsForm.disable();
      this.snackBar.open('Creating client', '', {
        duration: undefined,
      });

      // TODO: Remove companyNumber until requirements updated
      const companyNumberNew =
        this.generalCompanyDetailsForm.get('companyNumberNew')?.value;
      const companyNumberOld =
        this.generalCompanyDetailsForm.get('companyNumberOld')?.value;
      const idType =
        this.generalCompanyDetailsForm.get('identityTypeRef')?.value;
      const idNumber =
        this.generalCompanyDetailsForm.get('identityNumber')?.value;
      const auditors = this.auditors.value.map((data: any) => {
        // Transform dates to string
        data.appointmentOnDateStr = this.transformDateToString(
          data.appointmentOnDateStr
        );
        data.resignationOnDateStr = this.transformDateToString(
          data.resignationOnDateStr
        );
        return data;
      });
      const taxAgents = this.taxAgents.value.map((data: any) => {
        // Transform dates to string
        data.appointmentOnDateStr = this.transformDateToString(
          data.appointmentOnDateStr
        );
        data.resignationOnDateStr = this.transformDateToString(
          data.resignationOnDateStr
        );
        return data;
      });

      const params = {
        // TODO: Remove companyNumber until requirements updated
        companyNumberNew: companyNumberNew !== '' ? companyNumberNew : null,
        companyNumberOld: companyNumberOld !== '' ? companyNumberOld : null,
        identityTypeRef: idType !== '' ? idType : null,
        identityNumber: idNumber !== '' ? idNumber : null,
        name: this.generalCompanyDetailsForm.get('companyName')?.value,
        group: this.generalCompanyDetailsForm.get('group')?.value,
        fileRef: this.generalCompanyDetailsForm.get('fileRef')?.value,
        corresAddress: this.generalCompanyDetailsForm.get(
          'correspondentAddress'
        )?.value,
        yearEnd: this.getYearEndField(),
        remarksSharedServices:
          this.generalCompanyDetailsForm.get('remarks')?.value,
        oftenContactPersons: this.contactsForm.get('oftenContactPersons')
          ?.value,
        auditors,
        taxAgents: taxAgents,
        departmentRef: this.clientType,
        clientTaxTypeRef:
          this.generalCompanyDetailsForm.get('companyType')?.value,
        branchAddresses: null,
        secretaries: null,
        banks: null,
        managers: null,
      };

      clientService = this.useCreateAPI.value
        ? this.clientsService.createGeneralCompanyDetails(params)
        : this.clientsService.saveGeneralCompanyDetails(
            params,
            this.client.value.id
          );

      clientService.subscribe({
        next: (createdClient: Client) => {
          this.generalCompanyDetailsForm.reset();
          this.clientIsCreating = false;
          this.snackBar.dismiss();
          this.router.navigateByUrl(
            `/clients/${this.clientType}/create/success`,
            {
              state: {
                client: createdClient,
              },
            }
          );
        },
        error: (error) => {
          console.log(error);
          this.generalCompanyDetailsForm.enable();
          this.clientIsCreating = false;
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
    }
  }

  getYearEndField(): string | null {
    if (
      this.generalCompanyDetailsForm.get('yearEndDay')?.value &&
      this.generalCompanyDetailsForm.get('yearEndMonth')?.value
    ) {
      return (
        this.generalCompanyDetailsForm.get('yearEndDay')?.value +
        '/' +
        this.generalCompanyDetailsForm.get('yearEndMonth')?.value
      );
    }
    return null;
  }

  transformDateToString(date: Date): string | null {
    return this.datepipe.transform(date, 'yyy-MM-dd 00:00:00');
  }
}
