import { NotificationService } from './../../../core/services/notification/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { User } from 'src/app/shared/models/user/user.model';
import { Notification } from 'src/app/shared/models/notification/notification.model';
import * as moment from 'moment';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss'],
})
export class UserNotificationsComponent implements OnInit {
  // Variables
  user!: User;
  notifications: Notification[] = [];

  // Subscription
  userSubscription: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService
  ) {
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id !== undefined) {
          this.getNotifications(user);
        }
      },
    });
  }

  ngOnInit(): void {}

  getNotifications(user: User): void {
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.notificationService.getNotifications(user.id as number).subscribe({
      next: (notifications: Notification[]) => {
        if (notifications) {
          this.notifications = notifications;
        } else {
          this.notifications = [];
        }
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: undefined });
      },
    });
  }

  getNotificationTimeAgo(date: Date): string {
    return moment(date).fromNow();
  }

  markAsReadPressed(notification: Notification): void {
    this.snackBar.open('Marking as read', '', { duration: undefined });

    const formData = {
      hasRead: true,
    };

    this.notificationService
      .updateNotification(formData, notification.id)
      .subscribe({
        next: (updatedNotification: Notification) => {
          this.snackBar.dismiss();
          this.getNotifications(this.user);
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
        },
      });
  }
}
