import { ClientFormConstants } from './../../../../shared/constants/client-form.constants';
import { SecretarialClientsComponent } from './../secretarial-clients.component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-secretarial-clients-filter',
  templateUrl: './secretarial-clients-filter.component.html',
  styleUrls: ['./secretarial-clients-filter.component.scss'],
})
export class SecretarialClientsFilterComponent implements OnInit {
  companyTypes: Array<{ value: string; valueName: string }>;
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private formBuilder: FormBuilder,
    private clientFormConstants: ClientFormConstants,
    private secretarialClientsComponent: SecretarialClientsComponent
  ) {
    this.companyTypes = this.clientFormConstants.COMPANY_TYPES;
    this.filterForm = this.formBuilder.group({
      filterClient: new FormControl(''),
      companyType: new FormControl('ALL'),
      createdStart: new FormControl(null),
      createdEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.secretarialClientsComponent.pageSize;
    this.currentPage = this.secretarialClientsComponent.currentPage;

    const filterClient = this.filterForm.get('filterClient')?.value;
    const companyType = this.filterForm.get('companyType')?.value;
    const createdStart = this.filterForm.get('createdStart')?.value;
    const createdEnd = this.filterForm.get('createdEnd')?.value;

    this.secretarialClientsComponent.getSecretarialClients(
      filterClient,
      this.pageSize,
      this.currentPage,
      companyType,
      createdStart,
      createdEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.secretarialClientsComponent.getSecretarialClients();
  }
}
