<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="24px"
  class="padding-24"
>
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <!-- Start Title -->
    <app-page-title
      title="Entities"
      subtitle="Here lies your companies"
    ></app-page-title>
    <!-- End Title -->
  </div>

  <div fxLayout="column" fxLayoutGap="32px">
    <!-- Start Corporate -->
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <app-section-title title="Corporate"></app-section-title>
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutGap.xs="0px"
        class="width-100"
      >
        <app-entity-tile
          *ngFor="let entity of corporateEntities"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="33%"
          fxFlex.xl="25%"
          [entity]="entity"
        ></app-entity-tile>

        <!-- Start Loading -->
        <div class="shimmer loading-container" *ngIf="isLoading" fxFlex="33%">
          <mat-list>
            <mat-list-item>
              <div mat-line class="title">Loading</div>
              <div mat-line class="description">
                <span class="capitalize">Loading</span>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <!-- End Loading -->
        <!-- Start Not Available Message -->
        <p class="not-available" *ngIf="!isLoading && !corporateEntities">
          No corporate entities available
        </p>
        <!-- End Not Available Message -->
      </div>
    </div>
    <!-- End Corporate -->
    <!-- Start Others -->
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <app-section-title title="Others"></app-section-title>
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutGap.xs="0px"
        class="width-100"
      >
        <app-entity-tile
          *ngFor="let entity of otherEntities"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="33%"
          fxFlex.xl="25%"
          [entity]="entity"
        ></app-entity-tile>

        <!-- Start Loading -->
        <div class="shimmer loading-container" *ngIf="isLoading" fxFlex="33%">
          <mat-list>
            <mat-list-item>
              <div mat-line class="title">Loading</div>
              <div mat-line class="description">
                <span class="capitalize">Loading</span>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <!-- End Loading -->
        <!-- Start Not Available Message -->
        <p class="not-available" *ngIf="!isLoading && !otherEntities">
          No other entities available
        </p>
        <!-- End Not Available Message -->
      </div>
    </div>
    <!-- End Others -->
  </div>
</section>
