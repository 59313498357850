import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-delete-client-dialog',
  templateUrl: './delete-client-dialog.component.html',
  styleUrls: ['./delete-client-dialog.component.scss'],
})
export class DeleteClientDialogComponent implements OnInit {
  @Input() client: Client;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.client = data.client;
  }

  ngOnInit(): void {}
}
