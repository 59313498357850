import { MatDialog } from '@angular/material/dialog';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { Client } from 'src/app/shared/models/client/client.model';
import { Department } from 'src/app/shared/models/department/department.model';
import * as moment from 'moment';
import { PopulateFormDialogComponent } from '../populate-form-dialog/populate-form-dialog.component';
import { CompanyNumberType } from 'src/app/shared/enums/company-number-type.enum';
import { IdentificationType } from 'src/app/shared/models/identification-type/identification-type.model';
import { BranchAddress } from 'src/app/shared/models/client/branch-address/branch-address.model';
import { ContactPerson } from 'src/app/shared/models/client/contact-person/contact-person.model';
import { Auditor } from 'src/app/shared/models/auditor/auditor.model';
import { TaxAgent } from 'src/app/shared/models/tax-agent/tax-agent.model';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
})
export class ClientFormComponent implements OnInit {
  @Input() generalCompanyDetailsForm!: FormGroup;
  @Input() contactsForm!: FormGroup;
  @Input() auditorsForm!: FormGroup;
  @Input() branchAddressesForm!: FormGroup;
  @Input() taxAgentsForm!: FormGroup;
  @Input() isCreate!: BehaviorSubject<boolean>;
  @Input() client!: BehaviorSubject<Client>;
  @Input() useCreateAPI!: BehaviorSubject<boolean>;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;
  clientType!: ClientType;

  // Form Populated check
  isFormPopulated = false;
  companyNumberNewAvailable!: boolean;
  companyNumberOldAvailable!: boolean;
  icPassportAvailable!: boolean;

  // In Client Type / in department
  clientNewInClientType: string[] = [];
  clientOldInClientType: string[] = [];
  clientIcPassportInClientType: string[] = [];

  // Loading
  companyNumberNewIsLoading = false;
  companyNumberOldIsLoading = false;
  icPassportIsLoading = false;

  // Value
  selectedCompanyNumberType: CompanyNumberType = CompanyNumberType.new;
  initialCompanyNumberType = 'new';

  // ID Type
  isIC = true;

  // Dropdown/ List
  incorporatedByUsOptions: Array<{ value: boolean; valueName: string }>;
  departments: Array<Department> = [];
  companyNumberTypes: Array<{ value: string; valueName: string }> = [
    { value: 'new', valueName: 'New Company Number' },
    { value: 'old', valueName: 'Old Company Number' },
  ];
  companyTypes: Array<{ value: string; valueName: string }>;
  idTypes: Array<IdentificationType>;

  // Company Number
  clientNew: Client | undefined;
  clientOld: Client | undefined;
  clientIcPassport: Client | undefined;
  companyExistNew!: boolean | null;
  companyExistOld!: boolean | null;
  icPassportExist!: boolean | null;
  clientNewOptions: BehaviorSubject<Array<Client>> = new BehaviorSubject<
    Array<Client>
  >([]);
  clientOldOptions: BehaviorSubject<Array<Client>> = new BehaviorSubject<
    Array<Client>
  >([]);
  clientIcPassportOptions: BehaviorSubject<Array<Client>> = new BehaviorSubject<
    Array<Client>
  >([]);

  // Subscription
  companyNumberSubscription!: Subscription;
  idTypeSubscription: Subscription | undefined;

  constructor(
    private clientsService: ClientsService,
    private snackBar: MatSnackBar,
    private clientFormConstants: ClientFormConstants,
    private clientTypeService: ClientTypeService,
    private userFormConstants: UserFormConstants,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    this.companyTypes = this.clientFormConstants.COMPANY_TYPES;
    this.departments = this.userFormConstants.DEPARTMENTS;
    this.incorporatedByUsOptions = this.clientFormConstants.INCORPORATED_BY_US;
    this.idTypes = Array.from(this.userFormConstants.IDTYPE);
    this.idTypes.splice(2, 1);
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        this.clientType = clientType;
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            this.companyNumberTypes.push({
              value: 'icPassport',
              valueName: 'IC/ Passport',
            });
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            this.companyNumberTypes.push({
              value: 'icPassport',
              valueName: 'IC/ Passport',
            });
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    if (this.isCreate.value === false) {
      this.client.subscribe({
        next: (client: Client) => {
          if (
            client.companyNumberNew &&
            (!client.companyNumberOld || client.companyNumberOld) &&
            !client.identityNumber
          ) {
            // If there is only new company number
            this.subscribeToCompanyNumberNew();
            this.initialCompanyNumberType = 'new';
            this.selectedCompanyNumberType = CompanyNumberType.new;
            this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
              [Validators.required]
            );
            this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
              null
            );
            if (
              this.generalCompanyDetailsForm.controls.identityTypeRef &&
              this.generalCompanyDetailsForm.controls.identityNumber
            ) {
              this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
                null
              );
              this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
                null
              );
            }
          } else if (
            !client.companyNumberNew &&
            client.companyNumberOld &&
            !client.identityNumber
          ) {
            // If there is only old company number
            this.subscribeToCompanyNumberOld();
            this.initialCompanyNumberType = 'old';
            this.selectedCompanyNumberType = CompanyNumberType.old;
            this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
              null
            );
            this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
              [Validators.required]
            );
            this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
              null
            );
            this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
              null
            );
          } else if (
            !client.companyNumberNew &&
            !client.companyNumberOld &&
            client.identityNumber
          ) {
            this.subscribeToIDType();
            this.subscribeICPassport();
            this.initialCompanyNumberType = 'icPassport';
            this.selectedCompanyNumberType = CompanyNumberType.icPassport;
            this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
              null
            );
            this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
              null
            );
            this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
              [Validators.required]
            );
            this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
              [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(12),
              ]
            );
          } else {
            // Subscribe to new company number
            // When client has company number new/ old & identityNumber,
            // subscribe only new company number
            this.subscribeToCompanyNumberNew();
            this.initialCompanyNumberType = 'new';
            this.selectedCompanyNumberType = CompanyNumberType.new;
            this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
              [Validators.required]
            );
            this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
              null
            );
            if (
              this.generalCompanyDetailsForm.controls.identityTypeRef &&
              this.generalCompanyDetailsForm.controls.identityNumber
            ) {
              this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
                null
              );
              this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
                null
              );
            }
          }
        },
      });
    } else {
      // If isCreate is true, subscribe to company number new and old
      this.subscribeToCompanyNumberNew();
      this.subscribeToCompanyNumberOld();
      if (this.isTax || this.isSharedServices) {
        this.subscribeToIDType();
        this.subscribeICPassport();
      }
    }
  }

  subscribeToCompanyNumberNew(): void {
    this.companyNumberSubscription =
      this.generalCompanyDetailsForm.controls.companyNumberNew.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe({
          next: (companyNumber: string) => {
            switch (this.selectedCompanyNumberType) {
              case CompanyNumberType.new:
                this.getMatchingCompanyNumbers(companyNumber, true);
                break;
              // case CompanyNumberType.both:
              //   this.getMatchingCompanyNumbers(companyNumber, true);
              //   if (!companyNumber) {
              //     this.resetForm();
              //   }
              //   break;
              default:
                this.companyExistNew = null;
                this.clientNewOptions.next([]);
                break;
            }
          },
        });
  }

  subscribeToCompanyNumberOld(): void {
    this.companyNumberSubscription =
      this.generalCompanyDetailsForm.controls.companyNumberOld.valueChanges
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe({
          next: (companyNumber: string) => {
            switch (this.selectedCompanyNumberType) {
              case CompanyNumberType.old:
                this.getMatchingCompanyNumbers(companyNumber, false);
                break;
              // case CompanyNumberType.both:
              //   this.getMatchingCompanyNumbers(companyNumber, false);
              //   if (!companyNumber) {
              //     this.resetForm();
              //   }
              //   break;
              default:
                this.companyExistOld = null;
                this.clientOldOptions.next([]);
                break;
            }
          },
        });
  }

  getMatchingCompanyNumbers(
    companyNumber: string,
    newCompanyNumber: boolean
  ): void {
    // Check if company number value is available
    if (companyNumber?.length > 0) {
      if (newCompanyNumber) {
        this.companyNumberNewIsLoading = true;
      } else {
        this.companyNumberOldIsLoading = true;
      }

      // Check if client exist
      this.clientsService.checkClientExist(companyNumber).subscribe({
        next: (result: Array<Client>) => {
          // Store existing company options
          if (newCompanyNumber) {
            this.clientNewOptions.next(result);
          } else {
            this.clientOldOptions.next(result);
          }

          this.isFormPopulated = false;

          // Check if company number exists
          if (result.length > 0 && this.isCreate.value) {
            // There is result and is not edit
            if (newCompanyNumber) {
              // Get Departments
              this.checkExistInDepartments(true, false);
              // Set error and set company exist
              this.companyExistNew = true;
            } else {
              // Get Departments
              this.checkExistInDepartments(false, false);
              // Set error and set company exist
              this.companyExistOld = true;
            }
          } else if (result.length > 0 && !this.isCreate.value) {
            // There is result and is edit
            if (newCompanyNumber) {
              // Check if result is current client
              const companyNumberIsCurrentClient = result.some(
                (x) =>
                  x.companyNumberNew ===
                  this.client.value.companyNumberNew?.toString()
              );

              // If current company is current client
              if (companyNumberIsCurrentClient) {
                this.companyExistNew = false;
                this.generalCompanyDetailsForm.controls.companyNumberNew.setErrors(
                  null
                );
              } else {
                // if current company is not current client
                this.companyExistNew = true;
                this.generalCompanyDetailsForm.controls.companyNumberNew.setErrors(
                  {
                    exist: true,
                  }
                );
              }
            } else {
              // Check if result is current client
              const companyNumberIsCurrentClient = result.some(
                (x) =>
                  x.companyNumberOld ===
                  this.client.value.companyNumberOld?.toString()
              );

              // If current company is current client
              if (companyNumberIsCurrentClient) {
                this.companyExistOld = false;
                this.generalCompanyDetailsForm.controls.companyNumberOld.setErrors(
                  null
                );
              } else {
                // if current company is not current client
                this.companyExistOld = true;
                this.generalCompanyDetailsForm.controls.companyNumberOld.setErrors(
                  {
                    exist: true,
                  }
                );
              }
            }
          } else {
            // There is no result
            if (newCompanyNumber) {
              this.companyExistNew = false;
              this.generalCompanyDetailsForm.controls.companyNumberNew.setErrors(
                null
              );
            } else {
              this.companyExistOld = false;
              this.generalCompanyDetailsForm.controls.companyNumberOld.setErrors(
                null
              );
            }
          }
          if (newCompanyNumber) {
            this.companyNumberNewIsLoading = false;
          } else {
            this.companyNumberOldIsLoading = false;
          }
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          if (newCompanyNumber) {
            this.companyExistNew = null;
            this.companyNumberNewIsLoading = false;
          } else {
            this.companyExistOld = null;
            this.companyNumberOldIsLoading = false;
          }
        },
      });
    } else {
      if (newCompanyNumber) {
        this.companyExistNew = null;
        this.clientNewOptions.next([]);
        this.clientNew = undefined;
      } else {
        this.companyExistOld = null;
        this.clientOldOptions.next([]);
        this.clientOld = undefined;
      }
    }
  }

  subscribeToIDType(): void {
    this.idTypeSubscription = this.generalCompanyDetailsForm
      .get('identityTypeRef')
      ?.valueChanges.subscribe({
        next: (result) => {
          if (result === 'IC') {
            this.isIC = true;
            this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
              [
                Validators.required,
                Validators.minLength(12),
                Validators.maxLength(12),
              ]
            );
          } else if (result === 'PASSPORT' || result === 'COMP_ID') {
            this.isIC = false;
            this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
              [Validators.required]
            );
          }
        },
      });
  }

  subscribeICPassport(): void {
    this.idTypeSubscription = this.generalCompanyDetailsForm
      .get('identityNumber')
      ?.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe({
        next: (idNumber: string) => {
          this.getMatchingIcPassport(idNumber);
        },
      });
  }

  getMatchingIcPassport(idNumber: string): void {
    if (idNumber?.length > 0) {
      this.icPassportIsLoading = true;

      this.clientsService.checkIcPassportExist(idNumber).subscribe({
        next: (result: Array<Client>) => {
          // Store existing client options
          this.clientIcPassportOptions.next(result);
          this.isFormPopulated = false;

          // Check if client number exists
          if (result.length > 0 && this.isCreate.value) {
            // There is result and is not edit
            // Get Departments
            this.checkExistInDepartments(false, true);
            // Set error and set company exist
            this.icPassportExist = true;
          } else if (result.length > 0 && !this.isCreate.value) {
            // There is result and is edit
            // Check if result is current client
            const idNumberIsCurrentClient = result.some(
              (x) =>
                x.identityNumber ===
                this.client.value.identityNumber?.toString()
            );

            // If current client is current client
            if (idNumberIsCurrentClient) {
              this.icPassportExist = false;
              this.generalCompanyDetailsForm.controls.identityNumber.setErrors(
                null
              );
            } else {
              // if current company is not current client
              this.icPassportExist = true;
              this.generalCompanyDetailsForm.controls.identityNumber.setErrors({
                exist: true,
              });
            }
          } else {
            // There is no result
            this.icPassportExist = false;
            if (idNumber?.length >= 12) {
              this.generalCompanyDetailsForm.controls.identityNumber.setErrors(
                null
              );
            }
          }
          this.icPassportIsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.icPassportExist = null;
          this.icPassportIsLoading = false;
        },
      });
    } else {
      this.icPassportExist = null;
      this.clientIcPassportOptions.next([]);
      this.clientIcPassport = undefined;
    }
  }

  selectablePastDates(date: Date | null): boolean {
    const now: Date = new Date();
    const today: Date = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const selectedDate: Date = date as Date;
    return selectedDate <= today;
  }

  onCompanySelected(
    client: Client,
    newCompanyNumber?: boolean,
    isIcPassport?: boolean
  ): void {
    if (newCompanyNumber && !isIcPassport) {
      this.clientNew = client;
      this.checkExistInDepartments(true, false);
    } else if (!newCompanyNumber && !isIcPassport) {
      this.clientOld = client;
      this.checkExistInDepartments(false, false);
    } else {
      this.clientIcPassport = client;
      this.checkExistInDepartments(false, true);
    }
  }

  checkExistInDepartments(
    isNewCompanyNumber?: boolean,
    isIcPassport?: boolean
  ): void {
    this.clientNewInClientType = [];
    this.clientOldInClientType = [];
    this.clientIcPassportInClientType = [];
    // Get client's existing department
    let selectedClient: Client | undefined;

    if (isNewCompanyNumber === true && !isIcPassport) {
      // If new company number
      selectedClient = this.clientNew;
    } else if (isNewCompanyNumber === false && !isIcPassport) {
      // If old company number
      selectedClient = this.clientOld;
    } else {
      // If IC/ Passport
      selectedClient = this.clientIcPassport;
    }

    if (selectedClient) {
      for (const department of selectedClient.departments) {
        const matchedDepartment: Department = this.departments.find(
          (x) => x.value === department.department
        ) as Department;
        if (isNewCompanyNumber === true && !isIcPassport) {
          // If new company number
          this.clientNewInClientType.push(
            matchedDepartment.valueName as string
          );
        } else if (isNewCompanyNumber === false && !isIcPassport) {
          // If old company number
          this.clientOldInClientType.push(
            matchedDepartment.valueName as string
          );
        } else {
          // If IC/ Passport
          this.clientIcPassportInClientType.push(
            matchedDepartment.valueName as string
          );
        }
      }
    }
  }

  onCompanyNumberTypeChange(companyNumberType: {
    value: string;
    valueName: string;
  }): void {
    switch (companyNumberType.value) {
      case 'new':
        this.selectedCompanyNumberType = CompanyNumberType.new;
        this.subscribeToCompanyNumberNew();
        this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators([
          Validators.required,
        ]);
        this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
          null
        );
        if (this.isTax || this.isSharedServices) {
          this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
            null
          );
          this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
            null
          );
        }
        // this.generalCompanyDetailsForm.patchValue({
        //   companyNumberNew: '',
        //   companyNumberOld: null,
        //   identityTypeRef: null,
        //   identityNumber: null,
        // });
        this.clientOldOptions.next([]);
        this.clientIcPassportOptions.next([]);
        break;
      case 'old':
        this.selectedCompanyNumberType = CompanyNumberType.old;
        this.subscribeToCompanyNumberOld();
        this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
          null
        );
        this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators([
          Validators.required,
        ]);
        if (this.isTax || this.isSharedServices) {
          this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators(
            null
          );
          this.generalCompanyDetailsForm.controls.identityNumber.setValidators(
            null
          );
        }
        // this.generalCompanyDetailsForm.patchValue({
        //   companyNumberNew: null,
        //   companyNumberOld: '',
        //   identityTypeRef: null,
        //   identityNumber: null,
        // });
        this.clientNewOptions.next([]);
        this.clientIcPassportOptions.next([]);
        break;
      case 'icPassport':
        this.selectedCompanyNumberType = CompanyNumberType.icPassport;
        this.subscribeICPassport();
        this.generalCompanyDetailsForm.controls.companyNumberNew.setValidators(
          null
        );
        this.generalCompanyDetailsForm.controls.companyNumberOld.setValidators(
          null
        );
        this.generalCompanyDetailsForm.controls.identityTypeRef.setValidators([
          Validators.required,
        ]);
        this.generalCompanyDetailsForm.patchValue({
          identityTypeRef: 'IC',
        });
        this.generalCompanyDetailsForm.controls.identityNumber.setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]);
        this.clientOldOptions.next([]);
        this.clientNewOptions.next([]);
        break;
      default:
        break;
    }
  }

  showPopulateFormDialog(
    event: Event,
    isNewCompanyNumber?: boolean,
    isIcPassport?: boolean
  ): void {
    event.stopPropagation();
    let client: Client | undefined;
    let departments: string[] = [];

    if (isNewCompanyNumber === true && !isIcPassport) {
      client = this.clientNew;
      departments = this.clientNewInClientType;
    } else if (isNewCompanyNumber === false && !isIcPassport) {
      client = this.clientOld;
      departments = this.clientOldInClientType;
    } else {
      client = this.clientIcPassport;
      departments = this.clientIcPassportInClientType;
    }
    const dialogRef = this.dialog.open(PopulateFormDialogComponent, {
      data: {
        client,
        departments,
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.populateForm(
            client as Client,
            isNewCompanyNumber as boolean,
            isIcPassport as boolean
          );
        }
      },
    });
  }

  populateForm(
    client: Client,
    isNewCompanyNumber: boolean,
    isIcPassport: boolean
  ): void {
    this.generalCompanyDetailsForm.reset();
    const yearEndDate = moment(client.yearEnd, 'DD/MM').toDate();

    let data = {};

    switch (this.clientType) {
      case ClientType.secretarial:
        data = this.createSecretarialObject(client, yearEndDate);
        this.prefillBranchAddressesForm(client);
        break;
      case ClientType.tax:
        data = this.createTaxObject(client, yearEndDate);
        break;
      case ClientType.audit:
        data = this.createAuditObject(client, yearEndDate);
        break;
      case ClientType.shared_services:
        data = this.createSharedServicesObject(client, yearEndDate);
        break;
      default:
        break;
    }

    this.generalCompanyDetailsForm.patchValue(data);
    this.prefillContactsForm(client);
    this.prefillAuditorsForm(client);
    if (this.taxAgentsForm) {
      this.prefillTaxAgentsForm(client);
    }
    this.isFormPopulated = true;
    if (client.companyNumberOld) {
      this.companyNumberOldAvailable = true;
    }
    if (client.companyNumberNew) {
      this.companyNumberNewAvailable = true;
    }
    if (client.identityNumber) {
      this.icPassportAvailable = true;
    }
    this.useCreateAPI.next(false);
    this.client.next(client);
    if (isNewCompanyNumber && !isIcPassport) {
      // this.companyExistNew = false;
      if (this.selectedCompanyNumberType === CompanyNumberType.new) {
        this.companyExistNew = null;
      }
    } else if (!isNewCompanyNumber && !isIcPassport) {
      // this.companyExistOld = false;
      if (this.selectedCompanyNumberType === CompanyNumberType.old) {
        this.companyExistOld = null;
      }
    } else {
      if (this.selectedCompanyNumberType === CompanyNumberType.icPassport) {
        this.icPassportExist = null;
      }
    }
  }

  createSecretarialObject(client: Client, yearEndDate: Date): object {
    return {
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      companyName: client.name,
      formerlyKnownAs: client.formerlyKnownAs,
      group: client.group,
      clientSubGroup: client.clientSubGroup,
      fileRef: client.fileRef,
      incorporationDate: client.dateOfIncorp,
      circulationDate: client.circulationDate
        ? new Date(client.circulationDate)
        : client.circulationDate,
      chargeOrDischarge: client.chargeOrDischarge,
      chargeDate: client.chargeDate
        ? new Date(client.chargeDate)
        : client.chargeDate,
      dischargeDate: client.dischargeDate
        ? new Date(client.dischargeDate)
        : client.dischargeDate,
      registeredAddress: client.registeredAddress,
      correspondentAddress: client.corresAddress,
      registeredBookKeptAddress: client.registeredBookKeptAddress,
      financialRecordsKeptAddress: client.financialRecordsKeptAddress,
      yearEndDay: yearEndDate.getDate(),
      yearEndMonth: yearEndDate.getMonth() + 1,
      incorporatedByUs: client.incorpByUs,
      remarks: client.remarksSecretary,
      companyOfficeNo: client.companyOfficeNo,
      companyEmail: client.companyEmail,
      companyWebsite: client.companyWebsite,
      referredBy: client.referredBy,
      dateOfLastAccount: client.dateOfLastAccount
        ? new Date(client.dateOfLastAccount)
        : client.dateOfLastAccount,
      dateOfNextAccountDue: client.dateOfNextAccountDue
        ? new Date(client.dateOfNextAccountDue)
        : client.dateOfNextAccountDue,
      oftenContactPersons: client.oftenContactPersons,
    };
  }

  createTaxObject(client: Client, yearEndDate: Date): object {
    return {
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      identityTypeRef: client.identityTypeRef,
      identityNumber: client.identityNumber,
      companyName: client.name,
      group: client.group,
      fileRef: client.fileRef,
      companyType: client.clientTaxTypeRef,
      employerNumber: client.employerNumber,
      correspondentAddress: client.corresAddress,
      taxRefNumber: client.taxRefNumber,
      yearEndDay: yearEndDate.getDate(),
      yearEndMonth: yearEndDate.getMonth() + 1,
      clientStatus: client.clientStatus,
      remarks: client.remarksTax,
    };
  }

  createAuditObject(client: Client, yearEndDate: Date): object {
    return {
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      companyName: client.name,
      group: client.group,
      fileRef: client.fileRef,
      correspondentAddress: client.corresAddress,
      yearEndDay: yearEndDate.getDate(),
      yearEndMonth: yearEndDate.getMonth() + 1,
      remarks: client.remarksAudit,
    };
  }

  createSharedServicesObject(client: Client, yearEndDate: Date): object {
    return {
      companyName: client.name,
      // TODO: Remove companyNumber until requirements updated
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      identityTypeRef: client.identityTypeRef,
      identityNumber: client.identityNumber,
      group: client.group,
      fileRef: client.fileRef,
      correspondentAddress: client.corresAddress,
      yearEndDay: yearEndDate.getDate(),
      yearEndMonth: yearEndDate.getMonth() + 1,
      remarks: client.remarksSharedServices,
    };
  }

  prefillContactsForm(client: Client): void {
    this.oftenContactPersons.clear();
    if (client.oftenContactPersons?.length > 0) {
      client.oftenContactPersons.forEach(
        (contactPerson: ContactPerson, index: number) => {
          this.oftenContactPersons.push(this.createOftenContactPersonItem());
          const data = {
            name: contactPerson.name,
            phoneNumber: contactPerson.phoneNumber,
            businessEmail: contactPerson.businessEmail.toLowerCase(),
            personalEmail: contactPerson.personalEmail.toLowerCase(),
            contactPersonTypeStr: contactPerson.contactPersonTypeStr,
          };

          const contactFormGroup: FormGroup = this.oftenContactPersons.controls[
            index
          ] as FormGroup;
          contactFormGroup.patchValue(data);
        }
      );
    } else {
      this.oftenContactPersons.push(this.createOftenContactPersonItem());
    }
  }

  get auditors(): FormArray {
    return this.auditorsForm.get('auditors') as FormArray;
  }

  createAuditorItem(): FormGroup {
    return this.formBuilder.group({
      firmName: new FormControl('', Validators.required),
      firmNo: new FormControl('', Validators.required),
      firmAddress: new FormControl(''),
      firmContactNumber: new FormControl('', Validators.required),
      firmEmail: new FormControl('', Validators.email),
      picName: new FormControl(''),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  prefillAuditorsForm(client: Client): void {
    if (client.auditors?.length > 0) {
      this.auditors.clear();
      client.auditors.forEach((auditor: Auditor, index: number) => {
        this.auditors.push(this.createAuditorItem());
        const data = {
          firmName: auditor.firmName,
          firmNo: auditor.firmNo,
          firmAddress: auditor.firmAddress,
          firmContactNumber: auditor.firmContactNumber,
          firmEmail: auditor.firmEmail,
          picName: auditor.picName,
          appointmentOnDateStr: auditor.appointmentOnDate
            ? new Date(auditor.appointmentOnDate)
            : auditor.appointmentOnDate,
          resignationOnDateStr: auditor.resignationOnDate
            ? new Date(auditor.resignationOnDate)
            : auditor.resignationOnDate,
        };

        const auditorsFormGroup: FormGroup = this.auditors.controls[
          index
        ] as FormGroup;
        auditorsFormGroup.patchValue(data);
      });
    } else {
      this.auditors.clear();
    }
  }

  get taxAgents(): FormArray {
    return this.taxAgentsForm.get('taxAgents') as FormArray;
  }

  createTaxAgentItem(): FormGroup {
    return this.formBuilder.group({
      companyName: new FormControl('', Validators.required),
      picName: new FormControl(''),
      licenseNo: new FormControl(''),
      telNo: new FormControl('', Validators.required),
      companyEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      privateEmail: new FormControl('', Validators.email),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  prefillTaxAgentsForm(client: Client): void {
    if (client.taxAgents?.length > 0) {
      this.taxAgents.clear();
      client.taxAgents.forEach((taxAgent: TaxAgent, index: number) => {
        this.taxAgents.push(this.createTaxAgentItem());
        const data = {
          companyName: taxAgent.companyName,
          picName: taxAgent.picName,
          licenseNo: taxAgent.licenseNo,
          telNo: taxAgent.telNo,
          companyEmail: taxAgent.companyEmail,
          privateEmail: taxAgent.privateEmail,
          appointmentOnDateStr: taxAgent.appointmentOnDate
            ? new Date(taxAgent.appointmentOnDate)
            : taxAgent.appointmentOnDate,
          resignationOnDateStr: taxAgent.resignationOnDate
            ? new Date(taxAgent.resignationOnDate)
            : taxAgent.resignationOnDate,
        };

        const taxAgentsFormGroup: FormGroup = this.taxAgents.controls[
          index
        ] as FormGroup;
        taxAgentsFormGroup.patchValue(data);
      });
    } else {
      this.taxAgents.clear();
    }
  }

  prefillBranchAddressesForm(client: Client) {
    this.branchAddresses.clear();
    if (client.branchAddresses?.length > 0) {
      client.branchAddresses.forEach(
        (branchAddress: BranchAddress, index: number) => {
          this.branchAddresses.push(this.createBranchAddressItem());
          const data = {
            branchNumber: branchAddress.branchNumber,
            address: branchAddress.address,
            contactNumber: branchAddress.contactNumber,
            email: branchAddress.email.toLowerCase(),
          };

          const branchAddressFormGroup: FormGroup = this.branchAddresses
            .controls[index] as FormGroup;
          branchAddressFormGroup.patchValue(data);
        }
      );
    } else {
      this.branchAddresses.push(this.createBranchAddressItem());
    }
  }

  get branchAddresses(): FormArray {
    return this.branchAddressesForm.get('branchAddresses') as FormArray;
  }

  createBranchAddressItem(): FormGroup {
    return this.formBuilder.group({
      branchNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      address: new FormControl('', Validators.maxLength(255)),
      contactNumber: new FormControl('', [Validators.maxLength(50)]),
      email: new FormControl('', [Validators.maxLength(100), Validators.email]),
    });
  }

  get oftenContactPersons(): FormArray {
    return this.contactsForm.get('oftenContactPersons') as FormArray;
  }

  createOftenContactPersonItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      businessEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      personalEmail: new FormControl('', [Validators.email]),
      contactPersonTypeStr: new FormControl('', Validators.required),
    });
  }
}
