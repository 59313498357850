import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-entity-tile',
  templateUrl: './entity-tile.component.html',
  styleUrls: ['./entity-tile.component.scss'],
})
export class EntityTileComponent implements OnInit {
  @Input() entity!: Client;

  constructor() {}

  ngOnInit(): void {}
}
