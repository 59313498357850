import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { RequestsService } from 'src/app/core/services/requests/requests.service';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { Request } from 'src/app/shared/models/request/request.model';
import { User } from 'src/app/shared/models/user/user.model';
@Component({
  selector: 'app-new-customer-request',
  templateUrl: './new-customer-request.component.html',
  styleUrls: ['./new-customer-request.component.scss'],
})
export class NewCustomerRequestComponent implements OnInit {
  // Form
  customerRequestForm!: FormGroup;

  // Files
  pendingFiles: PendingFile[] = [];

  // Loading
  isCreatingRequest = false;
  isUploadingFile = false;
  failedToUploadFile = false;

  // Subscription
  userSubscription: Subscription;

  // Variables
  user!: User;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewCustomerRequestComponent>,
    private authenticationService: AuthenticationService,
    private requestsService: RequestsService,
    private snackBar: MatSnackBar,
    private jwtService: JwtService
  ) {
    this.setupCustomerRequestForm();
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
  }

  ngOnInit(): void {}

  setupCustomerRequestForm(): void {
    this.customerRequestForm = this.formBuilder.group({
      title: new FormControl('', Validators.required),
      remarks: new FormControl('', [
        Validators.required,
        Validators.maxLength(255),
      ]),
      file: new FormControl(''),
    });
  }

  uploadFilePressed(event: any): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    const pendingFile = {
      folderName: 'file',
      file: files[0],
    } as PendingFile;
    this.pendingFiles.push(pendingFile);
  }

  submitPressed(): void {
    if (this.customerRequestForm.invalid) {
      this.customerRequestForm.markAllAsTouched();
    } else {
      const formData = {
        title: this.customerRequestForm.get('title')?.value,
        remarks: this.customerRequestForm.get('title')?.value,
        creatorRefId: this.user.id,
        requestStatusRef: 'pending',
      };

      this.isCreatingRequest = true;
      this.customerRequestForm.disable();
      this.requestsService.createCustomerRequest(formData).subscribe({
        next: (createdRequest: Request) => {
          if (this.pendingFiles.length > 0) {
            this.isCreatingRequest = false;
            this.uploadFiles(createdRequest);
          } else {
            this.isCreatingRequest = false;
            this.dialogRef.close(true);
          }
        },
      });
    }
  }

  uploadFiles(createdRequest: Request): void {
    this.isUploadingFile = true;
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingFiles.length; index++) {
      const file: PendingFile = this.pendingFiles[index];
      formData.append(file.folderName, file.file);
    }

    this.requestsService
      .uploadRequestFile(formData, createdRequest.id)
      .subscribe({
        next: (_) => {
          this.isUploadingFile = false;
          this.dialogRef.close(true);
          this.jwtService.destroyContentType();
        },
        error: (error) => {
          console.log(error);
          this.isUploadingFile = false;
          this.failedToUploadFile = true;
          this.customerRequestForm.enable();
          this.snackBar.open(error, '', { duration: 3000 });
          this.jwtService.destroyContentType();
        },
      });
  }
}
