import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Device } from 'src/app/shared/enums/device.enum';
import { User } from 'src/app/shared/models/user/user.model';
import { UsersService } from './../../core/services/users/users.service';
import { CustomBreakpointService } from './../../shared/breakpoint/custom-breakpoint.service';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  filterName = '';
  pageSize = 10;
  currentPage = 0;
  length = 0;
  pageSizeOptions = [10, 20, 30, 40];
  users: Array<User> = [];
  isLoading = false;
  roles = null;
  status: number | null = null;
  createdStart: Date | null = null;
  createdEnd: Date | null = null;
  pageEvent!: PageEvent;

  constructor(
    private customBreakpointService: CustomBreakpointService,
    private usersService: UsersService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private datepipe: DatePipe
  ) {
    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        if (device === Device.mobile) {
          this.displayedColumns = ['id', 'name', 'email', 'options'];
        } else if (device === Device.tablet) {
          this.displayedColumns = ['id', 'name', 'email', 'status', 'options'];
        } else {
          this.displayedColumns = [
            'id',
            'name',
            'email',
            'identificationType',
            'role',
            'status',
            'createdDate',
            'options',
          ];
        }
      },
    });
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  getUsers(
    filterName?: string,
    pageSize?: number,
    currentPage?: number,
    roles?: Array<number>,
    status?: number,
    createdStart?: Date,
    createdEnd?: Date
  ): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', {
      duration: undefined,
    });
    const params: { [x: string]: any } = {
      name: filterName ?? this.filterName,
      size: pageSize ?? this.pageSize,
      page: currentPage ?? this.currentPage,
      role: roles ?? this.roles,
      active: status ?? this.status,
      createdDateFrom: this.datepipe.transform(
        createdStart ?? this.createdStart,
        'yyy-MM-dd 00:00:00'
      ),
      createdDateTo: this.datepipe.transform(
        createdEnd ?? this.createdEnd,
        'yyy-MM-dd 23:59:59'
      ),
    };

    this.usersService.getUsers(params as Map<any, any>).subscribe({
      next: (data) => {
        if (data !== null) {
          this.length = data.totalItems;
          this.users = data.users as User[];
        } else {
          this.length = 0;
          this.users = [];
        }
        this.dataSource.data = this.users;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  onPageChanged(pageEvent: PageEvent): PageEvent {
    this.currentPage = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;
    this.getUsers();
    return pageEvent;
  }

  showDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { user },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteUser(user);
        }
      },
    });
  }

  deleteUser(user: User): void {
    this.isLoading = true;
    this.snackBar.open(`Deleting "${user.name}"`, '', {
      duration: undefined,
    });
    this.usersService.deleteUser(user.id as number).subscribe({
      next: (result) => {
        this.getUsers();
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(`"${user.name}" deleted`, '', {
          duration: 3000,
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, '', {
          duration: 3000,
        });
      },
    });
  }
}
