import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { User } from 'src/app/shared/models/user/user.model';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { UserRole } from 'src/app/shared/enums/user-role.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  // User's Role
  isAdmin = false;
  isCustodian = false;
  isCustomer = false;

  // Subscription
  userSubscription: Subscription;

  // Variables
  user!: User;

  // Loading
  isLoading = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userAccessService: UserAccessService
  ) {
    // Get user from AuthenticationService
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
      },
    });
    // Get user's role
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          case UserRole.customer:
            this.isCustomer = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {}
}
