<h2 mat-dialog-title>Delete Service Details</h2>
<mat-dialog-content class="mat-typography">
  <h3>Are you sure you want to delete this service details?</h3>
  <p class="dialog-info-container">
    <span>Name: {{ serviceDetails.name }}</span>
    <br />
    <span>Email: {{ serviceDetails.email }}</span>
    <br />
    <span
      >Staff Remarks:
      {{
        serviceDetails.staffRemarks
          ? serviceDetails.staffRemarks
          : "Not available"
      }}</span
    >
  </p>
  <p></p>
  <p>
    This service details will be permanently deleted and will not be able to
    retrieve again.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
  <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
</mat-dialog-actions>
