import { ArticleCategory } from './../../../shared/models/article-category/article-category.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api_service/api.service';
import { Article } from 'src/app/shared/models/article/article.model';

@Injectable({
  providedIn: 'root',
})
export class ArticlesService {
  constructor(private apiService: ApiService) {}

  // Articles
  getArticles(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/articlessearch', data);
  }

  getDraftArticles(userID: number): Observable<Array<Article>> {
    return this.apiService.get(`/api/core/articles/drafts/${userID}`);
  }

  getArticleDetails(id: number): Observable<Article> {
    return this.apiService.get(`/api/core/articles/${id}`);
  }

  createArticle(data: object = {}): Observable<any> {
    return this.apiService.post('/api/core/articles', data);
  }

  updateArticle(data: object = {}, id: number): Observable<Article> {
    return this.apiService.put(`/api/core/articles/${id}`, data);
  }

  uploadArticleImage(data: FormData, articleID: number): Observable<any> {
    return this.apiService.post(
      `/api/core/articles/${articleID}/file`,
      data,
      true
    );
  }

  deleteArticleImage(id: number): Observable<any> {
    return this.apiService.delete(`/api/core/articles/file/${id}/delete`);
  }

  deleteArticle(id: number): Observable<any> {
    return this.apiService.delete(`/api/core/articles/${id}`);
  }

  // Article Category
  getAllArticleCategories(): Observable<Array<ArticleCategory>> {
    return this.apiService.get('/api/core/articles/categories');
  }
}
