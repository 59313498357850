import { ClientYearDetails } from './../../../../shared/models/client/client-year-details/client-year-details.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-year-dialog',
  templateUrl: './delete-year-dialog.component.html',
  styleUrls: ['./delete-year-dialog.component.scss'],
})
export class DeleteYearDialogComponent implements OnInit {
  clientYearDetails: ClientYearDetails;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.clientYearDetails = data.clientYearDetails;
  }

  ngOnInit(): void {}
}
