import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent implements OnInit {
  user: User;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.user = data.user;
  }

  ngOnInit(): void {}
}
