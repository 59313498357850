<form
  [formGroup]="auditorsForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title
      title="Add Auditor"
      *ngIf="!isMobile"
    ></app-section-title>
    <div formArrayName="auditors" fxLayout="column" fxLayoutGap="40px">
      <div
        *ngFor="
          let auditor of auditors.controls;
          let index = index;
          let last = last
        "
        [ngClass]="last ? 'border-primary-color' : 'border-grey-color'"
        class="dashed-container padding-16 margin-bottom-16"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="32px"
          fxLayoutAlign="start start"
          fxLayout.xs="column"
          fxLayoutAlign.xs="stretch"
          fxLayoutGap.xs=""
          class="width-100"
          [formGroupName]="index"
        >
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Start Firm Name -->
            <mat-form-field appearance="outline">
              <mat-label>Firm Name</mat-label>
              <input matInput type="text" formControlName="firmName" required />
              <mat-error
                *ngIf="
                  auditors.controls[index].get('firmName')?.hasError('required')
                "
              >
                Firm name is required
              </mat-error>
            </mat-form-field>
            <!-- End Firm Name -->

            <!-- Start Firm No -->
            <mat-form-field appearance="outline">
              <mat-label>Firm No.</mat-label>
              <input matInput type="text" formControlName="firmNo" required />
              <mat-error
                *ngIf="
                  auditors.controls[index].get('firmNo')?.hasError('required')
                "
              >
                Firm no. is required
              </mat-error>
            </mat-form-field>
            <!-- End Firm No -->

            <!-- Start Phone Number -->
            <mat-form-field class="contact-field" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <span matPrefix>+ &nbsp;</span>
              <input
                type="tel"
                matInput
                formControlName="firmContactNumber"
                required
              />
              <mat-error
                *ngIf="
                  auditors.controls[index]
                    .get('firmContactNumber')
                    ?.hasError('required')
                "
              >
                Phone number is required
              </mat-error>
            </mat-form-field>
            <!-- End Phone Number -->

            <!-- Start Firm Email -->
            <mat-form-field appearance="outline">
              <mat-label>Firm Email</mat-label>
              <input matInput type="email" formControlName="firmEmail" />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  auditors.controls[index].get('firmEmail')?.hasError('email')
                "
              >
                Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  auditors.controls[index]
                    .get('firmEmail')
                    ?.hasError('required')
                "
              >
                Firm email is required
              </mat-error>
            </mat-form-field>
            <!-- End Firm Email -->

            <!-- Start PIC Name -->
            <mat-form-field appearance="outline">
              <mat-label>PIC Name</mat-label>
              <input matInput type="text" formControlName="picName" />
              <mat-error
                *ngIf="
                  auditors.controls[index].get('picName')?.hasError('required')
                "
              >
                PIC name is required
              </mat-error>
            </mat-form-field>
            <!-- End PIC Name -->
          </div>
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Start Firm Address -->
            <mat-form-field appearance="outline" fxFlex="calc(50% - 16px)">
              <mat-label>Firm Address</mat-label>
              <textarea
                matInput
                type="text"
                rows="4"
                formControlName="firmAddress"
              ></textarea>
              <mat-error
                *ngIf="
                  auditors.controls[index]
                    .get('firmAddress')
                    ?.hasError('required')
                "
              >
                Firm Address is required
              </mat-error>
            </mat-form-field>
            <!-- End Firm Address -->

            <!-- Start Appointment On -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
            >
              <mat-label>Appointment On</mat-label>
              <input
                matInput
                [matDatepicker]="appointmentOnDateDP"
                formControlName="appointmentOnDateStr"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="appointmentOnDateDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #appointmentOnDateDP></mat-datepicker>
              <mat-error
                *ngIf="
                  auditors.controls[index]
                    .get('appointmentOnDateStr')
                    ?.hasError('required')
                "
              >
                Appointment On is required
              </mat-error>
            </mat-form-field>
            <!-- End Appointment On -->

            <!-- Start Resignation On -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
            >
              <mat-label>Resignation On</mat-label>
              <input
                matInput
                [matDatepicker]="resignationOnDateDP"
                formControlName="resignationOnDateStr"
                placeholder="dd/mm/yyyy"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="resignationOnDateDP"
              ></mat-datepicker-toggle>
              <mat-datepicker #resignationOnDateDP></mat-datepicker>
              <mat-error
                *ngIf="
                  auditors.controls[index]
                    .get('resignationOnDateStr')
                    ?.hasError('required')
                "
              >
                Resignation On is required
              </mat-error>
            </mat-form-field>
            <!-- End Resignation On -->
          </div>
        </div>
        <button
          mat-button
          type="button"
          color="warn"
          (click)="removeAuditor(index)"
          [disabled]="disabled"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Remove</span>
        </button>
      </div>
    </div>
    <button mat-button type="button" color="primary" (click)="addAuditor()">
      <mat-icon>add</mat-icon>
      <span>Auditor</span>
    </button>
  </div>
</form>
