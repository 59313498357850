import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { Client } from 'src/app/shared/models/client/client.model';
import { UsersService } from '../../../core/services/users/users.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  addUserForm: FormGroup = new FormGroup({
    roles: new FormControl([], Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    status: new FormControl(1, [Validators.required]),
    name: new FormControl('', [Validators.required]),
    identificationType: new FormControl('', [Validators.required]),
    identificationNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
    ]),
    handphoneNumber: new FormControl('', [Validators.required]),
    branch: new FormControl({ value: 'HQ', disabled: true }),
    department: new FormControl(null),
    clientAutoComplete: new FormControl(null),
  });
  editUserForm!: FormGroup;
  isCustomerFormSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  isCustomerForm$ = this.isCustomerFormSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  addingUser = false;
  selectedClients: Client[] = [];

  constructor(
    private userFormConstants: UserFormConstants,
    private snackBar: MatSnackBar,
    private usersService: UsersService,
    private location: Location
  ) {
    this.subscribeToRoles();
  }

  ngOnInit(): void {
    this.prefillForm();
  }

  prefillForm(): void {
    this.addUserForm.patchValue({
      roles: ['Customer'],
      status: 1,
      identificationType: 'IC',
    });
  }

  subscribeToRoles(): void {
    this.addUserForm.controls.roles.valueChanges.subscribe({
      next: (selectedRoles) => {
        if (selectedRoles !== null) {
          if (selectedRoles.length) {
            // If it is Admin/ Custodian/ Personnel
            if (selectedRoles.includes('Customer') === false) {
              this.isCustomerFormSubject.next(false);

              this.addUserForm.patchValue({
                identificationType: '',
                identificationNumber: '',
                handphoneNumber: '',
                branch: 'HQ',
                department: null,
              });
              this.addUserForm.controls.identificationType.setValidators(null);
              this.addUserForm.controls.identificationNumber.setValidators(
                null
              );
              this.addUserForm.controls.handphoneNumber.setValidators(null);
              this.addUserForm.controls.branch.setValidators([
                Validators.required,
              ]);
              this.addUserForm.controls.department.setValidators([
                Validators.required,
              ]);
            } else {
              this.isCustomerFormSubject.next(true);
              this.addUserForm.patchValue({
                identificationType: 'IC',
                identificationNumber: '',
                handphoneNumber: '',
                branch: '',
                department: null,
              });
              this.addUserForm.controls.identificationType.setValidators([
                Validators.required,
              ]);
              this.addUserForm.controls.identificationNumber.setValidators([
                Validators.required,
                Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
              ]);
              this.addUserForm.controls.handphoneNumber.setValidators([
                Validators.required,
              ]);
              this.addUserForm.controls.branch.setValidators(null);
              this.addUserForm.controls.department.setValidators(null);
            }
            this.addUserForm.updateValueAndValidity();
          }
        }
      },
    });
  }

  addUserPressed(): void {
    if (this.addUserForm.invalid) {
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const selectedClientIds = [];

      for (const client of this.selectedClients) {
        selectedClientIds.push(client.id);
      }

      const userMap = {
        name: this.addUserForm.get('name')?.value,
        email: this.addUserForm.get('email')?.value.toLowerCase(),
        branch: this.addUserForm.get('branch')?.value,
        department: this.addUserForm.get('department')?.value,
        active: this.addUserForm.get('status')?.value,
        role: this.addUserForm.get('roles')?.value,
        identificationType: this.addUserForm.get('identificationType')?.value,
        identificationNumber: this.addUserForm.get('identificationNumber')
          ?.value,
        handphoneNumber: this.addUserForm.get('handphoneNumber')?.value,
        clients: selectedClientIds.length ? selectedClientIds : null,
      };

      this.addingUser = true;
      this.addUserForm.disable();
      this.snackBar.open('Adding user', '', {
        duration: undefined,
      });
      this.usersService.addUser(userMap).subscribe({
        next: (result) => {
          this.addUserForm.reset();
          this.snackBar.dismiss();
          this.location.back();
        },
        error: (error) => {
          console.log(error);
          this.addUserForm.enable();
          this.addingUser = false;
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
    }
  }
}
