import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ArticleFormConstants } from 'src/app/shared/constants/article-form.constants';
import { ArticlesComponent } from '../articles.component';

@Component({
  selector: 'app-articles-filter',
  templateUrl: './articles-filter.component.html',
  styleUrls: ['./articles-filter.component.scss'],
})
export class ArticlesFilterComponent implements OnInit {
  categoryTypes: Array<{ value: string; valueName: string }>;
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private formBuilder: FormBuilder,
    private articleFormConstants: ArticleFormConstants,
    private articlesComponent: ArticlesComponent
  ) {
    this.categoryTypes = this.articleFormConstants.CATEGORY_TYPES;
    this.filterForm = this.formBuilder.group({
      filterArticle: new FormControl(''),
      category: new FormControl('ALL'),
      createdStart: new FormControl(null),
      createdEnd: new FormControl(null),
      publishedStart: new FormControl(null),
      publishedEnd: new FormControl(null),
      updatedStart: new FormControl(null),
      updatedEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.articlesComponent.pageSize;
    this.currentPage = this.articlesComponent.currentPage;

    const filterArticle = this.filterForm.get('filterArticle')?.value;
    const category = this.filterForm.get('category')?.value;
    const createdStart = this.filterForm.get('createdStart')?.value;
    const createdEnd = this.filterForm.get('createdEnd')?.value;
    const publishedStart = this.filterForm.get('publishedStart')?.value;
    const publishedEnd = this.filterForm.get('publishedEnd')?.value;
    const updatedStart = this.filterForm.get('updatedStart')?.value;
    const updatedEnd = this.filterForm.get('updatedEnd')?.value;

    this.articlesComponent.getArticles(
      filterArticle,
      this.pageSize,
      this.currentPage,
      category,
      createdStart,
      createdEnd,
      publishedStart,
      publishedEnd,
      updatedStart,
      updatedEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.articlesComponent.getArticles();
  }
}
