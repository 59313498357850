import { MatDialog } from '@angular/material/dialog';
import { DeleteYearDialogComponent } from './../delete-year-dialog/delete-year-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from './../../../../core/services/clients/clients.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { ServiceType } from 'src/app/shared/models/service-type/service-type.model';
import { ClientFormConstants } from 'src/app/shared/constants/client-form.constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-year-details',
  templateUrl: './year-details.component.html',
  styleUrls: ['./year-details.component.scss'],
})
export class YearDetailsComponent implements OnInit {
  // Variables
  clientType!: string;
  yearID!: number;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;

  // Data
  clientYearDetails!: ClientYearDetails;

  // List
  serviceTypes: Array<{ value: string; valueName: string }>;

  // Loading
  clientYearDetailsIsLoading = false;
  isDeleting = false;

  // Value
  isFreelancer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private clientTypeService: ClientTypeService,
    private clientFormConstants: ClientFormConstants
  ) {
    // Get client type
    this.clientTypeService.getClientType();
    this.subscribeToClientType();
    this.clientType = this.clientTypeService.clientTypeStr;

    // Service Types
    this.serviceTypes = this.clientFormConstants.SERVICE_TYPES;

    // Get year ID
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.yearID = params.year;
        this.getClientYearDetails();
      },
    });
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {}

  getClientYearDetails(): void {
    this.clientYearDetailsIsLoading = true;
    this.snackBar.open('Loading details', '', {
      duration: undefined,
    });
    this.clientsService.getClientDetailsForSpecificYear(this.yearID).subscribe({
      next: (clientYearDetails: ClientYearDetails) => {
        this.clientYearDetails = clientYearDetails;
        if (
          clientYearDetails.clientBean?.type?.type &&
          clientYearDetails.clientBean.identityNumber
        ) {
          this.isFreelancer.next(true);
        }
        this.snackBar.dismiss();
        this.clientYearDetailsIsLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.clientYearDetailsIsLoading = false;
      },
    });
  }

  showConfirmDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteYearDialogComponent, {
      data: { clientYearDetails: this.clientYearDetails },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteYear();
        }
      },
    });
  }

  deleteYear(): void {
    this.isDeleting = true;
    this.snackBar.open(`Deleting year ${this.clientYearDetails.year}`, '', {
      duration: undefined,
    });
    this.clientsService
      .deleteYearDetails(
        this.clientYearDetails.clientBean.id,
        this.clientYearDetails.id,
        this.clientType
      )
      .subscribe({
        next: (_) => {
          this.isDeleting = false;
          this.router.navigateByUrl(
            `/clients/${this.clientType}/details/${this.clientYearDetails.clientBean.id}`
          );
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.isDeleting = false;
        },
      });
  }

  displayServiceType(serviceType: ServiceType): string {
    const filteredServiceTypeList: { value: string; valueName: string }[] =
      this.serviceTypes.filter(
        (serviceTypeInList) => serviceTypeInList.value === serviceType.type
      );
    if (filteredServiceTypeList.length) {
      return filteredServiceTypeList[0].valueName;
    } else {
      return 'Could not match service type';
    }
  }
}
