import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { Component, OnInit } from '@angular/core';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';

@Component({
  selector: 'app-create-year-details-success',
  templateUrl: './create-year-details-success.component.html',
  styleUrls: ['./create-year-details-success.component.scss'],
})
export class CreateYearDetailsSuccessComponent implements OnInit {
  createdYearDetails!: ClientYearDetails;

  // Variables
  clientType!: string;
  pendingFiles: PendingFile[] = [];

  // Loading
  isUploading = false;

  // Boolean
  isUploaded = false;

  constructor(
    private clientTypeService: ClientTypeService,
    private clientsService: ClientsService,
    private jwtService: JwtService
  ) {
    // Get client type
    this.clientTypeService.getClientType();
    this.clientType = this.clientTypeService.clientTypeStr;
  }

  ngOnInit(): void {
    this.createdYearDetails = history.state.data;
    this.pendingFiles = history.state.files;

    if (this.pendingFiles?.length > 0) {
      this.uploadFiles(this.createdYearDetails);
    }
  }

  uploadFiles(clientYearDetails: ClientYearDetails): void {
    this.isUploading = true;
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingFiles.length; index++) {
      const file: PendingFile = this.pendingFiles[index];
      formData.append(file.folderName, file.file);
    }

    this.clientsService
      .uploadClientYearFiles(formData, clientYearDetails.id, this.clientType)
      .subscribe({
        next: (createdYearDetails: ClientYearDetails) => {
          this.isUploading = false;
          this.isUploaded = true;
          this.jwtService.destroyContentType();
        },
        error: (error) => {
          console.log(error);
          this.isUploading = false;
          this.isUploaded = false;
          this.jwtService.destroyContentType();
        },
      });
  }
}
