<div fxLayout="column">
  <p class="bold-500">Enquiries</p>
  <div
    class="border border-color border-radius-8 enquiries-container"
    ngClass="desktop"
    ngClass.xs="mobile"
  >
    <mat-list role="list" *ngIf="isLoading">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">Loading</p>
        <p mat-line class="subtitle">Loading</p>
        <small class="subtitle">Loading</small>
      </mat-list-item>
    </mat-list>
    <mat-list role="list" *ngIf="!isLoading && !enquiries.length">
      <mat-list-item role="listitem">
        <p mat-line class="subtitle">No unread enquiries available</p>
      </mat-list-item>
    </mat-list>
    <mat-nav-list *ngIf="!isLoading && enquiries">
      <a
        mat-list-item
        role="listitem"
        *ngFor="let enquiry of enquiries; let isLast = last"
        [routerLink]="['/enquiries', 'details', enquiry.id]"
      >
        <p mat-line>{{ enquiry.name }}</p>
        <p mat-line class="subtitle">
          {{ enquiry.title }} — {{ enquiry.description }}
        </p>
        <small
          fxFlex="20%"
          fxFlex.xs="20%"
          fxFlex.md="25%"
          fxFlex.sm="23%"
          class="text-align-right subtitle"
          >{{ getTimeAgo(enquiry.createdDate) }}</small
        >
        <mat-divider *ngIf="!isLast"></mat-divider>
      </a>
    </mat-nav-list>
  </div>
</div>
