import { Injectable } from '@angular/core';
import { Service } from '../models/service/service.model';

@Injectable({
  providedIn: 'root',
})
export class ServicesConstants {
  public SERVICES = [
    {
      value: 'TAX',
      title: 'Tax',
      description: 'Imposition of compulsory levies on taxpayers to government',
      iconName: 'point_of_sale',
    },
    {
      value: 'SECRETARIAL',
      title: 'Secretarial',
      description:
        'The legal process used to form a corporate entity or company',
      iconName: 'business',
    },
    {
      value: 'SHARED_SERVICES',
      title: 'Shared Services',
      description:
        'Process of recording financial transactions pertaining to a business',
      iconName: 'calculate',
    },
    {
      value: 'AUDIT',
      title: 'Audit',
      description: 'Examination of the financial report of the organization',
      iconName: 'policy',
    },
  ];

  public STATUSES = [
    { value: 'PENDING', valueName: 'Pending' },
    { value: 'FOLLOWING_UP', valueName: 'Following Up' },
    { value: 'COMPLETED', valueName: 'Completed' },
  ];
}
