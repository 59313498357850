import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { RequestsService } from 'src/app/core/services/requests/requests.service';
import { Request } from 'src/app/shared/models/request/request.model';
import { User } from 'src/app/shared/models/user/user.model';

@Component({
  selector: 'app-assigned-department-requests',
  templateUrl: './assigned-department-requests.component.html',
  styleUrls: ['./assigned-department-requests.component.scss'],
})
export class AssignedDepartmentRequestsComponent implements OnInit {
  // Loading
  isLoading = false;

  // Variables
  requests!: Array<Request>;
  user!: User;

  constructor(
    private snackBar: MatSnackBar,
    private requestsService: RequestsService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id && !this.isLoading) {
          this.getAssignedDepartmentRequests();
        }
      },
    });
  }

  ngOnInit(): void {}

  getAssignedDepartmentRequests(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });

    const formData = {
      remarks: null,
      page: 0,
      size: 10,
      status: 'ASSIGNED_TO_DEPARTMENT',
      receivedDateFrom: null,
      receivedDateTo: null,
      department: this.user.department?.department,
      assignedUser: null,
    };

    this.requestsService.getRequests(formData).subscribe({
      next: (data) => {
        if (data != null) {
          this.requests = data.requests as Request[];
          this.requests.sort(
            (a: Request, b: Request) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          );
        } else {
          this.requests = [] as Request[];
        }
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  getTimeAgo(date: Date): string {
    return moment(date).fromNow();
  }
}
