import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from './../users/users.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { User } from 'src/app/shared/models/user/user.model';
import { ApiService } from './../api_service/api.service';
import { JwtService } from './../jwt/jwt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User>({} as User);
  public user$ = this.userSubject.asObservable().pipe(distinctUntilChanged());

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private usersService: UsersService,
    private snackBar: MatSnackBar
  ) {}

  checkAuth(): void {
    const token = this.jwtService.getToken();
    const userID = this.jwtService.getUserId();

    if (token && userID) {
      this.isAuthenticatedSubject.next(true);
      this.getUserDetails(parseInt(userID, 10) as number);
    } else {
      this.signOut();
    }
  }

  setAuth(user: User): void {
    // Save JWT sent from server in localstorage
    if (user.accessToken && user.id) {
      this.jwtService.saveToken(user.accessToken);
      this.jwtService.saveUserId(user.id);
    }
    // Set current user data into observable
    this.getUserDetails(user.id as number);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  getUserDetails(userID: number): void {
    this.isLoadingSubject.next(true);
    this.usersService.getUserDetails(userID).subscribe({
      next: (user: User) => {
        this.isLoadingSubject.next(false);
        this.userSubject.next(user);
      },
      error: (error) => {
        this.isLoadingSubject.next(false);
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  // Sign in user with email and password
  passwordSignIn(credentials: Map<any, any>): Observable<User> {
    return this.apiService.post('/api/auth/signin', credentials).pipe(
      map((data: User) => {
        this.setAuth(data);
        return data;
      })
    );
  }

  requestForgotPasswordEmail(email: string): Observable<any> {
    return this.apiService.post(`/api/auth/forgot-password?email=${email}`);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.apiService.put(
      `/api/auth/reset-password?token=${token}&password=${password}`
    );
  }

  // Sign user out and navigate to root url
  signOut(): void {
    // Remove Content-Type header
    this.jwtService.destroyContentType();
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.userSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
  }
}
