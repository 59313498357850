import { Component, Input, OnInit } from '@angular/core';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  @Input() customerEntityDetails!: Client;
  @Input() isLoading = false;

  constructor() {}

  ngOnInit(): void {}
}
