<div fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="16px">
  <p class="bold-500">Notifications</p>
  <div
    fxLayout="row"
    ngClass="min-height-30 overflow-x width-100 padding-bottom-16"
    fxLayout.xs="column"
    fxLayoutAlign="start start"
    ngClass.xs=""
    fxLayoutGap="16px"
  >
    <div *ngIf="notifications.length < 1">
      <i class="not-available">You're all caught up</i>
    </div>
    <!-- Start Notification Item -->
    <div
      class="border border-color border-radius-8 padding-16"
      ngClass="width-30"
      ngClass.xs="width-100"
      ngClass.sm="width-30"
      ngClass.md="width-30"
      ngClass.lg="width-25"
      ngClass.xl="width-15"
      fxLayout="column"
      fxLayoutAlign="stretch"
      fxLayoutGap="16px"
      *ngFor="let notification of notifications"
    >
      <!-- Start Title & Description -->
      <div fxLayout="column" fxLayoutGap="0px">
        <h2>{{ notification.title }}</h2>
        <p>
          {{ notification.description }}
        </p>
      </div>
      <!-- End Title & Description -->
      <div *ngIf="notification.dueDate">
        <p>
          Due Date: <b>{{ notification.dueDate | date }}</b>
        </p>
      </div>
      <!-- Start Actions Required -->
      <div *ngIf="notification.actionDescription">
        <p class="no-margin">Actions required:</p>
        <p>{{ notification.actionDescription }}</p>
      </div>
      <!-- End Actions Required -->
      <div>
        <small class="italic">{{
          getNotificationTimeAgo(notification.createdDate)
        }}</small>
      </div>
      <!-- Start Mark As Read Button -->
      <div fxLayout="row" fxLayoutAlign="center center" class="margin-top-16">
        <button
          mat-stroked-button
          class="uppercase"
          color="primary"
          (click)="markAsReadPressed(notification)"
        >
          Mark as read
        </button>
      </div>
      <!-- End Mark As Read Button -->
    </div>
    <!-- End Notification Item -->
  </div>
</div>
