import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ServiceListComponent } from '../service-list.component';

@Component({
  selector: 'app-services-list-filter',
  templateUrl: './services-list-filter.component.html',
  styleUrls: ['./services-list-filter.component.scss'],
})
export class ServicesListFilterComponent implements OnInit {
  filterForm: FormGroup;
  pageSize = 10;
  currentPage = 0;

  constructor(
    private formBuilder: FormBuilder,
    private serviceListComponent: ServiceListComponent
  ) {
    this.filterForm = this.formBuilder.group({
      filterService: new FormControl(''),
      receivedStart: new FormControl(null),
      receivedEnd: new FormControl(null),
    });
  }

  ngOnInit(): void {}

  applyFilter(): void {
    this.pageSize = this.serviceListComponent.pageSize;
    this.currentPage = this.serviceListComponent.currentPage;

    const filterService = this.filterForm.get('filterService')?.value;
    const receivedStart = this.filterForm.get('receivedStart')?.value;
    const receivedEnd = this.filterForm.get('receivedEnd')?.value;

    this.serviceListComponent.getServices(
      filterService,
      this.pageSize,
      this.currentPage,
      receivedStart,
      receivedEnd
    );
  }

  resetFilters(): void {
    this.filterForm.reset();
    this.serviceListComponent.getServices();
  }
}
