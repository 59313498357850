<form
  [formGroup]="contactsForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title title="Add Often Contact Persons"></app-section-title>
    <div
      formArrayName="oftenContactPersons"
      fxLayout="column"
      fxLayoutGap="40px"
    >
      <div
        *ngFor="
          let oftenContactPerson of oftenContactPersons.controls;
          let index = index;
          let last = last
        "
        [ngClass]="last ? 'border-primary-color' : 'border-grey-color'"
        class="dashed-container padding-16 margin-bottom-16"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="32px"
          fxLayoutAlign="start start"
          fxLayout.xs="column"
          fxLayoutAlign.xs="stretch"
          fxLayoutGap.xs=""
          class="width-100"
          [formGroupName]="index"
        >
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Name -->
            <mat-form-field appearance="outline">
              <mat-label>Name</mat-label>
              <input matInput type="text" formControlName="name" required />
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('name')
                    ?.hasError('required')
                "
              >
                Name is required
              </mat-error>
            </mat-form-field>

            <!-- Phone Number -->
            <mat-form-field class="contact-field" appearance="outline">
              <mat-label>Phone Number</mat-label>
              <span matPrefix>+ &nbsp;</span>
              <input
                type="tel"
                matInput
                formControlName="phoneNumber"
                required
              />
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('phoneNumber')
                    ?.hasError('required')
                "
              >
                Phone number is required
              </mat-error>
            </mat-form-field>

            <!-- Contact Person Type -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              fxFlex="calc(50% - 16px)"
            >
              <mat-label>Contact Person Type</mat-label>
              <mat-select formControlName="contactPersonTypeStr" required>
                <mat-option
                  *ngFor="
                    let contactPersonType of contactPersonTypesOptions | async
                  "
                  [value]="contactPersonType"
                >
                  {{ contactPersonType }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('contactPersonTypeStr')
                    ?.hasError('required')
                "
              >
                Contact person type is required
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Personal Email -->
            <mat-form-field appearance="outline">
              <mat-label>Personal Email</mat-label>
              <input matInput type="email" formControlName="personalEmail" />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('personalEmail')
                    ?.hasError('email')
                "
              >
                Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('personalEmail')
                    ?.hasError('required')
                "
              >
                Personal email is required
              </mat-error>
            </mat-form-field>

            <!-- Business Email -->
            <mat-form-field appearance="outline">
              <mat-label>Business Email</mat-label>
              <input
                matInput
                type="email"
                formControlName="businessEmail"
                required
              />
              <mat-icon matSuffix>alternate_email</mat-icon>
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('businessEmail')
                    ?.hasError('email')
                "
              >
                Please enter a valid email address
              </mat-error>
              <mat-error
                *ngIf="
                  oftenContactPersons.controls[index]
                    .get('businessEmail')
                    ?.hasError('required')
                "
              >
                Business email is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button
          mat-button
          type="button"
          color="warn"
          *ngIf="oftenContactPersons.controls.length > 1"
          (click)="removeOftenContactPerson(index)"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Remove</span>
        </button>
      </div>
    </div>
    <button
      mat-button
      type="button"
      color="primary"
      (click)="addOftenContactPerson()"
    >
      <mat-icon>add</mat-icon>
      <span>Contact Person</span>
    </button>
  </div>
</form>
