import { DashboardService } from './../../../core/services/dashboard/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { Activity } from 'src/app/shared/models/activity/activity.model';
import * as moment from 'moment';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss'],
})
export class ActivitiesComponent implements OnInit {
  // Loading
  isLoading = false;

  // Variables
  activities!: Array<Activity>;

  constructor(
    private snackBar: MatSnackBar,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.getActivities();
  }

  getActivities(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.dashboardService.getActivities().subscribe({
      next: (activities: Array<Activity>) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        activities.sort(
          (a: Activity, b: Activity) =>
            new Date(b.revisionDate).getTime() -
            new Date(a.revisionDate).getTime()
        );
        this.activities = activities;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  getTimeAgo(date: Date): string {
    return moment(date).fromNow();
  }
}
