import { MatDialog } from '@angular/material/dialog';
import { ServicesConstants } from './../../../shared/constants/services-constants';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/shared/models/client/client.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Service } from 'src/app/shared/models/service/service.model';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { CustomerEntitiesService } from 'src/app/core/services/customer-entities/customer-entities.service';
import { Year } from 'src/app/shared/models/year/year.model';
import { NotSubscribedServiceDialogComponent } from './not-subscribed-service-dialog/not-subscribed-service-dialog.component';

@Component({
  selector: 'app-customer-entity-details',
  templateUrl: './customer-entity-details.component.html',
  styleUrls: ['./customer-entity-details.component.scss'],
})
export class CustomerEntityDetailsComponent implements OnInit {
  // Service List
  availableServices!: Service[];
  subscribedServices!: Service[];
  notSubscribedServices!: Service[];

  // Variables
  customerEntityDetails!: Client;
  entityYearDetails!: ClientYearDetails;
  availableYears!: Array<Year>;
  entityID!: number;
  selectedYearID!: number;

  // Loading
  isLoading = false;
  isYearsLoading = false;
  isYearDetailsLoading = false;

  // Conditional Renders
  clientHasCurrentYear = false;

  constructor(
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private clientsService: ClientsService,
    private servicesConstants: ServicesConstants,
    private customerEntitiesService: CustomerEntitiesService,
    private dialog: MatDialog
  ) {
    // Get client ID
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.entityID = params.id;
        if (this.entityID) {
          this.getCustomerEntityDetails();
        }
      },
    });
  }

  ngOnInit(): void {
    this.availableServices = this.servicesConstants.SERVICES as Service[];
  }

  getCustomerEntityDetails(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: 3000 });
    this.clientsService.getClientDetails(this.entityID).subscribe({
      next: (entity: Client) => {
        this.isLoading = false;
        this.snackBar.dismiss();
        this.customerEntityDetails = entity;
        this.getCustomerEntityAvailableYears();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  getCustomerEntityAvailableYears(): void {
    this.availableYears = [];
    this.isYearsLoading = true;
    this.customerEntitiesService
      .getCustomerEntitiesAvailableYears(this.customerEntityDetails.id)
      .subscribe({
        next: (availableYears: Array<Year>) => {
          if (availableYears) {
            this.checkCurrentYearDetailsExist(availableYears);
            const sortedYear = availableYears.sort((a: Year, b: Year) =>
              b.year.localeCompare(a.year)
            );
            this.availableYears = sortedYear;
            this.selectedYearID = this.availableYears[0].id;
            this.getYearDetails(this.selectedYearID);
          } else {
            this.clientHasCurrentYear = false;
          }
          this.isYearsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.isYearsLoading = false;
          this.snackBar.open(error, '', { duration: 3000 });
        },
      });
  }

  filterSubscribedServiceList(
    isSubscribed: boolean,
    entity: Client
  ): Service[] {
    if (isSubscribed) {
      return this.availableServices.filter(({ value: id1 }) =>
        entity.departments.some(({ department: id2 }) => id1 === id2)
      );
    } else {
      return this.availableServices.filter(
        ({ value: id1 }) =>
          !entity.departments.some(({ department: id2 }) => id1 === id2)
      );
    }
  }

  checkCurrentYearDetailsExist(years: Array<Year>): void {
    // Get current year
    const currentYear: number = new Date().getFullYear();

    // Check if there is any current year details
    const matchingCurrentYear: Year = years.find(
      (year) => year.year === currentYear.toString()
    ) as Year;

    if (matchingCurrentYear) {
      this.clientHasCurrentYear = true;
    } else {
      this.clientHasCurrentYear = false;
    }
  }

  onYearChanged(yearID: number): void {
    this.selectedYearID = yearID;
    this.getYearDetails(yearID);
  }

  getYearDetails(yearID: number): void {
    this.isYearDetailsLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.clientsService.getClientDetailsForSpecificYear(yearID).subscribe({
      next: (entityYearDetails: ClientYearDetails) => {
        this.snackBar.dismiss();
        this.isYearDetailsLoading = false;
        this.entityYearDetails = entityYearDetails;
        this.customerEntityDetails = entityYearDetails.clientBean;
        this.subscribedServices = this.filterSubscribedServiceList(
          true,
          entityYearDetails.clientBean
        );
        this.notSubscribedServices = this.filterSubscribedServiceList(
          false,
          entityYearDetails.clientBean
        );
      },
      error: (error) => {
        console.log(error);
        this.isYearDetailsLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }

  showNotSubscribedServiceDialog(selectedService: Service): void {
    const dialogRef = this.dialog.open(NotSubscribedServiceDialogComponent, {
      data: { service: selectedService },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          window.open('https://eurogain.com.my/', '_blank');
        }
      },
    });
  }
}
