<section fxLayout="column" fxLayoutAlign="start stretch" class="padding-24">
  <!-- Title -->
  <div
    fxLayout="row"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between center"
    class="width-100 margin-bottom-24"
  >
    <app-page-title
      title="Clients"
      subtitle="Manage companies, all at one place"
    ></app-page-title>
  </div>

  <div fxLayout="column" fxLayoutGap="32px">
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">
      <label>You have access</label>
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayout.xs="column"
        fxLayoutGap.xs="0px"
        class="width-100"
      >
        <app-service-tile
          *ngFor="let service of accessibleServices"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="calc(45% - 16px)"
          fxFlex.md="calc(45% - 16px)"
          [title]="service.title"
          [description]="service.description"
          [iconName]="service.iconName"
          [routerLink]="[service.value.toLowerCase()]"
        ></app-service-tile>
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="16px"
      *ngIf="notAccessibleServices?.length"
    >
      <label>Do not have access</label>
      <div fxLayout="row wrap" fxLayoutGap="16px grid" class="width-100">
        <app-service-tile
          *ngFor="let service of notAccessibleServices"
          fxFlex="33%"
          fxFlex.xs="100%"
          fxFlex.sm="calc(45% - 16px)"
          fxFlex.md="calc(45% - 16px)"
          [title]="service.title"
          [description]="service.description"
          [iconName]="service.iconName"
          [routerLink]="[service.value.toLowerCase()]"
          [enabled]="false"
        ></app-service-tile>
      </div>
    </div>
  </div>
</section>
