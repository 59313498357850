import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { ServicesConstants } from 'src/app/shared/constants/services-constants';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { Service } from 'src/app/shared/models/service/service.model';
import { User } from 'src/app/shared/models/user/user.model';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  availableServices!: Array<Service>;
  accessibleServices!: Array<Service>;
  notAccessibleServices!: Array<Service>;
  user!: User;
  isAdmin = false;

  constructor(
    private servicesConstants: ServicesConstants,
    private authenticationService: AuthenticationService,
    private userAccessService: UserAccessService
  ) {}

  ngOnInit(): void {
    this.availableServices = this.servicesConstants.SERVICES as Array<Service>;
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          default:
            this.isAdmin = false;
            break;
        }
      },
    });
    this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.isAdmin) {
          this.accessibleServices = this.availableServices;
          this.notAccessibleServices = [];
        } else if (this.user.department) {
          this.accessibleServices = this.filterAvailableServiceList(true);
          this.notAccessibleServices = this.filterAvailableServiceList(false);
        }
      },
    });
  }

  filterAvailableServiceList(hasAccess: boolean): Array<Service> {
    if (hasAccess) {
      return this.availableServices.filter(
        (service) => service.value === this.user.department?.department
      ) as Array<Service>;
    } else {
      return this.availableServices.filter(
        (service) => service.value !== this.user.department?.department
      ) as Array<Service>;
    }
  }
}
