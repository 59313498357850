import { MatSnackBar } from '@angular/material/snack-bar';
import { DashboardService } from './../../../core/services/dashboard/dashboard.service';
import { Component, OnInit } from '@angular/core';
import { UserRole } from 'src/app/shared/enums/user-role.enum';
import { UserAccessService } from 'src/app/shared/user-access/user-access.service';
import { WeeklyStats } from 'src/app/shared/models/weekly-stats/weekly-stats.model';

@Component({
  selector: 'app-weekly-stats',
  templateUrl: './weekly-stats.component.html',
  styleUrls: ['./weekly-stats.component.scss'],
})
export class WeeklyStatsComponent implements OnInit {
  // User's Role
  isAdmin = false;
  isCustodian = false;
  isCustomer = false;

  // Loading
  isLoading = false;

  // Variables
  weeklyStats!: Array<WeeklyStats>;

  constructor(
    private userAccessService: UserAccessService,
    private dashboardService: DashboardService,
    private snackBar: MatSnackBar
  ) {
    this.userAccessService.userRole$.subscribe({
      next: (userRole: UserRole) => {
        switch (userRole) {
          case UserRole.admin:
            this.isAdmin = true;
            break;
          case UserRole.custodian:
            this.isCustodian = true;
            break;
          case UserRole.customer:
            this.isCustomer = true;
            break;
          default:
            this.isAdmin = false;
            this.isCustodian = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.getWeeklyStats();
  }

  getWeeklyStats(): void {
    this.isLoading = false;
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.dashboardService.getWeeklyStats().subscribe({
      next: (weeklyStats: Array<WeeklyStats>) => {
        this.weeklyStats = weeklyStats;
        this.isLoading = false;
        this.snackBar.dismiss();
      },
      error: (error) => {
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }
}
