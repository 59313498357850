<form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
  <mat-form-field appearance="outline" class="width-100">
    <button matPrefix mat-icon-button type="button" (click)="applyFilter()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-label>Search articles</mat-label>
    <input matInput type="text" formControlName="filterArticle" />
    <button
      mat-icon-button
      matSuffix
      type="button"
      [matMenuTriggerFor]="filters"
      (click)="$event.stopPropagation()"
    >
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #filters="matMenu" class="padding-16">
      <div fxLayout="column">
        <!-- Category -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            <mat-option value="ALL">All</mat-option>
            <mat-option
              *ngFor="let categoryType of categoryTypes"
              [value]="categoryType.value"
            >
              {{ categoryType.valueName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Created Date -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Created Date (Range)</mat-label>
          <mat-date-range-input [rangePicker]="createdPicker">
            <input
              matStartDate
              formControlName="createdStart"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="createdEnd"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="createdPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #createdPicker></mat-date-range-picker>
        </mat-form-field>

        <!-- Published Date -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Published Date (Range)</mat-label>
          <mat-date-range-input [rangePicker]="publishedPicker">
            <input
              matStartDate
              formControlName="publishedStart"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="publishedEnd"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="publishedPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #publishedPicker></mat-date-range-picker>
        </mat-form-field>

        <!-- Updated Date -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Updated Date (Range)</mat-label>
          <mat-date-range-input [rangePicker]="updatedPicker">
            <input
              matStartDate
              formControlName="updatedStart"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="updatedEnd"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="updatedPicker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #updatedPicker></mat-date-range-picker>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button mat-button (click)="resetFilters()">CLEAR</button>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="applyFilter()"
          >
            FILTER
          </button>
        </div>
      </div>
    </mat-menu>
  </mat-form-field>
</form>
