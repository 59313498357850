import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-copy-year-dialog',
  templateUrl: './copy-year-dialog.component.html',
  styleUrls: ['./copy-year-dialog.component.scss'],
})
export class CopyYearDialogComponent implements OnInit {
  year: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.year = Number(data.year);
  }

  ngOnInit(): void {}
}
