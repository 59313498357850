<section
  fxLayout="column"
  fxLayoutAlign="start stretch"
  class="padding-24"
  fxLayoutGap="40px"
>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="16px"
    class="width-100"
    *ngIf="!isLoading"
  >
    <button
      mat-stroked-button
      [routerLink]="['/services', clientTypeStr]"
      class="back-button"
    >
      <mat-icon>arrow_back</mat-icon>
      <span class="capitalize">
        {{
          clientTypeStr !== "shared_services"
            ? clientTypeStr
            : "Shared Services"
        }}
      </span>
    </button>

    <!-- Action Buttons -->
    <!-- Mobile -->
    <button mat-stroked-button [matMenuTriggerFor]="menu" fxHide fxShow.xs>
      <span>More</span>
      <mat-icon class="margin-left-8">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        class="uppercase"
        (click)="markAsReadPressed(!serviceDetails.hasRead, false)"
      >
        <mat-icon>{{
          serviceDetails.hasRead ? "mark_as_unread" : "done_all"
        }}</mat-icon>
        <span class="margin-left-8">{{
          serviceDetails.hasRead ? "Mark as unread" : "Mark as read"
        }}</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item class="uppercase" (click)="showDeleteDialog()">
        <mat-icon>delete_outline</mat-icon>
        <span class="margin-left-8">Delete</span>
      </button>
    </mat-menu>

    <!-- Desktop -->
    <div fxLayout="row" fxLayoutGap="16px" fxHide.xs>
      <button
        mat-stroked-button
        class="uppercase"
        (click)="markAsReadPressed(!serviceDetails.hasRead, false)"
      >
        <mat-icon>{{
          serviceDetails.hasRead ? "mark_as_unread" : "done_all"
        }}</mat-icon>
        <span class="margin-left-8">{{
          serviceDetails.hasRead ? "Mark as unread" : "Mark as read"
        }}</span>
      </button>
      <mat-divider vertical style="height: 40px"></mat-divider>
      <button mat-stroked-button class="uppercase" (click)="showDeleteDialog()">
        <mat-icon>delete_outline</mat-icon>
        <span class="margin-left-8">Delete</span>
      </button>
    </div>
  </div>
  <form [formGroup]="receivedEnquiryForm">
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayout.xs="column"
      fxLayoutGap.xs="0px"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Name -->
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" formControlName="name" required />
          <mat-error
            *ngIf="receivedEnquiryForm.controls['name'].hasError('required')"
          >
            Name is required
          </mat-error>
        </mat-form-field>

        <!-- Phone Number -->
        <mat-form-field class="contact-field" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <span matPrefix>+ &nbsp;</span>
          <input type="tel" matInput formControlName="phoneNumber" required />
          <mat-error
            *ngIf="
              receivedEnquiryForm.controls['phoneNumber'].hasError('required')
            "
          >
            Phone number is required
          </mat-error>
        </mat-form-field>

        <!-- Email -->
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" required />
          <mat-icon matSuffix>alternate_email</mat-icon>
          <mat-error
            *ngIf="receivedEnquiryForm.controls['email'].hasError('email')"
          >
            Please enter a valid email address
          </mat-error>
          <mat-error
            *ngIf="receivedEnquiryForm.controls['email'].hasError('required')"
          >
            Email is required
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Remarks -->
        <mat-form-field appearance="outline">
          <mat-label>Remarks</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="remarks"
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>

  <mat-divider></mat-divider>

  <form [formGroup]="staffRemarksForm" (ngSubmit)="updatePressed()">
    <app-section-title title="Staff Remarks"></app-section-title>
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayout.xs="column"
      fxLayoutGap.xs="0px"
    >
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Status -->
        <mat-form-field appearance="outline" (click)="$event.stopPropagation()">
          <mat-label>Status</mat-label>
          <mat-select formControlName="status" required>
            <mat-option *ngFor="let status of statuses" [value]="status.value">
              {{ status.valueName }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="staffRemarksForm.controls['status'].hasError('required')"
          >
            Status is required
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
        <!-- Remarks -->
        <mat-form-field appearance="outline">
          <mat-label>Remarks</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="remarks"
            rows="4"
          ></textarea>
          <mat-error
            *ngIf="staffRemarksForm.controls['remarks'].hasError('required')"
          >
            Remarks is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="isLoading || refreshNeeded"
      >
        Update
      </button>
    </div>
  </form>
</section>
