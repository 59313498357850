import { Subscription } from 'rxjs';
import { CustomerEntitiesService } from './../../core/services/customer-entities/customer-entities.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-customer-entities',
  templateUrl: './customer-entities.component.html',
  styleUrls: ['./customer-entities.component.scss'],
})
export class CustomerEntitiesComponent implements OnInit {
  // Loading
  isLoading = false;

  // User
  user!: User;

  // Subscription
  userSubscription: Subscription;

  // Variables
  customerEntities!: Client[];
  corporateEntities!: Client[];
  otherEntities!: Client[];

  constructor(
    private snackBar: MatSnackBar,
    private customerEntitiesService: CustomerEntitiesService,
    private authenticationService: AuthenticationService
  ) {
    this.userSubscription = this.authenticationService.user$.subscribe({
      next: (user: User) => {
        this.user = user;
        if (this.user.id) {
          this.getCustomerEntities();
        }
      },
    });
  }

  ngOnInit(): void {}

  getCustomerEntities(): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });

    this.customerEntitiesService
      .getCustomerEntities(this.user.id as number)
      .subscribe({
        next: (customerEntities) => {
          this.isLoading = false;
          this.snackBar.dismiss();
          this.customerEntities = customerEntities;
          this.corporateEntities = this.filterEntities('CORPORATE');
          this.otherEntities = this.filterEntities();
        },
        error: (error) => {
          this.isLoading = false;
          this.snackBar.open(error, '', { duration: 3000 });
          console.log(error);
        },
      });
  }

  filterEntities(clientType?: string): Client[] {
    if (clientType) {
      // Filter entity
      return this.customerEntities.filter(
        (entity: Client) => entity.clientTaxType.type === clientType
      );
    } else {
      // Filter entities that are not `CORPORATE`
      return this.customerEntities.filter(
        (entity: Client) => entity.clientTaxType.type !== 'CORPORATE'
      );
    }
  }
}
