<mat-sidenav-container class="height-100">
  <mat-sidenav
    #sidenav
    [mode]="isMobile ? 'over' : 'side'"
    [fixedInViewport]="isMobile ? true : false"
    [opened]="isMobile ? 'false' : 'true'"
    [style.width]="isMobile ? '90%' : '20vw'"
  >
    <mat-nav-list fxLayout="column" fxFlexFill class="nav-item">
      <img
        src="assets/images/eurogain-logo.png"
        alt="Eurogain Logo"
        class="padding-40 width-50"
      />
      <div
        class="shimmer height-48"
        [fxHide]="!isLoading"
        *ngFor="let item of [1, 2, 3, 4, 5, 6, 7]"
      >
        <mat-divider></mat-divider>
      </div>
      <a
        *ngIf="isAdmin || isCustodian || isCustomer"
        mat-list-item
        routerLink="/dashboard"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Dashboard</span
        ></a
      >
      <a
        *ngIf="isAdmin || isCustodian || isPersonnel"
        mat-list-item
        routerLink="/clients"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
      >
        <span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Clients</span
        >
      </a>
      <a
        *ngIf="isAdmin || isCustodian || isPersonnel"
        mat-list-item
        routerLink="/enquiries"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Enquiries</span
        >
      </a>
      <a
        *ngIf="isAdmin || isCustodian || isPersonnel"
        mat-list-item
        routerLink="/requests"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Requests</span
        >
      </a>
      <a
        *ngIf="isAdmin || isCustodian || isPersonnel"
        mat-list-item
        routerLink="/services"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Services</span
        >
      </a>
      <a
        *ngIf="isAdmin"
        mat-list-item
        routerLink="/users"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Users</span
        >
      </a>
      <a
        *ngIf="isAdmin || isCustodian"
        mat-list-item
        routerLink="/articles"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Articles</span
        >
      </a>
      <a
        *ngIf="isCustomer"
        mat-list-item
        routerLink="/entities"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Entities</span
        >
      </a>
      <a
        *ngIf="isCustomer"
        mat-list-item
        routerLink="/user-requests"
        class="uppercase"
        (click)="isMobile ? sidenav.toggle() : null"
        ><span
          routerLinkActive="active"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="side-nav-item"
          >Requests</span
        >
      </a>
      <div fxFlex></div>
      <mat-divider></mat-divider>
      <div class="shimmer height-72" *ngIf="isLoading"></div>
      <a
        *ngIf="!isLoading"
        mat-list-item
        routerLink="/profile"
        (click)="isMobile ? sidenav.toggle() : null"
      >
        <div mat-line class="side-nav-item">{{ user.name }}</div>
        <div mat-line class="side-nav-item">
          <span class="ellipsis">
            <span
              class="capitalize"
              *ngFor="let role of user.roles; let isLast = last"
              >{{ role.role?.toLowerCase() }}{{ isLast ? "" : ", " }}</span
            >
          </span>
        </div>
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle()">
          <mat-icon class="transition-ease-in-out">menu</mat-icon>
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
