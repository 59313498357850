import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Service } from 'src/app/shared/models/service/service.model';

@Component({
  selector: 'app-not-subscribed-service-dialog',
  templateUrl: './not-subscribed-service-dialog.component.html',
  styleUrls: ['./not-subscribed-service-dialog.component.scss'],
})
export class NotSubscribedServiceDialogComponent implements OnInit {
  service: Service;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.service = data.service;
  }

  ngOnInit(): void {}
}
