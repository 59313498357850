import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-customer-entity-files',
  templateUrl: './customer-entity-files.component.html',
  styleUrls: ['./customer-entity-files.component.scss'],
})
export class CustomerEntityFilesComponent implements OnInit {
  // Loading
  isLoading = false;

  // Services
  currentService = '';

  // Client Details
  customerEntityDetails!: Client;
  entityYearDetails!: ClientYearDetails;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private clientsService: ClientsService
  ) {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const yearID = params.yearId;
        this.currentService = params.service;
        this.getYearDetails(yearID);
      },
    });
  }

  ngOnInit(): void {}

  getYearDetails(yearID: number): void {
    this.isLoading = true;
    this.snackBar.open('Loading data', '', { duration: undefined });
    this.clientsService.getClientDetailsForSpecificYear(yearID).subscribe({
      next: (entityYearDetails: ClientYearDetails) => {
        this.snackBar.dismiss();
        this.isLoading = false;
        this.entityYearDetails = entityYearDetails;
        this.customerEntityDetails = entityYearDetails.clientBean;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.open(error, '', { duration: 3000 });
      },
    });
  }
}
