import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnquiryFormConstants {
  public STATUSES = [
    { value: 1, valueName: 'Read' },
    { value: 0, valueName: 'Not Read' },
  ];
}
