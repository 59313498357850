import { ClientYearDetails } from './../../../shared/models/client/client-year-details/client-year-details.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Client } from 'src/app/shared/models/client/client.model';
import { Year } from 'src/app/shared/models/year/year.model';
import { CopyYearDialogComponent } from './copy-year-dialog/copy-year-dialog.component';
import { DeleteClientDialogComponent } from '../delete-client-dialog/delete-client-dialog.component';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit {
  client!: Client;
  clientYearDetails!: ClientYearDetails;
  clientID!: number;
  availableYears: Array<Year> = [];
  clientHasCurrentYear = false;
  clientType: string;

  // Client Type
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;

  // Loading
  clientIsLoading = false;
  clientIsDeleting = false;
  yearIsLoading = false;

  // Value
  isFreelancer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientTypeService: ClientTypeService,
    private clientsService: ClientsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.clientTypeService.getClientType();
    this.subscribeToClientType();
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;

    // Get client ID
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        this.clientID = params.id;
        this.getGeneralClientDetails();
        this.getListOfAvailableYears(this.clientID);
      },
    });
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  ngOnInit(): void {}

  getGeneralClientDetails(): void {
    this.clientIsLoading = true;
    this.snackBar.open('Loading client', '', {
      duration: undefined,
    });
    this.clientsService.getClientDetails(this.clientID).subscribe({
      next: (client: Client) => {
        this.client = client;
        if (client?.type?.type && client.identityNumber) {
          this.isFreelancer.next(true);
        }

        if (this.isSecretarial) {
          this.getLatestYearDetails();
        } else {
          this.snackBar.dismiss();
          this.clientIsLoading = false;
        }
      },
      error: (error) => {
        console.log(error);
        this.snackBar.open(error, '', { duration: 3000 });
        this.clientIsLoading = false;
      },
    });
  }

  getLatestYearDetails(): void {
    this.clientsService
      .getClientDetailsForLatestYear(this.client.id)
      .subscribe({
        next: (clientYearDetails: ClientYearDetails) => {
          this.clientYearDetails = clientYearDetails;
          this.snackBar.dismiss();
          this.clientIsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.snackBar.open(error, '', { duration: 3000 });
          this.clientIsLoading = false;
        },
      });
  }

  getListOfAvailableYears(clientID: number): void {
    this.availableYears = [];
    this.yearIsLoading = true;
    this.clientsService
      .getClientAvailableYears(clientID, this.clientType)
      .subscribe({
        next: (years: Array<Year>) => {
          if (years) {
            this.checkCurrentYearDetailsExist(years);
            const sortedYear = years.sort((a: Year, b: Year) =>
              b.year.localeCompare(a.year)
            );
            this.availableYears = sortedYear;
          } else {
            this.clientHasCurrentYear = false;
          }
          this.yearIsLoading = false;
        },
        error: (error) => {
          console.log(error);
          this.yearIsLoading = false;
        },
      });
  }

  checkCurrentYearDetailsExist(years: Array<Year>): void {
    // Get current year
    const currentYear: number = new Date().getFullYear();

    // Check if there is any current year details
    const matchingCurrentYear: Year = years.find(
      (year) => year.year === currentYear.toString()
    ) as Year;

    if (matchingCurrentYear) {
      this.clientHasCurrentYear = true;
    } else {
      this.clientHasCurrentYear = false;
    }
  }

  showConfirmDuplicateDialog(): void {
    const dialogRef = this.dialog.open(CopyYearDialogComponent, {
      data: { year: this.availableYears[0].year },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.duplicateLatestAvailableYear();
        }
      },
    });
  }

  duplicateLatestAvailableYear(): void {
    this.clientIsLoading = true;
    this.snackBar.open(`Copying ${this.availableYears[0].year} details`, '', {
      duration: undefined,
    });
    this.clientsService
      .duplicateYearDetails(
        this.clientID,
        Number(this.availableYears[0].year) + 1,
        this.clientType
      )
      .subscribe({
        next: (result) => {
          this.snackBar.dismiss();
          this.clientIsLoading = false;
          this.getListOfAvailableYears(this.clientID);
        },
        error: (error) => {
          this.snackBar.open(error, '', { duration: 3000 });
          this.clientIsLoading = false;
        },
      });
  }

  showConfirmDeleteDialog(): void {
    const dialogRef = this.dialog.open(DeleteClientDialogComponent, {
      data: { client: this.client },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.deleteClient();
        }
      },
    });
  }

  deleteClient(): void {
    this.clientIsDeleting = true;
    this.snackBar.open(`Deleting ${this.client.name} and it's details`, '', {
      duration: undefined,
    });
    this.clientsService
      .deleteClient(this.client.id, this.clientType)
      .subscribe({
        next: (_) => {
          this.clientIsDeleting = false;
          this.router.navigateByUrl(`/clients/${this.clientType}`);
          this.snackBar.open(`${this.client.name} deleted`, '', {
            duration: 3000,
          });
        },
        error: (error) => {
          this.snackBar.open(error, '', { duration: 3000 });
          this.clientIsDeleting = false;
        },
      });
  }
}
