<form
  [formGroup]="banksForm"
  class="margin-top-16 margin-bottom-32"
  fxLayout="column"
  fxLayoutGap="32px"
>
  <div>
    <app-section-title title="Add Banks" *ngIf="!isMobile"></app-section-title>
    <div formArrayName="banks" fxLayout="column" fxLayoutGap="40px">
      <div
        *ngFor="let bank of banks.controls; let index = index; let last = last"
        [ngClass]="last ? 'border-primary-color' : 'border-grey-color'"
        class="dashed-container padding-16 margin-bottom-16"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="32px"
          fxLayoutAlign="start start"
          fxLayout.xs="column"
          fxLayoutAlign.xs="stretch"
          fxLayoutGap.xs=""
          class="width-100"
          [formGroupName]="index"
        >
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Bank Name -->
            <mat-form-field appearance="outline">
              <mat-label>Bank Name</mat-label>
              <input
                matInput
                type="text"
                formControlName="bankNameStr"
                [matAutocomplete]="autoCompleteBankName"
                required
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autoCompleteBankName="matAutocomplete"
              >
                <mat-option
                  *ngFor="let bank of bankOptions | async"
                  [value]="bank.name"
                  class="multiline-options"
                  matTooltip="{{ bank.name }}"
                >
                  <span>{{ bank.name }}</span>
                </mat-option>
              </mat-autocomplete>
              <!-- Loader -->
              <mat-spinner
                matSuffix
                diameter="18"
                matTooltip="Checking bank name"
                *ngIf="isBankLoading"
              ></mat-spinner>
              <!-- Not available icon -->
              <mat-icon
                matSuffix
                *ngIf="
                  banks.controls[index]
                    .get('bankNameStr')
                    ?.hasError('notAvailable')
                "
                class="bankDontExistIcon"
                matTooltip="Bank don't exist"
                >cancel</mat-icon
              >
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('bankNameStr')
                    ?.hasError('notAvailable')
                "
              >
                Bank name not available
              </mat-error>
              <mat-error
                *ngIf="
                  banks.controls[index].get('bankNameStr')?.hasError('required')
                "
              >
                Bank name is required
              </mat-error>
            </mat-form-field>

            <!-- Branch -->
            <mat-form-field appearance="outline">
              <mat-label>Branch</mat-label>
              <input matInput type="text" formControlName="branch" required />
              <mat-error
                *ngIf="
                  banks.controls[index].get('branch')?.hasError('required')
                "
              >
                Branch is required
              </mat-error>
            </mat-form-field>

            <!-- Branch Address -->
            <mat-form-field appearance="outline">
              <mat-label>Branch Address</mat-label>
              <textarea
                matInput
                type="text"
                rows="4"
                formControlName="branchAddress"
                required
              >
              </textarea>
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('branchAddress')
                    ?.hasError('required')
                "
              >
                Branch Address is required
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="calc(50% - 32px)" fxFlex.xs="100%">
            <!-- Account Type -->
            <mat-form-field
              appearance="outline"
              (click)="$event.stopPropagation()"
              fxFlex="calc(50% - 16px)"
            >
              <mat-label>Account Type</mat-label>
              <mat-select formControlName="bankAccountTypeStr" required>
                <mat-option
                  *ngFor="let accountType of accountTypeOptions"
                  [value]="accountType.value"
                >
                  {{ accountType.valueName }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('bankAccountTypeStr')
                    ?.hasError('required')
                "
              >
                Account type is required
              </mat-error>
            </mat-form-field>

            <!-- Account Number -->
            <mat-form-field appearance="outline">
              <mat-label>Account Number</mat-label>
              <input
                matInput
                #accountNumber
                type="text"
                formControlName="accountNumber"
                minlength="1"
                maxlength="20"
                required
              />
              <mat-hint align="end"
                >{{ accountNumber.value?.length || 0 }}/20
              </mat-hint>
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('accountNumber')
                    ?.hasError('required')
                "
              >
                Account number is required
              </mat-error>
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('accountNumber')
                    ?.hasError('minlength') ||
                  banks.controls[index]
                    .get('accountNumber')
                    ?.hasError('maxlength')
                "
              >
                Account number needs to be within 1 to 20 characters
              </mat-error>
            </mat-form-field>

            <!-- Account Usage -->
            <mat-form-field appearance="outline">
              <mat-label>Account Usage</mat-label>
              <input
                matInput
                type="text"
                formControlName="accountUsage"
                required
              />
              <mat-error
                *ngIf="
                  banks.controls[index]
                    .get('accountUsage')
                    ?.hasError('required')
                "
              >
                Account usage is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <button
          mat-button
          type="button"
          color="warn"
          (click)="removeBank(index)"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Remove</span>
        </button>
      </div>
    </div>
    <button mat-button type="button" color="primary" (click)="addBank()">
      <mat-icon>add</mat-icon>
      <span>Bank</span>
    </button>
  </div>
</form>
