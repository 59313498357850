import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Client } from 'src/app/shared/models/client/client.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientsService } from 'src/app/core/services/clients/clients.service';
import { BehaviorSubject } from 'rxjs';
import { ClientTypeService } from 'src/app/shared/client-type/client-type.service';
import { Device } from 'src/app/shared/enums/device.enum';
import { CustomBreakpointService } from 'src/app/shared/breakpoint/custom-breakpoint.service';
import { ClientYearDetails } from 'src/app/shared/models/client/client-year-details/client-year-details.model';
import { MsicCode } from 'src/app/shared/models/msic-code/msic-code.model';
import { UserFormConstants } from 'src/app/shared/constants/user-form.constants';
import { Director } from 'src/app/shared/models/director/director.model';
import { Shareholder } from 'src/app/shared/models/shareholder/shareholder.model';
import { PendingFile } from 'src/app/shared/models/pending-file/pending-file.model';
import { JwtService } from 'src/app/core/services/jwt/jwt.service';
import { ContactPerson } from 'src/app/shared/models/client/contact-person/contact-person.model';
import { BranchAddress } from 'src/app/shared/models/client/branch-address/branch-address.model';
import { BankItem } from 'src/app/shared/models/client/bank-item/bank-item.model';
import { Secretary } from 'src/app/shared/models/secretary/secretary.model';
import { Manager } from 'src/app/shared/models/manager/manager.model';
import { Auditor } from 'src/app/shared/models/auditor/auditor.model';
import { ClientType } from 'src/app/shared/enums/client-type.enum';
import { TaxAgent } from 'src/app/shared/models/tax-agent/tax-agent.model';

@Component({
  selector: 'app-edit-secretarial-client',
  templateUrl: './edit-secretarial-client.component.html',
  styleUrls: ['./edit-secretarial-client.component.scss'],
})
export class EditSecretarialClientComponent implements OnInit {
  // Form
  generalCompanyDetailsForm!: FormGroup;
  yearDetailsForm!: FormGroup;
  branchAddressesForm!: FormGroup;
  contactsForm!: FormGroup;
  directorsForm!: FormGroup;
  membersForm!: FormGroup;
  secretariesForm!: FormGroup;
  managersForm!: FormGroup;
  auditorsForm!: FormGroup;
  taxAgentsForm!: FormGroup;
  banksForm!: FormGroup;
  filesForm!: FormGroup;

  // Client Type
  clientType: string;
  isSecretarial = false;
  isTax = false;
  isAudit = false;
  isSharedServices = false;

  // Variable
  client: BehaviorSubject<Client> = new BehaviorSubject<Client>({} as Client);
  clientYearDetails!: ClientYearDetails;
  selectedMsicCodes: Array<{ code: string }> = [];
  pendingFiles: PendingFile[] = [];
  clientIsInSecretarial = false;

  // Responsive
  isMobile = true;

  // Loading
  clientIsSaving = false;
  isLoading = false;

  // Value
  isCreate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isFreelancer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  useCreateAPI: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private router: Router,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private clientTypeService: ClientTypeService,
    private userFormConstants: UserFormConstants,
    private customBreakpointService: CustomBreakpointService,
    private jwtService: JwtService
  ) {
    this.clientTypeService.getClientType();
    this.subscribeToClientType();
    // Get client type
    this.clientType = this.clientTypeService.clientTypeStr;

    this.customBreakpointService.isDevice$.subscribe({
      next: (device: Device) => {
        switch (device) {
          case Device.mobile:
            this.isMobile = true;
            break;
          case Device.tablet:
            this.isMobile = false;
            break;
          case Device.largeScreen:
            this.isMobile = false;
            break;
          case Device.XLargeScreen:
            this.isMobile = false;
            break;
          default:
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe({
      next: (params: Params) => {
        const id = params.id;
        this.getClientDetails(id);
      },
    });
    this.setupGeneralCompanyDetailsForm();
    this.setupContactsForm();
    this.setupSecretarialYearDetailsFormGroup();
    if (!this.isFreelancer.value) {
      this.setupDirectorsForm();
      this.setupMembersForm();
      this.setupSecretariesForm();
      this.setupManagersForm();
      this.setupAuditorsForm();
      this.setupTaxAgentsForm();
    }
    this.setupBranchAddressesForm();
    this.setupBanksForm();
    this.setupFilesForm();
  }

  subscribeToClientType(): void {
    this.clientTypeService.clientType$.subscribe({
      next: (clientType: ClientType) => {
        switch (clientType) {
          case ClientType.secretarial:
            this.isSecretarial = true;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.tax:
            this.isSecretarial = false;
            this.isTax = true;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
          case ClientType.audit:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = true;
            this.isSharedServices = false;
            break;
          case ClientType.shared_services:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = true;
            break;
          default:
            this.isSecretarial = false;
            this.isTax = false;
            this.isAudit = false;
            this.isSharedServices = false;
            break;
        }
      },
    });
  }

  setupGeneralCompanyDetailsForm(): void {
    this.generalCompanyDetailsForm = this.formBuilder.group({
      companyNumberNew: new FormControl('', Validators.required),
      companyNumberOld: new FormControl('', Validators.required),
      companyName: new FormControl('', Validators.required),
      formerlyKnownAs: new FormControl(''),
      group: new FormControl(''),
      clientSubGroup: new FormControl('', Validators.maxLength(100)),
      fileRef: new FormControl(''),
      incorporationDate: new FormControl(null, Validators.required),
      circulationDate: new FormControl(null),
      chargeOrDischarge: new FormControl(''),
      chargeDate: new FormControl(null),
      dischargeDate: new FormControl(null),
      registeredAddress: new FormControl(''),
      correspondentAddress: new FormControl('', Validators.required),
      registeredBookKeptAddress: new FormControl(''),
      financialRecordsKeptAddress: new FormControl(''),
      yearEndDay: new FormControl('', [Validators.min(1), Validators.max(31)]),
      yearEndMonth: new FormControl('', [
        Validators.min(1),
        Validators.max(12),
      ]),
      incorporatedByUs: new FormControl(true, Validators.required),
      remarks: new FormControl('', Validators.maxLength(1000)),
      companyOfficeNo: new FormControl(''),
      companyEmail: new FormControl('', [Validators.email]),
      companyWebsite: new FormControl('', [
        Validators.pattern(
          '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
        ),
      ]),
      referredBy: new FormControl(''),
      dateOfLastAccount: new FormControl(null),
      dateOfNextAccountDue: new FormControl(null),
    });
  }

  setupSecretarialYearDetailsFormGroup(): void {
    this.yearDetailsForm = this.formBuilder.group({
      secretaryName: new FormControl('', Validators.required),
      natureOfBusiness: new FormControl('', Validators.required),
      msicCode: new FormControl(null, Validators.required),
      commonSeal: new FormControl(null),
      registerOffice: new FormControl(null),
      nameTag: new FormControl(null),
      taxAgentName: new FormControl(''),
      takeOverDate: new FormControl(null),
      lastAnnualReturnDate: new FormControl(null),
      nextAnnualReturnDueDate: new FormControl(null),
      lastAuditedPeriodDate: new FormControl(null),
      auditedAccountSubmissionDateSsm: new FormControl(null),
      circulationDate: new FormControl(null),
    });
  }

  setupContactsForm(): void {
    this.contactsForm = this.formBuilder.group({
      oftenContactPersons: this.formBuilder.array([]),
    });
    this.oftenContactPersons.push(this.createOftenContactPersonItem());
  }

  setupBranchAddressesForm(): void {
    this.branchAddressesForm = this.formBuilder.group({
      branchAddresses: this.formBuilder.array([]),
    });
    this.branchAddresses.push(this.createBranchAddressItem());
  }

  setupDirectorsForm(): void {
    this.directorsForm = this.formBuilder.group({
      directors: this.formBuilder.array([]),
    });
  }

  setupSecretariesForm(): void {
    this.secretariesForm = this.formBuilder.group({
      secretaries: this.formBuilder.array([]),
    });
  }

  setupManagersForm(): void {
    this.managersForm = this.formBuilder.group({
      managers: this.formBuilder.array([]),
    });
  }

  setupAuditorsForm(): void {
    this.auditorsForm = this.formBuilder.group({
      auditors: this.formBuilder.array([]),
    });
  }

  setupTaxAgentsForm(): void {
    this.taxAgentsForm = this.formBuilder.group({
      taxAgents: this.formBuilder.array([]),
    });
  }

  setupMembersForm(): void {
    this.membersForm = this.formBuilder.group({
      members: this.formBuilder.array([]),
    });
  }

  setupBanksForm(): void {
    this.banksForm = this.formBuilder.group({
      banks: this.formBuilder.array([]),
    });
  }

  setupFilesForm(): void {
    this.filesForm = this.formBuilder.group({
      files: new FormControl(''),
    });
  }

  getClientDetails(clientID: number): void {
    this.isLoading = true;
    this.snackBar.open('Getting client', '', {
      duration: undefined,
    });
    this.clientsService.getClientDetails(clientID).subscribe({
      next: (client: Client) => {
        this.client.next(client);
        this.prefillGeneralForm(client);
        this.prefillContactsForm(client);
        this.prefillBranchAddressesForm(client);
        this.prefillSecretariesForm(client);
        this.prefillManagersForm(client);
        this.prefillAuditorsForm(client);
        this.prefillTaxAgentsForm(client);
        this.getClientYearDetails();
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
        this.snackBar.dismiss();
        this.snackBar.open(error, 'DISMISS', {
          duration: 3000,
        });
      },
    });
  }

  getClientYearDetails(): void {
    this.clientsService
      .getClientDetailsForLatestYear(this.client.value.id)
      .subscribe({
        next: (clientYearDetails: ClientYearDetails) => {
          this.isLoading = false;
          this.snackBar.dismiss();
          this.clientYearDetails = clientYearDetails;
          // this.prefillYearForm();
          if (this.clientYearDetails) {
            this.prefillYearDetailsForm();
            this.prefillBanksForm();
            if (!this.isFreelancer.value) {
              this.prefillDirectorsForm();
              this.prefillMembersForm();
            }
          }
        },
        error: (error) => {
          console.log(error);
          this.isLoading = false;
        },
      });
  }

  prefillGeneralForm(client: Client): void {
    let yearEndDate: string[] = [];
    if (client.yearEnd) {
      yearEndDate = this.convertStringDate(client.yearEnd);
    } else {
      yearEndDate = [];
    }

    this.generalCompanyDetailsForm.patchValue({
      companyNumberNew: client.companyNumberNew,
      companyNumberOld: client.companyNumberOld,
      companyName: client.name,
      formerlyKnownAs: client.formerlyKnownAs,
      group: client.group,
      clientSubGroup: client.clientSubGroup,
      fileRef: client.fileRef,
      incorporationDate: new Date(client.dateOfIncorp),
      circulationDate: client.circulationDate
        ? new Date(client.circulationDate)
        : client.circulationDate,
      chargeOrDischarge: client.chargeOrDischarge,
      chargeDate: client.chargeDate
        ? new Date(client.chargeDate)
        : client.chargeDate,
      dischargeDate: client.dischargeDate
        ? new Date(client.dischargeDate)
        : client.dischargeDate,
      registeredAddress: client.registeredAddress,
      correspondentAddress: client.corresAddress,
      registeredBookKeptAddress: client.registeredBookKeptAddress,
      financialRecordsKeptAddress: client.financialRecordsKeptAddress,
      yearEndDay: Number(yearEndDate[0]),
      yearEndMonth: Number(yearEndDate[1]),
      incorporatedByUs: client.incorpByUs,
      remarks: client.remarksSecretary,
      companyOfficeNo: client.companyOfficeNo,
      companyEmail: client.companyEmail,
      companyWebsite: client.companyWebsite,
      referredBy: client.referredBy,
      dateOfLastAccount: client.dateOfLastAccount
        ? new Date(client.dateOfLastAccount)
        : client.dateOfLastAccount,
      dateOfNextAccountDue: client.dateOfNextAccountDue
        ? new Date(client.dateOfNextAccountDue)
        : client.dateOfNextAccountDue,
    });
  }

  prefillContactsForm(client: Client) {
    this.oftenContactPersons.clear();
    if (client.oftenContactPersons?.length > 0) {
      client.oftenContactPersons.forEach(
        (contactPerson: ContactPerson, index: number) => {
          this.oftenContactPersons.push(this.createOftenContactPersonItem());
          const data = {
            name: contactPerson.name,
            phoneNumber: contactPerson.phoneNumber,
            businessEmail: contactPerson.businessEmail.toLowerCase(),
            personalEmail: contactPerson.personalEmail.toLowerCase(),
            contactPersonTypeStr: contactPerson.contactPersonTypeStr,
          };

          const contactFormGroup: FormGroup = this.oftenContactPersons.controls[
            index
          ] as FormGroup;
          contactFormGroup.patchValue(data);
        }
      );
    } else {
      this.oftenContactPersons.push(this.createOftenContactPersonItem());
    }
  }

  prefillBranchAddressesForm(client: Client) {
    this.branchAddresses.clear();
    if (client.branchAddresses?.length > 0) {
      client.branchAddresses.forEach(
        (branchAddress: BranchAddress, index: number) => {
          this.branchAddresses.push(this.createBranchAddressItem());
          const data = {
            branchNumber: branchAddress.branchNumber,
            address: branchAddress.address,
            contactNumber: branchAddress.contactNumber,
            email: branchAddress.email.toLowerCase(),
          };

          const branchAddressFormGroup: FormGroup = this.branchAddresses
            .controls[index] as FormGroup;
          branchAddressFormGroup.patchValue(data);
        }
      );
    } else {
      this.branchAddresses.push(this.createBranchAddressItem());
    }
  }

  prefillYearDetailsForm(): void {
    if (this.clientYearDetails.msicCodes?.length) {
      const msicCodes: Array<{ code: string }> = [];
      this.clientYearDetails.msicCodes.forEach((msicCode: MsicCode) => {
        msicCodes.push({ code: msicCode.code });
      });

      this.selectedMsicCodes = msicCodes;

      this.yearDetailsForm.controls.msicCode.setErrors(null);
    }

    this.yearDetailsForm.patchValue({
      natureOfBusiness: this.clientYearDetails.natureOfBusiness,
      cp500: this.clientYearDetails.cp500,
      cp204: this.clientYearDetails.cp204,
      cp204A6th: this.clientYearDetails.cp204A6th,
      cp204A9th: this.clientYearDetails.cp204A9th,
      lastTaxReturnFiledYear: this.clientYearDetails.lastTaxReturnFiledYear,
      taxReturnSubmittedDateRef: this.clientYearDetails.taxReturnSubmittedDate,
      teamPIC: this.clientYearDetails.teamPIC,
      secretaryName: this.clientYearDetails.secretaryName,
      commonSeal: this.clientYearDetails.commonSeal,
      registerOffice: this.clientYearDetails.registerOffice,
      nameTag: this.clientYearDetails.nameTag,
      taxAgentName: this.clientYearDetails.taxAgentName,
      takeOverDate: this.clientYearDetails.takeOverDate
        ? new Date(this.clientYearDetails.takeOverDate)
        : null,
      lastAnnualReturnDate: this.clientYearDetails.lastAnnualReturnDate
        ? new Date(this.clientYearDetails.lastAnnualReturnDate)
        : null,
      nextAnnualReturnDueDate: this.clientYearDetails.nextAnnualReturnDueDate
        ? new Date(this.clientYearDetails.nextAnnualReturnDueDate)
        : null,
      lastAuditedPeriodDate: this.clientYearDetails.lastAuditedPeriodDate
        ? new Date(this.clientYearDetails.lastAuditedPeriodDate)
        : null,
      auditedAccountSubmissionDateSsm: this.clientYearDetails
        .auditedAccountSubmissionDateSsm
        ? new Date(this.clientYearDetails.auditedAccountSubmissionDateSsm)
        : null,
      circulationDate: this.clientYearDetails.circulationDate
        ? new Date(this.clientYearDetails.circulationDate)
        : null,
      mgmtAccountReceivedDate: this.clientYearDetails.mgmtAccountReceivedDate
        ? new Date(this.clientYearDetails.mgmtAccountReceivedDate)
        : null,
      stocktake: this.clientYearDetails.stocktake,
      serviceType: this.clientYearDetails.serviceType?.type,
    });
  }

  get branchAddresses(): FormArray {
    return this.branchAddressesForm.get('branchAddresses') as FormArray;
  }

  createBranchAddressItem(): FormGroup {
    return this.formBuilder.group({
      branchNumber: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      address: new FormControl('', Validators.maxLength(255)),
      contactNumber: new FormControl('', [Validators.maxLength(50)]),
      email: new FormControl('', [Validators.maxLength(100), Validators.email]),
    });
  }

  get oftenContactPersons(): FormArray {
    return this.contactsForm.get('oftenContactPersons') as FormArray;
  }

  createOftenContactPersonItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),
      businessEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      personalEmail: new FormControl('', [Validators.email]),
      contactPersonTypeStr: new FormControl('', Validators.required),
    });
  }

  get directors(): FormArray {
    return this.directorsForm.get('directors') as FormArray;
  }

  prefillDirectorsForm(): void {
    if (this.clientYearDetails.directors?.length > 0) {
      this.directors.clear();
      this.clientYearDetails.directors.forEach(
        (director: Director, index: number) => {
          this.directors.push(this.createDirectorItem());
          const data = {
            name: director.name,
            identificationTypeStr: director.type.type,
            identificationNumber: director.identificationNumber,
            email: director.email.toLowerCase(),
            address: director.address,
            dateOfPassportExpiryRef: director.dateOfPassportExpiry
              ? new Date(director.dateOfPassportExpiry)
              : director.dateOfPassportExpiry,
            nationality: director.nationality,
            race: director.race,
            male: director.male,
            dateOfBirthRef: director.dateOfBirth
              ? new Date(director.dateOfBirth)
              : director.dateOfBirth,
            residentialAddress: director.residentialAddress,
            phoneNumber: director.phoneNumber,
            dateOfAppointmentRef: director.dateOfAppointment
              ? new Date(director.dateOfAppointment)
              : director.dateOfAppointment,
            dateOfResignationRef: director.dateOfResignation
              ? new Date(director.dateOfResignation)
              : director.dateOfResignation,
            alternativeDirector: director.alternativeDirector,
            privateEmail: director.privateEmail?.toLowerCase(),
          };

          const directorFormGroup: FormGroup = this.directors.controls[
            index
          ] as FormGroup;
          directorFormGroup.patchValue(data);
        }
      );
    } else {
      this.directors.push(this.createDirectorItem());
    }
  }

  createDirectorItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      male: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      residentialAddress: new FormControl(''),
      phoneNumber: new FormControl(''),
      dateOfAppointmentRef: new FormControl(null),
      dateOfResignationRef: new FormControl(null),
      alternativeDirector: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
    });
  }

  get members(): FormArray {
    return this.membersForm.get('members') as FormArray;
  }

  prefillMembersForm(): void {
    if (this.clientYearDetails.shareholders?.length) {
      this.members.clear();
      this.clientYearDetails.shareholders.forEach(
        (member: Shareholder, index: number) => {
          this.members.push(this.createMemberItem());
          const data = {
            name: member.name,
            identificationTypeStr: member.type.type,
            identificationNumber: member.identificationNumber,
            email: member.email.toLowerCase(),
            address: member.address,
            numberOfShares: member.numberOfShares,
            dateOfPassportExpiryRef: member.dateOfPassportExpiryRef
              ? new Date(member.dateOfPassportExpiryRef)
              : member.dateOfPassportExpiryRef,
            nationality: member.nationality,
            race: member.race,
            isMale: member.isMale,
            dateOfBirthRef: member.dateOfBirthRef
              ? new Date(member.dateOfBirthRef)
              : member.dateOfBirthRef,
            phoneNumber: member.phoneNumber,
            privateEmail: member.privateEmail?.toLowerCase(),
            eventTypeStr: member.eventTypeStr,
            upToDateNumberOfShares: member.upToDateNumberOfShares,
            isCash: member.isCash,
            placeOfIncorporation: member.placeOfIncorporation,
          };

          const memberFormGroup: FormGroup = this.members.controls[
            index
          ] as FormGroup;
          memberFormGroup.patchValue(data);
        }
      );
    } else {
      this.members.push(this.createMemberItem());
    }
  }

  createMemberItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      numberOfShares: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      isMale: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      phoneNumber: new FormControl(''),
      privateEmail: new FormControl('', Validators.email),
      eventTypeStr: new FormControl(''),
      upToDateNumberOfShares: new FormControl('', [
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ]),
      isCash: new FormControl(null),
      placeOfIncorporation: new FormControl(''),
    });
  }

  get banks(): FormArray {
    return this.banksForm.get('banks') as FormArray;
  }

  prefillBanksForm(): void {
    if (this.clientYearDetails.banks) {
      this.banks.clear();
      this.clientYearDetails.banks.forEach(
        (bankItem: BankItem, index: number) => {
          this.banks.push(this.createBankItem());
          const data = {
            bankNameStr: bankItem.bank.name,
            branch: bankItem.branch,
            branchAddress: bankItem.branchAddress,
            bankAccountTypeStr: bankItem.accountType.type.toLowerCase(),
            accountNumber: bankItem.accountNumber,
            accountUsage: bankItem.accountUsage,
          };

          const bankFormGroup: FormGroup = this.banks.controls[
            index
          ] as FormGroup;
          bankFormGroup.patchValue(data);
        }
      );
    } else {
      this.banks.clear();
    }
  }

  createBankItem(): FormGroup {
    return this.formBuilder.group({
      bankNameStr: new FormControl('', Validators.required),
      branch: new FormControl('', Validators.required),
      branchAddress: new FormControl('', Validators.required),
      bankAccountTypeStr: new FormControl('', Validators.required),
      accountNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(20),
      ]),
      accountUsage: new FormControl('', Validators.required),
    });
  }

  get secretaries(): FormArray {
    return this.secretariesForm.get('secretaries') as FormArray;
  }

  createSecretaryItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC', Validators.required),
      identificationNumber: new FormControl('', [
        Validators.required,
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX),
      ]),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      isMale: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      residentialAddress: new FormControl(''),
      businessAddress: new FormControl(''),
      professionalTypeStr: new FormControl(null),
      secretaryOrMembershipNo: new FormControl(''),
      phoneNumber: new FormControl(''),
      companyEmail: new FormControl('', [Validators.email]),
      dateOfAppointmentRef: new FormControl(null),
      dateOfResignationRef: new FormControl(null),
    });
  }

  prefillSecretariesForm(client: Client): void {
    if (client.secretaries?.length) {
      this.secretaries.clear();
      client.secretaries.forEach((secretary: Secretary, index: number) => {
        this.secretaries.push(this.createSecretaryItem());
        const data = {
          name: secretary.name,
          identificationTypeStr: secretary.identificationType.type,
          identificationNumber: secretary.identificationNumber,
          dateOfPassportExpiryRef: secretary.dateOfPassportExpiry
            ? new Date(secretary.dateOfPassportExpiry)
            : secretary.dateOfPassportExpiry,
          nationality: secretary.nationality,
          race: secretary.race,
          isMale: secretary.isMale,
          dateOfBirthRef: secretary.dateOfBirth
            ? new Date(secretary.dateOfBirth)
            : secretary.dateOfBirth,
          residentialAddress: secretary.residentialAddress,
          businessAddress: secretary.businessAddress,
          professionalTypeStr: secretary.professionalTypeStr,
          secretaryOrMembershipNo: secretary.secretaryOrMembershipNo,
          phoneNumber: secretary.phoneNumber,
          companyEmail: secretary.companyEmail.toLowerCase(),
          dateOfAppointmentRef: secretary.dateOfAppointment
            ? new Date(secretary.dateOfAppointment)
            : secretary.dateOfAppointment,
          dateOfResignationRef: secretary.dateOfResignation
            ? new Date(secretary.dateOfResignation)
            : secretary.dateOfResignation,
        };

        const secretariesFormGroup: FormGroup = this.secretaries.controls[
          index
        ] as FormGroup;
        secretariesFormGroup.patchValue(data);
      });
    } else {
      this.secretaries.clear();
    }
  }

  get managers(): FormArray {
    return this.managersForm.get('managers') as FormArray;
  }

  createManagerItem(): FormGroup {
    return this.formBuilder.group({
      name: new FormControl('', Validators.required),
      identificationTypeStr: new FormControl('IC'),
      identificationNumber: new FormControl(
        '',
        Validators.pattern(this.userFormConstants.MALAYSIAN_IC_REGEX)
      ),
      dateOfPassportExpiryRef: new FormControl(null),
      nationality: new FormControl(''),
      race: new FormControl(''),
      isMale: new FormControl(null),
      dateOfBirthRef: new FormControl(null),
      businessAddress: new FormControl(''),
      residentialAddress: new FormControl(''),
      phoneNumber: new FormControl(''),
      companyEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      privateEmail: new FormControl('', Validators.email),
      dateOfAppointmentRef: new FormControl(null),
      dateOfResignationRef: new FormControl(null),
    });
  }

  prefillManagersForm(client: Client): void {
    if (client.managers?.length > 0) {
      this.managers.clear();
      client.managers.forEach((manager: Manager, index: number) => {
        this.managers.push(this.createManagerItem());
        const data = {
          name: manager.name,
          identificationTypeStr: manager.identificationType.type,
          identificationNumber: manager.identificationNumber,
          dateOfPassportExpiryRef: manager.dateOfPassportExpiry
            ? new Date(manager.dateOfPassportExpiry)
            : manager.dateOfPassportExpiry,
          nationality: manager.nationality,
          race: manager.race,
          isMale: manager.isMale,
          dateOfBirthRef: manager.dateOfBirth
            ? new Date(manager.dateOfBirth)
            : manager.dateOfBirth,
          businessAddress: manager.businessAddress,
          residentialAddress: manager.residentialAddress,
          phoneNumber: manager.phoneNumber,
          companyEmail: manager.companyEmail?.toLowerCase(),
          privateEmail: manager.privateEmail?.toLowerCase(),
          dateOfAppointmentRef: manager.dateOfAppointment
            ? new Date(manager.dateOfAppointment)
            : manager.dateOfAppointment,
          dateOfResignationRef: manager.dateOfResignation
            ? new Date(manager.dateOfResignation)
            : manager.dateOfResignation,
        };

        const managerFormGroup: FormGroup = this.managers.controls[
          index
        ] as FormGroup;
        managerFormGroup.patchValue(data);
      });
    } else {
      this.managers.clear();
    }
  }

  get auditors(): FormArray {
    return this.auditorsForm.get('auditors') as FormArray;
  }

  createAuditorItem(): FormGroup {
    return this.formBuilder.group({
      firmName: new FormControl('', Validators.required),
      firmNo: new FormControl('', Validators.required),
      firmAddress: new FormControl(''),
      firmContactNumber: new FormControl('', Validators.required),
      firmEmail: new FormControl('', Validators.email),
      picName: new FormControl(''),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  prefillAuditorsForm(client: Client): void {
    if (client.auditors?.length > 0) {
      this.auditors.clear();
      client.auditors.forEach((auditor: Auditor, index: number) => {
        this.auditors.push(this.createAuditorItem());
        const data = {
          firmName: auditor.firmName,
          firmNo: auditor.firmNo,
          firmAddress: auditor.firmAddress,
          firmContactNumber: auditor.firmContactNumber,
          firmEmail: auditor.firmEmail,
          picName: auditor.picName,
          appointmentOnDateStr: auditor.appointmentOnDate
            ? new Date(auditor.appointmentOnDate)
            : auditor.appointmentOnDate,
          resignationOnDateStr: auditor.resignationOnDate
            ? new Date(auditor.resignationOnDate)
            : auditor.resignationOnDate,
        };

        const auditorsFormGroup: FormGroup = this.auditors.controls[
          index
        ] as FormGroup;
        auditorsFormGroup.patchValue(data);
      });
    } else {
      this.auditors.clear();
    }
  }

  get taxAgents(): FormArray {
    return this.taxAgentsForm.get('taxAgents') as FormArray;
  }

  createTaxAgentItem(): FormGroup {
    return this.formBuilder.group({
      companyName: new FormControl('', Validators.required),
      picName: new FormControl(''),
      licenseNo: new FormControl(''),
      telNo: new FormControl('', Validators.required),
      companyEmail: new FormControl('', [
        Validators.required,
        Validators.email,
      ]),
      privateEmail: new FormControl('', Validators.email),
      appointmentOnDateStr: new FormControl(null),
      resignationOnDateStr: new FormControl(null),
    });
  }

  prefillTaxAgentsForm(client: Client): void {
    if (client.taxAgents?.length > 0) {
      this.taxAgents.clear();
      client.taxAgents.forEach((taxAgent: TaxAgent, index: number) => {
        this.taxAgents.push(this.createTaxAgentItem());
        const data = {
          companyName: taxAgent.companyName,
          picName: taxAgent.picName,
          licenseNo: taxAgent.licenseNo,
          telNo: taxAgent.telNo,
          companyEmail: taxAgent.companyEmail,
          privateEmail: taxAgent.privateEmail,
          appointmentOnDateStr: taxAgent.appointmentOnDate
            ? new Date(taxAgent.appointmentOnDate)
            : taxAgent.appointmentOnDate,
          resignationOnDateStr: taxAgent.resignationOnDate
            ? new Date(taxAgent.resignationOnDate)
            : taxAgent.resignationOnDate,
        };

        const taxAgentsFormGroup: FormGroup = this.taxAgents.controls[
          index
        ] as FormGroup;
        taxAgentsFormGroup.patchValue(data);
      });
    } else {
      this.taxAgents.clear();
    }
  }

  savePressed(): void {
    if (
      this.generalCompanyDetailsForm.invalid ||
      this.branchAddressesForm.invalid ||
      this.yearDetailsForm.invalid ||
      this.directorsForm.invalid ||
      this.membersForm.invalid ||
      this.banksForm.invalid ||
      this.secretariesForm.invalid ||
      this.taxAgentsForm.invalid
    ) {
      this.generalCompanyDetailsForm.markAllAsTouched();
      this.branchAddressesForm.markAllAsTouched();
      this.yearDetailsForm.markAllAsTouched();
      this.directorsForm.markAllAsTouched();
      this.membersForm.markAllAsTouched();
      this.banksForm.markAllAsTouched();
      this.secretariesForm.markAllAsTouched();
      this.taxAgentsForm.markAllAsTouched();
      this.snackBar.open('Please fill in the empty field', '', {
        duration: 3000,
      });
    } else {
      const companyNumberNew =
        this.generalCompanyDetailsForm.get('companyNumberNew')?.value;
      const companyNumberOld =
        this.generalCompanyDetailsForm.get('companyNumberOld')?.value;
      const directors = this.directors.value.map((data: any) => {
        // Transform dates to string
        data.dateOfPassportExpiryRef = this.transformDateToString(
          data.dateOfPassportExpiryRef
        );
        data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
        data.dateOfAppointmentRef = this.transformDateToString(
          data.dateOfAppointmentRef
        );
        data.dateOfResignationRef = this.transformDateToString(
          data.dateOfResignationRef
        );
        return data;
      });
      const secretaries = this.secretaries.value.map((data: any) => {
        // Transform dates to string
        data.dateOfPassportExpiryRef = this.transformDateToString(
          data.dateOfPassportExpiryRef
        );
        data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
        data.dateOfAppointmentRef = this.transformDateToString(
          data.dateOfAppointmentRef
        );
        data.dateOfResignationRef = this.transformDateToString(
          data.dateOfResignationRef
        );
        return data;
      });
      const managers = this.managers.value.map((data: any) => {
        // Transform dates to string
        data.dateOfPassportExpiryRef = this.transformDateToString(
          data.dateOfPassportExpiryRef
        );
        data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);
        data.dateOfAppointmentRef = this.transformDateToString(
          data.dateOfAppointmentRef
        );
        data.dateOfResignationRef = this.transformDateToString(
          data.dateOfResignationRef
        );
        return data;
      });

      const shareholders = this.members.value.map((data: any) => {
        data.dateOfPassportExpiryRef = this.transformDateToString(
          data.dateOfPassportExpiryRef
        );
        data.dateOfBirthRef = this.transformDateToString(data.dateOfBirthRef);

        return data;
      });
      const auditors = this.auditors.value.map((data: any) => {
        // Transform dates to string
        data.appointmentOnDateStr = this.transformDateToString(
          data.appointmentOnDateStr
        );
        data.resignationOnDateStr = this.transformDateToString(
          data.resignationOnDateStr
        );
        return data;
      });
      const taxAgents = this.taxAgents.value.map((data: any) => {
        // Transform dates to string
        data.appointmentOnDateStr = this.transformDateToString(
          data.appointmentOnDateStr
        );
        data.resignationOnDateStr = this.transformDateToString(
          data.resignationOnDateStr
        );
        return data;
      });

      const generalClientParams = {
        name: this.generalCompanyDetailsForm.get('companyName')?.value,
        formerlyKnownAs:
          this.generalCompanyDetailsForm.get('formerlyKnownAs')?.value,
        companyNumberNew: companyNumberNew !== '' ? companyNumberNew : null,
        companyNumberOld: companyNumberOld !== '' ? companyNumberOld : null,
        dateOfLastAccountRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('dateOfLastAccount')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        dateOfNextAccountDueRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('dateOfNextAccountDue')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        dateOfIncorpRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('incorporationDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        dateOfCirculationRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('circulationDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        dateOfChargeRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('chargeDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        dateOfDischargeRef: this.datepipe.transform(
          this.generalCompanyDetailsForm.get('dischargeDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        chargeOrDischarge:
          this.generalCompanyDetailsForm.get('chargeOrDischarge')?.value,
        fileRef: this.generalCompanyDetailsForm.get('fileRef')?.value,
        registeredAddress:
          this.generalCompanyDetailsForm.get('registeredAddress')?.value,
        corresAddress: this.generalCompanyDetailsForm.get(
          'correspondentAddress'
        )?.value,
        registeredBookKeptAddress: this.generalCompanyDetailsForm.get(
          'registeredBookKeptAddress'
        )?.value,
        financialRecordsKeptAddress: this.generalCompanyDetailsForm.get(
          'financialRecordsKeptAddress'
        )?.value,
        group: this.generalCompanyDetailsForm.get('group')?.value,
        clientSubGroup:
          this.generalCompanyDetailsForm.get('clientSubGroup')?.value,
        yearEnd: this.getYearEndField(),
        incorpByUs:
          this.generalCompanyDetailsForm.get('incorporatedByUs')?.value,
        departmentRef: this.clientType,
        remarksSecretary: this.generalCompanyDetailsForm.get('remarks')?.value,
        companyOfficeNo:
          this.generalCompanyDetailsForm.get('companyOfficeNo')?.value,
        companyEmail: this.generalCompanyDetailsForm.get('companyEmail')?.value,
        companyWebsite:
          this.generalCompanyDetailsForm.get('companyWebsite')?.value,
        referredBy: this.generalCompanyDetailsForm.get('referredBy')?.value,
        oftenContactPersons: this.contactsForm.get('oftenContactPersons')
          ?.value,
        branchAddresses: this.branchAddressesForm.get('branchAddresses')?.value,
        secretaries: secretaries,
        managers: managers,
        auditors,
        taxAgents: taxAgents,
        // clientTaxTypeRef needs to be dynamic
        // TODO: Make clientTaxTypeRef dynamic with dropdown to choose
        clientTaxTypeRef: this.client.value.clientTaxType
          ? this.client.value.clientTaxType.type
          : 'corporate',
      };

      const clientYearDetailsParams = {
        year: this.clientYearDetails.year,
        initiateDeptRef: this.clientType,
        commonSeal: this.yearDetailsForm.get('commonSeal')?.value,
        registerOffice: this.yearDetailsForm.get('registerOffice')?.value,
        nameTag: this.yearDetailsForm.get('nameTag')?.value,
        clientStatusRef: 'active',
        lastAuditedPeriodDateRef: this.datepipe.transform(
          this.yearDetailsForm.get('lastAuditedPeriodDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        lastAnnualReturnDateRef: this.datepipe.transform(
          this.yearDetailsForm.get('lastAnnualReturnDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        nextAnnualReturnDueDateRef: this.datepipe.transform(
          this.yearDetailsForm.get('nextAnnualReturnDueDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        natureOfBusiness: this.yearDetailsForm.get('natureOfBusiness')?.value,
        msicCodes: this.selectedMsicCodes,
        secretaryName: this.yearDetailsForm.get('secretaryName')?.value,
        taxAgentName: this.yearDetailsForm.get('taxAgentName')?.value,
        takeOverDateRef: this.datepipe.transform(
          this.yearDetailsForm.get('takeOverDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        circulationDateRef: this.datepipe.transform(
          this.yearDetailsForm.get('circulationDate')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        auditedAccountSubmissionDateSsmRef: this.datepipe.transform(
          this.yearDetailsForm.get('auditedAccountSubmissionDateSsm')?.value,
          'yyy-MM-dd 00:00:00'
        ),
        directors: directors,
        shareholders: shareholders,
        banks: this.banksForm.get('banks')?.value,
        toBeDeletedFiles: [],
      };

      this.clientIsSaving = true;
      this.generalCompanyDetailsForm.disable();
      this.yearDetailsForm.disable();
      this.directorsForm.disable();
      this.membersForm.disable();
      this.snackBar.open('Saving client', '', {
        duration: undefined,
      });

      this.clientsService
        .saveGeneralCompanyDetails(generalClientParams, this.client.value.id)
        .subscribe({
          next: (_: Client) => {
            this.saveYearDetails(clientYearDetailsParams);
          },
          error: (error) => {
            console.log(error);
            this.generalCompanyDetailsForm.enable();
            this.yearDetailsForm.enable();
            this.directorsForm.enable();
            this.membersForm.enable();
            this.filesForm.enable();
            this.clientIsSaving = false;
            this.snackBar.open(error, '', {
              duration: 3000,
            });
          },
        });
    }
  }

  saveYearDetails(params: object): void {
    this.clientsService
      .saveClientYearDetails(params, this.clientYearDetails.id)
      .subscribe({
        next: (clientYearDetails: ClientYearDetails) => {
          if (this.pendingFiles.length > 0) {
            this.uploadFiles(clientYearDetails);
          } else {
            this.generalCompanyDetailsForm.reset();
            this.yearDetailsForm.reset();
            this.directorsForm.reset();
            this.membersForm.reset();
            this.filesForm.reset();
            this.clientIsSaving = false;
            this.router.navigateByUrl(
              `/clients/${this.clientType}/details/${this.client.value.id}`
            );
            this.snackBar.open('Client saved', '', {
              duration: 3000,
            });
          }
        },
        error: (error) => {
          console.log(error);
          this.generalCompanyDetailsForm.enable();
          this.yearDetailsForm.enable();
          this.directorsForm.enable();
          this.membersForm.enable();
          this.filesForm.enable();
          this.clientIsSaving = false;
          this.snackBar.open(error, '', {
            duration: 3000,
          });
        },
      });
  }

  uploadFiles(clientYearDetails: ClientYearDetails): void {
    this.jwtService.saveContentType('multipart/form-data');

    const formData: FormData = new FormData();
    for (let index = 0; index < this.pendingFiles.length; index++) {
      const file: PendingFile = this.pendingFiles[index];
      formData.append(file.folderName, file.file);
    }

    this.clientsService
      .uploadClientYearFiles(formData, clientYearDetails.id, this.clientType)
      .subscribe({
        next: (createdYearDetails: ClientYearDetails) => {
          this.generalCompanyDetailsForm.reset();
          this.yearDetailsForm.reset();
          this.directorsForm.reset();
          this.membersForm.reset();
          this.filesForm.reset();
          this.clientIsSaving = false;
          this.snackBar.open(`${clientYearDetails.year} saved`, '', {
            duration: 3000,
          });
          this.router.navigateByUrl(
            `/clients/${this.clientType}/details/${this.client.value.id}`
          );
          this.jwtService.destroyContentType();
        },
        error: (error) => {
          console.log(error);
          this.generalCompanyDetailsForm.enable();
          this.yearDetailsForm.enable();
          this.directorsForm.enable();
          this.membersForm.enable();
          this.filesForm.enable();
          this.clientIsSaving = false;
          this.snackBar.open(error, '', {
            duration: 3000,
          });
          this.jwtService.destroyContentType();
        },
      });
  }

  convertStringDate(stringDate: string): string[] {
    return stringDate.split('/');
  }

  getYearEndField(): string | null {
    if (
      this.generalCompanyDetailsForm.get('yearEndDay')?.value &&
      this.generalCompanyDetailsForm.get('yearEndMonth')?.value
    ) {
      return (
        this.generalCompanyDetailsForm.get('yearEndDay')?.value +
        '/' +
        this.generalCompanyDetailsForm.get('yearEndMonth')?.value
      );
    }
    return null;
  }

  transformDateToString(date: Date): string | null {
    return this.datepipe.transform(date, 'yyy-MM-dd 00:00:00');
  }
}
