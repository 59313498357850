<section
  fxLayout="column"
  fxLayoutAlign="center center"
  [style.min-height.px]="contentMinHeight$ | async"
>
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="56px"
    class="padding-24"
    [style.width.px]="contentMaxWidth$ | async"
  >
    <form
      [formGroup]="loginForm"
      (ngSubmit)="loginPressed()"
      fxLayout="column"
      fxLayoutAlign="start stretch"
    >
      <div fxLayout="column" fxLayoutAlign="start center" class="padding-16">
        <img
          src="assets/images/eurogain-logo.png"
          alt="Eurogain Logo"
          width="70%"
        />
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="email" formControlName="email" required />
        <mat-error *ngIf="loginForm.controls['email'].hasError('email')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="loginForm.controls['email'].hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="hidePassword ? 'password' : 'text'"
          formControlName="password"
          (keyup.enter)="loginPressed()"
          required
        />
        <mat-error *ngIf="loginForm.controls['password'].hasError('required')"
          >Password is required</mat-error
        >
        <mat-error *ngIf="loginForm.controls['password'].hasError('minlength')"
          >Password needs to be at least 8 character long</mat-error
        >
        <button
          mat-icon-button
          matSuffix
          type="button"
          (click)="hidePassword = !hidePassword"
        >
          <mat-icon>{{
            hidePassword ? "visibility" : "visibility_off"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="isLoggingIn"
      >
        Login
      </button>
    </form>
    <a routerLink="/forgot-password">Forgot your password?</a>
  </div>
</section>
