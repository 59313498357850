import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Client } from 'src/app/shared/models/client/client.model';

@Component({
  selector: 'app-populate-form-dialog',
  templateUrl: './populate-form-dialog.component.html',
  styleUrls: ['./populate-form-dialog.component.scss'],
})
export class PopulateFormDialogComponent implements OnInit {
  client: Client;
  departmentsStr: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.client = data.client;
    this.departmentsStr = data.departments;
  }

  ngOnInit(): void {}
}
